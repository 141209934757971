<template>
    <div style="white-space: nowrap" v-flex>
      <v-btn icon>
          <v-icon>search</v-icon>
      </v-btn>
      <div :class="{'searching--closed': !searching}" class="searching">
          <v-text-field
            ref="search"
            v-model="search"
            append-icon="close"
            label="Pesquisa"
            hide-details
            single-line
            color="white"
          />
      </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                search: '',
                searching: true
            }
        }
    }
</script>

<style scoped>
.searching {
  overflow: hidden;
  width: 208px;
  padding-left: 8px;
  transition: 0.3s cubic-bezier(.25,.8,.50,1);
}

.searching--closed {
  padding-left: 0;
  width: 0;
}

.searching > * {
  right: 8px;
}

.searching--closed > * {
  display: none;
}
</style>