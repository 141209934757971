<template>
  <div>

    <v-container grid-list-md>
      <v-layout row wrap>
      <v-subheader>{{ titulo }}</v-subheader>
        <v-flex xs12 v-for="(arquivo, indice) in arquivos" :key="indice">
           <v-card>
            <v-list>
                <v-list-tile avatar>

                  <v-list-tile-avatar>
                    <v-icon class="grey lighten-2">insert_drive_file</v-icon>
                  </v-list-tile-avatar>

                  <v-list-tile-content>
                    <v-list-tile-title>{{ arquivo.descricao }}</v-list-tile-title>
                    <v-list-tile-sub-title>{{ arquivo.created_at | dataBR}}</v-list-tile-sub-title>
                    <v-list-tile-sub-title>{{ arquivo.nome }} ({{ arquivo.tamanho / 1024 }} kb)</v-list-tile-sub-title>
                  </v-list-tile-content>

                  <v-list-tile-action>
                    <v-progress-circular v-if="arquivo.processando && !arquivo.processado" :width="2" :size="22" indeterminate color="primary"></v-progress-circular>
                  </v-list-tile-action>

                  <v-list-tile-action v-if="arquivo.processado">
                    <v-tooltip top>
                      <v-icon slot="activator" color="green">check_circle</v-icon>
                      <span>Processado</span>
                    </v-tooltip>
                  </v-list-tile-action>

                  <v-list-tile-action v-if="!arquivo.processado">
                    <v-tooltip top>
                      <v-btn slot="activator" icon ripple @click="processar(arquivo)" :disabled="arquivo.processando">
                        <v-icon color="grey">play_circle_outline</v-icon>
                      </v-btn>
                      <span>Processar</span>
                    </v-tooltip>
                  </v-list-tile-action>

                  <v-list-tile-action>
                    <v-tooltip top>
                      <v-btn slot="activator" icon ripple :href="baseURL + '/arquivos/'+arquivo.id+'/download'">
                        <v-icon color="grey">cloud_download</v-icon>
                      </v-btn>
                      <span>Download</span>
                    </v-tooltip>
                  </v-list-tile-action>

                  <v-list-tile-action>
                    <v-tooltip top>
                      <v-btn slot="activator" icon ripple @click="abrirDialogoExcluir(arquivo)" :disabled="arquivos.indexOf(arquivo) != 0">
                        <v-icon color="grey">delete</v-icon>
                      </v-btn>
                      <span>Excluir</span>
                    </v-tooltip>
                  </v-list-tile-action>

                </v-list-tile>
            </v-list>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>

    <z-dialogo ref="dialogoExcluir" titulo="Deseja excluir esse arquivo?">
        <div slot="texto">
          {{ this.arquivo.descricao }} <br>
          {{ this.arquivo.nome }}
        </div>
        <v-btn color="primary darken-1" flat @click="confirmarExcluir" :loading="loading" :disabled="loading">Excluir</v-btn>
        <v-btn color="grey darken-1" flat @click="$refs.dialogoExcluir.fechar()" :disabled="loading">Cancelar</v-btn>
    </z-dialogo>

  </div>
</template>

<script>
  import config from "@/app/config";

  export default {
    props: {
      titulo: {
        type: String
      },
      arquivos: {
        type: Array
      }
    },

    data() {
      return {
        loading: false,
        arquivo: {},
        baseURL: config.BASE_URL
      }
    },

    mounted () {
      this.$store.commit('SET_TELA', 'Arquivos');
    },

    methods: {
      processar(arquivo) {
        arquivo.processando = true;
        this.$axios.post('/arquivos/' + arquivo.id + '/processar')
          .then(response => {
            arquivo.processado = true;
            this.$emit('processado');
            console.log(response.data);
          })
          .catch(error => {
            arquivo.processando = false;
            console.log(error.response);
          })
      },

      abrirDialogoExcluir(arquivo) {
        this.arquivo = arquivo;
        this.$refs.dialogoExcluir.abrir();
      },

      confirmarExcluir() {
        this.loading = true;
        this.$axios.delete('/arquivos/' + this.arquivo.id)
          .then(response => {
            this.excluirArquivo();
            this.$refs.dialogoExcluir.fechar();
            this.loading = false;
          })
          .catch(({response}) => {
            console.log(response.data);
            this.loading = false;
          })
      },

      excluirArquivo() {
        let indice = this.arquivos.findIndex(arquivo => arquivo.id == this.arquivo.id);
        this.arquivos.splice(indice, 1);
      },

      downloadArquivo() {

      },

      transformarArquivos(arquivos) {
        return arquivos.map(arquivo => {
          arquivo.processando = false;
          return arquivo;
        });
      }
    }
  }
</script>

<style scoped>

</style>
