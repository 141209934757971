<template>
  <z-dialogo
    persistent
    ref="dialogo"
    titulo="Substituição Nota Fiscal"
    largura="900px"
    altura_="600px"
    dividers
    scrollable
  >

    <template v-slot:texto>
      <v-form v-model="form_valido" ref="form" lazy-validation>
        <v-container fluid pa-0>
          <div class="font-weight-bold">Nota Fiscal Cancelada</div>
          <v-layout row>
            <v-flex>
              <v-text-field
                v-model="nota_fiscal_cancelada.nfse_numero"
                label="Número NFS-e"
                box
                hide-details
                readonly
              ></v-text-field>
            </v-flex>
            <v-flex>
              <v-text-field
                v-model="nota_fiscal_cancelada.nfse_codigo_verificacao"
                label="Código Verificação"
                box
                hide-details
                readonly
              ></v-text-field>
            </v-flex>
            <v-flex>
              <z-date-picker-menu
                v-model="nota_fiscal_cancelada.nfse_data_emissao"
                label="Data de Emissão"
                box
                hide-details
                readonly
              ></z-date-picker-menu>
            </v-flex>
            <v-flex>
              <z-campo-valor
                v-model="nota_fiscal_cancelada.servico_valor_liquido_nfse"
                label="Valor Líquido"
                box
                hide-details
                :rules="[regras.maiorquezero]"
              ></z-campo-valor>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex sm3>
              <z-cpf-cnpj
                v-model="nota_fiscal_cancelada.tomador_cpfcnpj"
                label="CPF/CNPJ"
                box
                hide-details
                readonly
              ></z-cpf-cnpj>
            </v-flex>
            <v-flex sm9>
              <v-text-field
                v-model="nota_fiscal_cancelada.tomador_razao_social"
                label="Tomador"
                box
                hide-details
                readonly
              ></v-text-field>
            </v-flex>

          </v-layout>

          <v-divider class="my-3"></v-divider>

          <div class="font-weight-bold">Nota Fiscal Substituta</div>

          <v-layout row>
            <v-flex sm4>
              <v-text-field
                v-model="nota_substituta.nfse_numero"
                label="Número NFS-e"
                box
                hide-details
                autofocus
                :rules="[regras.obrigatorio]"
                :disabled="buscando"
                :loading="buscando"
              ></v-text-field>
            </v-flex>
            <v-flex sm4>
              <v-text-field
                v-model="nota_substituta.nfse_codigo_verificacao"
                label="Código de Verificação"
                box
                hide-details
                :rules="[regras.obrigatorio]"
              ></v-text-field>
            </v-flex>
            <v-flex sm4>
              <z-date-picker-menu
                v-model="nota_substituta.nfse_data_emissao"
                label="Data de Emissão"
                box
                hide-details
                :rules="[regras.obrigatorio]"
              ></z-date-picker-menu>
            </v-flex>
            <v-flex sm4>
              <z-campo-valor
                v-model="nota_substituta.servico_valor_liquido_nfse"
                label="Valor Líquido"
                box
                hide-details
                :rules="[regras.maiorquezero]"
              ></z-campo-valor>
            </v-flex>
          </v-layout>
          <v-layout row wrap>
            <v-flex sm12>
              <v-select
                v-model="nota_substituta.emitente_id"
                box
                label="Emitente"
                hide-details
                readonly
                :items="emitentes"
                :rules="[regras.obrigatorio]"
              ></v-select>
            </v-flex>
            <v-flex sm12>
              <v-autocomplete
                v-model="nota_substituta.tomador_id"
                box
                label="Tomador"
                hide-details
                :items="tomadores"
                :rules="[regras.obrigatorio]"
              ></v-autocomplete>
            </v-flex>
          </v-layout>
        </v-container>
      </v-form>
    </template>


    <v-btn flat @click="fechar" :disabled="loading">{{ nota_fiscal_cancelada.status == 2 ? 'Fechar' : 'Cancelar' }}</v-btn>
    <v-btn color="primary" @click="substituir" v-show="nota_fiscal_cancelada.status == 1" :disabled="!form_valido" :loading="loading">Substituir</v-btn>


  </z-dialogo>
</template>

<script>
  import { formataCPFCNPJ, valorUS } from '@/helpers/formatacoes';

  import notasFicaisApi from '@/services/notasfiscais'
  export default {
    name: 'CancelamentoNotaFiscal',

    inject: ['rootRefs'],

    data() {
      return {
        // nota_substituta: {
        //   nfse_numero: null,
        //   nfse_codigo_verificacao: null,
        //   nfse_valor_liquido: null,
        //   nfse_data_emissao: null,
        //   tomador_id: null,
        //   emitente_id: null,
        // },

        buscando: false,
        loading: false,
        form_valido: true,
        regras: {
          obrigatorio: v => !!v || 'Campo obrigatório',
          maiorquezero: v => valorUS(v) > 0 || 'O valor é orbigatório'
        },
      }
    },

    computed: {
      emitentes() {
        return this.$store.state.listas.emitentes.map(emitente => {
          return {
            'text': emitente.razao,
            'value': emitente.id,
            'cnpj': emitente.cnpj,
            'ibpt': emitente.ibpt,
            'simples_nacional': emitente.simples_nacional,
          }
        })
      },

      tomadores() {
        return this.$store.state.listas.tomadores.map(tomador => {
          return {
            'text': tomador.razao,
            'value': tomador.id,
            'cnpj': tomador.cpfcnpj,
          }
        })
      },

      nota_fiscal_cancelada() {
        return this.$store.state.notasfiscais.nota_fiscal_cancelada;
      },

      nota_substituta() {
        return this.$store.state.notasfiscais.nota_substituta;
      }
    },

    methods: {
      async abrir() {
        this.nota_substituta.emitente_id = this.nota_fiscal_cancelada.emitente_id;
        this.$refs.dialogo.abrir();
      },

      fechar() {
        this.limparNotaSubstituta()
        this.$refs.dialogo.fechar();
        setTimeout(() => {
          this.$refs.form.resetValidation();
        }, 100);
      },

      async substituir() {

        if (!this.$refs.form.validate()) {
          return false;
        }

        try {
          this.loading = true;
          const nota_fiscal = await notasFicaisApi.substituirNota({
            nota_cancelada: this.nota_fiscal_cancelada,
            nota_substituta: this.nota_substituta
          });

          this.fechar();
          this.limparNotaSubstituta();
          this.$emit('onsubstituir');

        } catch (error) {
          this.rootRefs.notifications.notificarErro('Nota fiscal não encontrada.')
        } finally {
          this.loading = false;
        }
      },

      async abrirNota(nfse_numero) {

        if (nfse_numero == this.nota_fiscal_cancelada.nfse_numero) {
          this.rootRefs.notifications.notificar('O número da nota fiscal precisa ser diferente da nota cancelada.');
          return false;
        }

        try {
          this.limparNotaSubstituta();
          this.buscando = true;
          const nota_fiscal = await notasFicaisApi.findByNfseNumero(nfse_numero);

          // this.nota_substituta.emitente_id = nota_fiscal.emitente_id;
          // this.nota_substituta.nfse_data_emissao = nota_fiscal.nfse_data_emissao.split("-").reverse().join("/");
          // this.nota_substituta.tomador_cpfcnpj = formataCPFCNPJ(nota_fiscal.tomador_cpfcnpj);
          // this.nota_substituta.tomador_razao_social = nota_fiscal.tomador_razao_social;

        } catch (error) {
          this.rootRefs.notifications.notificarErro('Nota fiscal não encontrada.')
        } finally {
          this.buscando = false;
        }
      },

      limparNotaSubstituta() {
        this.$store.commit('notasfiscais/RESET_NOTAS');
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>
