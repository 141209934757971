<template>
  <div v-scroll="onScroll" id="topo">

    <v-container>

      <v-layout row wrap justify-center>
        <v-flex sm12 xl10>

            <v-layout row wrap>
              <v-subheader>Faturamento</v-subheader>
            </v-layout>
            <v-card>
              <v-container grid-list-md>
                <v-layout row>
                  <v-flex sm2>
                    <v-text-field label="Fatura" :value="fatura.numero" readonly></v-text-field>
                  </v-flex>
                  <v-flex sm10>
                    <v-text-field label="Cliente/Empresa" :value="fatura.clientenome" readonly></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout row>
                  <v-flex sm3>
                    <v-text-field label="CPF/CNPJ" :value="fatura.cliente.cpfcnpj" readonly></v-text-field>
                  </v-flex>
                  <v-flex sm6>
                    <v-text-field label="Contato" :value="fatura.cliente.contato" readonly></v-text-field>
                  </v-flex>
                  <v-flex sm3>
                    <v-text-field label="Telefone" :value="fatura.cliente.telefone" readonly></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex sm12>
                    <v-checkbox label="Optante pelo Simples Nacional" v-model="fatura.cliente.simples" hide-details disabled></v-checkbox>
                  </v-flex>
                  <v-flex sm12>
                    <v-checkbox label="Calcular ISS" v-model="fatura.cliente.iss" hide-details disabled></v-checkbox>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card>

            <v-layout row wrap>
              <v-subheader>Ligações</v-subheader>
            </v-layout>
            <v-data-table :headers="headersLigacoes" :items="fatura.subfaturamentoligacoes" :loading="loadingList" hide-actions class="mb-3 elevation-1" no-data-text="Não houve ligações" disable-initial-sort>
              <v-progress-linear slot="progress" color="primary" height="3" indeterminate></v-progress-linear>
              <template slot="items" slot-scope="props">
                <td>{{ props.item.descricao }}</td>
                <td>{{ props.item.ramal }}</td>
                <td>{{ fatura.periodo }}</td>
                <td class="text-xs-center">{{ props.item.minutos_consumidos }}</td>
                <td class="text-xs-right">
                  <span v-if="props.item.descricao != 'LOCAL'">{{ props.item.valor | valorBR }}</span>
                </td>
              </template>
              <template slot="footer" v-show="fatura.subfaturamentoligacoes.length > 0">
                <td colspan="5" class="text-xs-right"><b>{{ total_ligacoes | valorBR }}</b></td>
              </template>
            </v-data-table>

            <v-layout row wrap>
              <v-subheader>Serviços</v-subheader>
              <v-spacer></v-spacer>
              <v-btn color="primary" small @click="adicionarServico">Adicionar</v-btn>
            </v-layout>
            <v-data-table dense :headers="headersServicos" :items="servicos" :loading="loadingList" hide-actions class="elevation-1" no-data-text="Nenhum serviço registrado..." disable-initial-sort>
              <template slot="items" slot-scope="props">
                <td>{{ props.item.descricao }}</td>
                <td>{{ props.item.tipo | inicialMaiuscula}}</td>
                <td class="text-xs-right">{{ props.item.valor | valorBR }}</td>
                <td class="justify-center layout px-0">
                  <!-- <span v-show="!props.item.id"> -->
                    <v-btn icon class="mx-0" @click="editarServico(props.item)">
                      <v-icon color="grey darken-1">edit</v-icon>
                    </v-btn>
                    <v-btn icon class="mx-0" @click="abrirDialogoExcluir(props.item)">
                      <v-icon color="grey darken-1">delete</v-icon>
                    </v-btn>
                  <!-- </span> -->
                </td>
              </template>
              <template slot="footer" v-show="fatura.subfaturamentoservicos.length > 0">
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td class="text-xs-right"><b>{{ total_servicos | valorBR }}</b></td>
                <td>&nbsp;</td>
              </template>
            </v-data-table>

            <v-layout row wrap>
              <v-subheader>Totais/Impostos</v-subheader>
            </v-layout>
            <v-data-table dense :headers="headersTotais" :items="fatura.subfaturamentoimpostos" :loading="loadingList" hide-actions class="elevation-1" no-data-text="Nenhum totalizador..." disable-initial-sort>
              <template slot="items" slot-scope="props">
                <td>{{ props.item.descricao }}</td>
                <td class="text-xs-right">{{ props.item.valor | valorBR }}</td>
                <td class="text-xs-right">{{ props.item.valor_nf | valorBR }}</td>
                <td class="text-xs-right">{{ props.item.talao | valorBR }}</td>
                <td class="text-xs-right">{{ props.item.tributos | valorBR }}</td>
                <td class="text-xs-right">{{ props.item.iss | valorBR }}</td>
                <td class="text-xs-right">{{ props.item.csll | valorBR }}</td>
                <td class="text-xs-right">{{ props.item.cofins | valorBR }}</td>
                <td class="text-xs-right">{{ props.item.pis | valorBR }}</td>
                <td class="text-xs-right">{{ props.item.ir | valorBR }}</td>
                <td class="text-xs-right">{{ props.item.valor_liquido | valorBR }}</td>
                <td class="justify-center layout px-0">
                  <v-btn icon class="mx-0" @click="editarImposto(props.item)">
                    <v-icon color="grey darken-1">edit</v-icon>
                  </v-btn>
                </td>
              </template>
              <template slot="footer" v-show="fatura.subfaturamentoimpostos.length > 0">
                <td>&nbsp;</td>
                <td class="text-xs-right"><b>{{ total_impostos | valorBR }}</b></td>
                <td colspan="8">&nbsp;</td>
                <td class="text-xs-right"><b>{{ total_liquido | valorBR }}</b></td>
                <td>&nbsp;</td>
              </template>
            </v-data-table>

            <v-layout row wrap class="mt-4">
              <v-btn color="primary" @click="imprimir(fatura)" :disabled="saving">
                <v-icon left>print</v-icon>
                Imprimir
              </v-btn>

              <v-spacer></v-spacer>

              <v-btn flat color="secondary" @click="cancelar" :disabled="saving">Cancelar</v-btn>
              <v-btn color="primary" @click="salvar" :loading="saving">Salvar</v-btn>
            </v-layout>

        </v-flex>
      </v-layout>

    </v-container>

    <div style="height: 100px"></div>

    <v-fab-transition>
      <v-btn color="red darken-1" dark fab fixed bottom right v-show="showfab" @click="$vuetify.goTo('#topo', {duration: 300, offset: 0})">
         <v-icon>keyboard_arrow_up</v-icon>
      </v-btn>
    </v-fab-transition>

    <z-dialogo ref="dialogoServico" titulo="Serviço" largura="500px">
        <v-form slot="texto" ref="form" v-model="valido">
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex sm12>
                <v-text-field label="Descrição" ref="descricao_servico" v-model="servico.descricao" :rules="rules" autofocus></v-text-field>
              </v-flex>
              <v-flex sm6>
                <z-campo-valor label="Valor" ref="servico_valor" v-model="servico.valor"></z-campo-valor>
              </v-flex>
              <v-flex sm6>
                <v-select :items="tipos_servico" label="Tipo" v-model="servico.tipo" single-line></v-select>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
        <v-btn color="grey darken-1" flat @click="cancelarServico">Cancelar</v-btn>
        <v-btn color="primary darken-1" ref="bt_salvar_servico" flat @click="salvarServico">Confirmar</v-btn>
    </z-dialogo>

    <z-dialogo ref="dialogoImposto" :titulo="titulo_impostos" largura="800px">
        <v-form slot="texto" ref="form" v-model="valido">
          <v-container grid-list-md>
            <v-layout row >
              <v-flex>
                <z-campo-valor persistent-hint :hint="'(' + valorBR(config.TRIBUTOS) + '%)'" label="Tributos" v-model="imposto.tributos" @keyup.enter="$refs.iss.focus()" @change="atualizarValorLiquido"></z-campo-valor>
              </v-flex>
              <v-flex >
                <z-campo-valor persistent-hint :hint="'(' + valorBR(config.ISS) +'%)'" label="ISS" ref="iss" v-model="imposto.iss" @keyup.enter="$refs.csll.focus()" @change="atualizarValorLiquido"></z-campo-valor>
              </v-flex>
              <v-flex >
                <z-campo-valor persistent-hint :hint="'('+ valorBR(config.CSLL) +'%)'" label="CSLL" ref="csll" v-model="imposto.csll" @keyup.enter="$refs.cofins.focus()" @change="atualizarValorLiquido"></z-campo-valor>
              </v-flex>
              <v-flex >
                <z-campo-valor persistent-hint :hint="'('+ valorBR(config.COFINS) +'%)'" label="COFINS" ref="cofins" v-model="imposto.cofins" @keyup.enter="$refs.pis.focus()" @change="atualizarValorLiquido"></z-campo-valor>
              </v-flex>
              <v-flex >
                <z-campo-valor persistent-hint :hint="'('+ valorBR(config.PIS) +'%)'" label="PIS" ref="pis" v-model="imposto.pis" @keyup.enter="$refs.ir.focus()" @change="atualizarValorLiquido"></z-campo-valor>
              </v-flex>
              <v-flex >
                <z-campo-valor persistent-hint :hint="'('+ valorBR(config.IR) +'%)'" label="IR" ref="ir" v-model="imposto.ir" @keyup.enter="$refs.liquido.focus()" @change="atualizarValorLiquido"></z-campo-valor>
              </v-flex>
            </v-layout>
            <v-layout row>
              <v-flex >
                <z-campo-valor label="Valor Total" class="input-group--focused" :value="imposto.valor" hide-details readonly></z-campo-valor>
              </v-flex>
              <!-- <v-flex v-show="imposto.tipo == 'internet'">
                <z-campo-valor persistent-hint hint="(90%)" label="Valor NF" class="input-group--focused" :value="imposto.valor_nf" hide-details readonly></z-campo-valor>
              </v-flex>
              <v-flex v-show="imposto.tipo == 'internet'">
                <z-campo-valor persistent-hint hint="(10%)" label="Talão" class="input-group--focused" :value="imposto.talao" hide-details readonly></z-campo-valor>
              </v-flex> -->
              <v-flex >
                <z-campo-valor label="Valor Boleto" class="input-group--focused" ref="liquido" v-model="imposto.valor_liquido" hide-details readonly></z-campo-valor>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
        <v-btn color="primary darken-1" ref="bt_salvar_imposto" flat @click="salvarImposto">Confirmar</v-btn>
        <v-btn color="grey darken-1" flat @click="$refs.dialogoImposto.fechar()">Cancelar</v-btn>
    </z-dialogo>

    <z-dialogo ref="dialogoExcluir" texto="Deseja excluir esse serviço?">
      <v-btn color="grey darken-1" flat @click="$refs.dialogoExcluir.fechar()" :disabled="loading">Cancelar</v-btn>
      <v-btn color="primary darken-1" flat @click="confirmarExcluir" :loading="loading" :disabled="loading">Excluir</v-btn>
    </z-dialogo>
  </div>
</template>

<script>
  import config from "@/app/config";

  import {trunc} from "@/helpers/formatacoes"

  export default {
    props: {
      id: {
        type: [Number, String],
        default: null
      }
    },

    data() {
      return {
        valido: false,
        impostos: false,
        fatura: {
          cliente: {
            impostos: false
          },
          subfaturamentoservicos: [],
          subfaturamentoligacoes: [],
          subfaturamentoimpostos: []
        },
        servico: {
          fatura_id: null,
          id: null,
          descricao: '',
          valor: 0,
          excluir: false
        },
        loading: false,
        loadingList: false,
        saving: false,
        editando: false,
        offsetTop: 0,
        headersLigacoes:[
          { text: 'Descrição', value: 'descricao', width: '150px', sortable: false},
          { text: 'Ramal', value: 'ramal', width: '20px', sortable: false},
          { text: 'Período', value: 'fatura.periodo', sortable: false },
          { text: 'Minutos Consumidos', value: 'minutos_consumidos', align: 'center', width: '50px', sortable: false },
          { text: 'Valor', value: 'valor', align: 'right', width: '150px', sortable: false }
        ],
        headersServicos:[
          { text: 'Descrição', value: 'descricao', sortable: true},
          { text: 'Tipo', value: 'tipo',  width: '100px', sortable: true},
          { text: 'Valor', value: 'total', align: 'right', width: '100px', sortable: true },
          { text: 'Opções', value: '', align: 'center', width: '50px', sortable: false }
        ],
        headersTotais:[
          { text: 'Descrição', value: 'descricao', sortable: false},
          { text: 'Total', value: 'valor', align: 'right', sortable: false },
          { text: 'Valor NF(90%)', value: 'valor_nf', align: 'right', sortable: false },
          { text: 'Talão(10%)', value: 'talao', align: 'right', sortable: false },
          { text: 'Tributos', value: 'tributos', align: 'right', sortable: false },
          { text: 'ISS', value: 'iss', align: 'right', sortable: false },
          { text: 'CSLL', value: 'csll', align: 'right', sortable: false },
          { text: 'COFINS', value: 'cofins', align: 'right', sortable: false },
          { text: 'PIS', value: 'pis', align: 'right', sortable: false },
          { text: 'IR', value: 'ir', align: 'right', sortable: false },
          { text: 'Boleto', value: 'valor_liquido', align: 'right', sortable: false },
          { text: 'Opções', value: '', align: 'center', sortable: false }
        ],
        rules: [],
        totais: [],
        imposto: {},
        titulo_impostos: 'Impostos',
        tipos_servico: [
          {text: 'Telefonia', value: 'telefonia'},
          {text: 'Internet', value: 'internet'},
          {text: 'Outros', value: 'outros'}
        ]
      }
    },

    mounted () {
      this.$store.commit('SET_TELA', 'Faturamento');

      if (this.id) {
        this.abrirFatura();
      }
    },

    methods: {
      abrirFatura() {
        // this.loadingList = true;
        this.$axios.get('/faturas/'+this.id+'/abrir')
          .then(response => {
            // this.loadingList = false;
            this.fatura = response.data;
            this.processarImpostos();
          })
      },

      salvar(){
        this.saving = true;
        this.fatura.total         = this.total_impostos;
        this.fatura.valor_liquido = this.total_liquido;

        this.$axios.put('/faturas/'+ this.id, this.fatura)
          .then(response => {
            this.saving = false;
            this.fatura = response.data;
            this.processarImpostos();
            this.$router.push({path: '/faturamento'});
            // this.$router.push({path: '/clientes'});
          })
          .catch(error => {
            this.saving = false;
          });
      },

      cancelar() {
        this.$router.push({path: '/faturamento'});
      },

      adicionarServico() {
        this.limparServico();
        this.$refs.dialogoServico.abrir();
        this.setFocus('descricao_servico');
      },

      editarServico(servico) {
        this.servico = this.clone(servico);
        this.editando = true;
        this.$refs.dialogoServico.abrir();
        this.setFocus('descricao_servico');
      },

      editarImposto(imposto) {
        this.imposto = this.clone(imposto);
        this.titulo_impostos = 'Impostos - ' + this.imposto.descricao;
        this.editando = true;
        this.$refs.dialogoImposto.abrir();
      },

      salvarImposto() {
        let indice = this.fatura.subfaturamentoimpostos.findIndex(imposto_ => imposto_.id == this.imposto.id);
        this.fatura.subfaturamentoimpostos.splice(indice, 1, this.imposto);
        this.$refs.dialogoImposto.fechar();
      },

      salvarServico() {
        if (!this.servico.id && !this.editando) {
          this.fatura.subfaturamentoservicos.push(this.servico);
        } else {
          let indice = this.fatura.subfaturamentoservicos.findIndex(servico_ => servico_.id == this.servico.id);
          this.fatura.subfaturamentoservicos.splice(indice, 1, this.servico);
        }

        this.atualizarImpostos();
        setTimeout(() => {
          this.reCalcularImpostos();
        }, 1000);


        this.limparServico();
        this.$refs.dialogoServico.fechar();
        this.editando = false;
      },

      cancelarServico() {
        this.limparServico();
        this.$refs.dialogoServico.fechar();
      },

      atualizarValorLiquido() {
        this.$nextTick(() => {
          if (this.imposto.tipo == 'telefonia') {
            this.imposto.valor_liquido = trunc(this.imposto.valor
            - this.imposto.iss
            - this.imposto.csll
            - this.imposto.cofins
            - this.imposto.pis
            - this.imposto.ir
            + parseFloat(this.imposto.talao));
          }

          if (this.imposto.tipo == 'internet') {
            this.imposto.valor_liquido = trunc(this.imposto.valor_nf
            - this.imposto.iss
            - this.imposto.csll
            - this.imposto.cofins
            - this.imposto.pis
            - this.imposto.ir
            + parseFloat(this.imposto.talao));
          }
        })
      },

      calcularImpostos(imposto) {

        imposto.valor_nf = trunc(imposto.valor * 90 / 100);
        imposto.talao    = 0; // imposto.valor - imposto.valor_nf;

        // if (imposto.tipo == 'telefonia') {
            imposto.valor_liquido = imposto.valor;
            imposto.valor_nf      = imposto.valor;
            imposto.talao         = 0
        // } else {
            // imposto.valor_liquido = imposto.valor_nf;
        // }

        imposto.csll     = 0;
        imposto.cofins   = 0;
        imposto.pis      = 0;
        imposto.ir       = 0;
        imposto.iss      = 0;
        imposto.icms     = 0;
        imposto.tributos = 0

        if (imposto.valor >= 215) {
          imposto.csll          = trunc(imposto.valor_nf * (this.config.CSLL / 100));
          imposto.cofins        = trunc(imposto.valor_nf * (this.config.COFINS / 100));
          imposto.pis           = trunc(imposto.valor_nf * (this.config.PIS / 100));
          imposto.valor_liquido = trunc(imposto.valor_liquido - imposto.csll - imposto.cofins - imposto.pis);
        }

        if (imposto.valor >= 670) {
            imposto.ir            = trunc(imposto.valor_nf * (this.config.IR / 100));
            imposto.valor_liquido = trunc(imposto.valor_liquido - imposto.ir);
        }

        if (this.fatura.cliente.iss == 1) {
            imposto.iss           = trunc(imposto.valor_nf * (this.config.ISS / 100));
            imposto.valor_liquido = trunc(imposto.valor_liquido - imposto.iss);
        }

        imposto.icms     = trunc(imposto.talao * (this.config.ICMS / 100));

        // if (imposto.tipo == 'telefonia') {
          imposto.tributos = trunc(imposto.valor_nf * (this.config.TRIBUTOS / 100));
          imposto.valor_nf = 0;
          imposto.talao    = 0;
        // } else {
          // imposto.tributos = imposto.valor_nf * (this.config.TRIBUTOS / 100);
        // }

        imposto.valor_liquido = trunc(imposto.valor_liquido + imposto.talao);

      },

      processarImpostos() {
          this.fatura.subfaturamentoimpostos.map(imposto => {
            if (imposto.tipo == 'telefonia') {
              imposto.descricao = 'Total Telefonia';
            } else {
              imposto.descricao = 'Total Internet';
            }
          });

          // this.fatura.subfaturamentoimpostos.forEach(imposto => {
          //   this.calcularImpostos(imposto);
          // });
      },

      atualizarImpostos() {
        this.fatura.subfaturamentoimpostos.forEach(imposto => {
          if (imposto.tipo == 'telefonia') {
            imposto.valor = this.total_telefonia;
           }

          if (imposto.tipo == 'internet' || imposto.tipo == 'outros') {
            imposto.valor = this.total_internet;
          }
        });
      },

      reCalcularImpostos() {
        this.fatura.subfaturamentoimpostos.forEach(imposto => {
          this.calcularImpostos(imposto);
        });
      },

      limparServico() {
        this.servico = {
          fatura_id: this.fatura.id,
          id: null,
          descricao: '',
          valor: 0,
          tipo: 'internet',
          excluir: false
        }
      },

      abrirDialogoExcluir(servico) {
        this.servico = servico;
        this.$refs.dialogoExcluir.abrir();
      },

      confirmarExcluir() {
        this.servico.excluir = true;
        this.atualizarImpostos();
        this.reCalcularImpostos();
        this.$refs.dialogoExcluir.fechar();
      },

      imprimir(fatura) {
        this.abrirJanela(config.BASE_URL + '/faturas/' + fatura.id + '/imprimir');
      },

      imprimirTodos() {
        this.abrirJanela(config.BASE_URL + '/faturas/'+this.coleta+'/gerar');
      },

      imprimirRelatorio() {
        this.abrirJanela(config.BASE_URL + '/faturas/'+this.coleta+'/relatorio');
      },

      onScroll() {
        this.offsetTop = window.pageYOffset || document.documentElement.scrollTop;
      }
    },

    computed: {
      servicos() {
        return this.fatura.subfaturamentoservicos.filter(servico => {
          return servico.excluir == false;
        });
      },

      showfab() {
        return this.offsetTop > 50;
      },

      config() {
        return this.$store.state.configuracoes;
      },

      total_ligacoes() {
        return this.fatura.subfaturamentoligacoes.reduce((soma, ligacao) => {
          if (ligacao.descricao != 'LOCAL') {
            return soma + ligacao.valor;
          } else {
            return soma + 0;
          }
        }, 0)
      },

      total_telefonia(){
        let servicos_telefonia = this.servicos.reduce((soma, servico) => {
          if (servico.tipo == 'telefonia') {
            return soma + parseFloat(servico.valor);
          } else {
            return soma;
          }
        }, 0);

        return servicos_telefonia + this.total_ligacoes;
      },

      total_internet() {
        return this.servicos.reduce((soma, servico) => {
          if (servico.tipo == 'internet' || servico.tipo == 'outros') {
            return soma + parseFloat(servico.valor);
          } else {
            return soma;
          }
        }, 0);
      },

      total_servicos() {
        return this.servicos.reduce((soma, servico) => {
            return soma + parseFloat(servico.valor);
        }, 0)
      },

      total_impostos() {
        return this.fatura.subfaturamentoimpostos.reduce((soma, impostos) => {
            return soma + parseFloat(impostos.valor);
        }, 0)
      },

      total_liquido() {
        return this.fatura.subfaturamentoimpostos.reduce((soma, impostos) => {
            return soma + parseFloat(impostos.valor_liquido);
        }, 0)
      },

    }
  }
</script>

<style scoped>
  .v-input--checkbox {
    margin-top: 0px !important;
    padding: 0px;
  }
</style>
