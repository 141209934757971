<template>
  <div v-scroll="onScroll" id="topo">

    <v-container>

      <v-layout row wrap justify-center>
        <v-flex sm12 xl10>

          <v-layout row wrap>
            <v-subheader>Ramais</v-subheader>
          </v-layout>

          <v-card>
            <v-toolbar card color="white">
              <div style="width: 400px;">
                <v-text-field autofocus flat solo prepend-icon="search" placeholder="Pesquisar" v-model="search" hide-details></v-text-field>
              </div>

              <v-spacer></v-spacer>

              <v-btn color="primary" icon @click="$refs.dialogoAdicionarRamal.abrir()">
                <v-icon>add</v-icon>
              </v-btn>

            </v-toolbar>

            <v-divider></v-divider>

            <v-data-table dense :search="search" :headers="headers" :items="ramais" :loading="loadingList" class="elevation-1" disable-initial-sort :rows-per-page-items="globals.PAGINACAO">
              <v-progress-linear slot="progress" color="primary" height="3" indeterminate></v-progress-linear>
              <template slot="items" slot-scope="props">
                <td>{{ props.item.ramal }}</td>
                <td>{{ props.item.clientenome }}</td>
                <td class="justify-end align-center layout px-0">
                  <v-tooltip top v-if="props.item.cliente_id && !props.item.processando">
                    <v-btn slot="activator" icon class="mx-0" @click="limpar(props.item)">
                      <v-icon color="grey darken-1">clear</v-icon>
                    </v-btn>
                    <span>Limpar</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <v-btn slot="activator" icon class="mx-0" @click="remover(props.item)">
                      <v-icon color="grey darken-1">delete</v-icon>
                    </v-btn>
                    <span>Excluir</span>
                  </v-tooltip>
                </td>
              </template>
            </v-data-table>
          </v-card>

        </v-flex>
      </v-layout>

    </v-container>

    <div style="height: 100px"></div>

    <v-fab-transition>
      <v-btn color="red darken-1" dark fab fixed bottom right v-show="showfab" @click="$vuetify.goTo('#topo', {duration: 300, offset: 0})">
         <v-icon>keyboard_arrow_up</v-icon>
      </v-btn>
    </v-fab-transition>

    <z-dialogo ref="dialogoExcluir" texto="Deseja excluir esse ramal?">
        <v-btn color="primary darken-1" flat @click="confirmarExcluir" :loading="loading" :disabled="loading">Excluir</v-btn>
        <v-btn color="grey darken-1" flat @click="$refs.dialogoExcluir.fechar()" :disabled="loading">Cancelar</v-btn>
    </z-dialogo>

    <z-dialogo ref="dialogoAdicionarRamal" titulo="Adicionar ramal">
        <v-form slot="texto" ref="form" v-model="valido">
          <v-layout>
              <v-text-field label="Ramal" v-model="ramal.ramal" maxlength="4" :rules="[(v) => !!v && v.length == 4 || 'Ramal inválido']" autofocus></v-text-field>
          </v-layout>
        </v-form>
        <v-btn color="grey darken-1" flat @click="$refs.form.reset(); $refs.dialogoAdicionarRamal.fechar()" :disabled="loading">Cancelar</v-btn>
        <v-btn color="primary darken-1" flat @click="salvar" :loading="loading" :disabled="loading">OK</v-btn>
    </z-dialogo>

  </div>
</template>

<script>
  import globals from "@/app/globals";
  export default {
    props: {

    },

    data() {
      return {
        globals,
        search: '',
        ramal: {
          ramal: ''
        },
        ramais: [],
        loading: false,
        loadingList: false,
        valido: false,
        offsetTop: 0,
        headers:[
          { text: 'Ramal', value: 'ramal', width: '10px', sortable: true},
          { text: 'Empresa/Cliente', value: 'clientenome', sortable: true},
          { text: 'Opções', value: '', align: 'center', width: '10px', sortable: false }
        ],
        // pagination: {
        //   sortBy: 'cliente.clientenome'
        // },
      }
    },

    mounted () {
      this.$store.commit('SET_TELA', 'Ramais');

      this.listarRamais();
    },

    methods: {
      listarRamais() {
        this.loadingList = true;
        this.$axios.get('/ramais')
          .then(response => {
            this.loadingList = false;
            this.ramais = this.transformarRamais(response.data);
          })
          .catch(error => {
            this.loadingList = false;
            console.log('Catch:', error.response.data);
          })
      },

      remover(ramal){
        this.ramal = ramal;
        this.$refs.dialogoExcluir.abrir();
      },

      confirmarExcluir() {
        this.loading = true;
        this.$axios.delete('/ramais/' + this.ramal.id)
          .then(response => {
            this.loading = false;
            let indice = this.ramais.findIndex(ramal => ramal.id == this.ramal.id);
            this.ramais.splice(indice, 1);
            this.$refs.dialogoExcluir.fechar();
          });
      },

      limpar(ramal) {
        ramal.processando = true;
        this.$axios.put('/ramais/' + ramal.id)
          .then(response => {
            ramal.processando = false;
            ramal.cliente_id  = null;
            ramal.clientenome = '(Disponível)';
          });
      },

      salvar() {
        if (this.$refs.form.validate()) {
          this.loading = true;
          console.log('Form válido');
          this.$axios.post('/ramais', this.ramal)
            .then(response => {
              this.loading = false;
              let ramal = response.data;
              ramal.processando = false;
              ramal.cliente_id  = null;
              ramal.clientenome = '(Disponível)';
              this.ramais.push(ramal);
              this.$refs.dialogoAdicionarRamal.fechar();
            });
        } else {
          console.log('Form inválido');
        }
      },

      transformarRamais(ramais) {
        return ramais.map(ramal => {
          ramal.processando = false;
          return ramal;
        });
      },

      onScroll() {
        this.offsetTop = window.pageYOffset || document.documentElement.scrollTop;
      }
    },

    computed: {
      showfab() {
        return this.offsetTop > 50;
      }
    }
  }
</script>

<style scoped>

</style>
