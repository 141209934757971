import axios from '@/plugins/Axios'

function cidades(estado = "PE") {
  return axios.get(`listas/cidades/${estado}`)
    .then(response => response.data);
}

function estados() {
  return axios.get('listas/estados')
    .then(response => response.data);
}

function vencimentos() {
  return axios.get('listas/vencimentos')
    .then(response => response.data);
}

function emitentes() {
  return axios.get('listas/emitentes')
    .then(response => response.data);
}

function tomadores() {
  return axios.get('listas/tomadores')
    .then(response => response.data);
}

function arquivos() {
  return axios.get('listas/arquivos')
    .then(response => response.data);
}


export default {
  cidades,
  estados,
  vencimentos,
  emitentes,
  tomadores,
  arquivos,
}
