<template>
  <div id="topo">
    <v-container grid-list-xs>

      <v-layout row wrap justify-center>
        <v-flex sm12 xl10>

          <v-container>
            <v-layout row wrap>
              <v-subheader>Configurações</v-subheader>
            </v-layout>


            <v-card>
              <v-card-title>
                Impostos
              </v-card-title>
              <v-divider></v-divider>
              <v-container fluid grid-list-md>
                <v-layout>
                  <v-flex sm3>
                    <z-campo-valor box dense hide-details label="Tributos" v-model="impostos.tributos" suffix="%"></z-campo-valor>
                  </v-flex>
                  <v-flex sm3>
                    <z-campo-valor box dense hide-details label="ISS" ref="iss" v-model="impostos.iss" suffix="%"></z-campo-valor>
                  </v-flex>
                  <v-flex sm3>
                    <z-campo-valor box dense hide-details label="CSLL" ref="csll" v-model="impostos.csll" suffix="%"></z-campo-valor>
                  </v-flex>
                  <v-flex sm3>
                    <z-campo-valor box dense hide-details label="COFINS" ref="cofins" v-model="impostos.cofins" suffix="%"></z-campo-valor>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex sm3>
                    <z-campo-valor box dense hide-details label="PIS" ref="pis" v-model="impostos.pis" suffix="%"></z-campo-valor>
                  </v-flex>
                  <v-flex sm3>
                    <z-campo-valor box dense hide-details label="IR" ref="ir" v-model="impostos.ir" suffix="%"></z-campo-valor>
                  </v-flex>
                  <v-flex sm3>
                    <z-campo-valor box dense hide-details label="ICMS" ref="ir" v-model="impostos.icms" suffix="%"></z-campo-valor>
                  </v-flex>
                </v-layout>
              </v-container>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn flat color="primary darken-1" @click="salvar" :loading="salvando">Salvar</v-btn>
                <!-- <v-btn flat color="grey darken-1">Cancelar</v-btn> -->
              </v-card-actions>
            </v-card>
          </v-container>

        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tab: null,
      impostos: {
        tributos: 0,
        iss: 0,
        csll: 0,
        cofins: 0,
        pis: 0,
        ir: 0,
        icms: 0,
        contador_rps: null
      },
      salvando: false
    };
  },

  async mounted() {
    // this.$router.push({path: '/clientes'});
    this.$store.commit('SET_TELA', 'Configurações');

    await this.$store.dispatch('carregarConfiguracoes');

    this.impostos.tributos = this.config.TRIBUTOS;
    this.impostos.iss = this.config.ISS;
    this.impostos.csll = this.config.CSLL;
    this.impostos.cofins = this.config.COFINS;
    this.impostos.pis = this.config.PIS;
    this.impostos.ir = this.config.IR;
    this.impostos.icms = this.config.ICMS;
    this.impostos.contador_rps = this.config.CONTADOR_RPS;
  },

  methods: {
    async salvar() {
      try {
        this.salvando = true;
        await this.$axios.put("configuracoes/salvar", this.impostos);
        this.$store.dispatch("carregarConfiguracoes");
      } finally {
        this.salvando = false;
      }
    }
  },

  computed: {
    config() {
      return this.$store.state.configuracoes;
    }
  }
};
</script>

<style scoped>
</style>
