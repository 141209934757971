import { render, staticRenderFns } from "./EmitirNotaServico.vue?vue&type=template&id=09f84058&scoped=true&"
import script from "./EmitirNotaServico.vue?vue&type=script&lang=js&"
export * from "./EmitirNotaServico.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09f84058",
  null
  
)

export default component.exports