const createLocalStorage = function(config) {

  const { STORAGE_ENCRYPT, APP_PREFIX } = config;

  function set(variavel, valor) {

    if (STORAGE_ENCRYPT) {
      valor = btoa(JSON.stringify(valor));
    } else {
      valor = JSON.stringify(valor);
    }

    localStorage.setItem(APP_PREFIX +"_"+ variavel, valor);
  }

  function get(variavel, padrao = '') {

    if (has(variavel)) {
      let valor = localStorage.getItem(APP_PREFIX +"_"+ variavel);

      if (STORAGE_ENCRYPT) {
        valor = atob(valor); // Desencrita valor
      }

      return JSON.parse(valor);
    }

    return padrao;
  }

  function remove(variavel) {
    localStorage.removeItem(APP_PREFIX +"_"+ variavel);
  }

  function has(variavel) {
    return !!localStorage.getItem(APP_PREFIX +"_"+ variavel);
  }

  async function remember(key, minutes = 0, callback) {
    if (!has(key)) {

      const callback_data = await callback();

      cache_set(key, callback_data, minutes)

      return callback_data;
    }

    const cached_data = get(key);
    const is_expired = cached_data.expires_in < new Date().getTime();

    if (!is_expired) {
      return cached_data.data;
    }

    const callback_data = await callback();
    cache_set(key, callback_data, minutes);
    return callback_data;
  }

  function cache_get(key) {
    if (!has(key)) {
      return undefined;
    }

    const cached_data = get(key);
    const is_expirable = cached_data.expires_in > 0;
    const is_expired = cached_data.expires_in < new Date().getTime();

    if (!is_expirable || !is_expired) {
      return cached_data.data;
    } else {
      remove(key);
      return undefined;
    }
  }

  function cache_set(key, data, minutes = 0) {
    const expiration_time = minutes > 0 ? new Date().getTime() + (60000 * minutes) : minutes;
    set(key, {
      'expires_in': expiration_time,
      'data': data
    })
  }

  return { set, get, remove, has, remember, cache_get, cache_set };
};

export default createLocalStorage;
