export function dataBR (date) {
  return (new Date(date)).toLocaleDateString('pt-br', { timeZone: 'UTC' });
}

export function dataHoraBR(datahora) {
  if (datahora) {
    const data = (new Date(datahora)).toLocaleDateString();
    const hora = (new Date(datahora)).toLocaleTimeString();
    return data + ' ' + hora;
  }

  return null;
}

export function valorBR(valor, decimais = 2) {
  if (valor) {
    return valor.toLocaleString('pt-BR', { minimumFractionDigits: decimais, maximumFractionDigits: decimais } )
  }

  return (0).toLocaleString('pt-BR', { minimumFractionDigits: decimais, maximumFractionDigits: decimais } );
}

export function valorUS(valor) {
  if (valor) {
    let valor_us = valor.replace(/\./g, '').replace(/\,/g, '.');
    return Number(valor_us);
  }

  return 0;
}

export function datasParaIntervaloBR(datas = []) {

  if (!Array.isArray(datas)) {
    return 'Não é um array';
  }

  if (datas.length != 2) {
    return 'Informe um array com duas datas';
  }

  let intervalo = [dataBR(datas[0]), dataBR(datas[1])];
  return intervalo.join(' a ');
}

export function trunc(valor, decimais = 2) {
  let dec = Math.pow(10000000000, decimais);
  return Number((Math.round(valor * dec) / dec).toFixed(decimais));
}


export function formatarCPF(cpf) {
  const cpfRegex = /^(\d{3})(\d{3})(\d{3})(\d{2})$/;
  return cpf.replace(cpfRegex, '$1.$2.$3-$4');
}

export function formatarCNPJ(cnpj) {
  const cnpjRegex = /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/;
  return cnpj.replace(cnpjRegex, '$1.$2.$3/$4-$5');
}

export function formataCPFCNPJ(cpfcnpj) {
  if (cpfcnpj.length > 11) {
    return formatarCNPJ(cpfcnpj);
  }

  return formatarCPF(cpfcnpj);
}

export function onlyNumbers(string) {
  return string.replace(/\D/g, '');
}
