import p from '../../package.json';

const APP_NAME        = 'Cobranca';
const APP_DESCRIPTION = 'Cobrança GFT';
const APP_VERSION     = 'v' + p.version;

const IS_PRODUCTION   = process.env.NODE_ENV === 'production';

const BASE_URL_DEV    = 'http://localhost:8080';
const BASE_URL_PROD   = 'https://gft.etapps.com.br';
const BASE_URL_PRINT_DEV = 'http://localhost:8000';

const BASE_URL       = IS_PRODUCTION ? BASE_URL_PROD : BASE_URL_DEV;
const BASE_URL_PRINT = IS_PRODUCTION ? BASE_URL_PROD : BASE_URL_PRINT_DEV;
const API_URL        = BASE_URL + '/api/v1';

const COOKIE_EXPIRATION_TIME = 24 * 60;

const STORAGE_USER  = APP_NAME + '.user';
const STORAGE_TOKEN = APP_NAME + '.token';

export default {
  APP_NAME,
  APP_DESCRIPTION,
  APP_VERSION,
  BASE_URL,
  BASE_URL_PRINT,
  API_URL,
  IS_PRODUCTION,
  COOKIE_EXPIRATION_TIME,
  STORAGE_USER,
  STORAGE_TOKEN
}
