import axios from 'axios'

import cookie from '@/plugins/Cookie'
import config from '@/app/config'

class AxiosPlugin {

  constructor() {

    this.instance = axios.create({
      baseURL: config.API_URL,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Accept': 'application/json',
        // 'Content-Type': 'application/json'
      }
    });

    this.instance.interceptors.request.use(
      axiosConfig => {
        if (cookie.getCookie('api_token')) {
          axiosConfig.headers.Authorization = 'Bearer ' + cookie.getCookie('api_token');
        }
        return axiosConfig;
      }, 
      error => Promise.reject(error)
    );

  }

  install(Vue, options) {
    Vue.prototype.$axios = this.instance;
  }
}

const axiosPlugin = new AxiosPlugin();

export {axiosPlugin};

export default axiosPlugin.instance;