import moment from 'moment'

import { dataHoraBR } from '../helpers/formatacoes'

export default {
  data() {
      return {
        //   snackbar: false,
        //   mensagem: '',
      }
  },
  methods: {
      clone(object) {
          return JSON.parse(JSON.stringify(object))
      },

      randomInt(min, max) {
          min = Math.ceil(min);
          max = Math.floor(max);
          return Math.floor(Math.random() * (max - min)) + min;
      },

      setNomeTela(context){
          context.$store.commit('SET_NOME_TELA', context.tela);
      },

      formatarData(data) {
          if (!data) {
              return null;
          }
      //   const [ano, mes, dia] = data.split("-");
      //   return `${dia}/${mes}/${ano}`;

        return  data.split("-").reverse().join('/');
      },

      setFocus(field) {
        setTimeout(() => {
            this.$refs[field].focus();
        }, 0)
      },

      mostrarMensagem(mensagem) {
        this.mensagem = mensagem;
        this.snackbar = true;
      },

      corChip(status) {
        switch (status) {
            case 'ATIVO':
                return 'primary'
                break;

            case 'INATIVO':
                return 'red'
                break;

            case 'FINALIZADO':
                return 'green'
                break;

            default:
                break;
        }
      },
      valorBR(valor, decimais) {
        let formataNumero = (number, decimals, dec_point, thousands_sep) => {
          decimals      = typeof decimals      !== 'undefined' ? decimals      : 2;
          dec_point     = typeof dec_point     !== 'undefined' ? dec_point     : ',';
          thousands_sep = typeof thousands_sep !== 'undefined' ? thousands_sep : '.';

          number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
          var n = !isFinite(+number) ? 0 : +number,
              prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
              sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
              dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
              s = '',
              toFixedFix = function(n, prec) {
                  var k = Math.pow(10, prec);
                  return '' + (Math.round(n * k) / k).toFixed(prec);
              };
          // Fix for IE parseFloat(0.55).toFixed(0) = 0;
          s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
          if (s[0].length > 3) {
              s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
          }
          if ((s[1] || '').length < prec) {
              s[1] = s[1] || '';
              s[1] += new Array(prec - s[1].length + 1).join('0');
          }
          return s.join(dec);
        }

        let valorBR = (valor, decimais) => {
          decimais = typeof decimais !== 'undefined' ? decimais : 2;
          if (typeof valor == 'number') {
              return formataNumero(valor, decimais,',','.');
          }


          if (typeof valor == 'string') {
            //   valor = valor.replace('.', '').replace(',', '.');
            //   valor = parseFloat(valor).toFixed(decimais);
            return formataNumero(valor,decimais,',','.');
            //return parseFloat(valor.replace(",", ".")).toFixed(2).replace(".",",")
          }
        }

        return valorBR(valor, decimais);
      },

      abrirJanela(url) {
        window.open(url, '_blank');
      },

      hoje() {
        let data = new Date();
        return data.getFullYear() + '-' + (data.getMonth() + 1).toString().padStart(2, '0') + '-' + data.getDate().toString().padStart(2, '0');
      },

      capitalized(texto = '') {
        if (texto) {
          return texto.charAt(0).toUpperCase() + texto.slice(1);
        }

        return texto;
      }
  },

  filters: {
      inicialMaiuscula(string) {
        if (string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }
        return '';
      },

      mesExtenso(mes) {
        let meses = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro',  'Novembro',  'Dezembro'];
        return meses[mes - 1];
      },

      dataBR(data) {
          if (data) {
              return  moment(data).format('DD/MM/YYYY');
          }

          return '';
      },

      dataHoraBR,

      valorBR(valor, decimais) {
        let formataNumero = (number, decimals, dec_point, thousands_sep) => {
        decimals      = typeof decimals      !== 'undefined' ? decimals      : 2;
        dec_point     = typeof dec_point     !== 'undefined' ? dec_point     : ',';
        thousands_sep = typeof thousands_sep !== 'undefined' ? thousands_sep : '.';

        number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
        var n = !isFinite(+number) ? 0 : +number,
            prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
            sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
            dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
            s = '',
            toFixedFix = function(n, prec) {
                var k = Math.pow(10, prec);
                return '' + (Math.round(n * k) / k).toFixed(prec);
            };
        // Fix for IE parseFloat(0.55).toFixed(0) = 0;
        s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
        if (s[0].length > 3) {
            s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
        }
        if ((s[1] || '').length < prec) {
            s[1] = s[1] || '';
            s[1] += new Array(prec - s[1].length + 1).join('0');
        }
        return s.join(dec);
        }

        let valorBR = (valor, decimais) => {
            decimais = typeof decimais !== 'undefined' ? decimais : 2;
            if (typeof valor == 'number') {
                return formataNumero(valor, decimais,',','.');
            }


            if (typeof valor == 'string') {
            //   valor = valor.replace('.', '').replace(',', '.');
            //   valor = parseFloat(valor).toFixed(decimais);
            return formataNumero(valor,decimais,',','.');
            //return parseFloat(valor.replace(",", ".")).toFixed(2).replace(".",",")
        }
    }

        return valorBR(valor, decimais);
    },

    capitalized(texto = '') {
      if (texto) {
        return texto.charAt(0).toUpperCase() + texto.slice(1);
      }

      return texto;
    }
  }
}
