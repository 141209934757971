import Vue from 'vue'

// Carregar o Vuetify
import Vuetify from 'vuetify'
import 'material-design-icons/iconfont/material-icons.css'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css';


// Carrega o lang para o Vuetify
import pt_BR from '@/lang/pt-br'
import pt from 'vuetify/es5/locale/pt'

import colors from 'vuetify/es5/util/colors'

// Configura o Vuetify
Vue.use(Vuetify, {
  theme: {
    primary: colors.indigo,
    secondary: '#757575',
    accent: '#03A9F4',
    error: '#D32F2F',
    info: '#2196F3',
    success: '#64DD17',
    warning: '#FFC107'
  },
  lang: {
    locales: { pt },
    current: 'pt'
  }
})

import '@/assets/estilos.css';

// Carregar Mixins e Plugins
import mixins from '@/mixins/mixins'
Vue.mixin(mixins);

// Carregar Plugins
import {axiosPlugin} from '@/plugins/Axios'
import {authPlugin} from '@/plugins/Auth'
import {cookiePlugin} from '@/plugins/Cookie'
Vue.use(authPlugin);
Vue.use(axiosPlugin);
Vue.use(cookiePlugin);

// Carregamento global dos zComponents
import zComponents from '@/components/zComponents';
Vue.use(zComponents);

import config from "@/app/config";
Vue.prototype.$config = config;

