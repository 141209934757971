export default {
  data() {
    return {
      dialogo: false,
    }
  },

  methods: {
      abrir() {
        this.dialogo = true;
      },

      fechar() {
        this.dialogo = false;
      },
  },

}
