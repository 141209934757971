<template>
  <div >

    <v-card flat color="grey lighten-2">
      <v-card-text>
        <v-data-table
          dense
          :headers="headers"
          :items="faturamento.subfaturamentoimpostos"
          hide-actions
          class="elevation-1"
          no-data-text="Nenhum dado localizado..."
          disable-initial-sort
        >
          <template slot="items" slot-scope="props">
            <td>{{ props.item.descricao }}</td>
            <!-- <td class="text-xs-right">{{ props.item.valor_nf | valorBR }}</td> -->
            <!-- <td class="text-xs-right">{{ props.item.talao | valorBR }}</td> -->
            <td class="text-xs-right">{{ props.item.tributos | valorBR }}</td>
            <td class="text-xs-right">{{ props.item.iss | valorBR }}</td>
            <td class="text-xs-right">{{ props.item.csll | valorBR }}</td>
            <td class="text-xs-right">{{ props.item.cofins | valorBR }}</td>
            <td class="text-xs-right">{{ props.item.pis | valorBR }}</td>
            <td class="text-xs-right">{{ props.item.ir | valorBR }}</td>
            <td class="text-xs-right text-no-wrap font-weight-bold">{{ props.item.valor | valorBR }}</td>
            <!-- <td class="text-xs-right">{{ props.item.valor_liquido | valorBR }}</td> -->
            <!-- <td class="text-xs-center text-no-wrap"> -->
              <z-status-nfse :situacao="props.item.notafiscal != null ? props.item.notafiscal.rps_situacao : 1">Aguardando</z-status-nfse>
            </td>
            <td class="text-xs-right text-no-wrap">
              <v-tooltip top v-if="props.item.notafiscal != null && props.item.notafiscal.nfse_numero">
                <v-btn slot="activator" icon ripple @click.stop="imprimir(props.item)">
                  <v-icon color="grey darken-1">print</v-icon>
                </v-btn>
                <span>Imprimir</span>
              </v-tooltip>
              <v-btn color="primary" small @click="emitirNFSE(props.item)" :disabled="props.item.notafiscal && (props.item.notafiscal.nfse_numero != null || props.item.notafiscal.rps_situacao == 2)">
                Emitir
              </v-btn>
            </td>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

  </div>
</template>

<script>
  import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

  export default {
    name: 'ImpostosLista',

    props: ['faturamento'],

    data() {
      return {
        headers:[
          { text: 'Descrição', value: 'descricao', sortable: false},
          // { text: 'Valor NF(90%)', value: 'valor_nf', align: 'right', sortable: false },
          // { text: 'Talão(10%)', value: 'talao', align: 'right', sortable: false },
          { text: 'Tributos', value: 'tributos', align: 'right', sortable: false },
          { text: 'ISS', value: 'iss', align: 'right', sortable: false },
          { text: 'CSLL', value: 'csll', align: 'right', sortable: false },
          { text: 'COFINS', value: 'cofins', align: 'right', sortable: false },
          { text: 'PIS', value: 'pis', align: 'right', sortable: false },
          { text: 'IR', value: 'ir', align: 'right', sortable: false },
          { text: 'Total', value: 'valor', align: 'right', width: '10px', sortable: false },
          // { text: 'Boleto', value: 'valor_liquido', align: 'right', sortable: false },
          { text: 'Status NFSE', align: 'center', width: '10px', sortable: false },
          { text: 'Opções', value: '', align: 'center', width: '10px', sortable: false }
        ],
      }
    },

    computed: {

    },

    methods: {
      emitirNFSE(subfatura) {

        // this.faturamento.subfatura_index = subfatura_index;
        // this.$store.commit('notasfiscais/SET_FATURA', this.faturamento);

        if (subfatura.notafiscal && subfatura.notafiscal.subfaturamento_id) {
          this.$router.push({
            name: 'EditarNotaServico',
            params: {
              notafiscal_id: subfatura.notafiscal.id
            }
          })
        } else {
          this.$router.push({
            name: 'NovaNotaServico',
            params: {
              subfatura_id: subfatura.id
            }
          })
        }
      },

      imprimir(subfatura) {
        const nota = subfatura.notafiscal;
        if (this.$config.IS_PRODUCTION) {
          this.abrirJanela(`http://visualizar.ginfes.com.br/report/consultarNota?__report=nfs_ver4&cdVerificacao=${nota.nfse_codigo_verificacao}&numNota=${nota.nfse_numero}&cnpjPrestador=null`)
        } else {
          this.abrirJanela(`http://caruaru.ginfesh.com.br/report/consultarNota?__report=nfs_ver4&cdVerificacao=${nota.nfse_codigo_verificacao}&numNota=${nota.nfse_numero}&cnpjPrestador=null`)
        }
      }
    },

    watch: {

    },
  }
</script>

<style lang="scss" scoped>

</style>
