<template>
  <v-snackbar bottom elevation="24" :timeout="timeout" :color="cor" v-bind="$attrs" v-model="snackbar" multi-line @input="chamarCallback">
    <span v-html="mensagem"></span>
      <v-btn flat @click.native="snackbar = false; chamarCallback()" :color="corBotao">Fechar</v-btn>
  </v-snackbar>
</template>

<script>
  export default {
    name: 'z-notificacao',

    data(){
      return {
        snackbar: false,
        mensagem: '',
        cor     : '',
        timeout : 3000,
        callback: null
      }
    },

    methods: {
      exibir(opcoes, callback) {
        this.mensagem = opcoes.mensagem || '';
        this.cor      = opcoes.cor || 'primary';
        this.timeout  = opcoes.timeout != null ? opcoes.timeout : 3000;
        this.callback = opcoes.callback || null;

        this.snackbar = true;
      },

      fechar() {
          this.snackbar = false;
      },

      chamarCallback() {
        if (this.callback != null) {
          this.callback();
        }
      }
    },
    computed: {
      corBotao() {
        return this.cor == 'default' || this.cor == '' ? 'primary' : 'default';
      }
    }

  }
</script>

<style>

</style>
