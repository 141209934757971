<template>
  <div>

    <v-subheader>{{ titulo }}</v-subheader>

    <v-card>
      <v-toolbar card color="white">
        <div style="width: 400px;">
          <v-text-field autofocus flat solo prepend-icon="search" placeholder="Pesquisar" v-model="search" hide-details></v-text-field>
        </div>
        <v-btn v-show="exibir_botao" color="primary" dark absolute bottom right fab @click="$emit('novo')">
            <v-icon>add</v-icon>
        </v-btn>
      </v-toolbar>

      <v-divider></v-divider>
      <v-data-table dense :headers="headers" :search="search" :items="arquivos" hide-actions class="elevation-1" no-data-text="Carregando os dados..." disable-initial-sort>
        <template slot="items" slot-scope="props">
          <!-- <td>{{ props.item.descricao }}</td> -->
          <td>{{ props.item.created_at  | dataHoraBR }}</td>
          <!-- <td>{{ props.item.mes | mesExtenso}}/{{ props.item.ano }}</td> -->
          <!-- <td class="text-xs-center">{{ props.item.dia_vencimento }}</td> -->
          <td>{{ props.item.nome }}</td>
          <td>{{ props.item.tamanho / 1024 }} kb</td>
          <td class="justify-center align-center layout px-0">
            <v-progress-circular v-if="props.item.processando && !props.item.processado" :width="2" :size="22" indeterminate color="primary"></v-progress-circular>

            <v-tooltip top v-if="props.item.processado">
              <v-btn slot="activator" icon>
                <v-icon color="green">check_circle</v-icon>
              </v-btn>
              <span>Processado</span>
            </v-tooltip>

            <!-- <v-tooltip top v-if="!props.item.processado">
              <v-btn slot="activator" icon ripple @click="processar(props.item)" :disabled="props.item.processando">
                <v-icon color="grey">play_circle_outline</v-icon>
              </v-btn>
              <span>Processar</span>
            </v-tooltip> -->

            <v-tooltip top>
              <v-btn slot="activator" icon ripple :href="baseURL + '/arquivos/'+props.item.id+'/download'">
                <v-icon color="grey">cloud_download</v-icon>
              </v-btn>
              <span>Download</span>
            </v-tooltip>

            <v-tooltip top>
              <v-btn slot="activator" icon ripple @click="abrirDialogoExcluir(props.item)" :disabled="arquivos.indexOf(props.item) != 0">
                <v-icon color="grey">delete</v-icon>
              </v-btn>
              <span>Excluir</span>
            </v-tooltip>
          </td>
        </template>
      </v-data-table>
    </v-card>

    <z-dialogo ref="dialogoExcluir" titulo="Deseja excluir esse arquivo?" largura="600px">
        <div slot="texto">
          {{ this.arquivo.descricao }} <br>
          {{ this.arquivo.nome }}
        </div>
        <v-btn color="primary darken-1" flat @click="confirmarExcluir" :loading="loading" :disabled="loading">Excluir</v-btn>
        <v-btn color="grey darken-1" flat @click="$refs.dialogoExcluir.fechar()" :disabled="loading">Cancelar</v-btn>
    </z-dialogo>

  </div>
</template>

<script>
  import config from "@/app/config";

  export default {
    props: {
      titulo: {
        type: String
      },
      arquivos: {
        type: Array
      },
      exibir_botao: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        search: '',
        loading: false,
        arquivo: {},
        baseURL: config.BASE_URL,

        headers:[
          // { text: 'Descrição', value: 'descricao', sortable: false},
          { text: 'Data envio', value: 'created_at', sortable: false},
          // { text: 'Mês/Ano', value: 'mes', sortable: false },
          // { text: 'Dia Vencimento', value: 'dia_vencimento', align: 'center', sortable: false },
          { text: 'Arquivo', value: 'nome', sortable: false },
          { text: 'Tamanho', value: 'tamanho', sortable: false },
          { text: 'Opções', value: '', align: 'center', sortable: false }
        ],
      }
    },

    mounted () {
      this.$store.commit('SET_TELA', 'Arquivos');
    },

    methods: {
      processar(arquivo) {
        arquivo.processando = true;
        this.$axios.get('/arquivos/' + arquivo.id + '/processar')
          .then(response => {
            arquivo.processado = true;
            this.$emit('processado');
            console.log(response.data);
          })
          .catch(error => {
            arquivo.processando = false;
            console.log(error.response);
          })
      },

      abrirDialogoExcluir(arquivo) {
        this.arquivo = arquivo;
        this.$refs.dialogoExcluir.abrir();
      },

      confirmarExcluir() {
        this.loading = true;
        this.$axios.delete('/arquivos/' + this.arquivo.id)
          .then(response => {
            this.excluirArquivo();
            this.$refs.dialogoExcluir.fechar();
            this.loading = false;
          })
          .catch(({response}) => {
            console.log(response.data);
            this.loading = false;
          })
      },

      excluirArquivo() {
        let indice = this.arquivos.findIndex(arquivo => arquivo.id == this.arquivo.id);
        this.arquivos.splice(indice, 1);
      },

      downloadArquivo() {

      },

      transformarArquivos(arquivos) {
        return arquivos.map(arquivo => {
          arquivo.processando = false;
          return arquivo;
        });
      }
    }
  }
</script>

<style scoped>

</style>
