<template>
  <v-slide-y-reverse-transition>
    <v-card dark class="primary elevation-10 posicao-card cor_card">
      <v-card-text>
        <h3 class="headline"><b>Breakpoint</b>: {{ $vuetify.breakpoint.name }} ({{ $vuetify.breakpoint.width }}px)</h3>
        <h3 class="headline"><b>Route</b>: {{ $route.name }}</h3>
        <h3 class="headline"><b>Path</b>: {{ $route.path }}</h3>
        <!-- <h3 class="headline">Mouse position is at: {{ x }}, {{ y }}</h3> -->
      </v-card-text>
    </v-card>
  </v-slide-y-reverse-transition>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>

.cor_card {
  background-image: linear-gradient(90deg, #3F51B5, #283593) !important;
}

.posicao-card {
  opacity: 0.6;
  position: fixed;
  right: 20px;
  bottom: 50px;
}

</style>
