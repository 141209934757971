<template>
  <v-list dense>

    <v-list-tile>
      <v-list-tile-content>
        <v-list-tile-title>CADASTROS</v-list-tile-title>
      </v-list-tile-content>
    </v-list-tile>

    <v-list-tile to="/clientes">
      <v-list-tile-action>
        <v-icon>contacts</v-icon>
      </v-list-tile-action>
      <v-list-tile-content>
        <v-list-tile-title>Clientes</v-list-tile-title>
      </v-list-tile-content>
    </v-list-tile>

    <v-list-tile to="/planos">
      <v-list-tile-action>
        <v-icon>assignment_turned_in</v-icon>
      </v-list-tile-action>
      <v-list-tile-content>
        <v-list-tile-title>Planos de Serviço</v-list-tile-title>
      </v-list-tile-content>
    </v-list-tile>

    <v-list-tile to="/ramais">
      <v-list-tile-action>
        <v-icon>phone</v-icon>
      </v-list-tile-action>
      <v-list-tile-content>
        <v-list-tile-title>Ramais</v-list-tile-title>
      </v-list-tile-content>
    </v-list-tile>

    <v-list-tile style="margin-top: 20px">
      <v-list-tile-content>
        <v-list-tile-title>MOVIMENTAÇÃO</v-list-tile-title>
      </v-list-tile-content>
    </v-list-tile>

    <v-list-tile to="/faturamento">
      <v-list-tile-action>
        <v-icon>mdi-account</v-icon>
      </v-list-tile-action>
      <v-list-tile-content>
        <v-list-tile-title>Faturamento</v-list-tile-title>
      </v-list-tile-content>
    </v-list-tile>

    <v-list-tile to="/arquivos">
      <v-list-tile-action>
        <v-icon>insert_drive_file</v-icon>
      </v-list-tile-action>
      <v-list-tile-content>
        <v-list-tile-title>Arquivos</v-list-tile-title>
      </v-list-tile-content>
    </v-list-tile>

    <v-list-tile style="margin-top: 20px">
      <v-list-tile-content>
        <v-list-tile-title>NFSE</v-list-tile-title>
      </v-list-tile-content>
    </v-list-tile>

    <v-list-tile :to="{name: 'NotasFiscaisLista'}">
      <v-list-tile-action>
        <v-icon>receipt</v-icon>
      </v-list-tile-action>
      <v-list-tile-content>
        <v-list-tile-title>Notas Fiscais</v-list-tile-title>
      </v-list-tile-content>
    </v-list-tile>

    <v-list-tile to="/cadastros/emitentes">
      <v-list-tile-action>
        <v-icon>group</v-icon>
      </v-list-tile-action>
      <v-list-tile-content>
        <v-list-tile-title>Emitentes</v-list-tile-title>
      </v-list-tile-content>
    </v-list-tile>


    <v-divider></v-divider>

    <v-list-tile to="/configuracoes">
      <v-list-tile-action>
        <v-icon>settings</v-icon>
      </v-list-tile-action>
      <v-list-tile-content>
        <v-list-tile-title>Configurações</v-list-tile-title>
      </v-list-tile-content>
    </v-list-tile>

    <v-list-tile @click="sair">
      <v-list-tile-action>
        <v-icon>power_settings_new</v-icon>
      </v-list-tile-action>
      <v-list-tile-content>
        <v-list-tile-title>Sair</v-list-tile-title>
      </v-list-tile-content>
    </v-list-tile>

    <!-- <v-divider></v-divider> -->


    <!-- <v-list-group> -->

      <!-- <v-list-tile slot="activator"> -->
      <!-- <v-list-tile style="margin-top: 20px">
          <v-list-tile-content>
            <v-list-tile-title>CONFIGURAÇÕES</v-list-tile-title>
          </v-list-tile-content>
      </v-list-tile>


      <v-list-tile>
        <v-list-tile-action>
          <v-icon>group</v-icon>
        </v-list-tile-action>
        <v-list-tile-content>
          <v-list-tile-title>Usuários</v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>

      <v-list-tile>
        <v-list-tile-action>
          <v-icon>lock</v-icon>
        </v-list-tile-action>
        <v-list-tile-content>
          <v-list-tile-title>Permissões</v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile> -->

    <!-- </v-list-group> -->

  </v-list>
</template>

<script>
  export default {
    methods: {
      sair() {
        setTimeout(() => {
          this.$auth.logout();
          this.$router.push('/login');
        }, 1000);
      }
    },
  }
</script>

<style scoped>

</style>
