// import axios from '@/plugins/Axios'
// import cookie from '@/plugins/Cookie'
// import config from '@/config.js'

// const request = axios.create({
//   baseURL: config.API_URL,
//   token: cookie.getCookie('api_token')
// });

import axios from '@/plugins/Axios';
import qs from 'qs';

export default {
  namespaced: true,

  state: {
    fatura: {},
    faturas: [],
    coletas: [],
    busca: '',
    filtro: {
      coleta_id: null,
      dia_vencimento: 'Todos',
      tipo_cliente: 'todos'
    }
  },

  getters: {
    // faturasFiltro(state) {
    //   return state.faturas.filter(fatura => {
    //     let index = fatura.clientenome.toUpperCase().indexOf(state.busca.toUpperCase());
    //     if (index > -1) {
    //       return fatura;
    //     }
    //   })
    // },
  },

  mutations: {
    SET_FATURA(state, fatura) {
      state.fatura = fatura;
    },

    SET_FATURAS(state, faturas) {
      state.faturas = faturas;
    },

    SET_COLETAS(state, coletas) {
      state.coletas = coletas;
    },

    SET_BUSCA(state, busca) {
      state.busca = busca;
    },

    SET_FILTRO(state, filtro) {
      state.filtro = filtro;
    }
  },

  actions: {
    async listarColetas({commit}) {
      // if (state.faturas.length == 0) {
        let coletas = await axios.get('/coletas').then(response => response.data)
        commit('SET_COLETAS', coletas);
      // }
    },

    async listarFaturas({commit}, filtro) {
      // if (state.faturas.length == 0) {
        let faturas = await axios.get('/faturas/' + filtro.coleta_id + '?=' + qs.stringify(filtro)).then(response => response.data)
        commit('SET_FATURAS', faturas);
      // }
    }
  }
}
