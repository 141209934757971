<template>
  <div v-scroll="onScroll" id="topo">

    <EmitenteForm
      ref="diaglogoEmitente"
      :emitente="emitente"
      @onSalvar="onSalvar"
      @onAtualizar="onAtualizar"
    ></EmitenteForm>

    <z-loading :loading="loadingList" height="100px" color="indigo"></z-loading>

    <v-container v-show="!loadingList">
      <v-layout row wrap justify-center>
        <v-flex sm12 xl10>

          <v-subheader>Emitentes</v-subheader>

          <v-card>
            <v-toolbar card color="white">
              <div style="width: 400px;">
                <v-text-field autofocus flat solo prepend-icon="search" placeholder="Pesquisar" v-model="search" hide-details></v-text-field>
              </div>

              <v-spacer></v-spacer>

              <v-btn color="primary" icon @click="adicionar">
                  <v-icon>add</v-icon>
              </v-btn>

            </v-toolbar>

            <v-divider></v-divider>

            <v-data-table
              class="elevation-1"
              :search="search"
              :headers="headers"
              :items="emitentes"
              :rows-per-page-items="globals.PAGINACAO"
            >
              <template slot="items" slot-scope="props">
                <td>{{ props.item.razao }}</td>
                <td>{{ props.item.cnpj }}</td>
                <td class="text-xs-center">{{ props.item.simples_nacional ? 'Sim' : 'Não' }}</td>
                <td class="justify-center align-center layout px-0">
                  <v-tooltip top>
                    <v-btn slot="activator" icon @click="editar(props.item)">
                      <v-icon color="grey darken-1">edit</v-icon>
                    </v-btn>
                    <span>Editar</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <v-btn
                      slot="activator"
                      icon
                      ripple
                      @click.stop="abrirDialogoExcluir(props.item)"
                    >
                      <v-icon color="grey darken-1">delete</v-icon>
                    </v-btn>
                    <span>Excluir</span>
                  </v-tooltip>
                </td>
              </template>
            </v-data-table>
          </v-card>

        </v-flex>
      </v-layout>
    </v-container>

    <div style="height: 100px"></div>

    <z-dialogo ref="dialogoExcluir" texto="Deseja excluir esse emitente?">
        <v-btn color="grey darken-1" flat @click="$refs.dialogoExcluir.fechar()" :disabled="loading">Cancelar</v-btn>
        <v-btn color="primary darken-1" flat @click="confirmarExcluir" :loading="loading" :disabled="loading">Excluir</v-btn>
    </z-dialogo>

    <v-fab-transition>
      <v-btn style="right: 70px" color="red darken-1" dark fab fixed bottom right v-show="showfab" @click="$vuetify.goTo('#topo', {duration: 300, offset: 0})">
         <v-icon>keyboard_arrow_up</v-icon>
      </v-btn>
    </v-fab-transition>
  </div>
</template>

<script>

  import EmitenteForm from './EmitenteForm'
  import globals from "@/app/globals";
  import emitentesApi from "@/services/emitentes"

  export default {
    name: 'EmitentesLista',

    components: {
      EmitenteForm
    },

    props: {

    },

    data() {
      return {
        globals,
        search: '',
        emitente: {},
        emitentes: [],
        loading: false,
        loadingList: true,
        offsetTop: 0,
        headers:[
          { text: 'Razão', value: 'razao'},
          { text: 'CNPJ', value: 'cnpj'},
          { text: 'Simples Nacional', value: 'simples_nacional', align: 'center'},
          { text: 'Opções', value: '', align: 'center'}
        ],
        pagination: {},
      }
    },

    mounted() {
      this.$store.commit('SET_TELA', 'Emitentes');

      this.listarEmitentes();
    },

    methods: {
      adicionar() {
        this.$refs.diaglogoEmitente.abrir();
      },

      onSalvar(emitente) {
        this.emitentes.push(emitente);
      },

      onAtualizar(emitente) {
        let index = this.emitentes.findIndex(emitente_ => emitente_.id == emitente.id);
        this.emitentes.splice(index, 1, emitente);
      },

      editar(emitente) {
        this.emitente = this.clone(emitente);
        this.$refs.diaglogoEmitente.abrir();
      },

      async listarEmitentes() {
        try {
          this.loadingList = true;
          this.emitentes = await emitentesApi.list();
        } catch (error) {
          console.log(error)
        } finally {
          this.loadingList = false;
        }

      },

      abrirDialogoExcluir(emitente) {
        this.emitente = emitente;
        this.$refs.dialogoExcluir.abrir();
      },

      async confirmarExcluir() {

        try {
          this.loading = true;
          await emitentesApi.destroy(this.emitente.id);
          this.remover(this.emitente.id);
          this.$refs.dialogoExcluir.fechar();
        } catch (error) {
          console.log(error)
        } finally {
          this.loading = false;
        }

      },

      remover(id) {
        let index = this.emitentes.findIndex(emitente => emitente.id == id);
        this.emitentes.splice(index, 1);
      },

      onScroll() {
        this.offsetTop = window.pageYOffset || document.documentElement.scrollTop;
      }
    },

    computed: {
      showfab() {
        return this.offsetTop > 50;
      }
    }
  }
</script>

<style scoped>

</style>
