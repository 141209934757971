<template>
  <div>
    <v-slide-y-transition mode="out-in">
      <layout-principal v-if="!$route.meta.public"></layout-principal>
      <template v-else>
        <transition>
          <keep-alive>
            <router-view></router-view>
          </keep-alive>
        </transition>
      </template>
    </v-slide-y-transition>
  </div>
</template>

<script>
import LayoutPrincipal from "@/pages/layout/AppLayout.vue";

export default {
  name: "App",
  components: {
    LayoutPrincipal,
  },

  data() {
    return {};
  },

  mounted() {
    this.$store.dispatch('carregarConfiguracoes');
    // this.$vuetify.theme = {
    //   primary: '#3F51B5',
    //   secondary: '#424242',
    //   accent: '#82B1FF',
    //   error: '#FF5252',
    //   info: '#2196F3',
    //   success: '#4CAF50',
    //   warning: '#FFC107'
    // }
  },
};
</script>

<style scoped>
</style>
