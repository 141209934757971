import ServiceClientes from '@/services/clientes';

export default {
  namespaced: true,

  state: {
    cliente: {
      ramais: [],
      tipo_pessoa: 'J',
      estado: 'PE',
      cidade: 'CARUARU'
    },
    clientes: [],
    loading: false,
    loadingLista: false,
    loadingRamais: false,
    loadingExcluir: false,
    planos: [],
    faturas: [],
    ramais: [],
  },

  getters: {
    // TODO:
  },

  mutations: {
    SET_CLIENTE(state, cliente) {
      state.cliente = cliente;
    },
    SET_CLIENTES(state, clientes) {
      state.clientes = clientes;
    },
    RESET_CLIENTE(state, cliente) {
      state.cliente = {
        ramais: [],
        tipo_pessoa: 'J',
        estado: 'PE',
        cidade: 'CARUARU'
      };
    },
    SET_LOADINGLIST(state, payload) {
      state.loadingLista = payload;
    },
  },

  actions: {
    async listarClientes({commit, state}) {
      // if (state.clientes.length == 0) {
        try {
          commit('SET_LOADINGLIST', true);
          let clientes = await ServiceClientes.listarClientes();
          commit('SET_CLIENTES', clientes);

        } finally {
          commit('SET_LOADINGLIST', false);

        }
      // }
    },

    async abrirCliente({commit}, cliente_id) {
      // if (state.clientes.length == 0) {
        let cliente = await ServiceClientes.abrirCliente(cliente_id);
        commit('SET_CLIENTE', cliente);
      // }
    }
  }
}
