const ESTADOS = ["AC", "AL", "AM", "AP", "BA", "CE", "DF", "ES", "GO", "MA", "MT", "MS", "MG", "PA", "PB", "PR", "PE", "PI", "RJ", "RN", "RO", "RS", "RR", "SC", "SE", "SP", "TO"];

const TIPO_PESSOA = [{text: 'Física', value: 'F'}, {text: 'Jurídica', value: 'J'}];

const TIPO_CLIENTE = [
  {text: 'Empresarial', value: 'empresarial'},
  {text: 'Shopping', value: 'shopping'},
  {text: 'Externo', value: 'externo'},
  {text: 'Alphaville', value: 'alphaville'}
];

const PAGINACAO = [15, 20, 30, 50, {"text": "$vuetify.dataIterator.rowsPerPageAll", "value": -1}];

export default {
    ESTADOS,
    TIPO_PESSOA,
    TIPO_CLIENTE,
    PAGINACAO
}
