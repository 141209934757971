<template>
  <div>
    <v-layout v-if="loading" justify-center align-center :style="{'height': height, 'margin-top': marginTop}">
        <v-progress-circular indeterminate :size="size" :width="width" :color="color"></v-progress-circular>
    </v-layout>

    <slot v-else></slot>
  </div>
</template>

<script>
    export default {
        props: {
            loading: {
                type: Boolean,
                default: false
            },
            size: {
                type: Number,
                default: 50
            },
            width: {
                type: Number,
                default: 3
            },
            color: {
                type: String,
                default: 'primary'
            },
            height: {
                type: String,
                default: '300px'
            },
            marginTop: {
                type: String,
                default: '0px'
            }
        }
    }
</script>

<style scoped>

</style>
