<template>
  <div>
    <div class="display-2 text-xs-center">Início</div>
  </div>
</template>

<script>
  export default {
    mounted() {
      this.$router.push({path: '/clientes'});
    }
  }
</script>

<style scoped>

</style>
