<template>
  <div>

    <file-upload ref="fileUpload" @onConcluir="onConcluirUpload" @cancelar="exibirFileUpload = false"></file-upload>

    <z-loading :loading="loadingList" height="100px" color="indigo"></z-loading>

    <v-container v-show="!loadingList">

      <v-btn style="top: 80px" color="primary" dark fixed top right fab @click="$refs.fileUpload.abrir()">
        <v-icon>add</v-icon>
      </v-btn>

      <v-layout row wrap justify-center v-if="enviados.length > 0">
        <v-flex sm12 xl10>
          <arquivos-lista-tabela :arquivos="enviados" titulo="Arquivos Enviados" @processado="listarArquivos"></arquivos-lista-tabela>
        </v-flex>
      </v-layout>

      <v-layout row wrap justify-center v-if="processados.length > 0">
        <v-flex sm12 xl10>
          <arquivos-lista-tabela :arquivos="processados" titulo="Arquivos Processados"></arquivos-lista-tabela>
        </v-flex>
      </v-layout>

      <!-- <v-layout row wrap justify-center v-if="enviados.length > 0">
        <v-flex sm12 xl10>
          <arquivos-lista :arquivos="enviados" titulo="Arquivos Enviados" @processado="listarArquivos"></arquivos-lista>
        </v-flex>
      </v-layout>

      <v-layout row wrap justify-center v-if="processados.length > 0">
        <v-flex sm12 xl10>
          <arquivos-lista :arquivos="processados" titulo="Arquivos Processados"></arquivos-lista>
        </v-flex>
      </v-layout> -->

      <v-layout row wrap justify-center style="margin-top: 200px" v-if="enviados.length == 0 && processados.length == 0">
        <span class="title">Nenhum arquivo encontrado</span>
      </v-layout>
    </v-container>

    <div style="height: 100px"></div>

    <z-dialogo ref="dialogoExcluir" texto="Deseja excluir esse arquivo?">
        <v-btn color="primary darken-1" flat @click="confirmarExcluir" :loading="loading" :disabled="loading">Excluir</v-btn>
        <v-btn color="grey darken-1" flat @click="$refs.dialogoExcluir.fechar()" :disabled="loading">Cancelar</v-btn>
    </z-dialogo>


  </div>
</template>

<script>
  import FileUpload from './FileUpload'
  import ArquivosLista from './ArquivosLista'
  import ArquivosListaTabela from './ArquivosListaTabela'

  export default {
    components: {
      FileUpload,
      ArquivosLista,
      ArquivosListaTabela
    },

    data() {
      return {
        loading: false,
        loadingList: true,
        exibirFileUpload: false,
        arquivo: {},
        arquivos: [],
        enviados: [],
        processados: []

      }
    },

    mounted() {
      this.listarArquivos();
    },

    methods: {
      onConcluirUpload(arquivo) {
        arquivo.processado = false;
        arquivo.processando = false;

        setTimeout(() => {
          this.enviados.unshift(arquivo);
        }, 500);

      },

      listarArquivos() {
        this.loadingList = true;
        this.$axios.get('/arquivos')
          .then(response => {
            this.loadingList = false;
            this.enviados    = this.transformarArquivos(response.data.enviados);
            this.processados = this.transformarArquivos(response.data.processados);
          })
          .catch(error => {
            this.loadingList = false;
            console.log('Catch:', error.response.data)
          })
      },

      processar(arquivo) {
        arquivo.processando = true;
        this.$axios.get('/arquivos/' + arquivo.id + '/processar')
          .then(response => {
            arquivo.processado = true;
            console.log(response.data);
          })
          .catch(error => {
            arquivo.processando = false;
            console.log(error.response);
          })
      },

      abrirDialogoExcluir(arquivo) {
        this.arquivo = arquivo;
        this.$refs.dialogoExcluir.abrir();
      },

      confirmarExcluir() {
        this.loading = true;
        this.$axios.delete('/arquivos/' + this.arquivo.id)
          .then(response => {
            this.excluirArquivo();
            this.$refs.dialogoExcluir.fechar();
            this.loading = false;
          })
          .catch(({response}) => {
            console.log(response.data);
            this.loading = false;
          })
      },

      excluirArquivo() {
        let indice = this.arquivos.findIndex(arquivo => arquivo.id == this.arquivo.id);
        this.arquivos.splice(indice, 1);
      },

      transformarArquivos(arquivos) {
        return arquivos.map(arquivo => {
          arquivo.processando = false;
          return arquivo;
        });
      }

    }
  }
</script>

<style scoped>

</style>
