<template>
    <div>
        <v-slide-y-transition mode="out-in">
          <component :is="currentView"></component>
        </v-slide-y-transition>
    </div>
</template>

<script>
import LayoutPrincipal from "@/pages/LayoutPrincipal.vue";
import Login from "@/pages/Login.vue";
export default {
  name: "App",
  components: {
    LayoutPrincipal,
    Login
  },
  data() {
    return {};
  },

  mounted() {},

  computed: {
    currentView() {
      if (this.$route.path.indexOf("login") > -1) {
        return "Login";
      } else {
        return "LayoutPrincipal";
      }
    },
    isAuth() {
      return this.$route.path.indexOf("login") > -1;
    }
  }
};
</script>

<style scoped>
</style>
