import Vue from 'vue'
import Vuex from 'vuex'
import axios from '@/plugins/Axios'

import clientes from './modules/clientes'
import faturamentos from './modules/faturamentos'
import listas from './modules/listas'
import notasfiscais from './modules/notasfiscais'



Vue.use(Vuex)

export default new Vuex.Store({
  // namespaced: true,
  state: {
    tela: '',
    loadingList: false,
    loading: false,
    configuracoes: {},
    estados: []
  },

  getters: {
    getEstados: state => {
      return state.estados.map(estado => {
        return {
          'nome': estado.nome,
          'sigla': estado.sigla
        }
      });
    },

    getCidades: (state) => (sigla) => {
      const item = state.estados.find(estado => estado.sigla == sigla);

      if (item) {
        return item.cidades.map(cidade => {
          return {
            'nome': cidade.nome,
            'codigo': Number(cidade.codigo)
          }
        })
      }
    }
  },

  mutations: {
    SET_LOADINGLIST(state, status) {
      state.loadingList = status;
    },

    SET_LOADING(state, status) {
      state.loading = status
    },

    SET_CONFIGURACOES(state, status) {
      state.configuracoes = status
    },

    SET_TELA(state, tela) {
      state.tela = tela
    },

    SET_ESTADOS(state, estados) {
      state.estados = estados;
    },

  },

  actions: {
    carregarConfiguracoes({commit}) {
      return axios.get('configuracoes')
        .then(response => {
          commit('SET_CONFIGURACOES', response.data);
        });
    }
  },

  modules: {
    clientes,
    faturamentos,
    notasfiscais,
    listas
  }

})
