<template>
  <v-navigation-drawer width="250" persistent :mini-variant.sync="miniVariant" :clipped="clipped" :value="drawer" @input="$emit('update:drawer', $event)" enable-resize-watcher fixed app>

    <v-toolbar dark color="primary">
      <v-list class="pa-0">
        <v-list-tile>
          <v-list-tile-avatar>
            <v-icon>work</v-icon>
          </v-list-tile-avatar>

          <v-list-tile-content>
            <v-list-tile-title>COB ADM</v-list-tile-title>
          </v-list-tile-content>

          <!-- <v-list-tile-action>
            <v-btn icon @click.stop="miniVariant = !miniVariant">
              <v-icon>chevron_left</v-icon>
            </v-btn>
          </v-list-tile-action> -->
        </v-list-tile>
      </v-list>
    </v-toolbar>

    <app-menu></app-menu>
      <!-- <v-list>
        <v-list-tile v-for="(item, i) in items" :key="i" :to="item.to">
          <v-list-tile-action>
            <v-icon v-html="item.icon"></v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title v-text="item.title"></v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>

        <v-divider></v-divider>

        <v-list-tile @click="">
          <v-list-tile-action>
            <v-icon>exit_to_app</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>Sair</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list> -->

      <v-footer color="grey lighten-3" fixed class="pa-1 text-xs-center">
        <v-layout row wrap>
          <v-flex>
            <b>{{ config.APP_VERSION }}</b>
          </v-flex>
        </v-layout>
      </v-footer>

      <!-- <v-footer fixed inset color="transparent" class="pb-3">
        <v-list>
          <v-list-tile v-show="miniVariant">
            <v-list-tile-action>
              <v-btn icon @click.stop="miniVariant = !miniVariant">
                <v-icon>chevron_right</v-icon>
              </v-btn>
            </v-list-tile-action>
          </v-list-tile>
        </v-list>
      </v-footer> -->

  </v-navigation-drawer>
</template>

<script>

  import AppMenu from "./AppMenu";
  import config from "@/app/config";

  export default {
    components: {
      AppMenu
    },

    props: {
      drawer: {
        type: Boolean,
        default: true
      }
    },

    data() {
      return {
        clipped: false,
        fixed: false,
        miniVariant: false,
        config: config
      }
    }
  }
</script>

<style scoped>
</style>
