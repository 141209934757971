<template>
  <v-dialog v-model="exibir" persistent :max-width="largura">
    <v-card>
      <v-card-title v-show="titulo" class="font-weight-bold">{{ titulo }}</v-card-title>

      <v-card-text class="text--primary">
        <slot>
          <span v-html="mensagem"></span>
        </slot>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn flat color="primary" @click="fechar" width="100px">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "z-alerta",

  data() {
    return {
      exibir: false,
      titulo: '',
      mensagem: '',
      largura:  '500px',
      callback: null
    };
  },

  methods: {
    alertar(opcoes, callback) {
      this.titulo = opcoes.titulo || '';
      this.mensagem = opcoes.mensagem || '';
      this.largura = opcoes.largura || this.largura;
      this.callback = callback || null;

      this.abrir();
    },

    chamarCallback() {
      if (this.callback != null) {
        this.callback();
      }
    },

    abrir() {
      this.exibir = true;
      this.$emit("abrir");
    },

    fechar() {
      this.exibir = false;
      this.$emit("fechar");
      this.chamarCallback();
    }
  },
};
</script>

<style scoped>
</style>
