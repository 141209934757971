<template>
  <v-dialog v-model="exibir" persistent :max-width="largura">
    <v-card>
        <v-container row fluid>
          <v-layout row align-center>
              <v-progress-circular indeterminate :size="40" :color="color"></v-progress-circular>
              <v-flex class="text-xs-center">Aguarde um momento.</v-flex>
          </v-layout>
        </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
    export default {
        name: 'z-aguardar',

        props: {
            titulo: {
                type: String,
            },
            texto: {
                type: String,
            },
            largura: {
                type: String,
                default: "300px"
            },
            color: {
              type: String,
              default: "green darken-2"
            }
        },

        data() {
            return {
                exibir: false
            }
        },

        methods: {
            abrir() {
                this.exibir = true;
                this.$emit('abrir');
            },

            fechar() {
                this.exibir = false;
                this.$emit('fechar');
            },

            async executar(cb) {
                this.abrir();
                await cb();
                this.fechar();
            }
        }

    }
</script>

<style scoped>

</style>
