<template>

  <v-dialog v-model="dialogo" scrollable persistent max-width="500px">

      <v-card>
          <v-card-title>
              <h3 class="title">Plano</h3>
          </v-card-title>
          <v-divider></v-divider>

          <v-form ref="formulario" v-model="valido" lazy-validation>
              <v-container grid-list-md>

                <v-layout row wrap>
                  <v-flex sm12 class="mb-2">
                    <v-text-field
                    :hint="hintPlano"
                    :persistent-hint="true"
                    label="Descrição Plano"
                    v-model="p.descricao"
                    autofocus
                    :rules="[regras.obrigatorio]"
                  ></v-text-field>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <v-flex sm12>
                    <v-checkbox color="primary" label="Internet" v-model="p.internet" hide-details></v-checkbox>
                  </v-flex>
                  <v-flex sm6 class="pl-2">
                    <!-- <v-select label="Velocidade" :items="['5M', '10M', '15M', '25M', '30M']" v-model="p.velocidade" :disabled="!p.internet"></v-select> -->
                    <v-text-field label="Velocidade" v-model="p.velocidade" :disabled="!p.internet" @input="value => p.velocidade = value.toUpperCase()"></v-text-field>
                  </v-flex>
                  <v-flex sm6>
                    <!-- <v-text-field label="Valor Internet" v-model="p.valor_internet" :disabled="!p.internet"></v-text-field> -->
                    <z-campo-valor label="Valor Internet" v-model="p.valor_internet" :disabled="!p.internet"></z-campo-valor>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <v-flex sm12>
                    <v-checkbox color="primary" label="Telefonia" v-model="p.telefonia" hide-details></v-checkbox>
                  </v-flex>
                  <v-flex sm6 class="pl-2">
                    <z-campo-valor label="Franquia" v-model="p.franquia" suffix="minutos" :disabled="!p.telefonia" decimais="0"></z-campo-valor>
                  </v-flex>
                  <v-flex sm6>
                    <!-- <v-text-field label="Valor Franquia" v-model="p.valor_telefonia" :disabled="!p.telefonia"></v-text-field> -->
                    <z-campo-valor label="Valor Franquia" v-model="p.valor_telefonia" :disabled="!p.telefonia"></z-campo-valor>
                  </v-flex>
                </v-layout>

              </v-container>
          </v-form>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn flat color="secondary" @click="cancelar" :disabled="loading">Cancelar</v-btn>
            <v-btn flat color="primary" @click="confirmar" :loading="loading" :disabled="!valido">Salvar</v-btn>
          </v-card-actions>
      </v-card>

  </v-dialog>

</template>

<script>
  import mixinsdialogo from "@/mixins/mixins_dialogo";

  export default {
    mixins: [mixinsdialogo],
    props: {
      plano: {
        type: Object
      }
    },

    data() {
      return {
        loading: false,
        valido: true,
        p: {
          id: null,
          descricao: '',
          internet: false,
          velocidade: '',
          valor_internet: 0,
          telefonia: false,
          franquia: '',
          valor_telefonia: 0
        },
        regras: {
          obrigatorio: v => !!v || 'Campo obrigatório'
        },
      }
    },

    mounted () {
      this.$store.commit('SET_TELA', 'Plano');
    },

    methods: {

      fechar() {
        this.dialogo = false;
        this.limparForm();
      },

      confirmar() {
        if (!this.$refs.formulario.validate()) {
          return false;
        }

        if (this.p.id) {
          this.atualizar(this.p);
        } else {
          this.salvar(this.p);
        }
      },

      salvar(plano) {
        this.loading = true;
        this.$axios.post('/planos', plano)
          .then(response => {
            this.$emit('onSalvar', response.data);
            this.fechar();
            this.loading = false;
          })
          .catch(error => {
            this.loading = false;
            this.$emit('onErro');
            console.log(error.response.data)
          })
      },

      atualizar(plano) {
        this.loading = true;
        this.$axios.put('/planos/' + plano.id, plano)
          .then(response => {
            this.$emit('onAtualizar', response.data);
            this.fechar();
            this.loading = false;
          })
          .catch(error => {
            this.loading = false;
            this.$emit('onErro');
            console.log(error.response.data)
          })
      },

      remover(plano) {
        this.loading = true;
        this.$axios.delete('/planos/' + plano.id)
          .then(response => {
            this.$emit('onRemover', plano.id);
            this.fechar();
            this.loading = false;
          })
          .catch(error => {
            this.loading = false;
            this.$emit('onErro');
            console.log(error.response.data)
          })
      },

      cancelar() {
        this.$emit('onCancelar');
        this.fechar();
      },

      limparForm() {

        setTimeout(() => {
          // this.$refs.formulario.reset();

          this.p = {
            id: null,
            descricao: '',
            internet: false,
            velocidade: '',
            valor_internet: 0,
            telefonia: false,
            franquia: '',
            valor_telefonia: 0
          }

        }, 500);
      }
    },

    computed: {
      hintPlano() {
        if (this.p.internet && this.p.telefonia) {
          return 'Sugestão: Combo Internet ' + this.p.velocidade + ' + Telefonia ' + this.p.franquia + 'min'
        } else {

          if (this.p.internet) {
            return 'Sugestão: Plano Internet ' + this.p.velocidade
          }

          if (this.p.telefonia) {
            return 'Sugestão: Plano Telefonia ' + this.p.franquia + 'min'
          }

        }
        return '';
      }
    },

    watch: {
      plano(val) {
        if (val) {
          this.p = val;
        }
      }
    }
  }
</script>

<style scoped>
.container.grid-list-md .layout .flex{
    padding-top: 0px;
    padding-right: 4px;
    padding-bottom: 0px;
    padding-left: 4px;
}
</style>
