<template>
  <v-data-table dense :headers="headers" :items="faturas" hide-actions class="elevation-1" :pagination.sync="pagination" no-data-text="">
    <template slot="items" slot-scope="props">
      <td>{{ props.item.numero }}</td>
      <td>{{ props.item.periodo }}</td>
      <td>{{ props.item.emissao | dataBR }}</td>
      <td>{{ props.item.vencimento | dataBR }}</td>
      <td class="text-xs-right">{{ props.item.total | valorBR }}</td>
      <td class="justify-center layout px-0">
        <v-btn icon class="mx-0" @click="imprimir(props.item)">
          <v-icon color="grey darken-1">print</v-icon>
        </v-btn>
      </td>
    </template>
  </v-data-table>
</template>

<script>
  import config from "@/app/config";

  export default {
    props: {
      faturas: {
        type: Array
      }
    },

    data() {
      return {
        loading: false,
        loadingList: false,
        headers:[
          { text: 'Fatura', value: 'numero', width: '10px'},
          { text: 'Período', value: 'periodo'},
          { text: 'Emissão', value: 'emissao', width: '10px' },
          { text: 'Vencimento', value: 'vencimento' , width: '10px' },
          { text: 'Valor', value: 'total', align: 'right', width: '10px' },
          { text: 'Imprimir', value: '', align: 'center', width: '10px', sortable: false }
        ],
        pagination: {
          sortBy: 'numero'
        },
      }
    },

    methods: {
      imprimir(fatura) {
        this.abrirJanela(config.BASE_URL + '/faturas/' + fatura.id + '/imprimir');
      },
    }
  }
</script>

<style scoped>

</style>
