<template>
  <v-text-field
    ref="campo"
    :value="dateFormatted"
    v-bind="attributes"
    mask="##/##/####"
    return-masked-value
    :flat="isToBeFlat"
    :class="{ transparente: transparente && isToBeFlat }"
    :readonly="readonly || isSm"
    :disabled="disabled"
    @change="onChange"
    @input="onInput"
    @focus="onFocus"
    @keyup="$emit('keyup', $event)"
    @keydown="$emit('keydown', $event)"
    @keydown.esc="limpar"
    @click:clear="limpar"
  >
    <template v-slot:append>
      <v-menu
        ref="menu"
        v-model="menu"
        :left="left"
        :close-on-content-click="false"
        transition="scale-transition"
        origin="center center"
        offset-y
        nudge-bottom="20px"
      >
        <template v-slot:activator="{ on }">
          <v-icon
            v-on="on"
            v-show="!readonly && !disabled"
            tabindex="-1"
            class="mr-2"
            @click="setYearPicker"
            >event</v-icon
          >
        </template>
        <v-date-picker
          v-model="date"
          ref="picker"
          locale="pt-BR"
          no-title
          :min="min"
          :max="max"
          @input="onInputDatePicker"
        ></v-date-picker>
      </v-menu>
    </template>
  </v-text-field>
</template>

<script>
export default {
  name: 'z-date-picker-menu',
  props: {
    value: {
      type: [String],
      default: null,
    },
    transparente: {
      type: Boolean,
      default: false,
    },
    raiseOnFocus: Boolean,
    flat: Boolean,
    readonly: Boolean,
    disabled: Boolean,
    min: {
      type: String,
      default: '1930-01-01',
    },
    birthday: Boolean,
    left: {
      type: Boolean,
      default: false,
    },
    right: {
      type: Boolean,
      default: false,
    },

    validateFormat: {
      type: Boolean,
      default: false,
    },

  },

  data: (vm) => ({
    dateFormatted: null,
    date: null,
    menu: false,
    isFocused: false,
    max: null,
    internalChange: false,
  }),

  computed: {
    attributes() {
      return { ...this.$attrs };
    },

    isToBeFlat() {
      if (this.flat && this.raiseOnFocus && this.isFocused) {
        return false;
      }
      return this.flat;
    },

    isSm() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },

  mounted() {
    // this.date = this.value;
    // this.dateFormatted = this.dataBR(this.value);
  },

  methods: {
    onInput() {
      // this.internalChange = true;
      if (this.dateFormatted) {
        if (this.dateFormatted.length == 10) {
          this.date = this.dataUS(this.dateFormatted);
          this.$emit('input', this.date);
        }
      }
    },

    onChange() {
      this.internalChange = true;
    },

    onInputDatePicker() {
      this.internalChange = false;
      this.menu = false;
      this.$emit('input', this.date);
    },

    onFocus(e) {
      if (!this.isSm) {
        e.target.selectionStart = 0;
        e.target.selectionEnd = e.target.value.length;
        this.isFocused = true;
      }
    },

    selecionarAno() {
      setTimeout(() => {
        this.$refs.campo.$el.querySelector('input').selectionStart = 6;
        this.$refs.campo.$el.querySelector('input').selectionEnd = 10;
      }, 0);
    },

    selecionarMes() {
      setTimeout(() => {
        this.$refs.campo.$el.querySelector('input').selectionStart = 3;
        this.$refs.campo.$el.querySelector('input').selectionEnd = 5;
      }, 0);
    },

    dataBR(date) {
      if (!date) return null;

      if (date.length > 10) {
        date = date.substr(0, 10);
      }

      const [year, month, day] = date.split(/[-/]/);
      return `${day}/${month}/${year}`;
    },

    dataUS(date) {
      if (!date) return null;

      const [year, month, day] = date.split(/[-/]/).reverse();
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },

    setYearPicker() {
      if (this.birthday) {
        setTimeout(() => {
          this.max = new Date().toISOString().substr(0, 10);
          this.$refs.picker.activePicker = 'YEAR';
        }, 0);
      } else {
        this.max = null;
      }
    },

    focus() {
      this.$refs.campo.focus();
    },

    limpar() {
      this.dateFormatted = '';
      this.date = '';
      this.$emit('input', null);
    },

    isValidDate(date) {
      if (!this.validateFormat) {
        return true;
      }

      if (!date) {
        return true;
      }

      const message = "Data inválida. (DD/MM/AAAA)";

      if (date.length < 10) {
        return message;
      }

      let [ dia, mes, ano ] = date.split('/');

      if (dia > 31 || mes > 12) {
        return message;
      }

      let pattern = /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/;
      return pattern.test(date) || message;
    }
  },

  watch: {
    value(val) {
      if (!this.internalChange) {
        console.log(val);
        console.log('Value updated2')
        this.date = val;
        this.dateFormatted = !!val ? this.dataBR(val) : val;
        this.internalChange = false;
      }
    },
  },
};
</script>

<style scoped>
.transparente >>> .v-input__slot {
  background: none;
}

/* .borda >>> .v-input__slot {
    border: 1px solid silver;
    border-radius: 10px
  } */
</style>
