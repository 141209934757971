<template>
  <z-dialogo ref="dialogo" titulo="Clientes" scrollable dividers no-padding largura="900px" altura="383px">

    <template v-slot:prepend>
      <v-toolbar flat color="white">
        <v-text-field
          ref="busca"
          v-model="busca"
          full-width
          single-line
          label="Pesquisar"
          clearable
          hide-details
          prepend-inner-icon="mdi-magnify"
          @keydown.enter="onBuscarPressEnter"
          @focus="onBuscaFocus"
          @blur="is_busca_focused = false"
          @click:clear="limparBusca"
        ></v-text-field>
      </v-toolbar>
    </template>

    <template v-slot:texto>
      <div ref="topo"></div>
      <z-loading :loading="carregando">

        <v-data-table
          :headers="headers"
          :items="itens_busca"
          :loading="carregando"
          height_="383px"
          disable-initial-sort
          hide-actions
        >
          <template v-slot:items="props">
            <tr
              ref="urls"
              class="apontador noselect"
              :class="{'active': isActive(props.index)}"
              @click="selecionar(props.item)"
            >
              <td>{{ props.item.id }}</td>
              <td>{{ props.item.razao }}</td>
              <td class="text-no-wrap">{{ props.item.cpfcnpj }}</td>
            </tr>
          </template>
        </v-data-table>

      </z-loading>
    </template>

    <template v-slot:actions_left>
      <span class="text-subtitle-2">Clientes encontrados: {{ itens_busca.length }}</span>
    </template>
    <template v-slot:actions_right>
      <v-btn color="primary" @click="fechar()">Fechar</v-btn>
    </template>

  </z-dialogo>
</template>

<script>

import clientesApi from "@/services/clientes";
import buscas_mixin from "@/mixins/buscas";

export default {
  name: 'DialogoBuscarClientes',

  mixins: [buscas_mixin],

  props: {
    value: {
      type: String,
      default: ''
    },
  },

  data() {
    return {
      headers: [
        { text: "Código", value: "id", width: "100px" },
        { text: "Razão Social", value: "razao" },
        { text: "CPF/CNPJ", value: "razao" },
      ],
    };
  },

  methods: {

    async buscar() {
      try {
        if (this.busca && this.busca.length > 0) {
          this.carregando = true;
          this.itens_busca = await clientesApi.buscar(this.busca);
          this.atual = 0;
        }
      } catch (error) {
        console.log(error)
        // this.$eventbus.notificarErro('Não foi possível efetuar a busca.');
      } finally {
        this.carregando = false;
      }
    }

  },
}
</script>

<style scoped>

</style>
