import zDialogo from '@/components/zDialogo'
import zMensagem from '@/components/zMensagem'
import zLoading from '@/components/zLoading'
import zAguardar from '@/components/zAguardar'
import zCampoValor from '@/components/zCampoValor'
import zBusca from '@/components/zBusca'
import zCpfCnpj from '@/components/zCpfCnpj'
import zCep from '@/components/zCep'
import zStatusNfse from '@/components/zStatusNfse'
import zTableItems from '@/components/zTableItems'
import zDatePicker from '@/components/zDatePicker'
import zDatePickerMenu from '@/components/zDatePickerMenu'

export default new class ZComponents {

  install(Vue, options) {

    Vue.component('zDialogo', zDialogo);
    Vue.component('zMensagem', zMensagem);
    Vue.component('zLoading', zLoading);
    Vue.component('zAguardar', zAguardar);
    Vue.component('zCampoValor', zCampoValor);
    Vue.component('zBusca', zBusca);
    Vue.component('zCpfCnpj', zCpfCnpj);
    Vue.component('zCep', zCep);
    Vue.component('zStatusNfse', zStatusNfse);
    Vue.component('zTableItems', zTableItems);
    Vue.component('zDatePicker', zDatePicker);
    Vue.component('zDatePickerMenu', zDatePickerMenu);

  }
}
