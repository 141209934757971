<template>
  <div>

    <v-container fluid>
      <v-subheader>Notas Fiscais</v-subheader>
      <v-layout row wrap justify-center class="mb-3">
        <v-flex>
          <v-card>
            <v-card-title>
              Filtro
            </v-card-title>
            <v-divider></v-divider>
            <!-- <v-card-text> -->

              <v-form>
                <v-container fluid>
                  <v-layout row wrap>
                    <v-flex sm6>
                      <v-select
                        v-model="filtro.emitente_id"
                        :items="emitentes"
                        box
                        label="Emitente"
                        hide-details
                        clearable
                      ></v-select>
                    </v-flex>
                    <v-flex sm6>
                      <v-autocomplete
                        v-model="filtro.tomador_id"
                        :items="tomadores"
                        box
                        label="Tomador"
                        hide-details
                        clearable
                      ></v-autocomplete>
                    </v-flex>
                    <v-flex sm3>
                      <v-text-field
                        v-model="filtro.nfse_numero"
                        label="Número NFS-e"
                        box
                        hide-details
                        clearable
                      ></v-text-field>
                    </v-flex>
                    <v-flex sm3>
                      <z-date-picker-menu
                        v-model="filtro.data_inicial"
                        label="Data Inicial"
                        box
                        hide-details
                        clearable
                        left
                      ></z-date-picker-menu>
                    </v-flex>
                    <v-flex sm3>
                      <z-date-picker-menu
                        v-model="filtro.data_final"
                        label="Data Final"
                        box
                        hide-details
                        clearable
                        left
                      ></z-date-picker-menu>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>

            <!-- </v-card-text> -->
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn color="primary" @click="novaNotaFiscal">
                <v-icon left>mdi-note-plus</v-icon>
                Nota Fiscal Avulsa
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn flat @click="exportar" :loading="loading" :disabled="notasfiscais.data && notasfiscais.data.length == 0">
                <v-icon left>mdi-file-excel-outline</v-icon>
                Exportar
              </v-btn>
              <v-btn color="primary" @click="listar" :loading="loading">Filtrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>

      <v-subheader>
        Notas Fiscais ({{ notasfiscais.data ? notasfiscais.data.length : 0 }})
        <v-spacer></v-spacer>
        <v-icon small :color="cor_avulsa" class="pr-2">mdi-circle</v-icon>Notas Avulsas
      </v-subheader>

      <v-layout row justify-center>
        <v-flex>

          <v-card>
            <v-data-table
              class="elevation-1"
              disable-initial-sort
              :loading="loading"
              :headers="headers"
              :items="notasfiscais.data"
              :total-items="total_items"
              :rows-per-page-items="pagination_options"
              :pagination.sync="pagination"
            >
              <template slot="items" slot-scope="props">
                <td class="text-xs-center text-no-wrap">
                  <v-btn
                    v-if="props.item.rps_situacao == 1 || props.item.rps_situacao == 3"
                    color="primary"
                    small
                    :disabled="props.item.rps_situacao == 2"
                    @click="emitirNFSE(props.item)"
                  >Emitir</v-btn>
                  <span v-else>{{ props.item.nfse_numero }}</span>

                  <v-btn
                    v-if="props.item.rps_situacao == 2"
                    color="orange"
                    dark
                    small
                    :loading="props.item.consultando"
                    @click="consultarProtocolo(props.item)"
                  >Consultar</v-btn>

                  <v-btn
                    v-if="props.item.rps_situacao == 4 && !props.item.nfse_numero"
                    color="green"
                    dark
                    small
                    :loading="props.item.consultando"
                    @click="consultarNFSe(props.item)"
                  >Consultar</v-btn>

                </td>
                <td class="text-xs-left text-no-wrap">

                    <!-- <div v-if="props.item.status == 2">
                      <z-status-nfse
                        :situacao="-2"
                        @click="substituir(props.item)"
                      >Aguardando</z-status-nfse>
                    </div> -->

                    <z-status-nfse
                      :situacao="props.item.rps_situacao"
                      @click="onClickStatus(props.item)"
                    >Aguardando</z-status-nfse>

                    <v-icon title="Nota Avulsa" small :color="cor_avulsa" v-if="props.item.tipo_nota == 'avulsa'">mdi-circle</v-icon>

                  <!-- {{ props.item.rps_situacao }} -->
                </td>
                <td class="text-no-wrap">{{ props.item.rps_protocolo }}</td>
                <td class="text-no-wrap">{{ props.item.nfse_data_emissao | dataBR }}</td>
                <td class="text-no-wrap overflow-hidden text-truncate" style="position: relative; max-width: 200px" :title="props.item.emitente.razao">
                  {{ props.item.emitente.razao }}
                </td>

                <v-hover v-slot="{ hover }">
                <td class="text-no-wrap overflow-hidden text-truncate" style="position: relative; max-width: 200px" :title="props.item.tomador.razao">
                  {{ props.item.tomador.razao }}
                  <z-table-items :show="hover">
                    <v-btn color="primary" small icon :to="{name: 'ClientesFormEditar', params: { clienteId: props.item.tomador.id}, meta: {teste: 'Isso é um teste'}}">
                      <v-icon small>edit</v-icon>
                    </v-btn>
                  </z-table-items>
                </td>
                </v-hover>

                <td class="text-xs-right">{{ props.item.servico_valor_liquido_nfse | valorBR }}</td>
                <td class="text-xs-right text-no-wrap">
                  <v-tooltip top v-if="props.item.rps_situacao == 4 && props.item.status == 1">
                    <v-btn slot="activator" icon ripple @click.stop="substituir(props.item)">
                      <v-icon color="grey darken-1">mdi-swap-vertical-bold</v-icon>
                    </v-btn>
                    <span>Substituir</span>
                  </v-tooltip>
                  <v-tooltip top v-if="[4, -4].includes(props.item.rps_situacao)">
                    <v-btn slot="activator" icon ripple @click.stop="imprimir(props.item)">
                      <v-icon color="grey darken-1">print</v-icon>
                    </v-btn>
                    <span>Imprimir</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <v-btn slot="activator" icon ripple @click.stop="abrirDialogoExcluir(props.item)">
                      <v-icon color="grey darken-1">delete</v-icon>
                    </v-btn>
                    <span>Excluir</span>
                  </v-tooltip>
                </td>
              </template>
            </v-data-table>
          </v-card>

        </v-flex>
      </v-layout>
    </v-container>

    <z-dialogo ref="dialogoExcluir" titulo="Deseja excluir essa NFSe?" largura="600px" :loading="loading_excluir">
        <div slot="texto">
          {{ nota.tomador.razao || ""}}
        </div>
        <v-btn color="grey darken-1" flat @click="$refs.dialogoExcluir.fechar()" :disabled="loading_excluir">Cancelar</v-btn>
        <v-btn color="primary darken-1" flat @click="confirmarExcluir" :loading="loading_excluir" :disabled="loading_excluir">Excluir</v-btn>
    </z-dialogo>

    <CancelamentoNotaFiscal ref="dialogoCancelamentoNotaFiscal" @onsubstituir="listar"></CancelamentoNotaFiscal>
  </div>
</template>

<script>
  import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

  import globals from "@/app/globals";
  import notaFiscaisApi from '@/services/notasfiscais';
  import qs from 'qs';

  import CancelamentoNotaFiscal from './CancelamentoNotaFiscal'

  import { formataCPFCNPJ } from '@/helpers/formatacoes';

  export default {
    name: 'NotasFiscaisLista',

    components: {
      CancelamentoNotaFiscal,
    },

    inject: ['rootRefs'],

    data() {
      return {
        cor_avulsa: 'blue-grey',
        globals,
        loading_excluir: false,
        search: '',
        nota: {
          tomador: {
            razao: ""
          }
        },
        headers:[
          { text: 'Número NFSe', value: 'nfse_numero', align: 'center', width: '10px', sortable: false},
          { text: 'Situação', value: 'rps_situacao', align: 'center', width: '10px', sortable: false},
          { text: 'Protocolo', value: 'rps_protocolo', width: '10px', sortable: false},
          { text: 'Data Emissão', value: 'rps_data_emissao', width: '10px', sortable: false},
          { text: 'Emitente', value: 'emitente.razao', sortable: false},
          { text: 'Tomador', value: 'tomador.razao', sortable: false},
          { text: 'Valor Nota', value: 'servico_valor_liquido_nfse', align: 'right', sortable: false},
          { text: 'Opções', value: '', align: 'center', sortable: false}
        ],

        filtro: {
          emitente_id: null,
          tomador_id: null,
          nfse_numero: null,
          cpf_cnpj: null,
          data_inicial: null,
          data_final: null,
        },

        pagination: {
          descending: false,
          page: 1,
          rowsPerPage: 15,
        },
        pagination_options: [5, 10, 15, 20, 30, 40, 50, { text: 'Todos', value: -1 }],
        consultando: false,
      }
    },

    computed: {
      ...mapState('notasfiscais', ['notasfiscais', 'notafiscal', 'loading']),
      emitentes() {
        return this.$store.state.listas.emitentes.map(emitente => {
          return {
            'text': emitente.razao,
            'value': emitente.id,
            'cnpj': emitente.cnpj,
            'ibpt': emitente.ibpt,
            'simples_nacional': emitente.simples_nacional,
          }
        })
      },

      tomadores() {
        return this.$store.state.listas.tomadores.map(tomador => {
          return {
            'text': tomador.razao,
            'value': tomador.id,
            'cnpj': tomador.cpfcnpj,
          }
        })
      },

      total_items() {
        return this.notasfiscais.total || 0;
      }
    },

    async mounted() {
      this.$store.commit('SET_TELA', 'Notas Fiscais');

      this.filtro = {
        emitente_id: null,
        tomador_id: null,
        nfse_numero: null,
        cpf_cnpj: null,
        data_inicial: this.hoje(),
        data_final: this.hoje(),
      }

      await this.$store.dispatch('listas/emitentes');
      await this.$store.dispatch('listas/tomadores');
      // await this.listar();

    },

    methods: {
      ...mapActions('notasfiscais', ['listarNotasFiscais', 'excluirNotaFiscal', 'carregarSubstituta']),

      async listar() {
        let filtro = {...this.filtro, ...this.pagination}
        await this.listarNotasFiscais(filtro);
      },

      abrirDialogoExcluir(nota) {

        if (!!nota.nfse_numero && !!nota.rps_protocolo) {
          this.rootRefs.notifications.notificar("Não é possível excluir essa nota.");
          return false;
        }

        this.nota = nota;
        this.$refs.dialogoExcluir.abrir();
      },


      async confirmarExcluir() {
        try {
          this.loading_excluir = true;

          await this.excluirNotaFiscal(this.nota.id);

          this.$refs.dialogoExcluir.fechar()
        } finally {
          this.loading_excluir = false;
        }
      },

      excluir() {

      },

      emitirNFSE(nota) {

        // this.faturamento.subfatura_index = subfatura_index;
        // this.$store.commit('notasfiscais/SET_FATURA', this.faturamento);
        this.$router.push({
          name: 'EditarNotaServico',
          params: {
            notafiscal_id: nota.id
          }
        })
      },

      imprimir(nota) {
        // if (this.$config.IS_PRODUCTION) {
          this.abrirJanela(`http://visualizar.ginfes.com.br/report/consultarNota?__report=nfs_ver4&cdVerificacao=${nota.nfse_codigo_verificacao}&numNota=${nota.nfse_numero}&cnpjPrestador=null`)
        // } else {
          // this.abrirJanela(`http://caruaru.ginfesh.com.br/report/consultarNota?__report=nfs_ver4&cdVerificacao=${nota.nfse_codigo_verificacao}&numNota=${nota.nfse_numero}&cnpjPrestador=null`)
        // }
      },

      async consultarProtocolo(nota) {
        try {
          this.$set(nota, 'consultando', true);

          const response = await notaFiscaisApi.consultarProtocolo(nota.rps_protocolo);

          if (response.situacao == 4) {
            const nota_fiscal = await this.carregarNota(nota.id);
            nota.nfse_codigo_verificacao = nota_fiscal.nfse_codigo_verificacao;
            nota.rps_protocolo = nota_fiscal.rps_protocolo;
            nota.rps_situacao = nota_fiscal.rps_situacao;
            nota.nfse_numero = nota_fiscal.nfse_numero;
            nota.notafiscal = nota_fiscal.notafiscal;
          }

        } catch (error) {

          if (error.response) {
            const { data } = error.response;
            // console.log({...data});

            if (data.situacao == 3) {
              nota.rps_situacao = 3;
            }
          }

          // throw error;
        } finally {
          this.$set(nota, 'consultando', false);
        }
      },

      async consultarNFSe(nota) {
        try {
          this.$set(nota, 'consultando', true);
          const response = await notaFiscaisApi.consultarNota(nota.rps_protocolo);

          if (response.mensagem == "Nota Fiscal emitida com sucesso") {
            const nota_fiscal = await this.carregarNota(nota.id);
            nota.nfse_codigo_verificacao = nota_fiscal.nfse_codigo_verificacao;
            nota.rps_protocolo = nota_fiscal.rps_protocolo;
            nota.rps_situacao = nota_fiscal.rps_situacao;
            nota.nfse_numero = nota_fiscal.nfse_numero;
            nota.notafiscal = nota_fiscal.notafiscal;
          }

        } catch (error) {

          if (error.response) {
            const { data } = error.response;
            console.log({...data});

            if (data.codigo) {
              nota.rps_situacao = 3;
            }
          }

        } finally {
          this.consultando = false;
          this.$set(nota, 'consultando', false);
        }
      },

      async onClickStatus(nota) {
        let { retorno_codigo, retorno_mensagem, retorno_correcao, nota_substituta, nfse_numero } = nota;

        if (nota_substituta) {
          await this.carregarSubstituta(nfse_numero);
          this.$refs.dialogoCancelamentoNotaFiscal.abrir();
        }

        if (retorno_mensagem) {
          console.log({
            retorno_codigo,
            retorno_mensagem,
            retorno_correcao
          })
        }
      },

      async carregarNota(notafiscal_id) {
        await this.$store.dispatch('notasfiscais/carregarNfse', notafiscal_id);
        return this.$store.state.notasfiscais.fatura;
      },

      async exportar() {
        try {
          this.loading = true;
          // await this.$axios.get('exportar-notas-excel', this.filtro);
          if (this.$config.IS_PRODUCTION) {
            this.abrirJanela('https://gft.etapps.com.br/exportar-notas-excel?'  + qs.stringify(this.filtro));
          } else {
            this.abrirJanela('http://localhost:8000/exportar-notas-excel?'  + qs.stringify(this.filtro));
          }
          // await this.exportarExcel(this.filtro);
        } catch (error) {
          throw error
        } finally {
          this.loading = false;
        }
      },

      substituir(nota) {

        this.$store.commit('notasfiscais/SET_NOTAFISCAL_CANCELADA', {
          nfse_numero: nota.nfse_numero,
          nfse_codigo_verificacao: nota.nfse_codigo_verificacao,
          nfse_data_emissao: nota.nfse_data_emissao,
          emitente_id: nota.emitente_id,
          tomador_cpfcnpj: formataCPFCNPJ(nota.tomador_cpfcnpj),
          tomador_razao_social: nota.tomador_razao_social,
          status: nota.status,
          nota_substituida: nota.nota_substituida,
          servico_valor_liquido_nfse: nota.servico_valor_liquido_nfse,
        });

        this.$refs.dialogoCancelamentoNotaFiscal.abrir();
      },

      novaNotaFiscal() {
        this.$router.push({
          name: 'NotaServicoAvulsa'
        })
      }
    },

    watch: {
      pagination: {
        handler() {
          this.listar();
        },
        deep: true,
      },

      filtro: {
        handler() {
          this.listar();
        },
        deep: true,
      },

    },
  }
</script>

<style lang="scss" scoped>

</style>
