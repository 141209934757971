<template>
  <div class="table-items" :class="{'show': show}">
    <div class="d-inline-flex align-center">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: ['show']
};
</script>

<style lang="scss" scoped>

.table-items {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  // width: 100px;
  opacity: 0;

  div {
    height: 100%;
  }

  &.show {
    transition: opacity .3s ease-in-out;
    opacity: 1;
    background-color: #eee;
  }
}

</style>
