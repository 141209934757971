import listasApi from "@/services/listas";

export default {
  namespaced: true,

  state: {
    vencimentos: [],
    emitentes: [],
    tomadores: [],
    arquivos: [],
  },

  getters: {

  },

  mutations: {
    SET_VENCIMENTOS(state, payload) {
      state.vencimentos = payload;
    },

    SET_EMITENTES(state, emitentes) {
      state.emitentes = emitentes;
    },

    SET_TOMADORES(state, tomadores) {
      state.tomadores = tomadores;
    },

    SET_ARQUIVOS(state, arquivos) {
      state.arquivos = arquivos;
    },
  },

  actions: {
    async listarVencimentos({ commit }) {
      let vencimentos = await listasApi.vencimentos();
      commit("SET_VENCIMENTOS", vencimentos);
    },

    async emitentes({ commit }) {
      let emitentes = await listasApi.emitentes();
      commit("SET_EMITENTES", emitentes);
    },

    async tomadores({ commit }) {
      let tomadores = await listasApi.tomadores();
      commit("SET_TOMADORES", tomadores);
    },

    async arquivos({ commit }) {
      let arquivos = await listasApi.arquivos();
      commit("SET_ARQUIVOS", arquivos);
    },
  },
};
