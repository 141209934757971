const natureza_operacao = [
  { value: 1, text: "1 - Tributação no município" } ,
  { value: 2, text: "2 - Tributação fora do município" } ,
  { value: 3, text: "3 - Isenção" } ,
  { value: 4, text: "4 - Imune" } ,
  { value: 5, text: "5 - Exigibilidade suspensa por decisão judicial" } ,
  { value: 6, text: "6 - Exigibilidade suspensa por procedimento administrativo" } ,
]

const regime_especial_tributacao = [
  { value: 1, text: "1 - Microempresa municipal" },
  { value: 2, text: "2 - Estimativa" },
  { value: 3, text: "3 - Sociedade de profissionais" },
  { value: 4, text: "4 - Cooperativa" },
  { value: 5, text: "5 - MEI – Simples Nacional" },
  { value: 6, text: "6 - ME EPP – Simples Nacional" },
]

export {
  natureza_operacao,
  regime_especial_tributacao
}
