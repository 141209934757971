import axios from '@/plugins/Axios'

function carregarSubFaturamento(subfatura_id) {
  return axios.get(`faturas/${subfatura_id}/subfatura`)
    .then(response => response.data);
}

export default {
  carregarSubFaturamento
}
