<template>
  <div id="topo">

    <z-loading :loading="loadingList" height="100vh" color="indigo"></z-loading>

    <v-form v-model="form_valido" ref="form" lazy-validation>

      <v-container v-show="!loadingList">

        <div class="d-flex">
          <div class="d-inline-block">
            <v-subheader>Emitente</v-subheader>
          </div>

          <v-spacer></v-spacer>

          <div class="d-inline-block text-xs-right">
            <z-status-nfse
              :situacao="nfse.rps_situacao"
            >Aguardando</z-status-nfse>
          </div>
        </div>

        <v-divider></v-divider>

        <v-container fluid grid-list-md>
          <v-layout row wrap>
            <v-flex sm9>
              <v-select
                v-model="emitente"
                box
                label="Emitente"
                return-object
                hide-details
                :items="emitentes"
                :rules="[regras.cnpj]"
                @change="onChangeEmitente"
              ></v-select>
            </v-flex>
            <v-flex sm3>
              <v-text-field
                box
                label="CPF/CNPJ"
                readonly
                mask="##.###.###/####-##"
                hide-details
                :value="emitente.cnpj"
                :rules="[regras.obrigatorio]"
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-container>

        <div class="d-flex">
          <div class="d-inline-block">
            <v-subheader>Tomador</v-subheader>
          </div>

          <v-spacer></v-spacer>

          <div class="d-inline-block text-xs-right mr-3" v-if="!isNotaAvulsa">
            <v-btn color="primary" small :to="{ name: 'ClientesFormEditar', params: { clienteId: nfse.tomador.id } }">Editar</v-btn>
          </div>
        </div>
        <v-divider></v-divider>

        <v-container fluid grid-list-md>
          <v-layout row wrap>

            <v-flex sm3>
              <!-- <v-text-field box label="CPF/CNPJ" v-model="nfse.tomador.cpfcnpj" :readonly="!isNotaAvulsa" :rules="[regras.obrigatorio]" return-masked-value></v-text-field> -->
              <z-cpf-cnpj
                box
                v-model="nfse.tomador.cpfcnpj"
                :append-icon="isNotaAvulsa || nfse.tipo_nota == 'avulsa' ? 'mdi-magnify' : ''"
                :readonly="!isNotaAvulsa"
                :rules="[regras.obrigatorio]"
                @keyup="buscarTomador"
                @click:append="$refs.DialogoBuscarClientes.abrir()"
              ></z-cpf-cnpj>
            </v-flex>

            <v-flex sm9>
              <v-text-field box label="Razão Social" v-model="nfse.tomador.razao_social" :rules="[regras.obrigatorio]"></v-text-field>
            </v-flex>

            <v-flex sm4>
              <v-text-field box label="CEP" v-model="nfse.tomador.cep" :rules="[regras.obrigatorio]"></v-text-field>
            </v-flex>

            <v-flex sm4>
              <v-select
                v-model="nfse.tomador.uf"
                :items="estados"
                label="Estado"
                box
                readonly
                :rules="[regras.obrigatorio]"
              ></v-select>
            </v-flex>

            <v-flex sm4>
              <v-autocomplete
                v-model="nfse.tomador.codigo_municipio"
                :items="getCidadesByUF(nfse.tomador.uf)"
                label="Cidade"
                box
                readonly
                :rules="[regras.obrigatorio]"
              ></v-autocomplete>
            </v-flex>

            <v-flex sm9>
              <v-text-field box label="Endereço" v-model="nfse.tomador.endereco" readonly :rules="[regras.obrigatorio]"></v-text-field>
            </v-flex>

            <v-flex sm3>
              <v-text-field box label="Número" v-model="nfse.tomador.numero" readonly :rules="[regras.obrigatorio]"></v-text-field>
            </v-flex>

            <v-flex sm6>
              <v-text-field box label="Bairro" v-model="nfse.tomador.bairro" readonly :rules="[regras.obrigatorio]"></v-text-field>
            </v-flex>

            <v-flex sm6>
              <v-text-field box label="Complemento" v-model="nfse.tomador.complemento" readonly></v-text-field>
            </v-flex>

            <v-flex sm9>
              <v-text-field box label="Email" v-model="nfse.tomador.email" readonly></v-text-field>
            </v-flex>

            <v-flex sm3>
              <v-text-field box label="Telefone" v-model="nfse.tomador.telefone" readonly mask="(##) ####-####" return-masked-value></v-text-field>
            </v-flex>





          </v-layout>
        </v-container>


        <v-subheader>Competância e Local</v-subheader>
        <v-divider></v-divider>

        <v-container fluid grid-list-md>
          <v-layout row wrap>

            <v-flex sm2>
              <v-text-field box label="Data Emissão" v-model="nfse.data_emissao" readonly></v-text-field>
            </v-flex>

            <v-flex sm3>
              <v-select
                v-model="nfse.natureza_operacao"
                :items="natureza_operacao"
                box
                label="Local"
              ></v-select>
            </v-flex>

            <v-flex sm2>
              <v-select
                v-model="nfse.servico.estado"
                :items="estados"
                box
                label="Estado"
              ></v-select>
            </v-flex>

            <v-flex sm5>
              <v-autocomplete
                v-model="nfse.servico.codigo_municipio"
                :items="getCidadesByUF(nfse.servico.estado)"
                label="Cidade"
                box
              ></v-autocomplete>
            </v-flex>

          </v-layout>
        </v-container>

        <v-subheader>Discriminação do Serviço</v-subheader>
        <v-divider></v-divider>

        <v-container fluid grid-list-md>
          <v-layout row wrap>

            <v-flex sm4>

              <v-autocomplete
                v-model="codigo_servico"
                :rules="[regras.obrigatorio]"
                :items="lista_codigos_atividades"
                box
                return-object
                item-text="codigo_servico"
                label="Código Serviço"
                @change="mudarAliquota"
              ></v-autocomplete>

            </v-flex>

            <v-flex sm3>
              <v-text-field box label="Valor Alíquota" suffix="%" v-model="nfse.servico.valores.aliquota" :rules="[regras.obrigatorio]" @change="calcularValores"></v-text-field>
            </v-flex>

            <v-flex sm3>
              <v-select
                v-model="nfse.servico.valores.iss_retido"
                :items="[{value: 1, text: 'Sim'}, {value: 2, text: 'Não'}]"
                box
                label="Iss retido"
                @change="calcularValores"
              ></v-select>
            </v-flex>


            <v-flex sm12>
              <v-textarea box label="Discriminação" v-model="nfse.servico.discriminacao" :rules="[regras.obrigatorio]"></v-textarea>
            </v-flex>
          </v-layout>
        </v-container>

        <v-subheader>Valores</v-subheader>
        <v-divider></v-divider>

        <v-container fluid grid-list-md>
          <v-layout row wrap>

            <v-flex sm3>
              <z-campo-valor box label="Valor Serviço" v-model="nfse.servico.valores.valor_servicos" decimais="2" :rules="[regras.maiorquezero]" :readonly="!isNotaAvulsa" @change="calcularValores"></z-campo-valor>
            </v-flex>

            <v-flex sm3>
              <z-campo-valor box label="Valor Deduções" v-model="nfse.servico.valores.valor_deducoes" decimais="2" @change="calcularValores"></z-campo-valor>
            </v-flex>

          </v-layout>

          <v-layout row wrap>

            <v-flex sm3>
              <z-campo-valor box label="Desconto Incondicionado" v-model="nfse.servico.valores.desconto_incondicionado" decimais="2" @change="calcularValores"></z-campo-valor>
            </v-flex>

            <v-flex sm3>
              <z-campo-valor box label="Desconto Condicionado" v-model="nfse.servico.valores.desconto_condicionado" decimais="2" @change="calcularValores"></z-campo-valor>
            </v-flex>

            <v-flex sm3>
              <z-campo-valor box label="Valor Outras Retenções" v-model="nfse.servico.valores.outras_retencoes" decimais="2" @change="calcularValores"></z-campo-valor>
            </v-flex>

        </v-layout>
        </v-container>

        <v-subheader>Impostos Federais</v-subheader>
        <v-divider></v-divider>

        <v-container fluid grid-list-md>
          <v-layout row wrap>

            <v-flex sm3>
              <z-campo-valor box label="Pis" v-model="nfse.servico.valores.valor_pis" decimais="2" @change="calcularValores"></z-campo-valor>
            </v-flex>

            <v-flex sm3>
              <z-campo-valor box label="Cofins" v-model="nfse.servico.valores.valor_cofins" decimais="2" @change="calcularValores"></z-campo-valor>
            </v-flex>

            <v-flex sm3>
              <z-campo-valor box label="IR" v-model="nfse.servico.valores.valor_ir" decimais="2" @change="calcularValores"></z-campo-valor>
            </v-flex>

          </v-layout>

          <v-layout row wrap>
            <v-flex sm3>
              <z-campo-valor box label="INSS" v-model="nfse.servico.valores.valor_inss" decimais="2" @change="calcularValores"></z-campo-valor>
            </v-flex>

            <v-flex sm3>
              <z-campo-valor box label="CSLL" v-model="nfse.servico.valores.valor_csll" decimais="2" @change="calcularValores"></z-campo-valor>
            </v-flex>

            <!-- <v-flex sm3>
              <z-campo-valor box label="Valor ISS Retido" v-model="nfse.servico.valores.iss_retido" decimais="2" readonly></z-campo-valor>
            </v-flex> -->
          </v-layout>
        </v-container>

        <v-subheader>Totalizador</v-subheader>
        <v-divider></v-divider>

        <v-container fluid grid-list-md>
          <v-layout row wrap>
            <v-flex sm3>
              <z-campo-valor box label="Base de Cálculo" v-model="nfse.servico.valores.base_calculo" decimais="2" readonly></z-campo-valor>
            </v-flex>

            <v-flex sm3>
              <z-campo-valor box label="Valor líquido da nota" v-model="nfse.servico.valores.valor_liquido_nfse" decimais="2" readonly></z-campo-valor>
            </v-flex>

            <v-flex sm3>
              <z-campo-valor box label="Valor do ISS a pagar" v-model="nfse.servico.valores.valor_iss" decimais="2" readonly></z-campo-valor>
            </v-flex>

            <!-- <v-flex sm3>
              <z-campo-valor box label="Valor ISS Retido" v-model="nfse.servico.valores.valor_iss_retido" decimais="2" readonly></z-campo-valor>
            </v-flex> -->




          </v-layout>
        </v-container>

        <v-container fluid class="pt-0">

          <v-layout row>
            <v-flex sm12>

              <v-alert :value="nfse.rps_situacao == 3" color="error" icon="new_releases">
                <div v-if="nfse.retorno_codigo"><b>Código Erro: </b> {{ nfse.retorno_codigo }}</div>
                <div v-if="nfse.retorno_mensagem"><b>Mensagem: </b> {{ nfse.retorno_correcao }}</div>
                <div v-if="nfse.erro"><b>Erro: </b> {{ nfse.erro }}</div>
                <div v-if="nfse.retorno_correcao"><b>Correção: </b> {{ nfse.retorno_mensagem }}</div>
              </v-alert>

            </v-flex>
          </v-layout>

        </v-container>


        <!-- <v-subheader>Intermediário Serviço</v-subheader>
        <v-divider></v-divider>

        <v-container fluid grid-list-md>
          <v-layout row wrap>

            <v-flex sm4>
              <v-text-field label="Razão Social" v-model="nfse.intermediario_servico.razao_social" readonly></v-text-field>
            </v-flex>

            <v-flex sm4>
              <v-text-field label="CNPJ" v-model="nfse.intermediario_servico.cnpj" readonly></v-text-field>
            </v-flex>

            <v-flex sm4>
              <v-text-field label="Inscrição Municipal" v-model="nfse.intermediario_servico.inscricao_municipal" readonly></v-text-field>
            </v-flex>


          </v-layout>
        </v-container>

        <v-subheader>Cosntrução Civil</v-subheader>
        <v-divider></v-divider>

        <v-container fluid grid-list-md>
          <v-layout row wrap>

            <v-flex sm4>
              <v-text-field label="Código de Obra" v-model="nfse.construcao_civil.razao_social" readonly></v-text-field>
            </v-flex>

            <v-flex sm4>
              <v-text-field label="Art" v-model="nfse.construcao_civil.art" readonly></v-text-field>
            </v-flex>

          </v-layout>
        </v-container> -->



        <v-card flat>
          <v-card-actions>

            <v-btn color="primary" @click="emitirNFSE" :loading="emitindo" class="mr-5">Emitir</v-btn>
            <span v-if="!form_valido" class="red--text">Fomulário possui inconsistências. Verifique os dados acima.</span>

            <v-spacer></v-spacer>

            <v-btn color="primary" @click="salvarNFSE" :loading="emitindo || salvando">Salvar</v-btn>
            <v-btn  @click="voltar" :loading="emitindo">Voltar</v-btn>
          </v-card-actions>
        </v-card>

        <div>
        </div>


      </v-container>

    </v-form>

    <ProcessoEmissaoDialog ref="dialogoProcessoEmissao" :nota="nfse"></ProcessoEmissaoDialog>
    <dialogo-buscar-clientes ref="DialogoBuscarClientes" @busca:selecionar="selecionarTomador"></dialogo-buscar-clientes>
  </div>
</template>

<script>
  // import {mapState, mapActions} from 'vuex'
  import { natureza_operacao, regime_especial_tributacao } from "./codigos.js";
  import aliquotas from "./aliquotas.js";
  import globals from '@/app/globals';
  import moment from 'moment'
  import { dataBR, valorBR, onlyNumbers, valorUS } from '@/helpers/formatacoes'
  import notaFiscaisApi from '@/services/notasfiscais'
  import ProcessoEmissaoDialog from "./ProcessoEmissaoDialog"
  import DialogoBuscarClientes from "@/components/buscas/DialogoBuscarClientes";

  export default {
    name: 'EmitirNotaServico',

    components: {
      ProcessoEmissaoDialog,
      DialogoBuscarClientes
    },

    data() {
      return {
        loadingList: false,
        form_valido: true,
        globals,
        aliquotas,
        cidades_nfse: [],
        codigo_servico: {
          codigo_servico: '1.03/631190001',
          descricao: '',
          aliquota: 2,
        },
        natureza_operacao: natureza_operacao,
        regime_especial_tributacao: regime_especial_tributacao,
        emitente: {
          id: null,
          cnpj: null
        },

        nfse: {
          emitente_id: null,
          emitente_cnpj: null,
          rps_situacao: 1,
          retorno_codigo: null,
          retorno_correcao: null,
          retorno_mensagem: null,

          numero: 1234,
          serie: 1,
          tipo:1,

          data_emissao: moment().format("DD/MM/YYYY"),
          natureza_operacao: 1,
          // 1 – Tributação no município
          // 2 - Tributação fora do município
          // 3 - Isenção
          // 4 - Imune
          // 5 – Exigibilidade suspensa por decisão judicial
          // 6 – Exigibilidade suspensa por procedimento administrativo

          regime_especial_tributacao: 5,
          // 1 – Microempresa municipal
          // 2 - Estimativa
          // 3 – Sociedade de profissionais
          // 4 – Cooperativa
          // 5 – MEI – Simples Nacional
          // 6 – ME EPP – Simples Nacional

          optante_simples_nacional: 1,
          incentivador_cultural: 2,
          status: 1,

          tomador: {
            cpfcnpj: '',
            razao_social: '',
            endereco: '',
            numero: '',
            complemento: '',
            bairro: '',
            codigo_municipio: '',
            uf: '',
            cep: '',
            email: '',
            telefone: '',
            id: null,
          },

          servico: {

            // codigo_servico: "1.03/631190001",
            item_lista_servico: "1.03",
            codigo_tributacao_municipio: "631190001",
            discriminacao: "",
            codigo_municipio: 2604106,
            estado: 'PE',

            valores: {
              valor_servicos: 0.00,
              desconto_incondicionado: 0.00,
              desconto_condicionado: 0.00,
              valor_deducoes: 0.00,
              valor_pis: 0.00,
              valor_cofins: 0.00,
              valor_inss: 0.00,
              valor_ir: 0.00,
              valor_csll: 0.00,
              iss_retido: 2,
              valor_iss: 0.00,
              valor_iss_retido: 0.00,
              outras_retencoes: 0.00,
              base_calculo: 0.00,
              aliquota: 2,
              valor_liquido_nfse: 0.00
            }
          },

          intermediario_servico: {
            razao_social: 'INSCRICAO DE TESTE SIATU - D AGUA -PAULINO S',
            cnpj: '99999999000191',
            inscricao_municipal: '8041700010'
          },

          construcao_civil: {
            codigo_obra: '1234',
            art: '1234'
          }

        },

        emitindo: false,
        regras: {
          obrigatorio: v => !!v || 'Campo obrigatório',
          cnpj: v => !!v.cnpj || 'CPF/CNPJ obrigatório',
          maiorquezero: v => valorUS(v) > 0 || 'O valor é orbigatório'
        },
        from_route: {
          name: ''
        },

        salvando: false
      }
    },

    computed: {
      fatura() {
        return this.$store.state.notasfiscais.fatura;
      },

      lista_codigos_atividades() {
        return aliquotas.map(item => {
          return {
            codigo_servico: `${item.codigo_servico}/${item.codigo_atividade}`,
            descricao: item.descricao_servico,
            aliquota: item.aliquota,
          };
        })
      },

      estados() {
        return this.$store.getters.getEstados.map(estado => {
          return estado.sigla
        });
      },

      emitentes() {
        return this.$store.state.listas.emitentes.map(emitente => {
          return {
            'text': emitente.razao,
            'value': emitente.id,
            'cnpj': emitente.cnpj,
            'ibpt': emitente.ibpt,
            'simples_nacional': emitente.simples_nacional,
          }
        })
      },

      isAdicionando() {
        return this.$route.name == 'NovaNotaServico';
      },

      isEditando() {
        return this.$route.name == 'EditarNotaServico';
      },

      isNotaAvulsa() {
        return this.$route.name == 'NotaServicoAvulsa';
      }
    },


    async mounted () {
      this.loadingList = true;
      await this.$store.dispatch('listas/emitentes');

      if (this.$route.params.subfatura_id) {
        if (this.$route.name == 'NovaNotaServico') {
          await this.$store.dispatch('notasfiscais/carregarSubFaturamento', this.$route.params.subfatura_id);
        }
      }

      if (this.$route.params.notafiscal_id) {
        if (this.$route.name == 'EditarNotaServico') {
          await this.$store.dispatch('notasfiscais/carregarNfse', this.$route.params.notafiscal_id);
        }
      }

      setTimeout(() => {
        this.$refs.form.resetValidation();
        this.loadingList = false;
        this.$vuetify.goTo("#topo");
        this.init();
      }, 0);
    },

    methods: {
      init() {
        // this.setHTMLOverflowYVisible(false);
        // this.$refs.dialogo.abrir();

        if (!this.isNotaAvulsa) {
          this.setEmitente(this.fatura);
          this.setDadosTomador(this.fatura);
          this.setDiscriminacaoNFSE(this.fatura);
          this.calcularValores();
        }
      },

      async emitirNFSE() {
        if (this.$refs.form.validate()) {
          await this.salvarNFSE();
          this.$refs.dialogoProcessoEmissao.abrir();
        }
      },

      async salvarNFSE() {
        if (!this.$refs.form.validate()) {
          return false;
        }

        try {
          this.salvando = true;
          this.nfse
          const nota_fiscal = await notaFiscaisApi.store(this.nfse);
          this.nfse.id = nota_fiscal.id;


          if (this.$route.name != 'EditarNotaServico') {
            this.$router.push(
              {
                name: 'EditarNotaServico',
                params: {
                  notafiscal_id: this.nfse.id
                }
              }
            )
          }

        } catch (error) {
          throw error;
        } finally {
          this.salvando = false;
        }
      },

      setDadosTomador(fatura) {
        let codigo_servico = {}
        if (this.isAdicionando && fatura.servico.tipo == 'telefonia') {
          codigo_servico = {
            codigo_servico: "1.07/620910000",
            descricao: "",
            aliquota: 2
          }
        } else {
          codigo_servico = {
            codigo_servico: [fatura.servico.item_lista_servico, fatura.servico.codigo_tributacao_municipio].join('/'),
            descricao: "",
            aliquota: fatura.servico.aliquota
          }
          // this.codigo_servico = [fatura.servico.item_lista_servico, fatura.servico.codigo_tributacao_municipio].join('/');
        }

        this.mudarAliquota(codigo_servico);

        this.nfse.tipo_nota                           = fatura.tipo_nota;
        this.nfse.id                                  = fatura.id;
        this.nfse.servico.item_lista_servico          = fatura.servico.item_lista_servico;
        this.nfse.servico.codigo_tributacao_municipio = fatura.servico.codigo_tributacao_municipio;
        this.nfse.servico.discriminacao               = fatura.servico.discriminacao;
        this.nfse.servico.codigo_municipio            = fatura.servico.codigo_municipio;
        this.nfse.servico.estado                      = "PE"; //fatura.servico.estado;
        this.nfse.rps_situacao                        = fatura.rps_situacao;
        this.nfse.retorno_codigo                      = fatura.retorno_codigo;
        this.nfse.retorno_correcao                    = fatura.retorno_correcao;
        this.nfse.retorno_mensagem                    = fatura.retorno_mensagem;

        this.nfse.tomador = {
          id: fatura.tomador.id,
          cpfcnpj: fatura.tomador.cpfcnpj,
          razao_social: fatura.tomador.razao_social,
          endereco: fatura.tomador.endereco,
          numero: fatura.tomador.numero,
          complemento: fatura.tomador.complemento,
          bairro: fatura.tomador.bairro,
          codigo_municipio: fatura.tomador.codigo_municipio,
          uf: fatura.tomador.uf,
          cep: fatura.tomador.cep,
          email: fatura.tomador.email,
          telefone: fatura.tomador.telefone,
        }

        this.nfse.servico.valores.id                      = fatura.servico.id;
        this.nfse.servico.valores.valor_servicos          = fatura.servico.valor_servicos;
        this.nfse.servico.valores.desconto_incondicionado = fatura.servico.desconto_incondicionado;
        this.nfse.servico.valores.desconto_condicionado   = fatura.servico.desconto_condicionado;
        this.nfse.servico.valores.valor_deducoes          = fatura.servico.valor_deducoes;
        this.nfse.servico.valores.iss_retido              = fatura.servico.iss_retido == null ? fatura.tomador.retem_iss == 1 ? 1 : 2 : fatura.servico.iss_retido ;
        this.nfse.servico.valores.valor_iss               = fatura.servico.valor_iss;
        this.nfse.servico.valores.valor_iss_retido        = fatura.servico.valor_iss_retido;
        this.nfse.servico.valores.outras_retencoes        = fatura.servico.outras_retencoes;
        this.nfse.servico.valores.base_calculo            = fatura.servico.base_calculo;
        this.nfse.servico.valores.aliquota                = fatura.servico.aliquota;
        this.nfse.servico.valores.valor_liquido_nfse      = fatura.servico.valor_liquido_nfse;

        // Impostos federais - somente se o emitente não for do simples nacional
        if (!this.emitente.simples_nacional) {
          this.nfse.servico.valores.valor_pis    = fatura.servico.valor_pis;
          this.nfse.servico.valores.valor_cofins = fatura.servico.valor_cofins;
          this.nfse.servico.valores.valor_inss   = fatura.servico.valor_inss || 0;
          this.nfse.servico.valores.valor_ir     = fatura.servico.valor_ir;
          this.nfse.servico.valores.valor_csll   = fatura.servico.valor_csll;
        }

      },

      setHTMLOverflowYVisible(state = true) {
        let html = document.querySelector("html");
        html.style.overflowY = state == false ? "hidden" : "";
      },

      mudarAliquota(codigo_servico) {
        setTimeout(() => {
          this.codigo_servico = codigo_servico;
          this.nfse.servico.valores.aliquota = codigo_servico.aliquota;

          let [ item_lista_servico, codigo_tributacao_municipio ] = codigo_servico.codigo_servico.split('/');
          this.nfse.servico.item_lista_servico = item_lista_servico;
          this.nfse.servico.codigo_tributacao_municipio = codigo_tributacao_municipio;
          this.calcularValores();
        }, 10);
      },

      getCidadesByUF(sigla) {
        if (sigla) {
          const estado = this.$store.state.estados.find(estado => estado.sigla == sigla);

          if (estado) {
            return estado.cidades.map(cidade => {
              return {
                'text': cidade.nome,
                'value': Number(cidade.codigo)
              }
            })
          }
        }

        return [];
      },


      setDiscriminacaoNFSE(fatura) {

        let tipo_servico = 'internet';
        if (fatura.servico.tipo == 'telefonia') {
          tipo_servico = 'intranet';
        }

        if (this.$route.name == 'NovaNotaServico') {
          this.nfse.servico.discriminacao = `Prestação de serviço de valor adicionado SVA.
Ponto de acesso a ${tipo_servico} banda larga prestado de ${fatura.periodo}
Valor aproximado dos tributos R$ ${valorBR(fatura.servico.valor_servicos * this.emitente.ibpt)} Fonte: (IBPT)
Vencimento: ${dataBR(fatura.vencimento)}`;
        }

      },

      onChangeEmitente(emitente) {
        this.nfse.emitente_id = emitente.value;
        this.nfse.emitente_cnpj = emitente.cnpj;
        // this.setDiscriminacaoNFSE(this.fatura);
      },

      setEmitente(fatura) {
        if (fatura.emitente_cnpj) {
          const emitente = this.emitentes.find(emitente => emitente.cnpj == fatura.emitente_cnpj);
          this.emitente = emitente;
          this.nfse.emitente_id = fatura.emitente_id;
          this.nfse.emitente_cnpj = fatura.emitente_cnpj;
          return true;
        }

        if (fatura.servico.tipo == 'internet') {
          const emitente = this.emitentes.find(emitente => emitente.cnpj == '12236554000171');
          this.emitente = emitente;
        } else if (fatura.servico.tipo == 'telefonia') {
          const emitente = this.emitentes.find(emitente => emitente.cnpj == '10527742000123');
          this.emitente = emitente;
        }

        setTimeout(() => {
          this.onChangeEmitente(this.emitente)
          // this.nfse.emitente_id = this.emitente.value;
          // this.nfse.emitente_cnpj = this.emitente.cnpj;
        }, 100);
      },

      calcularValores() {
        console.log('calcularValores');

        const valores = this.nfse.servico.valores;

        valores.base_calculo = valores.valor_servicos;
        valores.valor_liquido_nfse = valores.valor_servicos;

        valores.base_calculo = valores.base_calculo - valores.valor_deducoes;
        valores.base_calculo = valores.base_calculo - valores.desconto_incondicionado;

        valores.valor_liquido_nfse = valores.valor_liquido_nfse - valores.desconto_incondicionado;
        valores.valor_liquido_nfse = valores.valor_liquido_nfse - valores.desconto_condicionado;
        valores.valor_liquido_nfse = valores.valor_liquido_nfse - valores.outras_retencoes;

        valores.valor_liquido_nfse = valores.valor_liquido_nfse - valores.valor_pis;
        valores.valor_liquido_nfse = valores.valor_liquido_nfse - valores.valor_cofins;
        valores.valor_liquido_nfse = valores.valor_liquido_nfse - valores.valor_inss;
        valores.valor_liquido_nfse = valores.valor_liquido_nfse - valores.valor_ir;
        valores.valor_liquido_nfse = valores.valor_liquido_nfse - valores.valor_csll;

        valores.valor_iss = valores.base_calculo * (valores.aliquota/100);
        if (this.nfse.servico.valores.iss_retido == 1) {
            valores.valor_liquido_nfse = valores.valor_liquido_nfse - valores.valor_iss;
            valores.valor_iss_retido = valores.valor_iss;
        } else {
          valores.valor_iss_retido = null;
        }
      },

      voltar() {
        if (this.$route.name == 'NovaNotaServico') {
          this.$router.push({name: 'FaturamentoLista'});
        } else {
          this.$router.push({name: 'NotasFiscaisLista'});
        }
      },

      async buscarTomador(event) {
        if (this.isEditando || this.isAdicionando) {
          return false;
        }

        const cpfcnpj = onlyNumbers(event.target.value);
        if (cpfcnpj.length == 11 || cpfcnpj.length == 14) {
          await this.$store.dispatch('notasfiscais/getTomador', cpfcnpj);
          const tomador = this.$store.state.notasfiscais.tomador;

          this.setTomador(tomador);
        }
      },

      selecionarTomador(tomador) {
        this.setTomador(tomador);
      },

      setTomador(tomador) {
        this.nfse.tomador = {
          id: tomador.id,
          cpfcnpj: tomador.cpfcnpj,
          razao_social: tomador.razao,
          endereco: tomador.endereco,
          numero: tomador.numero,
          complemento: tomador.complemento,
          bairro: tomador.bairro,
          codigo_municipio: tomador.codigo_municipio,
          uf: tomador.estado,
          cep: tomador.cep,
          email: tomador.email,
          telefone: tomador.telefone,
        }
      }

    },


  }
</script>

<style scoped>

</style>
