<template>
  <v-chip
    flat
    small
    :color="chip_options.bg"
    :dark="chip_options.dark"
    @click="$emit('click')"
  >
    {{ chip_options.text }}
    <v-icon class="pointer" right v-if="situacao == -2">mdi-information-outline</v-icon>
  </v-chip>
</template>

<script>

  const options = {
    '1': { bg: '', dark: false, text: 'Não emitida' },
    '2': { bg: 'orange', dark: true, text: 'Processando' },
    '3': { bg: 'red', dark: true, text: 'Erro' },
    '4': { bg: 'green', dark: true, text: 'Autorizada' },
    '-4': { bg: 'orange', dark: true, text: 'Substituída' },
  };

  export default {

    props: {
      situacao: {
        type: [String, Number],
        default: 1
      }
    },

    computed: {
      chip_options() {
        return options[this.situacao];
      }
    },
  }
</script>

<style lang="scss" scoped>
  .pointer {
    cursor: pointer !important;
  }

</style>
