import axios from '@/plugins/Axios'

function list(filtro) {
  return axios.get(`notasfiscais`, {params: {...filtro}})
    .then(response => response.data);
}

function show(notafiscal) {
  return axios.get(`notasfiscais/${notafiscal.id}`)
    .then(response => response.data);
}

function store(notafiscal) {
  return axios.post(`notasfiscais`, notafiscal)
    .then(response => response.data);
}

function update(notafiscal) {
  return axios.put(`notasfiscais/${notafiscal.id}`, notafiscal)
    .then(response => response.data);
}

function excluir(id) {
  return axios.delete(`notasfiscais/${id}`)
    .then(response => response.data);
}

function emitirNota(id) {
  return axios.post(`notasfiscais/${id}/emitirnota`)
    .then(response => response.data);
}
function consultarProtocolo(protocolo) {
  return axios.get(`notasfiscais/consultarsituacao`, {params: {'protocolo': protocolo }})
    .then(response => response.data);
}
function consultarNota(protocolo) {
  return axios.get(`notasfiscais/consultarnfse`, {params: {'protocolo': protocolo }})
    .then(response => response.data);
}

function carregarNfse(nfse_id) {
  return axios.get(`notasfiscais/${nfse_id}`)
    .then(response => response.data);
}

function findByNfseNumero(nfse_numero) {
  return axios.get(`notasfiscais/${nfse_numero}/abrir`)
    .then(response => response.data);
}

function substituirNota(notas) {
  return axios.post(`notasfiscais/substituir`, notas)
    .then(response => response.data);
}

function carregarSubstituta(nfse_numero) {
  return axios.get(`notasfiscais/${nfse_numero}/substituta`)
    .then(response => response.data);
}

export default {
  list,
  show,
  store,
  update,
  excluir,
  emitirNota,
  consultarProtocolo,
  consultarNota,
  carregarNfse,
  findByNfseNumero,
  substituirNota,
  carregarSubstituta
}
