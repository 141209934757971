<template>
    <v-snackbar :timeout="timeout" :bottom="'bottom'" v-model="snackbar">
        {{ mensagem }}
        <v-btn flat color="green" @click.native="snackbar = false">Fechar</v-btn>
    </v-snackbar>
</template>

<script>
export default {

    data(){
        return {
            snackbar: false,
            mensagem: ''
        }
    },

    props: {
        timeout: {
            type: Number,
            default: 3000
        }
    },

    methods: {
        exibir(mensagem) {
            this.mensagem = mensagem;
            this.snackbar = true;
        }
    }

}
</script>

<style>

</style>
