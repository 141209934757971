import axios from '@/plugins/Axios'

function list() {
  return axios.get(`emitentes`)
    .then(response => response.data);
}

function store(emitente) {
  return axios.post(`emitentes`, emitente)
    .then(response => response.data);
}

function update(emitente) {
  return axios.put(`emitentes/${emitente.id}`, emitente)
    .then(response => response.data);
}

function destroy(id) {
  return axios.delete(`emitentes/${id}`)
    .then(response => response.data);
}


export default {
  list,
  store,
  update,
  destroy
}
