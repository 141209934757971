import notasFiscaisApi from '@/services/notasfiscais';
import faturamentosApi from '@/services/faturamentos';
import clientesApi from '@/services/clientes';

const nota = {
  nfse_numero: null,
  nfse_codigo_verificacao: null,
  nfse_data_emissao: null,
  tomador_cpfcnpj: null,
  tomador_razao_social: null,
  status: null,
  nota_substituta: null,
  nota_substituida: null,
  emitente_id: null
}

export default {
  namespaced: true,

  state: {
    notafiscal: {},
    notasfiscais: [],
    loading: false,
    fatura: {},
    subfatura: {},
    nota_fiscal_cancelada: {
      nfse_numero: null,
      nfse_codigo_verificacao: null,
      nfse_data_emissao: null,
      tomador_cpfcnpj: null,
      tomador_razao_social: null,
      status: null,
      nota_substituta: null,
      nota_substituida: null,
      emitente_id: null
    },
    nota_substituta: {
      nfse_numero: null,
      nfse_codigo_verificacao: null,
      nfse_data_emissao: null,
      tomador_cpfcnpj: null,
      tomador_razao_social: null,
      status: null,
      nota_substituta: null,
      nota_substituida: null,
      emitente_id: null
    },
    tomador: {}

  },

  getters: {
    // TODO:
  },

  mutations: {
    SET_NOTAFISCAL(state, notafiscal) {
      state.notafiscal = notafiscal;
    },

    SET_NOTASFISCAIS(state, notasfiscais) {
      state.notasfiscais = notasfiscais;
    },

    RESET_NOTAFISCAL(state, notafiscal) {
      state.notafiscal = {};
    },

    SET_LOADING(state, payload) {
      state.loading = payload;
    },

    SET_FATURA(state, fatura) {
      state.fatura = fatura;
    },

    EXCLUIR(state, id) {
      let index = state.notasfiscais.data.findIndex(nota => nota.id == id);
      if (index > -1) {
        state.notasfiscais.data.splice(index, 1);
      }
    },

    SET_SUBFATURA(state, subfatura) {
      state.subfatura = subfatura;
    },

    SET_NOTAFISCAL_CANCELADA(state, nota_fiscal_cancelada) {
      state.nota_fiscal_cancelada = nota_fiscal_cancelada;
    },

    SET_TOMADOR(state, tomador) {
      state.tomador = tomador;
    },

    SET_NOTAS_SUBSTITUTAS(state, notas) {
      state.nota_fiscal_cancelada = notas.nota_substituida;
      state.nota_substituta = notas.nota_substituta;
    },

    RESET_NOTAS(state) {
      state.nota_fiscal_cancelada = {...nota};
      state.nota_substituta = {...nota};
    }
  },

  actions: {
    async listarNotasFiscais({commit, state}, filtro) {
      try {
        commit('SET_LOADING', true);
        let notasfiscais = await notasFiscaisApi.list(filtro);
        commit('SET_NOTASFISCAIS', notasfiscais);

      } finally {
        commit('SET_LOADING', false);
      }
    },

    async abrirNotasFiscais({commit}, notafiscal_id) {
      let notafiscal = await notasFiscaisApi.abrirNotafiscal(notafiscal_id);
      commit('SET_NOTAFISCAL', notafiscal);
    },

    async excluirNotaFiscal({commit}, notafiscal_id) {
      await notasFiscaisApi.excluir(notafiscal_id);
      commit('EXCLUIR', notafiscal_id);
    },

    async carregarSubFaturamento({commit}, subfatura_id) {
      let faturamento = await faturamentosApi.carregarSubFaturamento(subfatura_id);
      commit('SET_FATURA', faturamento);
    },

    async carregarNfse({commit}, subfatura_id) {
      let faturamento = await notasFiscaisApi.carregarNfse(subfatura_id);
      commit('SET_FATURA', faturamento);
    },

    async getTomador({commit}, cpfcnpj) {
      let tomador = await clientesApi.getTomador(cpfcnpj);
      commit('SET_TOMADOR', tomador);
    },

    async carregarSubstituta({commit}, nfse_numero) {
      let notas = await notasFiscaisApi.carregarSubstituta(nfse_numero);
      commit('SET_NOTAS_SUBSTITUTAS', notas);
    },
  }
}
