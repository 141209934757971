import { render, staticRenderFns } from "./zDialogo.vue?vue&type=template&id=7941feb2&scoped=true&"
import script from "./zDialogo.vue?vue&type=script&lang=js&"
export * from "./zDialogo.vue?vue&type=script&lang=js&"
import style0 from "./zDialogo.vue?vue&type=style&index=0&id=7941feb2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7941feb2",
  null
  
)

export default component.exports