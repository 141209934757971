import axios from '@/plugins/Axios'

export default new class ServiceClientes {

  salvar(cliente) {
    return axios.post('/clientes', cliente)
      .then(response => response.data);
  }

  atualizar(cliente) {
    return axios.put('/clientes/'+ cliente.id, cliente)
      .then(response => response.data);
  }

  buscar(busca) {
    return axios.get(`clientes/busca?q=${busca}`).then((response) => response.data);
  }

  listarClientes() {
    return axios.get('clientes')
      .then(response => response.data);
  }

  abrirCliente(id) {
    return axios.get('/clientes/' + id)
      .then(response => response.data);
  }

  excluirCliente(id) {
    return axios.delete('/clientes/' + id)
      .then(response => response.data);
  }

  listarPlanos() {
    return axios.get('/planos/lista')
      .then(response => response.data);
  }

  listarFaturas(id) {
    return axios.get('/clientes/' + id + '/faturas')
      .then(response => response.data);
  }

  listarRamais() {
    return axios.get('/ramaislivres')
      .then(response => response.data);
  }

  adicionarRamal(cliente_id, ramal) {
    return axios.post('/clientes/'+ cliente_id +'/ramal', {ramal: ramal})
      .then(response => response.data);
  }

  removerRamal(ramal_id) {
    return axios.delete('/clientes/'+ ramal_id + '/ramal')
      .then(response => response.data);
  }

  getTomador(cpfcnpj) {
    return axios.get(`/clientes/get-tomador?cpfcnpj=${cpfcnpj}`)
      .then(response => response.data);
  }
}
