<template>
  <v-text-field
    v-bind="attributes"
    v-model="valor"
    ref="input"
    type="tel"
    :flat="isToBeFlat"
    :disabled="disabled"
    :readonly="readonly"
    :class="{ transparente: transparente && isToBeFlat, direita: direita }"
    @input="onInput"
    @focus="onFocus"
    @blur="onBlur"
    @change="$emit('change', $event)"
    @keyup="$emit('keyup', $event)"
    @keydown="$emit('keydown', $event)"
    @keypress="onKeyPress"
    @click:append="$emit('click:append')"
  >
  </v-text-field>
</template>

<script>
export default {
  props: {
    value: {},
    decimais: {
      type: [String, Number],
      default: 2,
    },
    transparente: Boolean,
    raiseOnFocus: Boolean,
    selectOnFocus: {
      type: Boolean,
      default: true,
    },
    flat: Boolean,
    readonly: Boolean,
    disabled: Boolean,
  },

  data() {
    return {
      valor: "0,00",
      internalChange: false,
      isFocused: false,
    };
  },

  computed: {
    attributes() {
      return { ...this.$attrs };
    },

    isToBeFlat() {
      if (this.flat && this.raiseOnFocus && this.isFocused) {
        return false;
      } else {
        return this.flat;
      }
    },

    direita() {
      return true;
      if (this.isFocused) {
        if (this.disabled || this.readonly) {
          return true;
        }
        return false;
      } else {
        return true;
      }
    },
  },

  mounted() {
    this.valor = this.formataNumero(this.value, this.decimais);
  },

  methods: {
    onInput(value) {
      this.internalChange = true;
      //   this.valor = value.replace(/[^0-9+\-Ee,]/g, '');
      //   var formattedValue = this.valor.trim()
      //       .slice(0,
      //           this.valor.indexOf(',') === -1
      //           ? this.valor.length
      //           : this.valor.indexOf(',') + this.decimais + 1
      //       )
      //       if (formattedValue !== this.valor) {
      //           this.valor = formattedValue
      //       }
      //   console.log(this.valorUS(this.valor), this.valor, value);
      this.$emit("input", this.valorUS(this.valor));
    },

    onKeyPress(event) {
      const vm = this;
      function onlynumber(evt) {
        let theEvent = evt;
        let key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode(key);
        //let regex = /^[0-9.,]+$/;

        let regex = /^[0-9,]+$/
        if (vm.decimais == 0) {
          regex = /^[0-9]+$/;
        }

        if (!regex.test(key)) {
            theEvent.returnValue = false;
            if (theEvent.preventDefault) theEvent.preventDefault();
        }
      }

      onlynumber(event);
    },

    onBlur($event) {
      this.internalChange = false;
      this.isFocused = false;
      this.valor = this.formataNumero(this.value, this.decimais);
      this.$emit('blur', $event)
    },

    onFocus(e) {
      this.isFocused = true;
      if (this.selectOnFocus) {
        e.target.selectionStart = 0;
        e.target.selectionEnd = e.target.value.length;
      } else {
        setTimeout(() => {
          // e.target.selectionStart = e.target.selectionEnd = e.target.value.length;
          if (!this.disabled || !this.readonly) {
            e.target.setSelectionRange(
              e.target.value.length,
              e.target.value.length
            );
          }
        }, 0);
      }
    },

    focus() {
      this.$refs.input.focus();
    },

    valorUS(valor) {
      if (valor) {
        return parseFloat(valor.replace(/\./g, "").replace(",", "."));
      }
    },

    valorBR(valor) {
      if (valor) {
        return valor.toString().replace(/\./g, ",");
      }
    },

    trunc(valor, decimais) {
      decimais = !decimais ? 2 : decimais;
      valor = valor.replace(/\./g, "").replace(",", ".");
      valor =
        Math.trunc(parseFloat(valor) * Math.pow(10, decimais)) /
        Math.pow(10, decimais);
      return valor;
    },

    formataNumero(number, decimals, dec_point, thousands_sep) {
      decimals = typeof decimals !== "undefined" ? decimals : 2;
      dec_point = typeof dec_point !== "undefined" ? dec_point : ",";
      thousands_sep =
        typeof thousands_sep !== "undefined" ? thousands_sep : ".";

      number = (number + "").replace(/[^0-9+\-Ee.]/g, "");
      var n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = typeof thousands_sep === "undefined" ? "," : thousands_sep,
        dec = typeof dec_point === "undefined" ? "." : dec_point,
        s = "",
        toFixedFix = function (n, prec) {
          var k = Math.pow(10, prec);
          return "" + (Math.round(n * k) / k).toFixed(prec);
        },
        toFixedFix2 = function (n, prec) {
          return (
            "" + (Math.trunc(n * 1000000000000) / 1000000000000).toFixed(prec)
          );
        };
      // Fix for IE parseFloat(0.55).toFixed(0) = 0;
      s = (prec ? toFixedFix2(n, prec) : "" + Math.round(n)).split(".");
      if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
      }
      if ((s[1] || "").length < prec) {
        s[1] = s[1] || "";
        s[1] += new Array(prec - s[1].length + 1).join("0");
      }
      return s.join(dec);
    },
  },

  watch: {
    value(val, oldval) {
      if (!this.internalChange) {
        this.valor = this.formataNumero(val, this.decimais);
      }
    },
  },
};
</script>

<style scoped>
.direita.v-input >>> input {
  text-align: right !important;
}

.transparente >>> .v-input__slot {
  background: none;
}
</style>
