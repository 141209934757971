<template>
  <div v-scroll="onScroll" id="topo">

    <plano-form ref="planodialogo" :plano="plano" @onSalvar="onSalvar" @onAtualizar="onAtualizar"></plano-form>

    <z-loading :loading="loadingList" height="100px" color="indigo"></z-loading>

    <v-container v-show="!loadingList">
      <v-layout row wrap justify-center>
        <v-flex sm12 xl10>

          <v-subheader>Planos</v-subheader>

          <v-card>
            <v-toolbar card color="white">
              <div style="width: 400px;">
                <v-text-field autofocus flat solo prepend-icon="search" placeholder="Pesquisar" v-model="search" hide-details></v-text-field>
              </div>

              <v-spacer></v-spacer>

              <v-btn color="primary" icon @click="adicionar">
                  <v-icon>add</v-icon>
              </v-btn>

            </v-toolbar>

            <v-divider></v-divider>

            <v-data-table :search="search" :headers="headers" :items="planos" class="elevation-1" :rows-per-page-items="globals.PAGINACAO">
              <template slot="items" slot-scope="props">
                <td>{{ props.item.descricao }}</td>
                <td>{{ props.item.valor_internet | valorBR }} ({{ props.item.velocidade }})</td>
                <td>{{ props.item.valor_telefonia | valorBR }} ({{ props.item.franquia }} <span v-if="props.item.franquia > 0">min</span>)</td>
                <td class="justify-center align-center layout px-0">
                  <v-tooltip top>
                    <v-btn slot="activator" icon @click="editar(props.item)">
                      <v-icon color="grey darken-1">edit</v-icon>
                    </v-btn>
                    <span>Editar</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <v-btn slot="activator" icon ripple @click.stop="abrirDialogoExcluir(props.item)">
                      <v-icon color="grey">delete</v-icon>
                    </v-btn>
                    <span>Excluir</span>
                  </v-tooltip>
                </td>
              </template>
            </v-data-table>
          </v-card>

        </v-flex>
      </v-layout>
    </v-container>

    <div style="height: 100px"></div>

    <z-dialogo ref="dialogoExcluir" texto="Deseja excluir esse plano?">
        <v-btn color="grey darken-1" flat @click="$refs.dialogoExcluir.fechar()" :disabled="loading">Cancelar</v-btn>
        <v-btn color="primary darken-1" flat @click="confirmarExcluir" :loading="loading" :disabled="loading">Excluir</v-btn>
    </z-dialogo>

    <v-fab-transition>
      <v-btn style="right: 70px" color="red darken-1" dark fab fixed bottom right v-show="showfab" @click="$vuetify.goTo('#topo', {duration: 300, offset: 0})">
         <v-icon>keyboard_arrow_up</v-icon>
      </v-btn>
    </v-fab-transition>
  </div>
</template>

<script>

  import PlanoForm from './PlanoForm'
  import globals from "@/app/globals";

  export default {

    components: {
      PlanoForm
    },

    props: {

    },

    data() {
      return {
        globals,
        search: '',
        plano: {},
        planos: [],
        loading: false,
        loadingList: true,
        offsetTop: 0,
        headers:[
          { text: 'Descrição', value: 'descricao'},
          { text: 'Internet', value: 'velocidade'},
          { text: 'Telefonia', value: 'franquia'},
          { text: 'Opções', value: '', align: 'center'}
        ],
        pagination: {},
      }
    },

    mounted() {
      this.$store.commit('SET_TELA', 'Planos');

      this.listarPlanos();
    },

    methods: {
      adicionar() {
        this.$refs.planodialogo.abrir();
      },

      onSalvar(plano) {
        this.planos.push(plano);
      },

      onAtualizar(plano) {
        let index = this.planos.findIndex(plano_ => plano_.id == plano.id);
        this.planos.splice(index, 1, plano);
      },

      editar(plano) {
        this.plano = this.clone(plano);
        this.$refs.planodialogo.abrir();
      },

      listarPlanos() {
        this.loadingList = true;
        this.$axios.get('/planos')
          .then(response => {
            this.loadingList = false;
            this.planos = response.data
          })
          .catch(error => {
            this.loadingList = false;
            console.log('Catch:', error.response.data)
          })
      },

      abrirDialogoExcluir(plano) {
        this.plano = plano;
        this.$refs.dialogoExcluir.abrir();
      },

      confirmarExcluir() {
        this.loading = true;
        this.$axios.delete('/planos/' + this.plano.id)
          .then(response => {
            this.remover(this.plano.id);
            this.$refs.dialogoExcluir.fechar()
            this.loading = false;
          })
          .catch(error => {
            this.loading = false;
            console.log(error.response.data)
          })
      },

      remover(id) {
        let index = this.planos.findIndex(plano => plano.id == id);
        this.planos.splice(index, 1);
      },

      onScroll() {
        this.offsetTop = window.pageYOffset || document.documentElement.scrollTop;
      }
    },

    computed: {
      showfab() {
        return this.offsetTop > 50;
      }
    }
  }
</script>

<style scoped>

</style>
