<template>
  <z-dialogo ref="dialogo" titulo="Nova Faturamento" dividers largura="800px" persistent>
      <div slot="texto">

        <v-form ref="form" v-model="valido" lazy-validation>

          <v-container fluid class="pa-0">
            <v-layout row wrap>
              <v-flex sm12>
                <v-select
                  v-model="coleta.tipo"
                  box
                  label="Tipo de Fatura"
                  hide-details
                  :items="[{ text: 'Internet', value: 'internet' }, { text: 'Telefonia', value: 'telefonia' }]"
                  @change="onChangeMesReferencia"
                ></v-select>
              </v-flex>

              <v-flex sm12 v-if="coleta.tipo == 'telefonia'">
                <v-select
                box
                v-model="coleta.arquivo_id"
                name="id"
                label="Arquivo Coleta"
                :items="arquivos"
                hide-details
                >
              </v-select>

              </v-flex>
            </v-layout>

            <v-layout row>
              <v-flex sm4>
                <v-select
                  v-model="coleta.dia_vencimento"
                  box
                  label="Dia Vencimento"
                  hide-details
                  :items="vencimentos"
                  :rules="[rules.obrigatorio]"
                  @change="onChangeMesReferencia"
                ></v-select>
              </v-flex>
              <v-flex sm4>
                <v-select
                  v-model="coleta.mes"
                  box
                  :items="meses"
                  label="Mês de Referência"
                  hide-details
                  @change="onChangeMesReferencia"
                ></v-select>
              </v-flex>
              <v-flex sm4>
                <v-text-field
                  v-model="coleta.ano"
                  box
                  ref="ano"
                  name="ano"
                  label="Ano"
                  hide-details
                  :readonly="false"
                ></v-text-field>

                <!-- <v-select
                  :items="anos"
                  label="Ano"
                  v-model="coleta.ano"
                  @change="onChangeMesReferencia"
                ></v-select> -->
              </v-flex>
            </v-layout>

            <v-layout row>
              <v-flex sm12>
                <v-text-field
                  v-model="coleta.descricao"
                  ref="descricao"
                  box
                  label="Descrição Faturamento"
                  :rules="[rules.tamanhoMinimo]"
                  hide-details
                  autofocus required
                  :readonly="false"
                ></v-text-field>
              </v-flex>
            </v-layout>

            <v-layout row v-if="coleta.tipo == 'internet'">
              <v-flex sm4>
                <z-date-picker-menu
                  v-model="coleta.periodo.data_inicial"
                  label="Período Inicial"
                  box
                  hide-details
                  left
                  :rules="[rules.obrigatorio]"
                ></z-date-picker-menu>
              </v-flex>
              <v-flex sm4>
                <z-date-picker-menu
                  v-model="coleta.periodo.data_final"
                  label="Período Final"
                  box
                  hide-details
                  left
                  :rules="[rules.obrigatorio]"
                ></z-date-picker-menu>
              </v-flex>
            </v-layout>
          </v-container>

        </v-form>
      </div>
      <v-btn flat @click="fechar">Cancelar</v-btn>
      <v-btn color="primary" @click="processar" :loading="processando" :disabled="!valido">Gerar</v-btn>
  </z-dialogo>
</template>

<script>

  import dayjs from 'dayjs';

  export default {
    name: "DialogoNovaColeta",

    data() {
      return {
        coleta: {
          arquivo_id: null,
          nome: null,
          descricao: null,
          tipoarquivo: null,
          mes: null,
          ano: null,
          dia_vencimento: null,
          tipo: 'internet',
          periodo: {
            data_inicial: '',
            data_final: ''
          }
        },
        valido: true,
        processando: false,
        meses: [
          {text: 'Janeiro', value: 1},
          {text: 'Fevereiro', value: 2},
          {text: 'Março', value: 3},
          {text: 'Abril', value: 4},
          {text: 'Maio', value: 5},
          {text: 'Junho', value: 6},
          {text: 'Julho', value: 7},
          {text: 'Agosto', value: 8},
          {text: 'Setembro', value: 9},
          {text: 'Outubro', value: 10},
          {text: 'Novembro', value: 11},
          {text: 'Dezembro', value: 12}
        ],

        rules: {
          obrigatorio(v) {
            return !!v || 'Campo obrigatório';
          },

          tamanhoMinimo(v) {
            return !!v && v.length >= 5 || 'Informe uma descrição para esse arquivo'
          }
        },
      }
    },

    computed: {
      arquivos() {
        return this.$store.state.listas.arquivos.map(arquivo => {
          return {
            'text': `${arquivo.descricao} [${arquivo.nome}]`,
            'value': arquivo.id,
            'arquivo': arquivo.nome,
          }
        });
      },

      vencimentos() {
        return this.$store.state.listas.vencimentos;
      }
    },

    methods: {
      async abrir() {
        await this.$store.dispatch('listas/listarVencimentos');
        await this.$store.dispatch('listas/arquivos');
        this.$refs.dialogo.abrir();


        setTimeout(() => {
          this.$refs.form.resetValidation();

          const currentMonth = new Date().getMonth();
          const currentYear = new Date().getFullYear();

          this.coleta.mes = currentMonth + 1;
          this.coleta.ano = currentYear;
          this.coleta.dia_vencimento = this.vencimentos[0];
          this.coleta.arquivo_id = null;
          this.coleta.tipo = 'internet';

          this.onChangeMesReferencia();
        }, 10);
      },

      fechar() {
        this.setPeriodoFaturamento('internet');
        this.$refs.dialogo.fechar();
      },

      async processar() {

        if (this.$refs.form.validate()) {

          try {
            this.processando = true;
            const response = await this.$axios.post('/arquivos/processar-coleta', this.coleta);
            this.$emit('onColetaProcessada');
            console.log(response.data);
          } catch (error) {
            console.log(error.response);
          } finally {
            this.processando = false;
            this.fechar();
          }

        }
      },

      onChangeMesReferencia() {
        this.setDescricaoFaturamento();

        setTimeout(() => {
          this.setPeriodoFaturamento(this.coleta.tipo);
        }, 10);
      },

      setDescricaoFaturamento() {
        const mes = (this.meses[this.coleta.mes - 1].text).toUpperCase().toUpperCase();
        const vencimento = this.coleta.dia_vencimento;
        const hoje = this.hoje().split('-').reverse().join("/");
        const tipo = this.capitalized(this.coleta.tipo)

        this.coleta.descricao = `${tipo} - Faturamento ${mes} ${hoje} (Vencimento: dia ${vencimento})`;
      },

      setPeriodoFaturamento(tipo) {

        let mes_inicial = (this.coleta.mes - 1).toString().padStart(2, "0");
        let ano_inicial = this.coleta.ano;
        let mes_final = this.coleta.mes.toString().padStart(2, "0");
        let ano_final = this.coleta.ano;

        if (this.coleta.mes == 1) {
          mes_inicial = "12";
          ano_inicial -= 1;
        }

        this.coleta.periodo.data_inicial = `${ano_inicial}-${mes_inicial}-22`;
        this.coleta.periodo.data_final = `${ano_final}-${mes_final}-21`;

        if (tipo == 'telefonia') {
          this.coleta.arquivo_id = this.arquivos[0].value;
          this.coleta.periodo.data_inicial = '';
          this.coleta.periodo.data_final = '';
        } else {
          this.coleta.arquivo_id = null;
        }

      }
    },
  }
</script>

<style lang="scss" scoped>

</style>
