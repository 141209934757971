<template>
  <z-dialogo
    persistent
    ref="dialogo"
    titulo="Emissão Nota Fiscal de Serviço"
    largura="800px"
    altura_="600px"
    dividers
    scrollable
    :loading="emitindo"
    @fechar="onFechar"
  >


    <div slot="texto">

      <v-container class="pa-0">
        <v-layout row>
          <v-flex sm12>

            <div class="text-xs-center">
              <h1 class="text-xs-center">{{ mensagem }}</h1>
            </div>

            <!-- <v-timeline align-top dense>

              <v-timeline-item v-for="(etapa, index) in etapas" :color="etapa_atual == index + 1 ? 'teal lighten-3' : 'grey lighten-2'" small :key="index">
                <v-layout pt-3>
                  <v-flex>
                    <div class="caption">
                      <span>{{ etapa.descricao }}</span>

                      <v-progress-circular
                        v-show="etapa_atual == index + 1"
                        size="25"
                        width="2"
                        color="primary"
                        indeterminate
                        class="ml-3"
                      ></v-progress-circular>

                    </div>
                  </v-flex>
                </v-layout>
              </v-timeline-item>

              <v-timeline-item :color="etapa_atual == 4 ? 'teal lighten-3' : 'grey lighten-2'" small>
                <v-layout pt-3>
                  <v-flex>
                    <div class="caption">
                      <span>Finalizado</span>
                    </div>
                  </v-flex>
                </v-layout>
              </v-timeline-item>

            </v-timeline> -->

          </v-flex>
        </v-layout>

        <v-layout row>
          <v-flex sm12>

            <v-alert :value="alert" color="error" icon="new_releases" class="mt-4">
              <div v-if="error.codigo"><b>Código Erro: </b> {{ error.codigo }}</div>
              <div v-if="error.mensagem"><b>Mensagem: </b> {{ error.mensagem }}</div>
              <div v-if="error.erro"><b>Erro: </b> {{ error.erro }}</div>
              <div v-if="error.correcao"><b>Correção: </b> {{ error.correcao }}</div>
            </v-alert>

          </v-flex>
        </v-layout>
      </v-container>

    </div>

    <v-btn color="grey" flat @click="fechar" :disabled="emitindo">Fechar</v-btn>
    <v-btn v-if="!$config.IS_PRODUCTION" color="primary" @click="emitir">EmitirLoop</v-btn>
    <v-btn color="primary" @click="emitirNFSE" :loading="emitindo">Emitir</v-btn>
    <v-btn v-if="!$config.IS_PRODUCTION" color="green" dark @click="consultarProtocolo">Consultar Protocolo</v-btn>
    <v-btn v-if="!$config.IS_PRODUCTION" color="pink" dark @click="consultarNFSe">Consultar NFSe</v-btn>

  </z-dialogo>
</template>

<script>

import notaFiscaisApi from '@/services/notasfiscais'


export default {
  name: "ProcessoEmissaoDialog",

  props: ['nota'],

  data() {
    return {
      alert: false,
      emitindo: false,
      mensagem: '',
      etapas: [
        { descricao: 'Enviando nota fiscal' },
        { descricao: 'Consultando Protocolo' },
        { descricao: 'Consultando NFSe' },
      ],
      etapa_atual: 0,
      tentativas: 10,
      intervalo: 5000,
      situacao: 0,

      protocolo: null,

      error: {
        codigo: null,
        mensagem: null,
        erro: null,
        correcao: null
      }
    };
  },

  methods: {
    abrir() {
      this.mensagem = 'Clique no botão Emitir para enviar a nota fiscal.';
      this.$refs.dialogo.abrir();
      // this.emitirNFSE();
    },

    onFechar() {
      this.etapa_atual = 0;
      this.alert = false;
    },

    fechar() {
      this.onFechar();
      this.$refs.dialogo.fechar();
    },

    async emitir() {

      try {
        await this.emitirNFSE();

      } catch (error) {
        return false;
      }

      while (this.tentativas > 0) {

        console.log(this.tentativas);

        try {
          const response = await this.consultarProtocolo();
          if (response) {
            return false;
          }

        } catch (error) {
          throw error;
        }

        await new Promise(resolve => setTimeout(resolve, this.intervalo));
        this.tentativas--;
      }
      console.log("Terminou")


    },

    async emitirNFSE() {

      try {
        this.emitindo = true;
        this.alert = false;
        this.mensagem = 'Enviando a Nota Fiscal...'

        this.etapa_atual = 1;
        let response = await notaFiscaisApi.emitirNota(this.nota.id);
        if (response.protocolo) {

          this.protocolo = response.protocolo;
          this.etapa_atual = 0;

          setTimeout(() => {
            this.mensagem = 'Protocolo Recebido.'
            setTimeout(() => {
              this.emitindo = false;

              this.$router.push({
                name: 'FaturamentoLista',
              });
            }, 2000);
          }, 2000);

        }

      } catch (error) {
        this.emitindo = false;

        if (error.response) {
          const { data } = error.response;
          console.log({...data});

          if (data.error || data.message) {
            this.error.mensagem = data.message;
            this.error.erro = data.error;
          }

          if (data.codigo) {
            this.error.codigo   = data.codigo;
            this.error.mensagem = data.mensagem;
            this.error.correcao = data.correcao;
          }

          this.alert = true;
          this.etapa_atual = 0;

          this.mensagem = 'Houve um problema ao emitir a nota fiscal.'
        }

        throw error;

      }
    },


    async consultarProtocolo() {
      try {
        this.zeraErros()

        this.etapa_atual = 2;
        const response = await notaFiscaisApi.consultarProtocolo(this.protocolo);

        if (response.situacao) {

          // this.etapa_atual = 0;

          if (response.situacao == 1) {
            this.error.mensagem = response.message;
            this.alert = true;
            return false;
          }

          if (response.situacao == 2) {
            return false;
          }

          if (response.situacao > 3) {
            await this.consultarNFSe();
            return true;
          }

        }


      } catch (error) {

        console.log(error);

        if (error.response) {
          const { data } = error.response;
          console.log({...data});

          if (data.codigo) {
            this.error.codigo   = data.codigo;
            this.error.mensagem = data.mensagem;
            this.error.correcao = data.correcao;
            this.alert = true;
          }

          if (data.situacao == 3) {
            await this.consultarNFSe();
          }

          this.etapa_atual = 0;

          throw error;
        }

      }
    },

    async consultarNFSe() {
      try {
        this.zeraErros()

        this.etapa_atual = 3;
        const response = await notaFiscaisApi.consultarNota(this.protocolo);

        this.etapa_atual = 4;

        setTimeout(() => {
          this.$router.push({
            name: 'NotasFiscaisLista',
          })
        }, 1000);

      } catch (error) {

        console.log(error);
        if (error.response) {
          const { data } = error.response;
          console.log({...data});

          if (data.codigo) {
            this.error.codigo   = data.codigo;
            this.error.mensagem = data.mensagem;
            this.error.correcao = data.correcao;
            this.alert = true;
          }
        }

        this.etapa_atual = 0;
      }
    },


    zeraErros() {
      this.error = {
        codigo: null,
        mensagem: null,
        erro: null,
        correcao: null
      }
    }

  },
};
</script>

<style lang="scss" scoped></style>
