<template>
  <div v-scroll="onScroll" id="topo">

    <z-loading :loading="initialLoading" height="100px" color="indigo"></z-loading>

    <v-container fluid v-show="!initialLoading">

      <v-layout row wrap justify-center>
        <v-flex sm12 xl10>
          <v-card>
            <v-card-title>
              Filtro
            </v-card-title>
            <v-divider></v-divider>
            <!-- <v-card-text> -->

              <v-form>
                <v-container fluid>
                  <v-layout row wrap>
                    <v-flex sm9>
                      <v-select
                        box
                        v-model="coleta"
                        label="Faturas Geradas"
                        item-text="descricao"
                        item-value="id"
                        append-outer-icon="delete"
                        persistent-hint
                        return-object
                        :items="coletas"
                        :hint="periodo"
                        @change="onChangeColeta"
                      >
                        <template v-slot:append-outer >
                          <v-btn icon @click="excluirColeta" class="ma-0">
                            <v-icon color="grey darken-1">mdi-delete</v-icon>
                          </v-btn>
                        </template>
                      </v-select>
                    </v-flex>

                    <v-flex sm3>
                      <v-select
                        box
                        v-model="filtro.tipo_cliente"
                        label="Tipo Cliente"
                        :items="tipos_cliente"
                        @change="filtrar"
                      ></v-select>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>

            <!-- </v-card-text> -->
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn color="primary" @click="abrirNovaColeta" :disabled="faturas.length == 0">
                <v-icon>add</v-icon>
                Novo Faturamento
              </v-btn>

              <v-spacer></v-spacer>
              <v-btn flat @click="abrirDialogoImprimir" :disabled="faturas.length == 0">
                <v-icon left>print</v-icon>
                Imprimir
              </v-btn>
              <v-btn color="primary" @click="filtrar">Filtrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>

      <v-layout row wrap justify-center>
        <v-flex sm12 xl10>

            <!-- <v-layout row wrap>
              <v-menu offset-y>
                <v-subheader slot="activator">
                  Faturas Geradas
                  <span style="margin-left: 10px" v-if="coleta.id">({{ coleta.descricao }}) <v-icon>arrow_drop_down</v-icon></span>
                </v-subheader>
                <v-list>
                  <v-list-tile v-for="coleta in coletas" :key="coleta.id" @click="selecionarColeta(coleta)">
                    <v-list-tile-title>{{ coleta.descricao }}</v-list-tile-title>
                  </v-list-tile>
                </v-list>
              </v-menu>
              <v-spacer></v-spacer>
              <v-subheader v-if="coleta.id">{{ periodo }}</v-subheader>
            </v-layout> -->

          <v-subheader>Faturas Geradas ({{faturas.length}})</v-subheader>
          <v-card>
            <v-toolbar card :color="search_focused ? 'grey lighten-1' : 'white'">
              <v-text-field autofocus flat solo prepend-inner-icon="search" placeholder="Pesquisar" v-model="search" hide-details @focus="search_focused=true" @blur="search_focused=false"></v-text-field>

              <!-- <v-spacer></v-spacer>

              <v-menu offset-y>
                <v-btn icon slot="activator" color="primary" class="mb-2 mr-0" :disabled="coletas.length == 0">
                  <v-icon>print</v-icon>
                </v-btn>
                <v-list>
                  <v-list-tile @click="imprimirRelatorioTelefonia">
                    <v-list-tile-title>Relatório Telefonia</v-list-tile-title>
                  </v-list-tile>
                  <v-list-tile @click="imprimirRelatorioInternet">
                    <v-list-tile-title>Relatório Internet</v-list-tile-title>
                  </v-list-tile>
                  <v-list-tile @click="imprimirTodos">
                    <v-list-tile-title>Faturas</v-list-tile-title>
                  </v-list-tile>
                </v-list>
              </v-menu> -->
            </v-toolbar>

            <v-divider></v-divider>

            <v-data-table
              :headers="headers"
              :items="faturas"
              :search="search"
              :loading="loadingList"
              :rows-per-page-items="globals.PAGINACAO"
              dense
              class="elevation-1"
              disable-initial-sort
              hide-actions
              expand
            >
              <v-progress-linear slot="progress" color="primary" height="3" indeterminate></v-progress-linear>
              <template slot="items" slot-scope="props">
                <!-- <td>{{ props.item.numero }}</td> -->
                <v-hover v-slot="{ hover }">
                <td class="text-no-wrap overflow-hidden text-truncate" style="position: relative; max-width: 400px" :title="props.item.cliente.razao">
                  <span>{{ props.item.cliente.razao }}</span>

                  <z-table-items :show="hover">
                    <v-btn color="primary" small icon :to="{name: 'ClientesFormEditar', params: { clienteId: props.item.cliente.id}, meta: {teste: 'Isso é um teste'}}">
                      <v-icon small>edit</v-icon>
                    </v-btn>
                  </z-table-items>

                  </td>
                </v-hover>
                <td class="text-no-wrap">{{ props.item.cliente.cpfcnpj }}</td>
                <td class="text-no-wrap">{{ props.item.vencimento | dataBR }}</td>
                <td class="text-xs-center">{{ props.item.subfaturamentoimpostos_count }}/{{props.item.notasfiscais_count }}</td>
                <td class="text-xs-right">{{ props.item.total | valorBR }}</td>
                <!-- <td class="text-xs-right">{{ props.item.valor_liquido | valorBR }}</td> -->
                <td class="justify-center layout px-0">
                  <v-btn flat :color="props.item.notasfiscais_count > 0 ? 'green' : 'primary'" class="mx-0" @click="props.expanded = !props.expanded">
                    NFSE
                    <v-icon v-if="!props.expanded">arrow_drop_down</v-icon>
                    <v-icon v-else>arrow_drop_up</v-icon>
                  </v-btn>
                  <v-btn icon class="mx-0" @click="editar(props.item)">
                    <v-icon color="grey darken-1">edit</v-icon>
                  </v-btn>
                  <v-btn icon class="mx-0" @click="imprimir(props.item)">
                    <v-icon color="grey darken-1">print</v-icon>
                  </v-btn>
                </td>
              </template>

              <template v-slot:expand="props">

                <ImpostosLista :faturamento="props.item"></ImpostosLista>

              </template>

            </v-data-table>
          </v-card>



        </v-flex>
      </v-layout>

      <v-layout row wrap justify-center style="margin-top: 200px" v-if="coletas.length == 0 && loadingList">
        <span class="title">Nenhum faturamento gerado</span>
      </v-layout>

    </v-container>

    <div style="height: 100px"></div>

    <v-fab-transition>
      <v-btn color="red darken-1" dark fab fixed bottom right v-show="showfab" @click="$vuetify.goTo(0)">
         <v-icon>keyboard_arrow_up</v-icon>
      </v-btn>
    </v-fab-transition>

    <z-dialogo ref="dialogoImprimir" titulo="Imprimir" dividers>
        <div slot="texto">
          <v-radio-group v-model="tipo_impressao">
            <v-radio color="primary" :key="1" label="Relatório Telefonia" value="telefonia" ></v-radio>
            <v-radio color="primary" :key="2" label="Relatório Internet" value="internet" ></v-radio>
            <v-radio color="primary" :key="3" label="Faturas" value="faturas" ></v-radio>
          </v-radio-group>
        </div>
        <v-btn flat @click="$refs.dialogoImprimir.fechar()">Cancelar</v-btn>
        <v-btn color="primary" flat @click="imprimirRelatorios">Imprimir</v-btn>
    </z-dialogo>

    <dialogo-nova-coleta ref="dialogoNovaColeta" @onColetaProcessada="onColetaProcessada"></dialogo-nova-coleta>
  </div>
</template>

<script>
  import globals from "@/app/globals";
  import config from "@/app/config";
  import qs from 'qs';

  import ImpostosLista from "./ImpostosLista.vue";
  import DialogoNovaColeta from "./DialogoNovaColeta.vue";

  export default {
    name: "FaturamentoLista",

    inject: ['rootRefs'],

    components: {
      ImpostosLista,
      DialogoNovaColeta
    },

    props: {

    },

    data() {
      return {
        globals,
        tipos_cliente: [],
        coleta: {},
        coletas: [],
        loading: false,
        initialLoading: false,
        loadingList: false,
        offsetTop: 0,
        headers:[
          { text: 'Empresa/Cliente', value: 'cliente.razao', sortable: false},
          { text: 'CPF/CNPJ', value: 'cliente.cpfcnpj', sortable: false },
          { text: 'Vencimento', value: 'vencimento' , sortable: false },
          { text: 'Serviços/NFSE', value: '' , sortable: false, align: 'center' },
          { text: 'Valor', value: 'total', align: 'right', sortable: false },
          { text: 'Opções', value: '', align: 'center', sortable: false }
        ],
        // pagination: {
        //   sortBy: 'cliente.razao'
        // },
        searching: false,
        search: '',
        // filtro: {
        //   coleta_id: '',
        //   dia_vencimento: 'Todos',
        //   tipo_cliente: 'todos'
        // },
        dias: ['Todos', 1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31],
        tipo_impressao: 'telefonia',
        search_focused: false
      }
    },

    computed: {
      filtro: {
        get() {
          return this.$store.state.faturamentos.filtro;
        },
        set(filtro) {
          this.$store.commit('faturamentos/SET_FILTRO', filtro);
        },
      },

      // coletaSelecionada() {
      //   let coleta = this.coletas.find(coleta => coleta.id == this.coleta);
      //   if (coleta) {
      //     return coleta;
      //   }
      //   return {};
      // },

      faturas() {
        return this.$store.state.faturamentos.faturas;
      },

      // faturasBusca() {
      //   return this.faturas.filter(fatura => {
      //     let index = fatura.clientenome.toUpperCase().indexOf(this.search.toUpperCase());
      //     if (index > -1) {
      //       return fatura;
      //     }
      //   })
      // },

      periodo() {
        if (this.coleta && this.coleta.id) {
          return 'Período: '+ this.coleta.periodo
        }
        return '';
      },
      showfab() {
        return this.offsetTop > 50;
      }
    },

    async mounted () {
      console.log(this.mensagem)
      this.initialLoading = true;
      this.$store.commit('SET_TELA', 'Faturamento');
      await this.listarColetas();
      this.tipos_cliente = this.clone(this.globals.TIPO_CLIENTE);
      this.tipos_cliente.unshift({text: 'Todos', value: 'todos'});
      this.initialLoading = false;
    },

    methods: {
      alertar() {
        this.rootRefs.notifications.notificarErro('NÃO APAGUE ESSA PORCARIA!');
      },

      async listarColetas() {
        // this.loadingList = true;
        await this.$axios.get('/coletas')
          .then(response => {
            // this.loadingList = false;
            this.coletas = response.data;
            if (this.filtro.coleta_id == null) {
              this.coleta = this.coletas[0];
              this.filtro.coleta_id = this.coletas[0].id;
              this.listarFaturas();
            } else {
              this.coleta = this.getColeta(this.filtro.coleta_id);
            }
          })
          .catch(error => {
            // this.loadingList = false;
            // console.log('Catch:', error.response.data);
          })
      },

      onChangeColeta() {
        this.filtro.coleta_id = this.coleta.id;
        // this.coleta = this.coletas.find(coleta => coleta.id == this.filtro.coleta_id);
        this.filtrar();
      },

      async listarFaturas() {
        try {
          this.loadingList = true;
          await this.$store.dispatch('faturamentos/listarFaturas', this.filtro);
        } finally {
          this.loadingList = false;
        }
          // this.loadingList = false;

        // this.$axios.get('/faturas/' + this.coleta.id)
        //   .then(response => {
        //     this.loadingList = false;
        //     this.faturas = response.data;
        //   })
        //   .catch(error => {
        //     this.loadingList = false;
        //     console.log('Catch:', error.response.data);
        //   })
      },

      filtrar() {
        this.listarFaturas();
      },

      abrirDialogoImprimir() {
        this.$refs.dialogoImprimir.abrir();
      },

      imprimirRelatorios() {
        if (this.tipo_impressao == 'telefonia') {
          this.imprimirRelatorioTelefonia();
        }

        if (this.tipo_impressao == 'internet') {
          this.imprimirRelatorioInternet();
        }

        if (this.tipo_impressao == 'faturas') {
          this.imprimirTodos();
        }

        this.$refs.dialogoImprimir.fechar();
      },

      selecionarColeta(coleta) {
        this.coleta = coleta;
        this.listarFaturas();
      },

      editar(fatura) {
        this.$router.push({name: 'editar_fatura', params: {id: fatura.id}});
      },

      imprimir(fatura) {
        this.abrirJanela(config.BASE_URL_PRINT + '/faturas/' + fatura.id + '/imprimir');
      },

      imprimirTodos() {
        this.abrirJanela(config.BASE_URL_PRINT + '/faturas/'+this.coleta.id+'/gerar?=' + qs.stringify(this.filtro));
      },

      imprimirRelatorio() {
        this.abrirJanela(config.BASE_URL_PRINT + '/faturas/'+this.coleta.id+'/relatorio');
      },

      imprimirRelatorioTelefonia() {
        this.abrirJanela(config.BASE_URL_PRINT + '/faturas/'+this.coleta.id+'/relatorio/t?=' + qs.stringify(this.filtro));
      },

      imprimirRelatorioInternet() {
        this.abrirJanela(config.BASE_URL_PRINT + '/faturas/'+this.coleta.id+'/relatorio/i?=' + qs.stringify(this.filtro));
      },

      onScroll() {
        this.offsetTop = window.pageYOffset || document.documentElement.scrollTop;
      },

      exibirBusca () {
          this.searching = true
          setTimeout(() => this.$refs.search.focus(), 0)
      },

      onBlurBusca () {
        if (this.search == '') {
          this.searching = false
          this.search = ''
        }
      },

      ocultarBusca () {
          this.searching = false
          this.search = ''
          // this.listarClientes();
          // this.$refs.search.blur()
      },

      abrirNovaColeta() {
        this.$refs.dialogoNovaColeta.abrir();
      },

      async onColetaProcessada() {
        await this.listarColetas();
        this.coleta = this.coletas[0];
        this.filtro.coleta_id = this.coletas[0].id;
        await this.filtrar()
      },

      async excluirColeta(coleta) {

        let pode_excluir = true;
        this.faturas.forEach(fatura => {
          fatura.subfaturamentoimpostos.forEach(imposto => {
            if (imposto.notafiscal != null) {
              pode_excluir = false;
            }
          })
        });

        if (!pode_excluir) {
          this.rootRefs.notifications.alertar("Não é possível excluir essa coleta, pois já foram emitidas Notas Fiscais.");
          return false;
        }


        let resposta = await this.rootRefs.notifications.dialogo_pergunta.abrir({
          titulo: 'Remover Coleta?',
          texto: `A coleta "${this.coleta.descricao}" será removida. <br>Deseja remover essa coleta?`,
          botoes: ['Cancelar', 'Remover'],
          padrao: 'Remover',
          cor: 'red',
          largura: '600px'
        });

        if (resposta == 'Remover') {
          this.rootRefs.notifications.dialogo_pergunta.carregando();
          await this.confirmarExcluirColeta();
          this.rootRefs.notifications.dialogo_pergunta.fechar();

          await this.listarColetas();
          this.coleta = this.coletas[0];
          this.filtro.coleta_id = this.coletas[0].id;
          this.listarFaturas();

        } else {
          this.rootRefs.notifications.dialogo_pergunta.fechar();
        }
      },

      async confirmarExcluirColeta() {
        try {

          this.loading = true;
          const response = await this.$axios.delete('/coletas/' + this.coleta.id)

          await this.onColetaProcessada();

        } catch (error) {
            console.log(error);
            // console.log(error.response.data);
        } finally {
          this.loading = false;
        }
      },

      getColeta(coleta_id) {
        return this.coletas.find(coleta => coleta.id == coleta_id);
      }
    },


  }
</script>

<style scoped>
.searching {
  overflow: hidden;
  width: 208px;
  padding-left: 8px;
  transition: 0.3s cubic-bezier(.25,.8,.50,1);
}

.searching--closed {
  padding-left: 0;
  width: 0;
}

.searching > * {
  right: 8px;
}

.searching--closed > * {
  display: none;
}
</style>
