import { render, staticRenderFns } from "./ClienteFaturas.vue?vue&type=template&id=56eea67c&scoped=true&"
import script from "./ClienteFaturas.vue?vue&type=script&lang=js&"
export * from "./ClienteFaturas.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56eea67c",
  null
  
)

export default component.exports