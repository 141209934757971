export default {

  data() {
    return {
      itens_busca: [],
      busca: '',
      carregando: false,
      is_busca_focused: false,
      atual: 0
    }
  },


  methods: {
    abrir() {
      this.$refs.dialogo.abrir();
      this.setFocus('busca', 300);
    },

    fechar() {
      this.$refs.dialogo.fechar();
    },

    selecionar(item) {
      this.$emit('busca:selecionar', item);
      this.fechar();
    },

    onBuscarPressEnter(event) {
      event.target.select();
      this.buscar();
    },

    onBuscaFocus(event) {
      event.target.select();
      this.is_busca_focused = true;
    },

    limparBusca() {
      this.busca = '';
      this.itens_busca = [];
    },

    teclas(event) {
      if (event.code == 'ArrowUp') {
        event.preventDefault();
        this.up();
      }

      if (event.code == 'ArrowDown') {
        event.preventDefault();
        this.down();
      }

      if (event.code == 'Enter') {
        event.preventDefault();
        if (!this.is_busca_focused) {
          this.selecionar(this.itens_busca[this.atual]);
        }
      }
    },

    up() {
      if (this.itens_busca.length == 0) {
        return false;
      }

      if (this.is_busca_focused) {
        this.$refs.busca.blur();
      }

      if (this.atual == 0) {
        this.$refs.topo.scrollIntoView();
      }

      if(this.atual > 0) {
        this.atual--;
        this.$refs.urls[this.atual].scrollIntoView({behavior: "instant", block: "nearest", inline: "nearest"});
      }
    },

    down() {
      if (this.itens_busca.length == 0) {
        return false;
      }

      if (this.is_busca_focused) {
        this.$refs.busca.blur();
      }

      if(this.atual < this.itens_busca.length - 1) {
        this.atual++;
        this.scrollList();
      }
    },

    isActive(index) {
      return index === this.atual;
    },

    scrollList() {
      if (this.itens_busca.length > 0)
        this.$refs.urls[this.atual].scrollIntoView({behavior: "instant", block: "nearest", inline: "nearest"});
    },
  },
}
