<template>
  <div v-scroll="onScroll" id="topo">

    <z-loading :loading="loadingLista" height="100px" color="primary"></z-loading>

    <v-container v-if="!loadingLista">

        <v-layout row justify-center>
          <v-flex sm12 xl10>

            <v-subheader>Clientes [{{ clientes.length }}]</v-subheader>

            <v-card>
              <v-toolbar card color="white">
                <div style="width: 400px;">
                  <v-text-field autofocus flat solo prepend-icon="search" placeholder="Pesquisar" v-model="search" hide-details></v-text-field>
                </div>

                <v-spacer></v-spacer>

                <v-btn color="primary" icon @click="adicionar">
                    <v-icon>person_add</v-icon>
                </v-btn>

              </v-toolbar>

              <v-divider></v-divider>

              <v-data-table
                :search="search"
                :headers="headers"
                :pagination.sync="pagination"
                :items="clientes"
                :rows-per-page-items="[15, 20, 30, 50, {'text': 'Todos', 'value': -1}]"
                dia
              >
                <template slot="items" slot-scope="props">
                  <td>{{ props.item.razao }}</td>
                  <td class="text-no-wrap">{{ props.item.cpfcnpj }}</td>
                  <td>{{ props.item.tipo_cliente | capitalized }}</td>
                  <td class="text-xs-center">{{ props.item.dia_vencimento }}</td>
                  <td class="text-xs-center">
                    <v-chip v-if="props.item.ativo == 1" flat small color="green" dark>Ativo</v-chip>
                    <v-chip v-else flat small color="grey" dark>Inativo</v-chip>
                  </td>
                  <!-- <td>{{ props.item.franquia }} <span v-if="props.item.franquia > 0">min</span></td> -->
                  <td class="justify-end align-center layout px-0">
                    <v-tooltip top>
                      <v-btn slot="activator" icon @click="editar(props.item)">
                        <v-icon color="grey darken-1">edit</v-icon>
                      </v-btn>
                      <span>Editar</span>
                    </v-tooltip>
                    <v-tooltip top v-if="props.item.ativo == true">
                      <v-btn slot="activator" icon ripple @click.stop="abrirDialogoExcluir(props.item)">
                        <v-icon color="grey">delete</v-icon>
                      </v-btn>
                      <span>Excluir</span>
                    </v-tooltip>
                  </td>
                </template>
              </v-data-table>
            </v-card>

          </v-flex>
        </v-layout>
    </v-container>

    <div style="height: 100px"></div>

    <z-dialogo ref="dialogoExcluir" titulo="Deseja inativar esse cliente?">
        <div slot="texto">
          {{this.cliente.razao}}
        </div>
        <v-btn color="grey darken-1" flat @click="$refs.dialogoExcluir.fechar()" :disabled="loading">Cancelar</v-btn>
        <v-btn color="primary darken-1" flat @click="confirmarExcluir" :loading="loading" :disabled="loading">Inativar</v-btn>
    </z-dialogo>

    <v-fab-transition>
      <v-btn style="right: 60px" color="red darken-1" dark fab fixed bottom right v-show="showfab" @click="$vuetify.goTo('#topo', {duration: 300, offset: 0})">
         <v-icon>keyboard_arrow_up</v-icon>
      </v-btn>
    </v-fab-transition>

  </div>
</template>

<script>

  import ClientesForm from './ClientesForm.vue'
  import ServiceClientes from '@/services/clientes'
  import {mapState, mapActions} from 'vuex'

  export default {
    components: {
      ClientesForm
    },
    data () {
      return {
        cliente_id: null,
        cliente: {},
        // clientes: [],
        searching: false,
        search: '',
        exibirForm: false,
        mensagem: 'Isso é um teste',
        loading: false,
        // loadingLista: false,
        offsetTop: 0,
        headers:[
          { text: 'Cliente/Empresa', value: 'razao', sortable: false },
          { text: 'CPF/CNPJ', value: 'cpfcnpj', sortable: false },
          { text: 'Tipo', value: 'tipo_cliente', sortable: false },
          { text: 'Vencimento', value: 'dia_vencimento', align: 'center', sortable: false },
          { text: 'Situação', value: 'ativo', align: 'center', sortable: false },
          { text: 'Opções', value: '', align: 'right', sortable: false }
        ],
        pagination: {},
      }
    },

    computed: {
      ...mapState('clientes', [
        'clientes',
        'loadingLista'
      ]),

      clientesBusca() {
        return this.clientes.filter(cliente => {
          let index = cliente.razao.toUpperCase().indexOf(this.search.toUpperCase());
          if (index > -1) {
            return cliente;
          }
        })
      },

      showfab() {
        return this.offsetTop > 50;
      },
    },

    mounted() {
      this.$store.commit('SET_TELA', 'Clientes');
      this.listarClientes();
    },

    methods: {
      ...mapActions('clientes', [
        'listarClientes'
      ]),

      buscar() {
        this.clientes = [];
        this.loadingLista = true;
        this.$axios.get('clientes/busca?q=' + this.search)
          .then(response => {
            this.clientes = response.data;
            this.loadingLista = false;
          })
          .catch(error => {
            this.loadingLista = false;
          })
      },

      adicionar() {
        this.$router.push({path: 'clientes/adicionar'});
      },

      editar(cliente) {
        this.$router.push({
          name: 'ClientesFormEditar',
          params: { clienteId: cliente.id}
        });
      },

      async confirmarExcluir() {
        try {
          this.loading = true;
          await ServiceClientes.excluirCliente(this.cliente.id);
          let cliente = this.clientes.find(cliente => cliente.id == this.cliente.id);
          cliente.ativo = false;
          // this.clientes.splice(index, 1);
          this.$refs.dialogoExcluir.fechar()
        } finally {
          this.loading = false;
        }
      },

      confirmar(cliente) {
        let indice = this.clientes.findIndex(cliente_ => cliente_.id == cliente.id);
        if (indice > -1) {
          this.clientes.splice(indice, 1, cliente);
          this.cliente_id = null;
        } else {
          this.clientes.push(cliente);
        }
        this.exibirForm = false;
      },

      cancelar() {
        this.exibirForm = false;
      },

      exibirBusca () {
          this.searching = true
          setTimeout(() => this.$refs.search.focus(), 0)
      },

      onBlurBusca () {
        if (this.search == '') {
          this.searching = false
          this.search = ''
        }
      },

      ocultarBusca () {
          this.searching = false
          this.search = ''
          // this.listarClientes();
          // this.$refs.search.blur()
      },

      abrirDialogoExcluir(cliente) {
        this.cliente = cliente;
        this.$refs.dialogoExcluir.abrir();
      },

      onScroll() {
        this.offsetTop = window.pageYOffset || document.documentElement.scrollTop;
      }
    },


  }
</script>

<style scoped>

.searching {
  overflow: hidden;
  width: 208px;
  padding-left: 8px;
  transition: 0.3s cubic-bezier(.25,.8,.50,1);
}

.searching--closed {
  padding-left: 0;
  width: 0;
}

.searching > * {
  right: 8px;
}

.searching--closed > * {
  display: none;
}

</style>
