<template>
    <v-app style="background1: #FFF">

    <v-navigation-drawer width="256" v-model="drawer" :mini-variant.sync="mini" enable-resize-watcher :permanent="permanente" app>
      <!-- <v-toolbar flat dark height="56" color="primary">
          <v-toolbar-side-icon @click.stop="drawer = !drawer"></v-toolbar-side-icon>
          <v-toolbar-title style="font-size: 24px; font-weight: 400">Cob2</v-toolbar-title>
      </v-toolbar> -->
      <!-- <v-toolbar flat height="56">
        <v-list>
          <v-list-tile>
            <v-list-tile-title class="title">
              Cob2
            </v-list-tile-title>
          </v-list-tile>
        </v-list>
      </v-toolbar> -->

      <!-- <v-divider></v-divider> -->

      <v-list dense>

        <v-list-tile>
          <v-list-tile-content>
            <v-list-tile-title>CADASTROS</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>

        <v-list-tile to="/clientes">
          <v-list-tile-action>
            <v-icon>contacts</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>Clientes</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>

        <v-list-tile to="/planos">
          <v-list-tile-action>
            <v-icon>assignment_turned_in</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>Planos de Serviço</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>

        <v-list-tile to="/ramais">
          <v-list-tile-action>
            <v-icon>phone</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>Ramais</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>

        <v-list-tile style="margin-top: 20px">
          <v-list-tile-content>
            <v-list-tile-title>MOVIMENTAÇÃO</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>

        <v-list-tile to="/faturamento">
          <v-list-tile-action>
            <v-icon>monetization_on</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>Faturamento</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>

        <v-list-tile to="/arquivos">
          <v-list-tile-action>
            <v-icon>insert_drive_file</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>Arquivos</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>

        <v-divider></v-divider>

        <v-list-tile to="/configuracoes">
          <v-list-tile-action>
            <v-icon>settings</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>Configurações</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>

        <v-list-tile @click="sair">
          <v-list-tile-action>
            <v-icon>power_settings_new</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>Sair</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>

        <!-- <v-divider></v-divider> -->


        <!-- <v-list-group> -->

          <!-- <v-list-tile slot="activator"> -->
          <!-- <v-list-tile style="margin-top: 20px">
              <v-list-tile-content>
                <v-list-tile-title>CONFIGURAÇÕES</v-list-tile-title>
              </v-list-tile-content>
          </v-list-tile>


          <v-list-tile>
            <v-list-tile-action>
              <v-icon>group</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title>Usuários</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>

          <v-list-tile>
            <v-list-tile-action>
              <v-icon>lock</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title>Permissões</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile> -->

        <!-- </v-list-group> -->

      </v-list>

    </v-navigation-drawer>


    <v-content>
        <!-- <v-container grid-list-xs> -->
          <v-slide-y-transition mode="out-in">
            <router-view></router-view>
          </v-slide-y-transition>
        <!-- </v-container> -->
    </v-content>

    <z-aguardar ref="aguardar" color="primary"></z-aguardar>

    </v-app>
</template>

<script>
    import config from "@/app/config";
    export default {
      data() {
        return {
          drawer: false,
          mini: false,
          config: config
        }
      },
      mounted() {
        this.drawer = this.$vuetify.breakpoint.mdAndUp;
        // console.log(JSON.parse(JSON.stringify(this.$vuetify.breakpoint)))
        this.$store.dispatch('carregarConfiguracoes');
      },

      methods: {
        sair() {
            this.drawer = false;
            this.$refs.aguardar.abrir();
            setTimeout(() => {
                this.$auth.logout();
                this.$router.push('/login');
            }, 1000);
        }
      },

      computed: {
        permanente() {
          return true;
          return this.$vuetify.breakpoint.mdAndUp;
        }
      }

    }
</script>

<style scoped>

</style>
