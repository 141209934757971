import Vue from 'vue'
import Router from 'vue-router'

import Inicio from '@/pages/Inicio'
import ClientesLista from '@/pages/clientes/ClientesLista'
import ClientesForm from '@/pages/clientes/ClientesForm'
import Arquivos from '@/pages/arquivos/Arquivos'
import PlanosLista from '@/pages/planos/PlanosLista'
import FaturamentoLista from '@/pages/faturamento/FaturamentoLista'
import FaturamentoEdit from '@/pages/faturamento/FaturamentoEdit'
import Ramais from '@/pages/ramais/Ramais'
import Login from '@/pages/Login'
import Configuracoes from '@/pages/Configuracoes'
import EmitentesLista from '@/pages/cadastros/emitentes/EmitentesLista'
import EmitenteForm from '@/pages/cadastros/emitentes/EmitenteForm'

import NotasFiscaisLista from '@/pages/notasfiscais/NotasFiscaisLista'
import EmitirNotaServico from '@/pages/faturamento/EmitirNotaServico'

import Auth from '@/plugins/Auth'

Vue.use(Router);

const router = new Router({
  // mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Inicio',
      component: Inicio
    },

    {
      path: '/login',
      meta: { public: true },
      name: 'login',
      component: Login
    },

    {
      path: '/clientes',
      name: 'ClientesLista',
      component: ClientesLista
    },

    {
      path: '/clientes/adicionar',
      name: 'ClientesFormAdicionar',
      component: ClientesForm
    },

    {
      path: '/clientes/:clienteId/editar',
      name: 'ClientesFormEditar',
      component: ClientesForm,
      props: true
    },

    {
      path: '/arquivos',
      name: 'Arquivos',
      component: Arquivos
    },
    {
      path: '/planos',
      name: 'PlanosLista',
      component: PlanosLista
    },
    {
      path: '/faturamento',
      name: 'FaturamentoLista',
      component: FaturamentoLista
    },
    {
      path: '/faturamento/:id/edit',
      name: 'editar_fatura',
      component: FaturamentoEdit,
      props: true
    },
    {
      path: '/ramais',
      name: 'ramais',
      component: Ramais
    },
    {
      path: '/configuracoes',
      name: 'configuracoes',
      component: Configuracoes
    },

    {
      path: '/cadastros/emitentes',
      name: 'EmitentesLista',
      component: EmitentesLista
    },

    {
      path: '/cadastros/emitentes/adicionar',
      name: 'AdicionarEmitente',
      component: EmitenteForm
    },

    {
      path: '/cadastros/emitentes/{id}/adicionar',
      name: 'EditarEmitente',
      component: EmitenteForm
    },

    {
      path: '/notasfiscais',
      name: 'NotasFiscaisLista',
      component: NotasFiscaisLista
    },

    {
      path: '/notasfiscais/:subfatura_id/nova',
      name: 'NovaNotaServico',
      component: EmitirNotaServico
    },
    {
      path: '/notasfiscais/:notafiscal_id/editar',
      name: 'EditarNotaServico',
      component: EmitirNotaServico
    },
    {
      path: '/notasfiscais/notaavulsa',
      name: 'NotaServicoAvulsa',
      component: EmitirNotaServico
    },
  ]
});

router.beforeEach((to, from, next) => {
  // let api_token = window.localStorage.getItem('organize.token');
  // let api_token = Auth.getToken();

  if (to.meta.public) {
    next();
  } else {
      if (Auth.check()) {
          next();
      } else {
          next('/login');
      }
  }
})

export default router;
