import axios from '@/plugins/Axios'
import cookie from '@/plugins/Cookie'
import config from '@/app/config'

class Auth {
  constructor() {
    this.user = {
      id: '',
      nome: '',
      email: '',
      token: '',
      nivel: 0
    }
  }

  login(credenciais) {
    return axios.post('/login', credenciais)
      .then(response => {
        this.setUserData(response.data);
      })
      .catch(error => {
        this.unsetUserData();
      });
  }

  logout() {
    this.unsetUserData()
  }

  check() {
    if (this.getToken()) {
      return true;
    } else {
      this.unsetUserData();
      return false;
    }
  }

  setUserData(user) {
    this.user = user;
    window.localStorage.setItem(config.STORAGE_USER, JSON.stringify(user));
    this.setToken(user.token);
  }

  unsetUserData() {
    window.localStorage.removeItem(config.STORAGE_USER);
    window.localStorage.removeItem(config.STORAGE_TOKEN);
    cookie.deleteCookie('api_token');
  }

  setToken(token) {
    window.localStorage.setItem(config.STORAGE_TOKEN, token);
    cookie.setCookie('api_token', token, config.COOKIE_EXPIRATION_TIME);
  }

  getToken() {
    return cookie.getCookie('api_token');
    // return window.localStorage.getItem(config.STORAGE_TOKEN);
  }

  getUser() {
    return this.user;
  }

  getAuthHeader() {
    return {
      'Authorization': 'Bearer ' + this.getToken()
    }
  }

  getJWTDecode() {
    return JSON.parse(window.atob(this.getToken().split('.')[1]))
  }
}

const auth = new Auth();

const authPlugin = {
  install(Vue, options) {
    Vue.prototype.$auth = auth;
  }
};

export {authPlugin};

export default auth;
