export default [
  {
    "codigo_servico": "1.02",
    "codigo_atividade": "620150104",
    "descricao_servico": "ATIVIDADES DE PROGRAMAÇÃO COM O USO DE LINGUAGENS DE PROGRAMAÇÃO",
    "aliquota": 5
  },
  {
    "codigo_servico": "1.03",
    "codigo_atividade": "631190001",
    "descricao_servico": "TRATAMENTO DE DADOS, PROVEDORES DE SERVIÇOS DE APLICAÇÃO E SERVIÇOS DE HOSPEDAGEM NA INTERNET",
    "aliquota": 2
  },
  {
    "codigo_servico": "1.04",
    "codigo_atividade": "620230002",
    "descricao_servico": "DESENVOLVIMENTO DE PROGRAMAS DE INFORMÁTICA CUSTOMIZÁVEIS",
    "aliquota": 5
  },
  {
    "codigo_servico": "1.04",
    "codigo_atividade": "620150105",
    "descricao_servico": "ELABORAÇÃO DE PROGRAMAS DE BANCO DE DADOS SOB ENCOMENDA",
    "aliquota": 5
  },
  {
    "codigo_servico": "1.04",
    "codigo_atividade": "620150106",
    "descricao_servico": "ELABORAÇÃO DE PROGRAMAS DE COMPUTADORES, INCLUSIVE DE JOGOS ELETRÔNICOS, INDEPENDENTEMENTE DA ARQUIT",
    "aliquota": 5
  },
  {
    "codigo_servico": "1.04",
    "codigo_atividade": "620150101",
    "descricao_servico": "DESENVOLVIMENTO DE PROGRAMAS DE COMPUTADOR SOB ENCOMENDA",
    "aliquota": 5
  },
  {
    "codigo_servico": "1.04",
    "codigo_atividade": "620310001",
    "descricao_servico": "DESENVOLVIMENTO E LICENCIAMENTO DE PROGRAMAS DE COMPUTADOR NÃO-CUSTOMIZÁVEIS",
    "aliquota": 5
  },
  {
    "codigo_servico": "1.05",
    "codigo_atividade": "620230001",
    "descricao_servico": "DESENVOLVIMENTO E LICENCIAMENTO DE PROGRAMAS DE COMPUTADOR CUSTOMIZÁVEIS",
    "aliquota": 5
  },
  {
    "codigo_servico": "1.05",
    "codigo_atividade": "620150102",
    "descricao_servico": "FORNECIMENTO DE DOCUMENTAÇÃO DE PROGRAMAS DE COMPUTADOR DESENVOLVIDOS SOB ENCOMENDA",
    "aliquota": 5
  },
  {
    "codigo_servico": "1.05",
    "codigo_atividade": "631190004",
    "descricao_servico": "SERVIÇOS DE HOSPEDAGEM DE PÁGINAS, SITES E DADOS - WEB HOSTING",
    "aliquota": 5
  },
  {
    "codigo_servico": "1.05",
    "codigo_atividade": "620310002",
    "descricao_servico": "LICENCIAMENTO DE PROGRAMAS DE INFORMÁTICA NÃO-CUSTOMIZÁVEIS",
    "aliquota": 5
  },
  {
    "codigo_servico": "1.06",
    "codigo_atividade": "620400001",
    "descricao_servico": "CONSULTORIA EM TECNOLOGIA DA INFORMAÇÃO",
    "aliquota": 5
  },
  {
    "codigo_servico": "1.07",
    "codigo_atividade": "620150103",
    "descricao_servico": "SERVIÇOS DE EDIÇÃO DE SITE, BANCO DE DADOS E SOFTWARE SOB ENCOMENDA",
    "aliquota": 2
  },
  {
    "codigo_servico": "1.07",
    "codigo_atividade": "620910000",
    "descricao_servico": "SUPORTE TÉCNICO, MANUTENÇÃO E OUTROS SERVIÇOS EM TECNOLOGIA DA INFORMAÇÃO",
    "aliquota": 2
  },
  {
    "codigo_servico": "1.08",
    "codigo_atividade": "631940005",
    "descricao_servico": "SITE DE BUSCA NA INTERNET",
    "aliquota": 5
  },
  {
    "codigo_servico": "1.08",
    "codigo_atividade": "620150200",
    "descricao_servico": "WEB DESIGN",
    "aliquota": 5
  },
  {
    "codigo_servico": "1.09",
    "codigo_atividade": "631940002",
    "descricao_servico": "SERVIÇOS DE DISTRIBUIÇÃO ON LINE DE CONTEÚDO",
    "aliquota": 5
  },
  {
    "codigo_servico": "1.09",
    "codigo_atividade": "631940001",
    "descricao_servico": "PORTAIS, PROVEDORES DE CONTEÚDO E OUTROS SERVIÇOS DE INFORMAÇÃO NA INTERNET",
    "aliquota": 5
  },
  {
    "codigo_servico": "10.01",
    "codigo_atividade": "662910000",
    "descricao_servico": "ATIVIDADES AUXILIARES DOS SEGUROS, DA PREVIDÊNCIA COMPLEMENTAR E DOS PLANOS DE SAÚDE NÃO ESPECIFICAD",
    "aliquota": 5
  },
  {
    "codigo_servico": "10.01",
    "codigo_atividade": "662230000",
    "descricao_servico": "CORRETORES E AGENTES DE SEGUROS, DE PLANOS DE PREVIDÊNCIA COMPLEMENTAR E DE SAÚDE",
    "aliquota": 5
  },
  {
    "codigo_servico": "10.01",
    "codigo_atividade": "662150100",
    "descricao_servico": "PERITOS E AVALIADORES DE SEGUROS",
    "aliquota": 5
  },
  {
    "codigo_servico": "10.01",
    "codigo_atividade": "663040000",
    "descricao_servico": "ATIVIDADES DE ADMINISTRAÇÃO DE FUNDOS POR CONTRATO OU COMISSÃO",
    "aliquota": 5
  },
  {
    "codigo_servico": "10.01",
    "codigo_atividade": "651200000",
    "descricao_servico": "SOCIEDADE SEGURADORA DE SEGUROS NÃO VIDA",
    "aliquota": 5
  },
  {
    "codigo_servico": "10.01",
    "codigo_atividade": "651110100",
    "descricao_servico": "SOCIEDADE SEGURADORA DE SEGUROS VIDA",
    "aliquota": 5
  },
  {
    "codigo_servico": "10.01",
    "codigo_atividade": "653080000",
    "descricao_servico": "RESSEGUROS",
    "aliquota": 5
  },
  {
    "codigo_servico": "10.01",
    "codigo_atividade": "654130000",
    "descricao_servico": "PREVIDÊNCIA COMPLEMENTAR FECHADA",
    "aliquota": 5
  },
  {
    "codigo_servico": "10.01",
    "codigo_atividade": "652010000",
    "descricao_servico": "SOCIEDADE SEGURADORA DE SEGUROS SAÚDE",
    "aliquota": 5
  },
  {
    "codigo_servico": "10.01",
    "codigo_atividade": "654210000",
    "descricao_servico": "PREVIDÊNCIA COMPLEMENTAR ABERTA",
    "aliquota": 5
  },
  {
    "codigo_servico": "10.02",
    "codigo_atividade": "661180100",
    "descricao_servico": "BOLSA DE VALORES",
    "aliquota": 5
  },
  {
    "codigo_servico": "10.02",
    "codigo_atividade": "661180200",
    "descricao_servico": "BOLSA DE MERCADORIAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "10.02",
    "codigo_atividade": "661260200",
    "descricao_servico": "DISTRIBUIDORAS DE TÍTULOS E VALORES MOBILIÁRIOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "10.02",
    "codigo_atividade": "661260100",
    "descricao_servico": "CORRETORAS DE TÍTULOS E VALORES MOBILIÁRIOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "10.02",
    "codigo_atividade": "661260500",
    "descricao_servico": "AGENTES DE INVESTIMENTOS EM APLICAÇÕES FINANCEIRAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "10.02",
    "codigo_atividade": "661939901",
    "descricao_servico": "OUTRAS ATIVIDADES AUXILIARES DOS SERVIÇOS FINANCEIROS NÃO ESPECIFICADAS ANTERIORMENTE",
    "aliquota": 5
  },
  {
    "codigo_servico": "10.02",
    "codigo_atividade": "661260400",
    "descricao_servico": "CORRETORAS DE CONTRATOS DE MERCADORIAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "10.02",
    "codigo_atividade": "661260300",
    "descricao_servico": "CORRETORAS DE CÂMBIO",
    "aliquota": 5
  },
  {
    "codigo_servico": "10.02",
    "codigo_atividade": "661180300",
    "descricao_servico": "BOLSA DE MERCADORIAS E FUTUROS",
    "aliquota": 5
  },
  {
    "codigo_servico": "10.02",
    "codigo_atividade": "749010400",
    "descricao_servico": "ATIVIDADES DE INTERMEDIAÇÃO E AGENCIAMENTO DE SERVIÇOS E NEGÓCIOS EM GERAL, EXCETO IMOBILIÁRIOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "10.02",
    "codigo_atividade": "661180400",
    "descricao_servico": "ADMINISTRAÇÃO DE MERCADOS DE BALCÃO ORGANIZADOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "10.03",
    "codigo_atividade": "691170300",
    "descricao_servico": "AGENTE DE PROPRIEDADE INDUSTRIAL",
    "aliquota": 5
  },
  {
    "codigo_servico": "10.03",
    "codigo_atividade": "749010502",
    "descricao_servico": "SERVIÇOS DE AGENTE DE OBRAS DE ARTE E LITERÁRIAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "10.04",
    "codigo_atividade": "773900102",
    "descricao_servico": "LEASING OPERACIONAL DE MÁQUINAS E EQUIPAMENTOS PARA EXTRAÇÃO DE MINÉRIOS E PETRÓLEO",
    "aliquota": 5
  },
  {
    "codigo_servico": "10.04",
    "codigo_atividade": "773900302",
    "descricao_servico": "LEASING OPERACIONAL DE PALCOS, COBERTURAS E OUTRAS ESTRUTURAS DE USO TEMPORÁRIO",
    "aliquota": 5
  },
  {
    "codigo_servico": "10.04",
    "codigo_atividade": "773900202",
    "descricao_servico": "LEASING OPERACIONAL DE EQUIPAMENTOS CIENTÍFICOS, MÉDICOS E HOSPITALARES",
    "aliquota": 5
  },
  {
    "codigo_servico": "10.04",
    "codigo_atividade": "649130000",
    "descricao_servico": "SOCIEDADES DE FOMENTO MERCANTIL - FACTORING",
    "aliquota": 5
  },
  {
    "codigo_servico": "10.04",
    "codigo_atividade": "773310002",
    "descricao_servico": "LEASING OPERACIONAL DE MÁQUINAS E EQUIPAMENTOS PARA ESCRITÓRIO",
    "aliquota": 5
  },
  {
    "codigo_servico": "10.04",
    "codigo_atividade": "773220202",
    "descricao_servico": "LEASING OPERACIONAL DE ANDAIMES",
    "aliquota": 5
  },
  {
    "codigo_servico": "10.04",
    "codigo_atividade": "773140002",
    "descricao_servico": "LEASING OPERACIONAL DE MÁQUINAS E EQUIPAMENTOS AGRÍCOLAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "10.05",
    "codigo_atividade": "960920200",
    "descricao_servico": "AGÊNCIAS MATRIMONIAIS",
    "aliquota": 5
  },
  {
    "codigo_servico": "10.05",
    "codigo_atividade": "702040004",
    "descricao_servico": "ATIVIDADE DE LOBISTA",
    "aliquota": 5
  },
  {
    "codigo_servico": "10.05",
    "codigo_atividade": "681020300",
    "descricao_servico": "LOTEAMENTO DE IMÓVEIS PRÓPRIOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "10.05",
    "codigo_atividade": "525080301",
    "descricao_servico": "AGENCIAMENTO DE CARGAS, EXCETO PARA O TRANSPORTE MARÍTIMO",
    "aliquota": 5
  },
  {
    "codigo_servico": "10.05",
    "codigo_atividade": "451290202",
    "descricao_servico": "CORRETORA DE VEÍCULOS (EM CONSIGNAÇÃO)",
    "aliquota": 5
  },
  {
    "codigo_servico": "10.05",
    "codigo_atividade": "682180100",
    "descricao_servico": "CORRETAGEM NA COMPRA E VENDA E AVALIAÇÃO DE IMÓVEIS",
    "aliquota": 5
  },
  {
    "codigo_servico": "10.05",
    "codigo_atividade": "643440000",
    "descricao_servico": "AGÊNCIAS DE FOMENTO",
    "aliquota": 5
  },
  {
    "codigo_servico": "10.05",
    "codigo_atividade": "681020100",
    "descricao_servico": "COMPRA E VENDA DE IMÓVEIS PRÓPRIOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "10.05",
    "codigo_atividade": "352040202",
    "descricao_servico": "ATIVIDADES DE CORRETORES OU AGENTES DE GÁS QUE ORGANIZAM A VENDA DE GÁS ATRAVÉS DE SISTEMAS DE DISTR",
    "aliquota": 5
  },
  {
    "codigo_servico": "10.05",
    "codigo_atividade": "646200000",
    "descricao_servico": "HOLDINGS DE INSTITUIÇÕES NÃO-FINANCEIRAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "10.05",
    "codigo_atividade": "682180200",
    "descricao_servico": "CORRETAGEM NO ALUGUEL DE IMÓVEIS",
    "aliquota": 5
  },
  {
    "codigo_servico": "10.05",
    "codigo_atividade": "351310002",
    "descricao_servico": "CORRETAGEM, INTERMEDIAÇÃO E AGENTES (AGENCIAMENTO) DE ENERGIA ELÉTRICA PRODUZIDA POR TERCEIROS",
    "aliquota": 5
  },
  {
    "codigo_servico": "10.05",
    "codigo_atividade": "646110000",
    "descricao_servico": "HOLDINGS DE INSTITUIÇÕES FINANCEIRAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "10.05",
    "codigo_atividade": "646380000",
    "descricao_servico": "OUTRAS SOCIEDADES DE PARTICIPAÇÃO, EXCETO HOLDINGS",
    "aliquota": 5
  },
  {
    "codigo_servico": "10.05",
    "codigo_atividade": "451290102",
    "descricao_servico": "INTERMEDIÁRIOS NA VENDA DE VEÍCULOS AUTOMOTORES, ATACADISTA E VAREJISTA",
    "aliquota": 5
  },
  {
    "codigo_servico": "10.06",
    "codigo_atividade": "523200000",
    "descricao_servico": "ATIVIDADES DE AGENCIAMENTO MARÍTIMO",
    "aliquota": 5
  },
  {
    "codigo_servico": "10.07",
    "codigo_atividade": "639170000",
    "descricao_servico": "AGÊNCIAS DE NOTÍCIAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "10.08",
    "codigo_atividade": "602170000",
    "descricao_servico": "ATIVIDADES DE TELEVISÃO ABERTA",
    "aliquota": 5
  },
  {
    "codigo_servico": "10.08",
    "codigo_atividade": "601010000",
    "descricao_servico": "ATIVIDADES DE RÁDIO",
    "aliquota": 5
  },
  {
    "codigo_servico": "10.08",
    "codigo_atividade": "602250200",
    "descricao_servico": "ATIVIDADES RELACIONADAS À TELEVISÃO POR ASSINATURA, EXCETO PROGRAMADORAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "10.08",
    "codigo_atividade": "602250100",
    "descricao_servico": "PROGRAMADORAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "10.09",
    "codigo_atividade": "461840200",
    "descricao_servico": "REPRESENTANTES COMERCIAIS E AGENTES DO COMÉRCIO DE INSTRUMENTOS E MATERIAIS ODONTO-MÉDICO-HOSPITALAR",
    "aliquota": 5
  },
  {
    "codigo_servico": "10.09",
    "codigo_atividade": "461330000",
    "descricao_servico": "REPRESENTANTES COMERCIAIS E AGENTES DO COMÉRCIO DE MADEIRA, MATERIAL DE CONSTRUÇÃO E FERRAGENS",
    "aliquota": 5
  },
  {
    "codigo_servico": "10.09",
    "codigo_atividade": "461170000",
    "descricao_servico": "REPRESENTANTES COMERCIAIS E AGENTES DO COMÉRCIO DE MATÉRIAS-PRIMAS AGRÍCOLAS E ANIMAIS VIVOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "10.09",
    "codigo_atividade": "454210200",
    "descricao_servico": "COMÉRCIO SOB CONSIGNAÇÃO DE MOTOCICLETAS E MOTONETAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "10.09",
    "codigo_atividade": "461760000",
    "descricao_servico": "REPRESENTANTES COMERCIAIS E AGENTES DO COMÉRCIO DE PRODUTOS ALIMENTÍCIOS, BEBIDAS E FUMO",
    "aliquota": 5
  },
  {
    "codigo_servico": "10.09",
    "codigo_atividade": "454210100",
    "descricao_servico": "REPRESENTANTES COMERCIAIS E AGENTES DO COMÉRCIO DE MOTOCICLETAS E MOTONETAS, PEÇAS E ACESSÓRIOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "10.09",
    "codigo_atividade": "461410000",
    "descricao_servico": "REPRESENTANTES COMERCIAIS E AGENTES DO COMÉRCIO DE MÁQUINAS, EQUIPAMENTOS, EMBARCAÇÕES E AERONAVES",
    "aliquota": 5
  },
  {
    "codigo_servico": "10.09",
    "codigo_atividade": "661930300",
    "descricao_servico": "REPRESENTAÇÕES DE BANCOS ESTRANGEIROS",
    "aliquota": 5
  },
  {
    "codigo_servico": "10.09",
    "codigo_atividade": "461849900",
    "descricao_servico": "OUTROS REPRESENTANTES COMERCIAIS E AGENTES DO COMÉRCIO ESPECIALIZADO EM PRODUTOS NÃO ESPECIFICADOS A",
    "aliquota": 5
  },
  {
    "codigo_servico": "10.09",
    "codigo_atividade": "461500000",
    "descricao_servico": "REPRESENTANTES COMERCIAIS E AGENTES DO COMÉRCIO DE ELETRODOMÉSTICOS, MÓVEIS E ARTIGOS DE USO DOMÉSTI",
    "aliquota": 5
  },
  {
    "codigo_servico": "10.09",
    "codigo_atividade": "453070600",
    "descricao_servico": "REPRESENTANTES COMERCIAIS E AGENTES DO COMÉRCIO DE PEÇAS E ACESSÓRIOS NOVOS E USADOS PARA VEÍCULOS A",
    "aliquota": 5
  },
  {
    "codigo_servico": "10.09",
    "codigo_atividade": "620230003",
    "descricao_servico": "REPRESENTAÇÃO DE SOFTWARE CUSTOMIZÁVEIS",
    "aliquota": 5
  },
  {
    "codigo_servico": "10.09",
    "codigo_atividade": "451290101",
    "descricao_servico": "REPRESENTANTES COMERCIAIS E AGENTES DO COMÉRCIO DE VEÍCULOS AUTOMOTORES",
    "aliquota": 5
  },
  {
    "codigo_servico": "10.09",
    "codigo_atividade": "461250000",
    "descricao_servico": "REPRESENTANTES COMERCIAIS E AGENTES DO COMÉRCIO DE COMBUSTÍVEIS, MINERAIS, PRODUTOS SIDERÚRGICOS E Q",
    "aliquota": 5
  },
  {
    "codigo_servico": "10.09",
    "codigo_atividade": "620310003",
    "descricao_servico": "REPRESENTAÇÃO DE PROGRAMAS DE INFORMÁTICA NÃO-CUSTOMIZÁVEIS",
    "aliquota": 5
  },
  {
    "codigo_servico": "10.09",
    "codigo_atividade": "461920000",
    "descricao_servico": "REPRESENTANTES COMERCIAIS E AGENTES DO COMÉRCIO DE MERCADORIAS EM GERAL NÃO ESPECIALIZADO",
    "aliquota": 5
  },
  {
    "codigo_servico": "10.09",
    "codigo_atividade": "829979910",
    "descricao_servico": "ESCRITÓRIO DE REPRESENTAÇÃO - FILIAL DE EMPRESA ESTRANGEIRA, EXCETO DE BANCOS ESTRANGEIROS",
    "aliquota": 5
  },
  {
    "codigo_servico": "10.09",
    "codigo_atividade": "461840100",
    "descricao_servico": "REPRESENTANTES COMERCIAIS E AGENTES DO COMÉRCIO DE MEDICAMENTOS, COSMÉTICOS E PRODUTOS DE PERFUMARIA",
    "aliquota": 5
  },
  {
    "codigo_servico": "10.09",
    "codigo_atividade": "461680000",
    "descricao_servico": "REPRESENTANTES COMERCIAIS E AGENTES DO COMÉRCIO DE TÊXTEIS, VESTUÁRIO, CALÇADOS E ARTIGOS DE VIAGEM",
    "aliquota": 5
  },
  {
    "codigo_servico": "10.09",
    "codigo_atividade": "461840300",
    "descricao_servico": "REPRESENTANTES COMERCIAIS E AGENTES DO COMÉRCIO DE JORNAIS, REVISTAS E OUTRAS PUBLICAÇÕES",
    "aliquota": 5
  },
  {
    "codigo_servico": "10.10",
    "codigo_atividade": "351150100",
    "descricao_servico": "GERAÇÃO DE ENERGIA ELÉTRICA",
    "aliquota": 5
  },
  {
    "codigo_servico": "10.10",
    "codigo_atividade": "351400000",
    "descricao_servico": "DISTRIBUIÇÃO DE ENERGIA ELÉTRICA",
    "aliquota": 5
  },
  {
    "codigo_servico": "10.10",
    "codigo_atividade": "351230000",
    "descricao_servico": "TRANSMISSÃO DE ENERGIA ELÉTRICA",
    "aliquota": 5
  },
  {
    "codigo_servico": "11.01",
    "codigo_atividade": "522310000",
    "descricao_servico": "ESTACIONAMENTO DE VEÍCULOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "11.01",
    "codigo_atividade": "960929909",
    "descricao_servico": "SERVIÇOS DE MANOBRISTAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "11.01",
    "codigo_atividade": "524019903",
    "descricao_servico": "SERVIÇOS DE ESTACIONAMENTO E HANGARAGEM DE HELICÓPTEROS",
    "aliquota": 5
  },
  {
    "codigo_servico": "11.02",
    "codigo_atividade": "801110102",
    "descricao_servico": "ATIVIDADES DE VIGILÂNCIA E SEGURANÇA PRIVADA COM PORTE DE ARMA",
    "aliquota": 5
  },
  {
    "codigo_servico": "11.02",
    "codigo_atividade": "524019904",
    "descricao_servico": "SERVIÇOS DE GUARDA-VOLUMES, EM TERMINAIS AÉREOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "11.02",
    "codigo_atividade": "802000299",
    "descricao_servico": "OUTRAS ATIVIDADES DE SERVIÇOS DE SEGURANÇA",
    "aliquota": 5
  },
  {
    "codigo_servico": "11.02",
    "codigo_atividade": "619069904",
    "descricao_servico": "SERVIÇOS DE RASTRAMENTO POR SATÉLITE",
    "aliquota": 5
  },
  {
    "codigo_servico": "11.02",
    "codigo_atividade": "970050003",
    "descricao_servico": "GUARDA-COSTA",
    "aliquota": 5
  },
  {
    "codigo_servico": "11.02",
    "codigo_atividade": "523979901",
    "descricao_servico": "SERVIÇOS DE GUARDA-VOLUMES, NO TRANSPORTE HIDROVIÁRIO",
    "aliquota": 5
  },
  {
    "codigo_servico": "11.02",
    "codigo_atividade": "802000104",
    "descricao_servico": "SERVIÇOS DE RASTREAMENTO E/OU RECUPERAÇÃO DE VEÍCULOS ROUBADOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "11.02",
    "codigo_atividade": "960929908",
    "descricao_servico": "SERVIÇOS DE GUARDA DE PISCINA",
    "aliquota": 5
  },
  {
    "codigo_servico": "11.02",
    "codigo_atividade": "522909904",
    "descricao_servico": "SERVIÇOS DE GUARDA-VOLUMES EM TERMINAIS RODOVIÁRIOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "11.02",
    "codigo_atividade": "802000102",
    "descricao_servico": "SERVIÇOS DE MONITORAMENTO DE SISTEMAS DE SEGURANÇA ELETRÔNICOS (ALARMES DE INCÊNDIO, ALARMES DE PROT",
    "aliquota": 5
  },
  {
    "codigo_servico": "11.02",
    "codigo_atividade": "802000199",
    "descricao_servico": "ATIVIDADES DE MONITORAMENTO DE SISTEMAS DE SEGURANÇA ELETRÔNICO",
    "aliquota": 5
  },
  {
    "codigo_servico": "11.02",
    "codigo_atividade": "801110101",
    "descricao_servico": "ATIVIDADES DE VIGILÂNCIA E SEGURANÇA PRIVADA SEM PORTE DE ARMA",
    "aliquota": 5
  },
  {
    "codigo_servico": "11.03",
    "codigo_atividade": "522909902",
    "descricao_servico": "SERVIÇOS DE ESCOLTA NO TRANSPORTE RODOVIÁRIO DE CARGAS ESPECIAIS",
    "aliquota": 5
  },
  {
    "codigo_servico": "11.04",
    "codigo_atividade": "521250000",
    "descricao_servico": "CARGA E DESCARGA",
    "aliquota": 5
  },
  {
    "codigo_servico": "11.04",
    "codigo_atividade": "023060004",
    "descricao_servico": "SERVIÇOS DE DESCARREGAMENTO DE MADEIRAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "11.04",
    "codigo_atividade": "521179900",
    "descricao_servico": "DEPÓSITOS DE MERCADORIAS PARA TERCEIROS, EXCETO ARMAZÉNS GERAIS E GUARDA-MÓVEIS",
    "aliquota": 5
  },
  {
    "codigo_servico": "11.04",
    "codigo_atividade": "801110103",
    "descricao_servico": "Serviços de vigilância a propriedades",
    "aliquota": 5
  },
  {
    "codigo_servico": "11.04",
    "codigo_atividade": "521170100",
    "descricao_servico": "ARMAZÉNS GERAIS - EMISSÃO DE WARRANT",
    "aliquota": 5
  },
  {
    "codigo_servico": "11.04",
    "codigo_atividade": "521170200",
    "descricao_servico": "GUARDA-MÓVEIS",
    "aliquota": 5
  },
  {
    "codigo_servico": "11.04",
    "codigo_atividade": "525080302",
    "descricao_servico": "SERVIÇOS DE ACONDICIONAMENTO E AGRUPAMENTO DE CARGAS NO TRANSPORTE AÉREO, FERROVIÁRIO E RODOVIÁRIO",
    "aliquota": 5
  },
  {
    "codigo_servico": "11.04",
    "codigo_atividade": "801110104",
    "descricao_servico": "ARMAZENAMENTO, DEPOSITO, DESCARGA,ARMACAO E GUARDA DE BENS DE QUALQUER ESPECIE",
    "aliquota": 5
  },
  {
    "codigo_servico": "11.05",
    "codigo_atividade": "802000101",
    "descricao_servico": "SERVIÇO DE MONITORAMENTO DE BENS E DE PESSOAS, COM USO DE IMAGEM POR SATÉLITE",
    "aliquota": 5
  },
  {
    "codigo_servico": "12.01",
    "codigo_atividade": "900190100",
    "descricao_servico": "PRODUÇÃO TEATRAL",
    "aliquota": 5
  },
  {
    "codigo_servico": "12.01",
    "codigo_atividade": "900190199",
    "descricao_servico": "PRODUÇÃO TEATRAL (ARTISTAS PERNAMBUCANOS)",
    "aliquota": 5
  },
  {
    "codigo_servico": "12.01",
    "codigo_atividade": "900350002",
    "descricao_servico": "CASA DE CULTURA, ESPETÁCULOS OU SHOWS",
    "aliquota": 5
  },
  {
    "codigo_servico": "12.02",
    "codigo_atividade": "742000102",
    "descricao_servico": "ATELIER FOTOGRÁFICO",
    "aliquota": 5
  },
  {
    "codigo_servico": "12.02",
    "codigo_atividade": "591460000",
    "descricao_servico": "ATIVIDADES DE EXIBIÇÃO CINEMATOGRÁFICA",
    "aliquota": 5
  },
  {
    "codigo_servico": "12.03",
    "codigo_atividade": "900190400",
    "descricao_servico": "PRODUÇÃO DE ESPETÁCULOS CIRCENSES, DE MARIONETES E SIMILARES",
    "aliquota": 5
  },
  {
    "codigo_servico": "12.04",
    "codigo_atividade": "591119902",
    "descricao_servico": "FILMAGEM DE PROGRAMAS DE TELEVISÃO POR PRODUTORES INDEPENDENTES",
    "aliquota": 5
  },
  {
    "codigo_servico": "12.04",
    "codigo_atividade": "900199902",
    "descricao_servico": "ATIVIDADES DE APRESENTADORES DE PROGRAMAS DE TELEVISÃO E RÁDIO",
    "aliquota": 5
  },
  {
    "codigo_servico": "12.05",
    "codigo_atividade": "900190500",
    "descricao_servico": "PRODUÇÃO DE ESPETÁCULOS DE RODEIOS, VAQUEJADAS E SIMILARES",
    "aliquota": 5
  },
  {
    "codigo_servico": "12.05",
    "codigo_atividade": "932120000",
    "descricao_servico": "PARQUES DE DIVERSÃO E PARQUES TEMÁTICOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "12.05",
    "codigo_atividade": "932989901",
    "descricao_servico": "OUTRAS ATIVIDADES DE RECREAÇÃO E LAZER NÃO ESPECIFICADAS ANTERIORMENTE",
    "aliquota": 5
  },
  {
    "codigo_servico": "12.06",
    "codigo_atividade": "932980100",
    "descricao_servico": "DISCOTECAS, DANCETERIAS, SALÕES DE DANÇA E SIMILARES",
    "aliquota": 5
  },
  {
    "codigo_servico": "12.07",
    "codigo_atividade": "900190300",
    "descricao_servico": "PRODUÇÃO DE ESPETÁCULOS DE DANÇA",
    "aliquota": 5
  },
  {
    "codigo_servico": "12.09",
    "codigo_atividade": "932980300",
    "descricao_servico": "EXPLORAÇÃO DE JOGOS DE SINUCA, BILHAR E SIMILARES",
    "aliquota": 5
  },
  {
    "codigo_servico": "12.09",
    "codigo_atividade": "932980400",
    "descricao_servico": "EXPLORAÇÃO DE JOGOS ELETRÔNICOS RECREATIVOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "12.09",
    "codigo_atividade": "631190002",
    "descricao_servico": "ALUGUEL DE HORA EM COMPUTADOR",
    "aliquota": 5
  },
  {
    "codigo_servico": "12.09",
    "codigo_atividade": "932980200",
    "descricao_servico": "EXPLORAÇÃO DE BOLICHES",
    "aliquota": 5
  },
  {
    "codigo_servico": "12.09",
    "codigo_atividade": "932989903",
    "descricao_servico": "EXPLORAÇÃO DE JOGOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "12.09",
    "codigo_atividade": "960920400",
    "descricao_servico": "EXPLORAÇÃO DE MÁQUINAS DE SERVIÇOS PESSOAIS ACIONADAS POR MOEDA",
    "aliquota": 5
  },
  {
    "codigo_servico": "12.09",
    "codigo_atividade": "829970701",
    "descricao_servico": "SALAS DE ACESSO À INTERNET",
    "aliquota": 5
  },
  {
    "codigo_servico": "12.10",
    "codigo_atividade": "920030200",
    "descricao_servico": "EXPLORAÇÃO DE APOSTAS EM CORRIDAS DE CAVALOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "12.11",
    "codigo_atividade": "931919900",
    "descricao_servico": "OUTRAS ATIVIDADES ESPORTIVAS NÃO ESPECIFICADAS ANTERIORMENTE",
    "aliquota": 5
  },
  {
    "codigo_servico": "12.11",
    "codigo_atividade": "931910101",
    "descricao_servico": "PRODUÇÃO E PROMOÇÃO DE EVENTOS ESPORTIVOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "12.12",
    "codigo_atividade": "900190299",
    "descricao_servico": "PRODUÇÃO MUSICAL (ARTISTAS PERNAMBUCANOS)",
    "aliquota": 5
  },
  {
    "codigo_servico": "12.12",
    "codigo_atividade": "900190201",
    "descricao_servico": "PRODUÇÃO MUSICAL",
    "aliquota": 5
  },
  {
    "codigo_servico": "12.12",
    "codigo_atividade": "631940003",
    "descricao_servico": "SERVIÇOS DE DISPONIBILIZAÇÃO DE MÚSICA ATRAVÉS DA INTERNET",
    "aliquota": 5
  },
  {
    "codigo_servico": "12.13",
    "codigo_atividade": "900199901",
    "descricao_servico": "ARTES CÊNICAS, ESPETÁCULOS E ATIVIDADES COMPLEMENTARES NÃO ESPECIFICADOS ANTERIORMENTE",
    "aliquota": 5
  },
  {
    "codigo_servico": "12.13",
    "codigo_atividade": "900190603",
    "descricao_servico": "SERVIÇOS DE ILUMINAÇÃO LIGADA ÀS ATIVIDADES ARTÍSTICAS, CÊNICAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "12.14",
    "codigo_atividade": "900190601",
    "descricao_servico": "ATIVIDADES DE SONORIZAÇÃO E DE ILUMINAÇÃO",
    "aliquota": 5
  },
  {
    "codigo_servico": "12.15",
    "codigo_atividade": "949360002",
    "descricao_servico": "ATIVIDADE DE BLOCOS CARNAVALESCOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "12.16",
    "codigo_atividade": "900190202",
    "descricao_servico": "ATIVIDADES DE PRODUÇÃO E PROMOÇÃO DE BANDAS, GRUPOS MUSICAIS, ORQUESTRAS E OUTRAS COMPANHIAS MUSICAI",
    "aliquota": 5
  },
  {
    "codigo_servico": "12.17",
    "codigo_atividade": "932989902",
    "descricao_servico": "ATIVIDADES DE ANIMAÇÃO E RECREAÇÃO EM FESTAS E EVENTOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "13.02",
    "codigo_atividade": "183000100",
    "descricao_servico": "REPRODUÇÃO DE SOM EM QUALQUER SUPORTE",
    "aliquota": 5
  },
  {
    "codigo_servico": "13.02",
    "codigo_atividade": "591200100",
    "descricao_servico": "SERVIÇOS DE DUBLAGEM",
    "aliquota": 5
  },
  {
    "codigo_servico": "13.02",
    "codigo_atividade": "592010000",
    "descricao_servico": "ATIVIDADES DE GRAVAÇÃO DE SOM E DE EDIÇÃO DE MÚSICA",
    "aliquota": 5
  },
  {
    "codigo_servico": "13.02",
    "codigo_atividade": "591200200",
    "descricao_servico": "SERVIÇOS DE MIXAGEM SONORA EM PRODUÇÃO AUDIOVISUAL",
    "aliquota": 5
  },
  {
    "codigo_servico": "13.02",
    "codigo_atividade": "829979914",
    "descricao_servico": "SERVIÇOS DE SONORIZAÇÃO PARA TELEFONE",
    "aliquota": 5
  },
  {
    "codigo_servico": "13.03",
    "codigo_atividade": "742000400",
    "descricao_servico": "FILMAGEM DE FESTAS E EVENTOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "13.03",
    "codigo_atividade": "591110200",
    "descricao_servico": "PRODUÇÃO DE FILMES PARA PUBLICIDADE",
    "aliquota": 5
  },
  {
    "codigo_servico": "13.03",
    "codigo_atividade": "742000200",
    "descricao_servico": "ATIVIDADES DE PRODUÇÃO DE FOTOGRAFIAS AÉREAS E SUBMARINAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "13.03",
    "codigo_atividade": "183000200",
    "descricao_servico": "REPRODUÇÃO DE VÍDEO EM QUALQUER SUPORTE",
    "aliquota": 5
  },
  {
    "codigo_servico": "13.03",
    "codigo_atividade": "742000300",
    "descricao_servico": "LABORATÓRIOS FOTOGRÁFICOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "13.03",
    "codigo_atividade": "591209900",
    "descricao_servico": "ATIVIDADES DE PÓS-PRODUÇÃO CINEMATOGRÁFICA, DE VÍDEOS E DE PROGRAMAS DE TELEVISÃO NÃO ESPECIFICADAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "13.03",
    "codigo_atividade": "183000300",
    "descricao_servico": "REPRODUÇÃO DE SOFTWARE EM QUALQUER SUPORTE",
    "aliquota": 5
  },
  {
    "codigo_servico": "13.03",
    "codigo_atividade": "591119901",
    "descricao_servico": "ATIVIDADES DE PRODUÇÃO CINEMATOGRÁFICA, DE VÍDEOS E DE PROGRAMAS DE TELEVISÃO NÃO ESPECIFICADAS ANTE",
    "aliquota": 5
  },
  {
    "codigo_servico": "13.03",
    "codigo_atividade": "591110100",
    "descricao_servico": "ESTÚDIOS CINEMATOGRÁFICOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "13.03",
    "codigo_atividade": "742000101",
    "descricao_servico": "ATIVIDADES DE PRODUÇÃO DE FOTOGRAFIAS, EXCETO AÉREA E SUBMARINA",
    "aliquota": 5
  },
  {
    "codigo_servico": "13.04",
    "codigo_atividade": "742000500",
    "descricao_servico": "SERVIÇOS DE MICROFILMAGEM",
    "aliquota": 5
  },
  {
    "codigo_servico": "13.04",
    "codigo_atividade": "631190003",
    "descricao_servico": "DIGITALIZAÇÃO PARA ENTRADA DE DADOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "13.04",
    "codigo_atividade": "821990100",
    "descricao_servico": "FOTOCÓPIAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "13.05",
    "codigo_atividade": "181130100",
    "descricao_servico": "IMPRESSÃO DE JORNAIS",
    "aliquota": 5
  },
  {
    "codigo_servico": "13.05",
    "codigo_atividade": "181210002",
    "descricao_servico": "DOCUMENTOS FISCAIS IMPRESSOS SOB ENCOMENDA",
    "aliquota": 5
  },
  {
    "codigo_servico": "13.05",
    "codigo_atividade": "181300100",
    "descricao_servico": "IMPRESSÃO DE MATERIAL PARA USO PUBLICITÁRIO",
    "aliquota": 5
  },
  {
    "codigo_servico": "13.05",
    "codigo_atividade": "182299900",
    "descricao_servico": "SERVIÇOS DE ACABAMENTOS GRÁFICOS, EXCETO ENCADERNAÇÃO E PLASTIFICAÇÃO",
    "aliquota": 5
  },
  {
    "codigo_servico": "13.05",
    "codigo_atividade": "181309900",
    "descricao_servico": "IMPRESSÃO DE MATERIAL PARA OUTROS USOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "13.05",
    "codigo_atividade": "182110000",
    "descricao_servico": "SERVIÇOS DE PRÉ-IMPRESSÃO",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.01",
    "codigo_atividade": "331471500",
    "descricao_servico": "MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS E EQUIPAMENTOS PARA USO NA EXTRAÇÃO MINERAL, EXCETO NA EXTRAÇÃO D",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.01",
    "codigo_atividade": "331710100",
    "descricao_servico": "MANUTENÇÃO E REPARAÇÃO DE EMBARCAÇÕES E ESTRUTURAS FLUTUANTES",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.01",
    "codigo_atividade": "331471200",
    "descricao_servico": "MANUTENÇÃO E REPARAÇÃO DE TRATORES AGRÍCOLAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.01",
    "codigo_atividade": "331479900",
    "descricao_servico": "MANUTENÇÃO E REPARAÇÃO DE OUTRAS MÁQUINAS E EQUIPAMENTOS PARA USOS INDUSTRIAIS NÃO ESPECIFICADOS ANT",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.01",
    "codigo_atividade": "952910600",
    "descricao_servico": "REPARAÇÃO DE JÓIAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.01",
    "codigo_atividade": "432230202",
    "descricao_servico": "MANUTENÇÃO DE AR CONDICIONADO CENTRAL",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.01",
    "codigo_atividade": "331470300",
    "descricao_servico": "MANUTENÇÃO E REPARAÇÃO DE VÁLVULAS INDUSTRIAIS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.01",
    "codigo_atividade": "432910300",
    "descricao_servico": "INSTALAÇÃO, MANUTENÇÃO E REPARAÇÃO DE ELEVADORES, ESCADAS E ESTEIRAS ROLANTES",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.01",
    "codigo_atividade": "951180001",
    "descricao_servico": "REPARAÇÃO E MANUTENÇÃO DE COMPUTADORES E DE EQUIPAMENTOS PERIFÉRICOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.01",
    "codigo_atividade": "432910102",
    "descricao_servico": "REPARAÇÃO OU MANUTENÇÃO DE ANÚNCIOS LUMINOSOS OU NÃO",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.01",
    "codigo_atividade": "331470500",
    "descricao_servico": "MANUTENÇÃO E REPARAÇÃO DE EQUIPAMENTOS DE TRANSMISSÃO PARA FINS INDUSTRIAIS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.01",
    "codigo_atividade": "331630200",
    "descricao_servico": "MANUTENÇÃO DE AERONAVES NA PISTA",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.01",
    "codigo_atividade": "331470100",
    "descricao_servico": "MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS MOTRIZES NÃO-ELÉTRICAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.01",
    "codigo_atividade": "452000100",
    "descricao_servico": "SERVIÇOS DE MANUTENÇÃO E REPARAÇÃO MECÂNICA DE VEÍCULOS AUTOMOTORES",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.01",
    "codigo_atividade": "952150001",
    "descricao_servico": "REPARAÇÃO E MANUTENÇÃO DE EQUIPAMENTOS ELETROELETRÔNICOS DE USO PESSOAL E DOMÉSTICO",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.01",
    "codigo_atividade": "429280102",
    "descricao_servico": "SERVIÇO DE SOLDAGEM PARA CONSTRUÇÃO CIVIL",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.01",
    "codigo_atividade": "091060002",
    "descricao_servico": "SERVIÇOS DE LIQUEFAÇÃO E REGASEIFICAÇÃO DE GÁS NATURAL, NA MINA, PARA TRANSPORTE",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.01",
    "codigo_atividade": "952910501",
    "descricao_servico": "REPARAÇÃO DE ARTIGOS DO MOBILIÁRIO",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.01",
    "codigo_atividade": "331550000",
    "descricao_servico": "MANUTENÇÃO E REPARAÇÃO DE VEÍCULOS FERROVIÁRIOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.01",
    "codigo_atividade": "331470800",
    "descricao_servico": "MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS, EQUIPAMENTOS E APARELHOS PARA TRANSPORTE E ELEVAÇÃO DE CARGAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.01",
    "codigo_atividade": "331980000",
    "descricao_servico": "MANUTENÇÃO E REPARAÇÃO DE EQUIPAMENTOS E PRODUTOS NÃO ESPECIFICADOS ANTERIORMENTE",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.01",
    "codigo_atividade": "331630100",
    "descricao_servico": "MANUTENÇÃO E REPARAÇÃO DE AERONAVES, EXCETO A MANUTENÇÃO NA PISTA",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.01",
    "codigo_atividade": "952919901",
    "descricao_servico": "REPARAÇÃO E MANUTENÇÃO DE OUTROS OBJETOS E EQUIPAMENTOS PESSOAIS E DOMÉSTICOS NÃO ESPECIFICADOS ANTE",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.01",
    "codigo_atividade": "494000002",
    "descricao_servico": "MANUTENÇÃO DE DUTOS DE TRANSPORTE",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.01",
    "codigo_atividade": "331471600",
    "descricao_servico": "MANUTENÇÃO E REPARAÇÃO DE TRATORES, EXCETO AGRÍCOLAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.01",
    "codigo_atividade": "952910401",
    "descricao_servico": "REPARAÇÃO DE BICICLETAS, TRICICLOS E OUTROS VEÍCULOS NÃO-MOTORIZADOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.01",
    "codigo_atividade": "331470400",
    "descricao_servico": "MANUTENÇÃO E REPARAÇÃO DE COMPRESSORES",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.01",
    "codigo_atividade": "331471800",
    "descricao_servico": "MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS PARA A INDÚSTRIA METALÚRGICA, EXCETO MÁQUINAS-FERRAMENTA",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.01",
    "codigo_atividade": "952910100",
    "descricao_servico": "REPARAÇÃO DE CALÇADOS, BOLSAS E ARTIGOS DE VIAGEM",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.01",
    "codigo_atividade": "331472200",
    "descricao_servico": "MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS E APARELHOS PARA A INDÚSTRIA DO PLÁSTICO",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.01",
    "codigo_atividade": "331472000",
    "descricao_servico": "MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS E EQUIPAMENTOS PARA A INDÚSTRIA TÊXTIL, DO VESTUÁRIO, DO COURO E",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.01",
    "codigo_atividade": "331471400",
    "descricao_servico": "MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS E EQUIPAMENTOS PARA A PROSPECÇÃO E EXTRAÇÃO DE PETRÓLEO",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.01",
    "codigo_atividade": "331471300",
    "descricao_servico": "MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS-FERRAMENTA",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.01",
    "codigo_atividade": "331471700",
    "descricao_servico": "MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS E EQUIPAMENTOS DE TERRAPLENAGEM, PAVIMENTAÇÃO E CONSTRUÇÃO, EXCET",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.01",
    "codigo_atividade": "331210200",
    "descricao_servico": "MANUTENÇÃO E REPARAÇÃO DE APARELHOS E INSTRUMENTOS DE MEDIDA, TESTE E CONTROLE",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.01",
    "codigo_atividade": "331470200",
    "descricao_servico": "MANUTENÇÃO E REPARAÇÃO DE EQUIPAMENTOS HIDRÁULICOS E PNEUMÁTICOS, EXCETO VÁLVULAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.01",
    "codigo_atividade": "829979911",
    "descricao_servico": "SERVIÇO DE MANUTENÇÃO DE AQUÁRIOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.01",
    "codigo_atividade": "331470900",
    "descricao_servico": "MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS DE ESCREVER, CALCULAR E DE OUTROS EQUIPAMENTOS NÃO-ELETRÔNICOS PA",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.01",
    "codigo_atividade": "454390000",
    "descricao_servico": "MANUTENÇÃO E REPARAÇÃO DE MOTOCICLETAS E MOTONETAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.01",
    "codigo_atividade": "331471100",
    "descricao_servico": "MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS E EQUIPAMENTOS PARA AGRICULTURA E PECUÁRIA",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.01",
    "codigo_atividade": "331472100",
    "descricao_servico": "MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS E APARELHOS PARA A INDÚSTRIA DE CELULOSE, PAPEL E PAPELÃO E ARTEF",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.01",
    "codigo_atividade": "951260001",
    "descricao_servico": "REPARAÇÃO E MANUTENÇÃO DE EQUIPAMENTOS DE COMUNICAÇÃO",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.01",
    "codigo_atividade": "353010002",
    "descricao_servico": "SERVIÇO DE SUPRIMENTO DE AR CONDICIONADO",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.01",
    "codigo_atividade": "802000103",
    "descricao_servico": "MANUTENÇÃO DE EQUIPAMENTOS E SISTEMAS DE SEGURANÇA ELETRÔNICOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.01",
    "codigo_atividade": "331120000",
    "descricao_servico": "MANUTENÇÃO E REPARAÇÃO DE TANQUES, RESERVATÓRIOS METÁLICOS E CALDEIRAS, EXCETO PARA VEÍCULOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.01",
    "codigo_atividade": "331470600",
    "descricao_servico": "MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS, APARELHOS E EQUIPAMENTOS PARA INSTALAÇÕES TÉRMICAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.01",
    "codigo_atividade": "293010302",
    "descricao_servico": "SERVIÇO DE BLINDAGEM DE VEÍCULOS AUTOMOTORES",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.01",
    "codigo_atividade": "331710200",
    "descricao_servico": "MANUTENÇÃO E REPARAÇÃO DE EMBARCAÇÕES PARA ESPORTE E LAZER",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.01",
    "codigo_atividade": "331471000",
    "descricao_servico": "MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS E EQUIPAMENTOS PARA USO GERAL NÃO ESPECIFICADOS ANTERIORMENTE",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.01",
    "codigo_atividade": "331210300",
    "descricao_servico": "MANUTENÇÃO E REPARAÇÃO DE APARELHOS ELETROMÉDICOS E ELETROTERAPÊUTICOS E EQUIPAMENTOS DE IRRADIAÇÃO",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.01",
    "codigo_atividade": "331390100",
    "descricao_servico": "MANUTENÇÃO E REPARAÇÃO DE GERADORES, TRANSFORMADORES E MOTORES ELÉTRICOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.01",
    "codigo_atividade": "952910300",
    "descricao_servico": "REPARAÇÃO DE RELÓGIOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.01",
    "codigo_atividade": "452000300",
    "descricao_servico": "SERVIÇOS DE MANUTENÇÃO E REPARAÇÃO ELÉTRICA DE VEÍCULOS AUTOMOTORES",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.01",
    "codigo_atividade": "331471900",
    "descricao_servico": "MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS E EQUIPAMENTOS PARA AS INDÚSTRIAS DE ALIMENTOS, BEBIDAS E FUMO",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.01",
    "codigo_atividade": "331210400",
    "descricao_servico": "MANUTENÇÃO E REPARAÇÃO DE EQUIPAMENTOS E INSTRUMENTOS ÓPTICOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.01",
    "codigo_atividade": "331390200",
    "descricao_servico": "MANUTENÇÃO E REPARAÇÃO DE BATERIAS E ACUMULADORES ELÉTRICOS, EXCETO PARA VEÍCULOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.01",
    "codigo_atividade": "331399900",
    "descricao_servico": "MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS, APARELHOS E MATERIAIS ELÉTRICOS NÃO ESPECIFICADOS ANTERIORMENTE",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.01",
    "codigo_atividade": "802000201",
    "descricao_servico": "REPARAÇÃO OU AJUSTE MECÂNICO DE COFRES, TRANCAS E TRAVAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.01",
    "codigo_atividade": "331470700",
    "descricao_servico": "MANUTENÇÃO E REPARAÇÃO DE MÁQUINAS E APARELHOS DE REFRIGERAÇÃO E VENTILAÇÃO PARA USO INDUSTRIAL E CO",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.01",
    "codigo_atividade": "452000400",
    "descricao_servico": "SERVIÇOS DE ALINHAMENTO E BALANCEAMENTO DE VEÍCULOS AUTOMOTORES",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.01",
    "codigo_atividade": "619069902",
    "descricao_servico": "SERVIÇOS DE INSTALAÇÃO E MANUTENÇÃO DE LINHAS DE TELEFONES",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.01",
    "codigo_atividade": "475120200",
    "descricao_servico": "RECARGA DE CARTUCHOS PARA EQUIPAMENTOS DE INFORMÁTICA",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.02",
    "codigo_atividade": "951260002",
    "descricao_servico": "ASSISTÊNCIA TÉCNICA EM TELEFONE",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.02",
    "codigo_atividade": "952150002",
    "descricao_servico": "CONSERTO DE ELETRODOMÉSTICOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.02",
    "codigo_atividade": "951180002",
    "descricao_servico": "SERVIÇOS DE ASSISTÊNCIA TÉCNICA EM EQUIPAMENTOS DE INFORMÁTICA",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.03",
    "codigo_atividade": "272280200",
    "descricao_servico": "RECONDICIONAMENTO DE BATERIAS E ACUMULADORES PARA VEÍCULOS AUTOMOTORES",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.03",
    "codigo_atividade": "295060000",
    "descricao_servico": "RECONDICIONAMENTO E RECUPERAÇÃO DE MOTORES PARA VEÍCULOS AUTOMOTORES",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.04",
    "codigo_atividade": "452000600",
    "descricao_servico": "SERVIÇOS DE BORRACHARIA PARA VEÍCULOS AUTOMOTORES",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.04",
    "codigo_atividade": "221290002",
    "descricao_servico": "SERVIÇO DE PNEUS (RECONDICIONAMENTO, RECAUCHUTAGEM, RECAPAGEM OU REMOLDAGEM)",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.04",
    "codigo_atividade": "952910404",
    "descricao_servico": "REPARAÇÃO DE, CONSERTO DE PNEUS E CÂMARAS DE AR DE BICICLETAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.05",
    "codigo_atividade": "134050200",
    "descricao_servico": "ALVEJAMENTO, TINGIMENTO E TORÇÃO EM FIOS, TECIDOS, ARTEFATOS TÊXTEIS E PEÇAS DO VESTUÁRIO",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.05",
    "codigo_atividade": "952919903",
    "descricao_servico": "ATIVIDADE DE AMOLADOR DE FACAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.05",
    "codigo_atividade": "329909902",
    "descricao_servico": "BENEFICIAMENTO DE ALGAS MARINHAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.05",
    "codigo_atividade": "101120302",
    "descricao_servico": "SERVIÇO DE COURO DE OVINOS E CAPRINOS, SECAGEM E SALGA",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.05",
    "codigo_atividade": "524019902",
    "descricao_servico": "LIMPEZA DE AVIÕES, AERONAVES",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.05",
    "codigo_atividade": "829979912",
    "descricao_servico": "SERVIÇO DE PINTURA DE FAIXAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.05",
    "codigo_atividade": "222939902",
    "descricao_servico": "BENEFICIAMENTOS DE MATERIAL PLÁSTICO, TINGIMENTO, PIGMENTAÇÃO E OUTROS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.05",
    "codigo_atividade": "072430200",
    "descricao_servico": "BENEFICIAMENTO DE MINÉRIO DE METAIS PRECIOSOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.05",
    "codigo_atividade": "072940302",
    "descricao_servico": "BENEFICIAMENTO DE MINÉRIO DE NÍQUEL",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.05",
    "codigo_atividade": "105110002",
    "descricao_servico": "BENEFICIAMENTO DE LEITE",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.05",
    "codigo_atividade": "253220102",
    "descricao_servico": "SERVIÇO DE ESTAMPARIA DE METAIS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.05",
    "codigo_atividade": "072190200",
    "descricao_servico": "BENEFICIAMENTO DE MINÉRIO DE ALUMÍNIO",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.05",
    "codigo_atividade": "153190102",
    "descricao_servico": "SERVIÇO DE COSTURA E PESPONTO DE CALCADOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.05",
    "codigo_atividade": "050030200",
    "descricao_servico": "BENEFICIAMENTO DE CARVÃO MINERAL",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.05",
    "codigo_atividade": "104220002",
    "descricao_servico": "REFINO DE ÓLEOS VEGETAIS COMESTÍVEIS E NÃO COMESTÍVEIS(EXCETO DE MILHO)",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.05",
    "codigo_atividade": "325070900",
    "descricao_servico": "LABORATORIOS DE SERVIÇOS OPTICOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.05",
    "codigo_atividade": "192250200",
    "descricao_servico": "RERREFINO DE ÓLEOS LUBRIFICANTES",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.05",
    "codigo_atividade": "071030200",
    "descricao_servico": "PELOTIZAÇÃO, SINTERIZAÇÃO E OUTROS BENEFICIAMENTOS DE MINÉRIO DE FERRO",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.05",
    "codigo_atividade": "960929906",
    "descricao_servico": "SERVIÇOS DE LAVAGEM DE ESTOFADO - EXCETO DE VEÍCULOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.05",
    "codigo_atividade": "089910102",
    "descricao_servico": "BENEFICIAMENTO DE GRAFITA",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.05",
    "codigo_atividade": "109969902",
    "descricao_servico": "BENEFICIAMENTO DE GUARANÁ (EM BASTÃO, EM PÓ, ETC)",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.05",
    "codigo_atividade": "131200002",
    "descricao_servico": "BENEFICIAMENTO DE FIBRAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.05",
    "codigo_atividade": "106940002",
    "descricao_servico": "BENEFICIAMENTO DE CEVADA EM GRÃO E SAGU",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.05",
    "codigo_atividade": "151060002",
    "descricao_servico": "CURTIMENTO E OUTRAS PREPARAÇÕES DE COUROS E PELES DE ANIMAIS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.05",
    "codigo_atividade": "259939902",
    "descricao_servico": "BRITAMENTO, LAVAGEM, PENEIRAÇÃO E OUTROS BENEFICIAMENTOS DE ESCORIAS OU DE OUTROS RESIDUOS METALÚRGI",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.05",
    "codigo_atividade": "239919902",
    "descricao_servico": "SERVIÇO DEBENEFICIAMENTO DE MINERAIS NAO-METALICOS NÃO ASSOCIADO A EXTRAÇÃO",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.05",
    "codigo_atividade": "464430102",
    "descricao_servico": "SERVIÇOS DE FRACIONAMENTO E ENVASAMENTO DE PRODUTOS FARMACÊUTICOS DE USO HUMANO PRÓPRIOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.05",
    "codigo_atividade": "101120402",
    "descricao_servico": "SERVIÇO DE SECAGEM E SALGA DE COURO DE BUFALINOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.05",
    "codigo_atividade": "101120202",
    "descricao_servico": "SECAGEM E SALGA DE COUROS E PELES DE EQÜINOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.05",
    "codigo_atividade": "960929903",
    "descricao_servico": "SERVIÇOS DE ENGRAXATE",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.05",
    "codigo_atividade": "072940500",
    "descricao_servico": "BENEFICIAMENTO DE MINÉRIOS DE COBRE, CHUMBO, ZINCO E OUTROS MINERAIS METÁLICOS NÃO-FERROSOS NÃO ESPE",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.05",
    "codigo_atividade": "134050100",
    "descricao_servico": "ESTAMPARIA E TEXTURIZAÇÃO EM FIOS, TECIDOS, ARTEFATOS TÊXTEIS E PEÇAS DO VESTUÁRIO",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.05",
    "codigo_atividade": "452000500",
    "descricao_servico": "SERVIÇOS DE LAVAGEM, LUBRIFICAÇÃO E POLIMENTO DE VEÍCULOS AUTOMOTORES",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.05",
    "codigo_atividade": "072270200",
    "descricao_servico": "BENEFICIAMENTO DE MINÉRIO DE ESTANHO",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.05",
    "codigo_atividade": "072510002",
    "descricao_servico": "BENEFICIAMENTO DE MINERAIS RADIOATIVOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.05",
    "codigo_atividade": "153190200",
    "descricao_servico": "ACABAMENTO DE CALÇADOS DE COURO SOB CONTRATO",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.05",
    "codigo_atividade": "101210302",
    "descricao_servico": "SALGA DE COUROS E PELES DE SUÍNOS (PORCOS)",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.05",
    "codigo_atividade": "089240302",
    "descricao_servico": "BENEFICIAMENTO DE SAL MARINHO",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.05",
    "codigo_atividade": "131110002",
    "descricao_servico": "BENEFICIAMENTO REALIZADO EM ESTABELECIMENTO NÃO AGRÍCOLA DE ALGODÃO (ALGODÃO CARDADO, PENTEADO, ETC.",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.05",
    "codigo_atividade": "089240202",
    "descricao_servico": "BENEFICIAMENTO DE SAL-GEMA",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.05",
    "codigo_atividade": "812900001",
    "descricao_servico": "ATIVIDADES DE LIMPEZA NÃO ESPECIFICADAS ANTERIORMENTE",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.05",
    "codigo_atividade": "829200000",
    "descricao_servico": "ENVASAMENTO E EMPACOTAMENTO SOB CONTRATO",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.05",
    "codigo_atividade": "089919902",
    "descricao_servico": "BENEFICIAMENTO DE OUTROS MINERAIS NÃO-METÁLICOS NÃO ESPECIFICADOS ANTERIORMENTE",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.05",
    "codigo_atividade": "022090400",
    "descricao_servico": "COLETA DE LÁTEX EM FLORESTAS NATIVAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.05",
    "codigo_atividade": "072350200",
    "descricao_servico": "BENEFICIAMENTO DE MINÉRIO DE MANGANÊS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.05",
    "codigo_atividade": "161020202",
    "descricao_servico": "BENEFICIAMENTO DE MADEIRA (SERRADA, RESSERRADA, ETC.)",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.05",
    "codigo_atividade": "103170002",
    "descricao_servico": "BENEFICIAMENTO DE CASTANHA-DE-CAJU E DE CASTANHA-DO-PARÁ",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.05",
    "codigo_atividade": "108130100",
    "descricao_servico": "BENEFICIAMENTO DE CAFÉ",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.05",
    "codigo_atividade": "072940202",
    "descricao_servico": "BENEFICIAMENTO DE MINÉRIO DE TUNGSTÊNIO",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.05",
    "codigo_atividade": "259930200",
    "descricao_servico": "SERVIÇO DE CORTE E DOBRA DE METAIS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.05",
    "codigo_atividade": "072940102",
    "descricao_servico": "BENEFICIAMENTO DE MINÉRIO DE NIÓBIO",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.05",
    "codigo_atividade": "239910101",
    "descricao_servico": "DECORAÇÃO, LAPIDAÇÃO, GRAVAÇÃO, VITRIFICAÇÃO E OUTROS TRABALHOS EM CERÂMICA, LOUÇA, VIDRO E CRISTAL",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.05",
    "codigo_atividade": "109960502",
    "descricao_servico": "BENEFICIAMENTO DE CHÁ DE ERVAS DIVERSAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.05",
    "codigo_atividade": "464430202",
    "descricao_servico": "SERVIÇOS DE FRACIONAMENTO E ENVASAMENTO DE PRODUTOS FARMACÊUTICOS DE USO VETERINÁRIO PRÓPRIOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.05",
    "codigo_atividade": "254380002",
    "descricao_servico": "SERVIÇOS DE FERRAMENTARIA",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.05",
    "codigo_atividade": "016360000",
    "descricao_servico": "ATIVIDADES DE PÓS-COLHEITA",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.05",
    "codigo_atividade": "952910403",
    "descricao_servico": "SERVIÇOS DE PINTURA DE BICICLETAS, TRICICLOS E DE OUTROS VEÍCULOS RECREATIVOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.05",
    "codigo_atividade": "106190100",
    "descricao_servico": "BENEFICIAMENTO DE ARROZ",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.05",
    "codigo_atividade": "089320002",
    "descricao_servico": "BENEFICIAMENTO DE GEMAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.05",
    "codigo_atividade": "239230002",
    "descricao_servico": "BENEFICIAMENTO DE GESSO OU GIPSITA",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.05",
    "codigo_atividade": "101120102",
    "descricao_servico": "SECAGEM E SALGA DE COUROS E PELES DE BOVINOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.05",
    "codigo_atividade": "253900100",
    "descricao_servico": "SERVIÇOS DE USINAGEM, TORNEARIA E SOLDA",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.05",
    "codigo_atividade": "253900200",
    "descricao_servico": "SERVIÇOS DE TRATAMENTO E REVESTIMENTO EM METAIS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.05",
    "codigo_atividade": "522909905",
    "descricao_servico": "SERVIÇOS DE LIMPEZA DE LOCOMOTIVAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.05",
    "codigo_atividade": "221960002",
    "descricao_servico": "BENEFICIAMENTO DE BORRACHA NATURAL E SINTÉTICA",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.05",
    "codigo_atividade": "329909903",
    "descricao_servico": "SERVIÇO DE TAXIDERMIA",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.06",
    "codigo_atividade": "332959900",
    "descricao_servico": "INSTALAÇÃO DE OUTROS EQUIPAMENTOS NÃO ESPECIFICADOS ANTERIORMENTE",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.06",
    "codigo_atividade": "432910101",
    "descricao_servico": "INSTALAÇÃO DE PAINÉIS PUBLICITÁRIOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.06",
    "codigo_atividade": "265230002",
    "descricao_servico": "SERVIÇO DE MONTAGEM DE CRONÔMETROS E RELÓGIOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.06",
    "codigo_atividade": "261080002",
    "descricao_servico": "MONTAGEM DE CIRCUITOS ELETRÔNICOS PARA TERCEIROS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.06",
    "codigo_atividade": "952150003",
    "descricao_servico": "INSTALAÇÃO DE PEÇAS E ACESSÓRIOS PARA ELETRODOMÉSTICOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.06",
    "codigo_atividade": "265150002",
    "descricao_servico": "CONCEPÇÃO, INSTALAÇÃO E INTEGRAÇÃO DE SISTEMAS DE AUTOMAÇÃO INDUSTRIAL",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.06",
    "codigo_atividade": "432919902",
    "descricao_servico": "MONTAGEM DE (POR CONTA DE TERCEIROS) ESTRUTURAS DE MADEIRA",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.06",
    "codigo_atividade": "952910402",
    "descricao_servico": "INSTALAÇÃO DE ACESSÓRIOS BICICLETAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.06",
    "codigo_atividade": "432910200",
    "descricao_servico": "INSTALAÇÃO DE EQUIPAMENTOS PARA ORIENTAÇÃO À NAVEGAÇÃO MARÍTIMA, FLUVIAL E LACUSTRE",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.06",
    "codigo_atividade": "332100000",
    "descricao_servico": "INSTALAÇÃO DE MÁQUINAS E EQUIPAMENTOS INDUSTRIAIS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.06",
    "codigo_atividade": "619069903",
    "descricao_servico": "SERVIÇOS DE INSTALAÇÃO E MANUTENÇÃO DE SUPORTES PARA CIRCUITOS DE DADOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.06",
    "codigo_atividade": "802000202",
    "descricao_servico": "INSTALAÇÃO DE COFRES, TRANCAS E TRAVAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.06",
    "codigo_atividade": "332950100",
    "descricao_servico": "SERVIÇOS DE MONTAGEM DE MÓVEIS DE QUALQUER MATERIAL",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.06",
    "codigo_atividade": "452000700",
    "descricao_servico": "SERVIÇOS DE INSTALAÇÃO, MANUTENÇÃO E REPARAÇÃO DE ACESSÓRIOS PARA VEÍCULOS AUTOMOTORES",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.07",
    "codigo_atividade": "478909913",
    "descricao_servico": "COLOCAÇÃO DE MOLDURAS E CONGÊNERES",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.07",
    "codigo_atividade": "433049905",
    "descricao_servico": "INSTALAÇÃO DE (POR CONTA DE TERCEIROS) MOLDURAS E ESPELHOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.08",
    "codigo_atividade": "581310000",
    "descricao_servico": "EDIÇÃO DE REVISTAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.08",
    "codigo_atividade": "582120000",
    "descricao_servico": "EDIÇÃO INTEGRADA À IMPRESSÃO DE LIVROS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.08",
    "codigo_atividade": "581230100",
    "descricao_servico": "EDIÇÃO DE JORNAIS DIÁRIOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.08",
    "codigo_atividade": "581150001",
    "descricao_servico": "EDIÇÃO DE LIVROS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.08",
    "codigo_atividade": "182290100",
    "descricao_servico": "SERVIÇOS DE ENCADERNAÇÃO E PLASTIFICAÇÃO",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.08",
    "codigo_atividade": "582390000",
    "descricao_servico": "EDIÇÃO INTEGRADA À IMPRESSÃO DE REVISTAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.08",
    "codigo_atividade": "581910000",
    "descricao_servico": "EDIÇÃO DE CADASTROS, LISTAS E OUTROS PRODUTOS GRÁFICOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.08",
    "codigo_atividade": "582980000",
    "descricao_servico": "EDIÇÃO INTEGRADA À IMPRESSÃO DE CADASTROS, LISTAS E OUTROS PRODUTOS GRÁFICOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.08",
    "codigo_atividade": "582210200",
    "descricao_servico": "EDIÇÃO INTEGRADA À IMPRESSÃO DE JORNAIS NÃO DIÁRIOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.08",
    "codigo_atividade": "581230200",
    "descricao_servico": "EDIÇÃO DE JORNAIS NÃO DIÁRIOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.08",
    "codigo_atividade": "582210100",
    "descricao_servico": "EDIÇÃO INTEGRADA À IMPRESSÃO DE JORNAIS DIÁRIOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.09",
    "codigo_atividade": "141340300",
    "descricao_servico": "FACÇÃO DE ROUPAS PROFISSIONAIS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.09",
    "codigo_atividade": "141340200",
    "descricao_servico": "CONFECÇÃO, SOB MEDIDA, DE ROUPAS PROFISSIONAIS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.09",
    "codigo_atividade": "141260300",
    "descricao_servico": "FACÇÃO DE PEÇAS DO VESTUÁRIO, EXCETO ROUPAS ÍNTIMAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.09",
    "codigo_atividade": "142150002",
    "descricao_servico": "SERVIÇO DE CONFECÇÃO DE MEIAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.09",
    "codigo_atividade": "952919902",
    "descricao_servico": "SERVIÇOS DE CONSERTO E RECUPERACAO DE ROUPAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.09",
    "codigo_atividade": "141180202",
    "descricao_servico": "SERVIÇO DE FACÇÃO DE ROUPAS INTIMAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.09",
    "codigo_atividade": "134059900",
    "descricao_servico": "OUTROS SERVIÇOS DE ACABAMENTO EM FIOS, TECIDOS, ARTEFATOS TÊXTEIS E PEÇAS DO VESTUÁRIO",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.09",
    "codigo_atividade": "141260102",
    "descricao_servico": "ATELIER DE COSTURA (EXCETO PEÇAS ÍNTIMAS)",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.09",
    "codigo_atividade": "141260200",
    "descricao_servico": "CONFECÇÃO, SOB MEDIDA, DE PEÇAS DO VESTUÁRIO, EXCETO ROUPAS ÍNTIMAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.09",
    "codigo_atividade": "135960002",
    "descricao_servico": "CONFECÇÃO DE OUTROS PRODUTOS TÊXTEIS NÃO ESPECIFICADOS ANTERIORMENTE, QUANDO NÃO INTEGRADA A TECELAG",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.09",
    "codigo_atividade": "141180102",
    "descricao_servico": "SERVIÇO DE MONTAGEM DE PEÇAS INTERIORES DO VESTUÁRIO (COSTURA)",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.09",
    "codigo_atividade": "135110002",
    "descricao_servico": "CONFECÇÃO DE ARTEFATOS DE TECIDOS PARA USO DOMÉSTICO, QUANDO NÃO INTEGRADA A TECELAGEM",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.10",
    "codigo_atividade": "960170100",
    "descricao_servico": "LAVANDERIAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.10",
    "codigo_atividade": "960170301",
    "descricao_servico": "TOALHEIROS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.10",
    "codigo_atividade": "960170200",
    "descricao_servico": "TINTURARIAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.11",
    "codigo_atividade": "452000800",
    "descricao_servico": "SERVIÇOS DE CAPOTARIA",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.11",
    "codigo_atividade": "952910502",
    "descricao_servico": "REPARAÇÃO DE ARTIGOS DE TAPEÇARIA",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.11",
    "codigo_atividade": "524019905",
    "descricao_servico": "MANUTENÇÃO DE INTERIOR DE AERONAVES (CARPETES E ESTOFADOS)",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.12",
    "codigo_atividade": "452000200",
    "descricao_servico": "SERVIÇOS DE LANTERNAGEM OU FUNILARIA E PINTURA DE VEÍCULOS AUTOMOTORES",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.13",
    "codigo_atividade": "162269902",
    "descricao_servico": "SERVIÇO DE CARPINTARIA",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.13",
    "codigo_atividade": "251280002",
    "descricao_servico": "SERRALHERIA PARA ESQUADRIAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.13",
    "codigo_atividade": "433040202",
    "descricao_servico": "EXECUÇÃO DE TRABALHOS DE CARPINTARIA EM OBRAS, RESIDÊNCIAS, LOJAS E ETC. QUANDO NÃO REALIZADA PELO P",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.13",
    "codigo_atividade": "161020102",
    "descricao_servico": "SERVIÇO DE DESDOBRAMENTO DE MADEIRA",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.13",
    "codigo_atividade": "251100002",
    "descricao_servico": "FABRICAÇÃO DE ESTRUTURAS METÁLICAS SOB ENCOMENDA",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.13",
    "codigo_atividade": "254200002",
    "descricao_servico": "SERRALHERIA (EXCETO ESQUADRIAS)",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.13",
    "codigo_atividade": "254200001",
    "descricao_servico": "FABRICAÇÃO DE ARTIGOS DE SERRALHERIA, EXCETO ESQUADRIAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "14.14",
    "codigo_atividade": "521250001",
    "descricao_servico": "serviços de guindaste e içamento intramunicipal - locação com operador",
    "aliquota": 5
  },
  {
    "codigo_servico": "15.01",
    "codigo_atividade": "647010100",
    "descricao_servico": "FUNDOS DE INVESTIMENTO, EXCETO PREVIDENCIÁRIOS E IMOBILIÁRIOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "15.01",
    "codigo_atividade": "661930500",
    "descricao_servico": "OPERADORAS DE CARTÕES DE DÉBITO",
    "aliquota": 5
  },
  {
    "codigo_servico": "15.01",
    "codigo_atividade": "642390000",
    "descricao_servico": "CAIXAS ECONÔMICAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "15.01",
    "codigo_atividade": "643280000",
    "descricao_servico": "BANCOS DE INVESTIMENTO",
    "aliquota": 5
  },
  {
    "codigo_servico": "15.01",
    "codigo_atividade": "649990400",
    "descricao_servico": "CAIXAS DE FINANCIAMENTO DE CORPORAÇÕES",
    "aliquota": 5
  },
  {
    "codigo_servico": "15.01",
    "codigo_atividade": "647010300",
    "descricao_servico": "FUNDOS DE INVESTIMENTO IMOBILIÁRIOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "15.01",
    "codigo_atividade": "643360000",
    "descricao_servico": "BANCOS DE DESENVOLVIMENTO",
    "aliquota": 5
  },
  {
    "codigo_servico": "15.01",
    "codigo_atividade": "649990200",
    "descricao_servico": "SOCIEDADES DE INVESTIMENTO",
    "aliquota": 5
  },
  {
    "codigo_servico": "15.01",
    "codigo_atividade": "641070000",
    "descricao_servico": "BANCO CENTRAL",
    "aliquota": 5
  },
  {
    "codigo_servico": "15.01",
    "codigo_atividade": "642210000",
    "descricao_servico": "BANCOS MÚLTIPLOS, COM CARTEIRA COMERCIAL",
    "aliquota": 5
  },
  {
    "codigo_servico": "15.01",
    "codigo_atividade": "661340000",
    "descricao_servico": "ADMINISTRAÇÃO DE CARTÕES DE CRÉDITO",
    "aliquota": 5
  },
  {
    "codigo_servico": "15.01",
    "codigo_atividade": "643100000",
    "descricao_servico": "BANCOS MÚLTIPLOS, SEM CARTEIRA COMERCIAL",
    "aliquota": 5
  },
  {
    "codigo_servico": "15.01",
    "codigo_atividade": "642120001",
    "descricao_servico": "BANCOS COMERCIAIS",
    "aliquota": 5
  },
  {
    "codigo_servico": "15.01",
    "codigo_atividade": "649999900",
    "descricao_servico": "OUTRAS ATIVIDADES DE SERVIÇOS FINANCEIROS NÃO ESPECIFICADAS ANTERIORMENTE",
    "aliquota": 5
  },
  {
    "codigo_servico": "15.01",
    "codigo_atividade": "649300000",
    "descricao_servico": "ADMINISTRAÇÃO DE CONSÓRCIOS PARA AQUISIÇÃO DE BENS E DIREITOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "15.01",
    "codigo_atividade": "647010200",
    "descricao_servico": "FUNDOS DE INVESTIMENTO PREVIDENCIÁRIOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "15.01",
    "codigo_atividade": "642470400",
    "descricao_servico": "COOPERATIVAS DE CRÉDITO RURAL",
    "aliquota": 5
  },
  {
    "codigo_servico": "15.01",
    "codigo_atividade": "649990500",
    "descricao_servico": "CONCESSÃO DE CRÉDITO PELAS OSCIP",
    "aliquota": 5
  },
  {
    "codigo_servico": "15.01",
    "codigo_atividade": "649990100",
    "descricao_servico": "CLUBES DE INVESTIMENTO",
    "aliquota": 5
  },
  {
    "codigo_servico": "15.01",
    "codigo_atividade": "642470200",
    "descricao_servico": "COOPERATIVAS CENTRAIS DE CRÉDITO",
    "aliquota": 5
  },
  {
    "codigo_servico": "15.01",
    "codigo_atividade": "642470300",
    "descricao_servico": "COOPERATIVAS DE CRÉDITO MÚTUO",
    "aliquota": 5
  },
  {
    "codigo_servico": "15.01",
    "codigo_atividade": "642470100",
    "descricao_servico": "BANCOS COOPERATIVOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "15.01",
    "codigo_atividade": "649990300",
    "descricao_servico": "FUNDO GARANTIDOR DE CRÉDITO",
    "aliquota": 5
  },
  {
    "codigo_servico": "15.01",
    "codigo_atividade": "649210000",
    "descricao_servico": "SECURITIZAÇÃO DE CRÉDITOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "15.01",
    "codigo_atividade": "645060000",
    "descricao_servico": "SOCIEDADES DE CAPITALIZAÇÃO",
    "aliquota": 5
  },
  {
    "codigo_servico": "15.02",
    "codigo_atividade": "642120002",
    "descricao_servico": "ABERTURA DE CONTAS EM GERAL",
    "aliquota": 5
  },
  {
    "codigo_servico": "15.03",
    "codigo_atividade": "661939902",
    "descricao_servico": "LOCAÇÃO DE TERMINAL ELETRÔNICO PARA BANCO",
    "aliquota": 5
  },
  {
    "codigo_servico": "15.04",
    "codigo_atividade": "642120003",
    "descricao_servico": "FORNECIMENTO OU EMISSÃO DE ATESTADOS EM GERAL, INCLUSIVE ATESTADO DE IDONEIDADE, ATESTADO DE CAPACID",
    "aliquota": 5
  },
  {
    "codigo_servico": "15.05",
    "codigo_atividade": "642120004",
    "descricao_servico": "CADASTRO, ELABORAÇÃO DE FICHA CADASTRAL, RENOVAÇÃO CADASTRAL E CONGÊNERES, INCLUSÃO OU EXCLUSÃO NO C",
    "aliquota": 5
  },
  {
    "codigo_servico": "15.06",
    "codigo_atividade": "642120005",
    "descricao_servico": "EMISSÃO, REEMISSÃO E FORNECIMENTO DE AVISOS, COMPROVANTES E DOCUMENTOS EM GERAL",
    "aliquota": 5
  },
  {
    "codigo_servico": "15.07",
    "codigo_atividade": "829979913",
    "descricao_servico": "POSTO, AGÊNCIA EM ESTABELECIMENTOS COMERCIAIS PARA PAGAMENTO DE CONTAS DE LUZ, GÁS, ETC.",
    "aliquota": 5
  },
  {
    "codigo_servico": "15.07",
    "codigo_atividade": "661930400",
    "descricao_servico": "CAIXAS ELETRÔNICOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "15.08",
    "codigo_atividade": "643610000",
    "descricao_servico": "SOCIEDADES DE CRÉDITO, FINANCIAMENTO E INVESTIMENTO - FINANCEIRAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "15.08",
    "codigo_atividade": "643520100",
    "descricao_servico": "SOCIEDADES DE CRÉDITO IMOBILIÁRIO",
    "aliquota": 5
  },
  {
    "codigo_servico": "15.09",
    "codigo_atividade": "644090000",
    "descricao_servico": "ARRENDAMENTO MERCANTIL",
    "aliquota": 5
  },
  {
    "codigo_servico": "15.10",
    "codigo_atividade": "643790000",
    "descricao_servico": "SOCIEDADES DE CRÉDITO AO MICROEMPREENDEDOR",
    "aliquota": 5
  },
  {
    "codigo_servico": "15.10",
    "codigo_atividade": "643879900",
    "descricao_servico": "OUTRAS INSTITUIÇÕES DE INTERMEDIAÇÃO NÃO-MONETÁRIA NÃO ESPECIFICADAS ANTERIORMENTE",
    "aliquota": 5
  },
  {
    "codigo_servico": "15.10",
    "codigo_atividade": "661930200",
    "descricao_servico": "CORRESPONDENTES DE INSTITUIÇÕES FINANCEIRAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "15.10",
    "codigo_atividade": "643870100",
    "descricao_servico": "BANCOS DE CÂMBIO",
    "aliquota": 5
  },
  {
    "codigo_servico": "15.11",
    "codigo_atividade": "642120006",
    "descricao_servico": "DEVOLUÇÃO DE TÍTULOS, PROTESTO DE TÍTULOS, SUSTAÇÃO DE PROTESTO, MANUTENÇÃO DE TÍTULOS, REAPRESENTAÇ",
    "aliquota": 5
  },
  {
    "codigo_servico": "15.12",
    "codigo_atividade": "661930100",
    "descricao_servico": "SERVIÇOS DE LIQUIDAÇÃO E CUSTÓDIA",
    "aliquota": 5
  },
  {
    "codigo_servico": "15.13",
    "codigo_atividade": "642120007",
    "descricao_servico": "SERVIÇOS RELACIONADOS A OPERAÇÕES DE CÂMBIO EM GERAL",
    "aliquota": 5
  },
  {
    "codigo_servico": "15.14",
    "codigo_atividade": "642120008",
    "descricao_servico": "FORNECIMENTO, EMISSÃO, REEMISSÃO, RENOVAÇÃO E MANUTENÇÃO DE CARTÕES E CONGÊNERES",
    "aliquota": 5
  },
  {
    "codigo_servico": "15.15",
    "codigo_atividade": "642120009",
    "descricao_servico": "COMPENSAÇÃO DE CHEQUES E TÍTULOS QUAISQUER",
    "aliquota": 5
  },
  {
    "codigo_servico": "15.16",
    "codigo_atividade": "642120010",
    "descricao_servico": "EMISSÃO, REEMISSÃO, LIQUIDAÇÃO, ALTERAÇÃO, CANCELAMENTO E BAIXA DE ORDENS DE PAGAMENTO E SIMILARES",
    "aliquota": 5
  },
  {
    "codigo_servico": "15.17",
    "codigo_atividade": "642120011",
    "descricao_servico": "EMISSÃO, FORNECIMENTO, DEVOLUÇÃO, SUSTAÇÃO, CANCELAMENTO E OPOSIÇÃO DE CHEQUES QUAISQUER",
    "aliquota": 5
  },
  {
    "codigo_servico": "15.18",
    "codigo_atividade": "643520300",
    "descricao_servico": "COMPANHIAS HIPOTECÁRIAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "15.18",
    "codigo_atividade": "643520200",
    "descricao_servico": "ASSOCIAÇÕES DE POUPANÇA E EMPRÉSTIMO",
    "aliquota": 5
  },
  {
    "codigo_servico": "16.01",
    "codigo_atividade": "492130101",
    "descricao_servico": "TRANSPORTE RODOVIÁRIO COLETIVO DE PASSAGEIROS, COM ITINERÁRIO FIXO, MUNICIPAL (INCENTIVO)",
    "aliquota": 2
  },
  {
    "codigo_servico": "16.01",
    "codigo_atividade": "495070001",
    "descricao_servico": "TRENS TURÍSTICOS, TELEFÉRICOS E SIMILARES (INCENTIVO)",
    "aliquota": 2
  },
  {
    "codigo_servico": "16.01",
    "codigo_atividade": "491240200",
    "descricao_servico": "TRANSPORTE FERROVIÁRIO DE PASSAGEIROS MUNICIPAL E EM REGIÃO METROPOLITANA",
    "aliquota": 2
  },
  {
    "codigo_servico": "16.01",
    "codigo_atividade": "495070000",
    "descricao_servico": "TRENS TURÍSTICOS, TELEFÉRICOS E SIMILARES",
    "aliquota": 2
  },
  {
    "codigo_servico": "16.01",
    "codigo_atividade": "492130100",
    "descricao_servico": "TRANSPORTE RODOVIÁRIO COLETIVO DE PASSAGEIROS, COM ITINERÁRIO FIXO, MUNICIPAL",
    "aliquota": 2
  },
  {
    "codigo_servico": "16.01",
    "codigo_atividade": "491240301",
    "descricao_servico": "TRANSPORTE METROVIÁRIO (INCENTIVO)",
    "aliquota": 2
  },
  {
    "codigo_servico": "16.01",
    "codigo_atividade": "491240201",
    "descricao_servico": "TRANSPORTE FERROVIÁRIO DE PASSAGEIROS MUNICIPAL E EM REGIÃO METROPOLITANA (INCENTIVO)",
    "aliquota": 2
  },
  {
    "codigo_servico": "16.01",
    "codigo_atividade": "491240300",
    "descricao_servico": "TRANSPORTE METROVIÁRIO",
    "aliquota": 2
  },
  {
    "codigo_servico": "16.02",
    "codigo_atividade": "493020301",
    "descricao_servico": "transporte rodoviário municipal de produtos perigosos",
    "aliquota": 5
  },
  {
    "codigo_servico": "16.02",
    "codigo_atividade": "492300200",
    "descricao_servico": "SERVIÇO DE TRANSPORTE DE PASSAGEIROS - LOCAÇÃO DE AUTOMÓVEIS COM MOTORISTA",
    "aliquota": 5
  },
  {
    "codigo_servico": "16.02",
    "codigo_atividade": "023060002",
    "descricao_servico": "SERVIÇO DE ABATE, DERRUBADA DE ARVORES E TRANSPORTE DE TORAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "16.02",
    "codigo_atividade": "492300100",
    "descricao_servico": "SERVIÇO DE TÁXI",
    "aliquota": 5
  },
  {
    "codigo_servico": "16.02",
    "codigo_atividade": "492480000",
    "descricao_servico": "TRANSPORTE ESCOLAR",
    "aliquota": 5
  },
  {
    "codigo_servico": "16.02",
    "codigo_atividade": "523979902",
    "descricao_servico": "SERVIÇOS DE TRANSLADO DE PASSAGEIROS NO INTERIOR DAS INSTALAÇÕES PORTUÁRIAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "16.02",
    "codigo_atividade": "522909900",
    "descricao_servico": "Outras atividades auxiliares dos transportes terrestres não especificadas anteriormente na lista de",
    "aliquota": 5
  },
  {
    "codigo_servico": "16.02",
    "codigo_atividade": "492300103",
    "descricao_servico": "serviço de táxi executivo",
    "aliquota": 5
  },
  {
    "codigo_servico": "16.02",
    "codigo_atividade": "512000001",
    "descricao_servico": "transporte aéreo de carga municipal",
    "aliquota": 5
  },
  {
    "codigo_servico": "16.02",
    "codigo_atividade": "493020401",
    "descricao_servico": "transporte rodoviário municipal de mudanças",
    "aliquota": 5
  },
  {
    "codigo_servico": "16.02",
    "codigo_atividade": "509120102",
    "descricao_servico": "transporte de cargas por navegação de travessia, municipal",
    "aliquota": 5
  },
  {
    "codigo_servico": "16.02",
    "codigo_atividade": "493020201",
    "descricao_servico": "LOCAÇÃO DE VEÍCULOS DE CARGA COM MOTORISTA",
    "aliquota": 5
  },
  {
    "codigo_servico": "16.02",
    "codigo_atividade": "522900200",
    "descricao_servico": "SERVIÇOS DE REBOQUE DE VEÍCULOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "16.02",
    "codigo_atividade": "932989905",
    "descricao_servico": "TRANSPORTE DE VEÍCULOS DE TRAÇÃO ANIMAL",
    "aliquota": 5
  },
  {
    "codigo_servico": "16.02",
    "codigo_atividade": "360060200",
    "descricao_servico": "DISTRIBUIÇÃO DE ÁGUA POR CAMINHÕES",
    "aliquota": 5
  },
  {
    "codigo_servico": "16.02",
    "codigo_atividade": "509989900",
    "descricao_servico": "outros transportes aquaviários não especificados anteriormente",
    "aliquota": 5
  },
  {
    "codigo_servico": "16.02",
    "codigo_atividade": "932989906",
    "descricao_servico": "exploração de trenzinho para passeios turísticos",
    "aliquota": 5
  },
  {
    "codigo_servico": "16.02",
    "codigo_atividade": "511299901",
    "descricao_servico": "outros serviços de transporte aéreo de passageiros não - regular municipal",
    "aliquota": 5
  },
  {
    "codigo_servico": "16.02",
    "codigo_atividade": "522900100",
    "descricao_servico": "SERVIÇOS DE APOIO AO TRANSPORTE POR TÁXI, INCLUSIVE CENTRAIS DE CHAMADA",
    "aliquota": 5
  },
  {
    "codigo_servico": "16.02",
    "codigo_atividade": "493020100",
    "descricao_servico": "TRANSPORTE RODOVIÁRIO DE CARGA, EXCETO PRODUTOS PERIGOSOS E MUDANÇAS, MUNICIPAL",
    "aliquota": 5
  },
  {
    "codigo_servico": "16.02",
    "codigo_atividade": "492300102",
    "descricao_servico": "serviço de moto táxi",
    "aliquota": 5
  },
  {
    "codigo_servico": "16.02",
    "codigo_atividade": "492990100",
    "descricao_servico": "TRANSPORTE RODOVIÁRIO COLETIVO DE PASSAGEIROS, SOB REGIME DE FRETAMENTO, MUNICIPAL",
    "aliquota": 5
  },
  {
    "codigo_servico": "16.02",
    "codigo_atividade": "522909907",
    "descricao_servico": "SERVIÇOS DE TRANSLADO DE PASSAGEIROS NO TRANSPORTE TERRESTRE",
    "aliquota": 5
  },
  {
    "codigo_servico": "16.02",
    "codigo_atividade": "492999902",
    "descricao_servico": "TRANSPORTE RODOVIÁRIO DE CARGAS EM GERAL, MUNICIPAL",
    "aliquota": 5
  },
  {
    "codigo_servico": "16.02",
    "codigo_atividade": "511290101",
    "descricao_servico": "serviço de táxi aéreo e locação de aeronaves com tripulação municipal",
    "aliquota": 5
  },
  {
    "codigo_servico": "17.01",
    "codigo_atividade": "829979916",
    "descricao_servico": "OUTRAS ATIVIDADES DE SERVIÇOS PRESTADOS PRINCIPALMENTE AS EMPRESAS NÃO ESPECIFICADAS ANTERIORMENTE",
    "aliquota": 5
  },
  {
    "codigo_servico": "17.01",
    "codigo_atividade": "702040001",
    "descricao_servico": "ATIVIDADES DE CONSULTORIA EM GESTÃO EMPRESARIAL, EXCETO CONSULTORIA TÉCNICA ESPECÍFICA",
    "aliquota": 5
  },
  {
    "codigo_servico": "17.01",
    "codigo_atividade": "712010001",
    "descricao_servico": "TESTES E ANÁLISES TÉCNICAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "17.01",
    "codigo_atividade": "749019901",
    "descricao_servico": "OUTRAS ATIVIDADES PROFISSIONAIS, CIENTÍFICAS E TÉCNICAS NÃO ESPECIFICADAS ANTERIORMENTE",
    "aliquota": 5
  },
  {
    "codigo_servico": "17.01",
    "codigo_atividade": "639920000",
    "descricao_servico": "OUTRAS ATIVIDADES DE PRESTAÇÃO DE SERVIÇOS DE INFORMAÇÃO NÃO ESPECIFICADAS ANTERIORMENTE",
    "aliquota": 5
  },
  {
    "codigo_servico": "17.02",
    "codigo_atividade": "822020000",
    "descricao_servico": "ATIVIDADES DE TELEATENDIMENTO",
    "aliquota": 5
  },
  {
    "codigo_servico": "17.02",
    "codigo_atividade": "749010100",
    "descricao_servico": "SERVIÇOS DE TRADUÇÃO, INTERPRETAÇÃO E SIMILARES",
    "aliquota": 5
  },
  {
    "codigo_servico": "17.02",
    "codigo_atividade": "821130000",
    "descricao_servico": "SERVIÇOS COMBINADOS DE ESCRITÓRIO E APOIO ADMINISTRATIVO",
    "aliquota": 5
  },
  {
    "codigo_servico": "17.02",
    "codigo_atividade": "960929905",
    "descricao_servico": "SERVIÇOS DE MENSAGENS FONADAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "17.02",
    "codigo_atividade": "821999900",
    "descricao_servico": "PREPARAÇÃO DE DOCUMENTOS E SERVIÇOS ESPECIALIZADOS DE APOIO ADMINISTRATIVO NÃO ESPECIFICADOS ANTERIO",
    "aliquota": 5
  },
  {
    "codigo_servico": "17.02",
    "codigo_atividade": "900270102",
    "descricao_servico": "ATIVIDADES LITERÁRIAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "17.02",
    "codigo_atividade": "910150002",
    "descricao_servico": "ATIVIDADE DE ARQUIVO",
    "aliquota": 5
  },
  {
    "codigo_servico": "17.03",
    "codigo_atividade": "829979903",
    "descricao_servico": "SERVIÇO DE ALMOXARIFADO",
    "aliquota": 5
  },
  {
    "codigo_servico": "17.03",
    "codigo_atividade": "829979905",
    "descricao_servico": "SERVIÇO DE PREVENÇÃO DE INCÊNDIO POR EMPRESA PRIVADA",
    "aliquota": 5
  },
  {
    "codigo_servico": "17.03",
    "codigo_atividade": "525080500",
    "descricao_servico": "OPERADOR DE TRANSPORTE MULTIMODAL - OTM",
    "aliquota": 5
  },
  {
    "codigo_servico": "17.03",
    "codigo_atividade": "525080400",
    "descricao_servico": "ORGANIZAÇÃO LOGÍSTICA DO TRANSPORTE DE CARGA",
    "aliquota": 5
  },
  {
    "codigo_servico": "17.04",
    "codigo_atividade": "781080000",
    "descricao_servico": "SELEÇÃO E AGENCIAMENTO DE MÃO-DE-OBRA",
    "aliquota": 5
  },
  {
    "codigo_servico": "17.04",
    "codigo_atividade": "783020000",
    "descricao_servico": "FORNECIMENTO E GESTÃO DE RECURSOS HUMANOS PARA TERCEIROS",
    "aliquota": 5
  },
  {
    "codigo_servico": "17.04",
    "codigo_atividade": "782050000",
    "descricao_servico": "LOCAÇÃO DE MÃO-DE-OBRA TEMPORÁRIA",
    "aliquota": 5
  },
  {
    "codigo_servico": "17.05",
    "codigo_atividade": "016289904",
    "descricao_servico": "EMPREGADORES DE MÃO-DE-OBRA PARA A PECUÁRIA",
    "aliquota": 5
  },
  {
    "codigo_servico": "17.05",
    "codigo_atividade": "900190602",
    "descricao_servico": "ATIVIDADE DE EQUIPAMENTO DE SOM E LUZ COM OPERADOR",
    "aliquota": 5
  },
  {
    "codigo_servico": "17.05",
    "codigo_atividade": "970050002",
    "descricao_servico": "SERVIÇOS DE BABÁ",
    "aliquota": 5
  },
  {
    "codigo_servico": "17.05",
    "codigo_atividade": "101210400",
    "descricao_servico": "MATADOURO - ABATE DE SUÍNOS SOB CONTRATO",
    "aliquota": 5
  },
  {
    "codigo_servico": "17.05",
    "codigo_atividade": "016109902",
    "descricao_servico": "LOCAÇÃO DE MÁQUINAS AGRÍCOLAS COM OPERADOR E SERVIÇOS DE CONTRATANTES DE MÃO-DE-OBRA PARA A AGRICULT",
    "aliquota": 5
  },
  {
    "codigo_servico": "17.05",
    "codigo_atividade": "811170002",
    "descricao_servico": "SERVIÇO DE RECEPÇÃO EM PRÉDIOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "17.05",
    "codigo_atividade": "439160002",
    "descricao_servico": "LOCAÇÃO DE BATE-ESTACAS E EQUIPAMENTOS DE PERFURAÇÃO COM OPERADOR",
    "aliquota": 5
  },
  {
    "codigo_servico": "17.05",
    "codigo_atividade": "829970100",
    "descricao_servico": "MEDIÇÃO DE CONSUMO DE ENERGIA ELÉTRICA, GÁS E ÁGUA",
    "aliquota": 5
  },
  {
    "codigo_servico": "17.05",
    "codigo_atividade": "101120500",
    "descricao_servico": "MATADOURO - ABATE DE RESES SOB CONTRATO, EXCETO ABATE DE SUÍNOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "17.05",
    "codigo_atividade": "960929902",
    "descricao_servico": "SERVIÇOS DE BABY SITER",
    "aliquota": 5
  },
  {
    "codigo_servico": "17.05",
    "codigo_atividade": "439910400",
    "descricao_servico": "SERVIÇOS DE OPERAÇÃO E FORNECIMENTO DE EQUIPAMENTOS PARA TRANSPORTE E ELEVAÇÃO DE CARGAS E PESSOAS P",
    "aliquota": 5
  },
  {
    "codigo_servico": "17.05",
    "codigo_atividade": "829979907",
    "descricao_servico": "SERVIÇO DE COLETA DE BOTIJÃO DE GÁS",
    "aliquota": 5
  },
  {
    "codigo_servico": "17.05",
    "codigo_atividade": "970050001",
    "descricao_servico": "SERVIÇOS DOMÉSTICOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "17.06",
    "codigo_atividade": "731140000",
    "descricao_servico": "AGÊNCIAS DE PUBLICIDADE",
    "aliquota": 5
  },
  {
    "codigo_servico": "17.06",
    "codigo_atividade": "731909900",
    "descricao_servico": "OUTRAS ATIVIDADES DE PUBLICIDADE NÃO ESPECIFICADAS ANTERIORMENTE",
    "aliquota": 5
  },
  {
    "codigo_servico": "17.06",
    "codigo_atividade": "731900100",
    "descricao_servico": "CRIAÇÃO DE ESTANDES PARA FEIRAS E EXPOSIÇÕES",
    "aliquota": 5
  },
  {
    "codigo_servico": "17.06",
    "codigo_atividade": "731220000",
    "descricao_servico": "AGENCIAMENTO DE ESPAÇOS PARA PUBLICIDADE, EXCETO EM VEÍCULOS DE COMUNICAÇÃO",
    "aliquota": 5
  },
  {
    "codigo_servico": "17.06",
    "codigo_atividade": "731900400",
    "descricao_servico": "CONSULTORIA EM PUBLICIDADE",
    "aliquota": 5
  },
  {
    "codigo_servico": "17.06",
    "codigo_atividade": "731900200",
    "descricao_servico": "PROMOÇÃO DE VENDAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "17.06",
    "codigo_atividade": "731900300",
    "descricao_servico": "MARKETING DIRETO",
    "aliquota": 5
  },
  {
    "codigo_servico": "17.06",
    "codigo_atividade": "829979906",
    "descricao_servico": "SERVIÇO DE CARTAZISTA",
    "aliquota": 5
  },
  {
    "codigo_servico": "17.06",
    "codigo_atividade": "631940004",
    "descricao_servico": "PÁGINA DE PUBLICIDADE NA INTERNET",
    "aliquota": 5
  },
  {
    "codigo_servico": "17.08",
    "codigo_atividade": "774030000",
    "descricao_servico": "GESTÃO DE ATIVOS INTANGÍVEIS NÃO-FINANCEIROS",
    "aliquota": 5
  },
  {
    "codigo_servico": "17.09",
    "codigo_atividade": "711970400",
    "descricao_servico": "SERVIÇOS DE PERÍCIA TÉCNICA RELACIONADOS À SEGURANÇA DO TRABALHO",
    "aliquota": 5
  },
  {
    "codigo_servico": "17.10",
    "codigo_atividade": "823000101",
    "descricao_servico": "SERVIÇOS DE ORGANIZAÇÃO DE FEIRAS, CONGRESSOS, EXPOSIÇÕES E FESTAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "17.10",
    "codigo_atividade": "823000200",
    "descricao_servico": "CASAS DE FESTAS E EVENTOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "17.11",
    "codigo_atividade": "562010200",
    "descricao_servico": "SERVIÇOS DE ALIMENTAÇÃO PARA EVENTOS E RECEPÇÕES - BUFÊ",
    "aliquota": 5
  },
  {
    "codigo_servico": "17.11",
    "codigo_atividade": "562010300",
    "descricao_servico": "CANTINAS - SERVIÇOS DE ALIMENTAÇÃO PRIVATIVOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "17.12",
    "codigo_atividade": "829970200",
    "descricao_servico": "EMISSÃO DE VALES-ALIMENTAÇÃO, VALES-TRANSPORTE E SIMILARES",
    "aliquota": 5
  },
  {
    "codigo_servico": "17.12",
    "codigo_atividade": "829970702",
    "descricao_servico": "POSTO TELEFÔNICO",
    "aliquota": 5
  },
  {
    "codigo_servico": "17.12",
    "codigo_atividade": "829970500",
    "descricao_servico": "SERVIÇOS DE LEVANTAMENTO DE FUNDOS SOB CONTRATO",
    "aliquota": 5
  },
  {
    "codigo_servico": "17.12",
    "codigo_atividade": "829979902",
    "descricao_servico": "SERVIÇOS DE ADMINISTRAÇÃO EM GERAL",
    "aliquota": 5
  },
  {
    "codigo_servico": "17.12",
    "codigo_atividade": "855030100",
    "descricao_servico": "ADMINISTRAÇÃO DE CAIXAS ESCOLARES",
    "aliquota": 5
  },
  {
    "codigo_servico": "17.13",
    "codigo_atividade": "829970400",
    "descricao_servico": "LEILOEIROS INDEPENDENTES",
    "aliquota": 5
  },
  {
    "codigo_servico": "17.14",
    "codigo_atividade": "702040002",
    "descricao_servico": "CONSULTORIA EM NEGOCIAÇÃO TRABALHISTA",
    "aliquota": 5
  },
  {
    "codigo_servico": "17.14",
    "codigo_atividade": "691170100",
    "descricao_servico": "SERVIÇOS ADVOCATÍCIOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "17.15",
    "codigo_atividade": "691170200",
    "descricao_servico": "ATIVIDADES AUXILIARES DA JUSTIÇA",
    "aliquota": 5
  },
  {
    "codigo_servico": "17.16",
    "codigo_atividade": "662150202",
    "descricao_servico": "AUDITORIA ATUARIAL",
    "aliquota": 5
  },
  {
    "codigo_servico": "17.17",
    "codigo_atividade": "620400002",
    "descricao_servico": "ANÁLISE DE NECESSIDADES DO CLIENTE OU DO MERCADO POTENCIAL E A ESPECIFICAÇÃO TÉCNICA DE SISTEMAS, FU",
    "aliquota": 5
  },
  {
    "codigo_servico": "17.18",
    "codigo_atividade": "662150201",
    "descricao_servico": "AUDITORIA E CONSULTORIA ATUARIAL",
    "aliquota": 5
  },
  {
    "codigo_servico": "17.19",
    "codigo_atividade": "692060100",
    "descricao_servico": "ATIVIDADES DE CONTABILIDADE",
    "aliquota": 5
  },
  {
    "codigo_servico": "17.19",
    "codigo_atividade": "692060200",
    "descricao_servico": "ATIVIDADES DE CONSULTORIA E AUDITORIA CONTÁBIL E TRIBUTÁRIA",
    "aliquota": 5
  },
  {
    "codigo_servico": "17.20",
    "codigo_atividade": "749010300",
    "descricao_servico": "SERVIÇOS DE AGRONOMIA E DE CONSULTORIA ÀS ATIVIDADES AGRÍCOLAS E PECUÁRIAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "17.20",
    "codigo_atividade": "702040003",
    "descricao_servico": "CONSULTORIA FINANCEIRA A EMPRESAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "17.21",
    "codigo_atividade": "749019902",
    "descricao_servico": "CONSULTORIA NA ÁREA DE ESTATÍSTICA",
    "aliquota": 5
  },
  {
    "codigo_servico": "17.22",
    "codigo_atividade": "829110000",
    "descricao_servico": "ATIVIDADES DE COBRANÇA E INFORMAÇÕES CADASTRAIS",
    "aliquota": 5
  },
  {
    "codigo_servico": "17.23",
    "codigo_atividade": "829979904",
    "descricao_servico": "SERVIÇO DE AVALIADOR, EXCETO DE SEGUROS E DE IMÓVEIS",
    "aliquota": 5
  },
  {
    "codigo_servico": "17.24",
    "codigo_atividade": "823000102",
    "descricao_servico": "SERVIÇOS DE APRESENTAÇÃO DE PALESTRAS, CONFERÊNCIAS, SEMINÁRIOS E CONGÊNERES",
    "aliquota": 5
  },
  {
    "codigo_servico": "17.25",
    "codigo_atividade": "329900400",
    "descricao_servico": "FABRICAÇÃO DE PAINÉIS E LETREIROS LUMINOSOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "17.25",
    "codigo_atividade": "731140003",
    "descricao_servico": "colocação de material publicitário, em nome do cliente, em qualquer meio, exceto em livros, jornais,",
    "aliquota": 5
  },
  {
    "codigo_servico": "17.25",
    "codigo_atividade": "329900300",
    "descricao_servico": "FABRICAÇÃO DE LETRAS, LETREIROS E PLACAS DE QUALQUER MATERIAL, EXCETO LUMINOSOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "18.01",
    "codigo_atividade": "829979915",
    "descricao_servico": "SERVIÇOS DE VISTORIA DE AUTOMÓVEIS",
    "aliquota": 5
  },
  {
    "codigo_servico": "19.01",
    "codigo_atividade": "920030100",
    "descricao_servico": "CASAS DE BINGO",
    "aliquota": 5
  },
  {
    "codigo_servico": "19.01",
    "codigo_atividade": "920039900",
    "descricao_servico": "EXPLORAÇÃO DE JOGOS DE AZAR E APOSTAS NÃO ESPECIFICADOS ANTERIORMENTE",
    "aliquota": 5
  },
  {
    "codigo_servico": "19.01",
    "codigo_atividade": "829970600",
    "descricao_servico": "CASAS LOTÉRICAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "2.01",
    "codigo_atividade": "712010002",
    "descricao_servico": "ATIVIDADES DE PESQUISA E DESENVOLVIMENTO EXPERIMENTAL EM CIÊNCIAS FÍSICAS E NATURAIS",
    "aliquota": 5
  },
  {
    "codigo_servico": "2.01",
    "codigo_atividade": "721000000",
    "descricao_servico": "PESQUISA E DESENVOLVIMENTO EXPERIMENTAL EM CIÊNCIAS FÍSICAS E NATURAIS",
    "aliquota": 5
  },
  {
    "codigo_servico": "2.01",
    "codigo_atividade": "722070000",
    "descricao_servico": "PESQUISA E DESENVOLVIMENTO EXPERIMENTAL EM CIÊNCIAS SOCIAIS E HUMANAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "2.01",
    "codigo_atividade": "732030000",
    "descricao_servico": "PESQUISAS DE MERCADO E DE OPINIÃO PÚBLICA",
    "aliquota": 5
  },
  {
    "codigo_servico": "20.01",
    "codigo_atividade": "523110200",
    "descricao_servico": "ATIVIDADES DO OPERADOR PORTUÁRIO",
    "aliquota": 5
  },
  {
    "codigo_servico": "20.01",
    "codigo_atividade": "503010100",
    "descricao_servico": "NAVEGAÇÃO DE APOIO MARÍTIMO",
    "aliquota": 5
  },
  {
    "codigo_servico": "20.01",
    "codigo_atividade": "501140100",
    "descricao_servico": "TRANSPORTE MARÍTIMO DE CABOTAGEM - CARGA",
    "aliquota": 5
  },
  {
    "codigo_servico": "20.01",
    "codigo_atividade": "523979999",
    "descricao_servico": "ATIVIDADES AUXILIARES DOS TRANSPORTES AQUAVIÁRIOS NÃO ESPECIFICADAS ANTERIORMENTE",
    "aliquota": 5
  },
  {
    "codigo_servico": "20.01",
    "codigo_atividade": "501140200",
    "descricao_servico": "TRANSPORTE MARÍTIMO DE CABOTAGEM - PASSAGEIROS",
    "aliquota": 5
  },
  {
    "codigo_servico": "20.01",
    "codigo_atividade": "502200100",
    "descricao_servico": "TRANSPORTE POR NAVEGAÇÃO INTERIOR DE PASSAGEIROS EM LINHAS REGULARES, MUNICIPAL, EXCETO TRAVESSIA",
    "aliquota": 5
  },
  {
    "codigo_servico": "20.01",
    "codigo_atividade": "502110100",
    "descricao_servico": "TRANSPORTE POR NAVEGAÇÃO INTERIOR DE CARGA, MUNICIPAL, EXCETO TRAVESSIA",
    "aliquota": 5
  },
  {
    "codigo_servico": "20.01",
    "codigo_atividade": "523110100",
    "descricao_servico": "ADMINISTRAÇÃO DA INFRA-ESTRUTURA PORTUÁRIA",
    "aliquota": 5
  },
  {
    "codigo_servico": "20.01",
    "codigo_atividade": "503010300",
    "descricao_servico": "SERVIÇO DE REBOCADORES E EMPURRADORES",
    "aliquota": 5
  },
  {
    "codigo_servico": "20.01",
    "codigo_atividade": "509980100",
    "descricao_servico": "TRANSPORTE AQUAVIÁRIO PARA PASSEIOS TURÍSTICOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "20.01",
    "codigo_atividade": "932989904",
    "descricao_servico": "SERVIÇOS DE ESTADIA DE BARCOS, LANCHAS, IATES E JET SKI",
    "aliquota": 5
  },
  {
    "codigo_servico": "20.01",
    "codigo_atividade": "523110300",
    "descricao_servico": "GESTÃO DE TERMINAIS AQUAVIÁRIOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "20.01",
    "codigo_atividade": "503010200",
    "descricao_servico": "NAVEGAÇÃO DE APOIO PORTUÁRIO",
    "aliquota": 5
  },
  {
    "codigo_servico": "20.01",
    "codigo_atividade": "509120100",
    "descricao_servico": "TRANSPORTE POR NAVEGAÇÃO DE TRAVESSIA, MUNICIPAL",
    "aliquota": 5
  },
  {
    "codigo_servico": "20.01",
    "codigo_atividade": "523970100",
    "descricao_servico": "SERVIÇOS DE PRATICAGEM",
    "aliquota": 5
  },
  {
    "codigo_servico": "20.01",
    "codigo_atividade": "509120200",
    "descricao_servico": "TRANSPORTE POR NAVEGAÇÃO DE TRAVESSIA INTERMUNICIPAL, INTERESTADUAL E INTERNACIONAL",
    "aliquota": 5
  },
  {
    "codigo_servico": "20.02",
    "codigo_atividade": "524019901",
    "descricao_servico": "ATIVIDADES AUXILIARES DOS TRANSPORTES AÉREOS, EXCETO OPERAÇÃO DOS AEROPORTOS E CAMPOS DE ATERRISSAGE",
    "aliquota": 5
  },
  {
    "codigo_servico": "20.02",
    "codigo_atividade": "512000000",
    "descricao_servico": "TRANSPORTE AÉREO DE CARGA",
    "aliquota": 5
  },
  {
    "codigo_servico": "20.02",
    "codigo_atividade": "511110000",
    "descricao_servico": "TRANSPORTE AÉREO DE PASSAGEIROS REGULAR",
    "aliquota": 5
  },
  {
    "codigo_servico": "20.02",
    "codigo_atividade": "511299900",
    "descricao_servico": "OUTROS SERVIÇOS DE TRANSPORTE AÉREO DE PASSAGEIROS NÃO-REGULAR",
    "aliquota": 5
  },
  {
    "codigo_servico": "20.02",
    "codigo_atividade": "524010100",
    "descricao_servico": "OPERAÇÃO DOS AEROPORTOS E CAMPOS DE ATERRISSAGEM",
    "aliquota": 5
  },
  {
    "codigo_servico": "20.03",
    "codigo_atividade": "522909903",
    "descricao_servico": "SERVIÇOS DE GERENCIAMENTO DE TRÂNSITO,TRAFEGO; SERVIÇOS DE PESAGEM DE VEÍCULOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "20.03",
    "codigo_atividade": "522220000",
    "descricao_servico": "TERMINAIS RODOVIÁRIOS E FERROVIÁRIOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "21.01",
    "codigo_atividade": "691250000",
    "descricao_servico": "CARTÓRIOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "22.01",
    "codigo_atividade": "522140000",
    "descricao_servico": "CONCESSIONÁRIAS DE RODOVIAS, PONTES, TÚNEIS E SERVIÇOS RELACIONADOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "23.01",
    "codigo_atividade": "741020301",
    "descricao_servico": "DESIGN DE PRODUTO",
    "aliquota": 5
  },
  {
    "codigo_servico": "23.01",
    "codigo_atividade": "829979908",
    "descricao_servico": "SERVIÇOS DE COMPUTAÇÃO GRÁFICA",
    "aliquota": 5
  },
  {
    "codigo_servico": "23.01",
    "codigo_atividade": "741020303",
    "descricao_servico": "DESIGN DE MODA",
    "aliquota": 5
  },
  {
    "codigo_servico": "23.01",
    "codigo_atividade": "741020201",
    "descricao_servico": "DESIGN DE INTERIORES",
    "aliquota": 5
  },
  {
    "codigo_servico": "23.01",
    "codigo_atividade": "741020302",
    "descricao_servico": "SERVIÇOS DE DESENHO INDUSTRIAL",
    "aliquota": 5
  },
  {
    "codigo_servico": "23.01",
    "codigo_atividade": "741029900",
    "descricao_servico": "ATIVIDADES DE DESIGN NÃO ESPECIFICADAS ANTERIORMENTE",
    "aliquota": 5
  },
  {
    "codigo_servico": "24.01",
    "codigo_atividade": "952910200",
    "descricao_servico": "CHAVEIROS",
    "aliquota": 5
  },
  {
    "codigo_servico": "24.01",
    "codigo_atividade": "829970300",
    "descricao_servico": "SERVIÇOS DE GRAVAÇÃO DE CARIMBOS, EXCETO CONFECÇÃO",
    "aliquota": 5
  },
  {
    "codigo_servico": "25.01",
    "codigo_atividade": "960330300",
    "descricao_servico": "SERVIÇOS DE SEPULTAMENTO",
    "aliquota": 5
  },
  {
    "codigo_servico": "25.01",
    "codigo_atividade": "960330500",
    "descricao_servico": "SERVIÇOS DE SOMATOCONSERVAÇÃO",
    "aliquota": 5
  },
  {
    "codigo_servico": "25.01",
    "codigo_atividade": "960339900",
    "descricao_servico": "ATIVIDADES FUNERÁRIAS E SERVIÇOS RELACIONADOS NÃO ESPECIFICADOS ANTERIORMENTE",
    "aliquota": 5
  },
  {
    "codigo_servico": "25.01",
    "codigo_atividade": "960330400",
    "descricao_servico": "SERVIÇOS DE FUNERÁRIAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "25.02",
    "codigo_atividade": "960330200",
    "descricao_servico": "SERVIÇOS DE CREMAÇÃO",
    "aliquota": 5
  },
  {
    "codigo_servico": "25.03",
    "codigo_atividade": "651110200",
    "descricao_servico": "PLANOS DE AUXÍLIO-FUNERAL",
    "aliquota": 5
  },
  {
    "codigo_servico": "25.04",
    "codigo_atividade": "960330100",
    "descricao_servico": "GESTÃO E MANUTENÇÃO DE CEMITÉRIOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "26.01",
    "codigo_atividade": "829979909",
    "descricao_servico": "SERVIÇO DE DISTRIBUIÇÃO DE DIÁRIO OFICIAL DA UNIÃO",
    "aliquota": 5
  },
  {
    "codigo_servico": "26.01",
    "codigo_atividade": "532020100",
    "descricao_servico": "SERVIÇOS DE MALOTE NÃO REALIZADOS PELO CORREIO NACIONAL",
    "aliquota": 5
  },
  {
    "codigo_servico": "26.01",
    "codigo_atividade": "531050200",
    "descricao_servico": "ATIVIDADES DE FRANQUEADAS E PERMISSIONÁRIAS DO CORREIO NACIONAL",
    "aliquota": 5
  },
  {
    "codigo_servico": "26.01",
    "codigo_atividade": "801290000",
    "descricao_servico": "ATIVIDADES DE TRANSPORTE DE VALORES",
    "aliquota": 5
  },
  {
    "codigo_servico": "26.01",
    "codigo_atividade": "531050100",
    "descricao_servico": "ATIVIDADES DO CORREIO NACIONAL",
    "aliquota": 5
  },
  {
    "codigo_servico": "26.01",
    "codigo_atividade": "532020200",
    "descricao_servico": "SERVIÇOS DE ENTREGA RÁPIDA",
    "aliquota": 5
  },
  {
    "codigo_servico": "27.01",
    "codigo_atividade": "873019900",
    "descricao_servico": "ATIVIDADES DE ASSISTÊNCIA SOCIAL PRESTADAS EM RESIDÊNCIAS COLETIVAS E PARTICULARES NÃO ESPECIFICADAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "27.01",
    "codigo_atividade": "880060000",
    "descricao_servico": "SERVIÇOS DE ASSISTÊNCIA SOCIAL SEM ALOJAMENTO",
    "aliquota": 5
  },
  {
    "codigo_servico": "27.01",
    "codigo_atividade": "873010100",
    "descricao_servico": "ORFANATOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "27.01",
    "codigo_atividade": "873010200",
    "descricao_servico": "ALBERGUES ASSISTENCIAIS",
    "aliquota": 5
  },
  {
    "codigo_servico": "27.01",
    "codigo_atividade": "943080000",
    "descricao_servico": "ATIVIDADES DE ASSOCIAÇÕES DE DEFESA DE DIREITOS SOCIAIS",
    "aliquota": 5
  },
  {
    "codigo_servico": "28.01",
    "codigo_atividade": "023060003",
    "descricao_servico": "SERVIÇOS DE AVALIAÇÃO DE MADEIRA",
    "aliquota": 5
  },
  {
    "codigo_servico": "29.01",
    "codigo_atividade": "910150001",
    "descricao_servico": "ATIVIDADES DE BIBLIOTECAS E ARQUIVOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "3.02",
    "codigo_atividade": "581150002",
    "descricao_servico": "GESTÃO OU AQUISIÇÃO DE DIREITOS AUTORAIS DE OBRAS LITERÁRIAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "3.02",
    "codigo_atividade": "591380000",
    "descricao_servico": "DISTRIBUIÇÃO CINEMATOGRÁFICA, DE VÍDEO E DE PROGRAMAS DE TELEVISÃO",
    "aliquota": 5
  },
  {
    "codigo_servico": "3.03",
    "codigo_atividade": "931910102",
    "descricao_servico": "ASSOCIAÇÃO OU FEDERAÇÃO ESPORTIVA",
    "aliquota": 5
  },
  {
    "codigo_servico": "3.03",
    "codigo_atividade": "960929904",
    "descricao_servico": "SERVIÇOS DE EXPLORAÇÃO DE SANITÁRIOS PÚBLICOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "3.03",
    "codigo_atividade": "931150000",
    "descricao_servico": "GESTÃO DE INSTALAÇÕES DE ESPORTES",
    "aliquota": 5
  },
  {
    "codigo_servico": "3.03",
    "codigo_atividade": "900350001",
    "descricao_servico": "GESTÃO DE ESPAÇOS PARA ARTES CÊNICAS, ESPETÁCULOS E OUTRAS ATIVIDADES ARTÍSTICAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "3.03",
    "codigo_atividade": "931230000",
    "descricao_servico": "CLUBES SOCIAIS, ESPORTIVOS E SIMILARES",
    "aliquota": 5
  },
  {
    "codigo_servico": "3.04",
    "codigo_atividade": "494000001",
    "descricao_servico": "TRANSPORTE DUTOVIÁRIO",
    "aliquota": 5
  },
  {
    "codigo_servico": "3.05",
    "codigo_atividade": "773900301",
    "descricao_servico": "ALUGUEL DE PALCOS, COBERTURAS E OUTRAS ESTRUTURAS DE USO TEMPORÁRIO, EXCETO ANDAIMES",
    "aliquota": 5
  },
  {
    "codigo_servico": "30.01",
    "codigo_atividade": "712010003",
    "descricao_servico": "SERVIÇOS DE TESTES E ANÁLISES FÍSICOS, QUÍMICOS DE MATERIAIS E PRODUTOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "31.01",
    "codigo_atividade": "711979900",
    "descricao_servico": "ATIVIDADES TÉCNICAS RELACIONADAS À ENGENHARIA E ARQUITETURA NÃO ESPECIFICADAS ANTERIORMENTE",
    "aliquota": 5
  },
  {
    "codigo_servico": "32.01",
    "codigo_atividade": "900270103",
    "descricao_servico": "ATIVIDADE DE CRIADOR DE DESENHO ANIMADO",
    "aliquota": 5
  },
  {
    "codigo_servico": "32.01",
    "codigo_atividade": "711970300",
    "descricao_servico": "SERVIÇOS DE DESENHO TÉCNICO RELACIONADOS À ARQUITETURA E ENGENHARIA",
    "aliquota": 5
  },
  {
    "codigo_servico": "33.01",
    "codigo_atividade": "525080100",
    "descricao_servico": "COMISSARIA DE DESPACHOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "33.01",
    "codigo_atividade": "525080200",
    "descricao_servico": "ATIVIDADES DE DESPACHANTES ADUANEIROS",
    "aliquota": 5
  },
  {
    "codigo_servico": "34.01",
    "codigo_atividade": "803070000",
    "descricao_servico": "ATIVIDADES DE INVESTIGAÇÃO PARTICULAR",
    "aliquota": 5
  },
  {
    "codigo_servico": "35.01",
    "codigo_atividade": "900270105",
    "descricao_servico": "RELAÇÕES PUBLICAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "35.01",
    "codigo_atividade": "900270104",
    "descricao_servico": "ATIVIDADE DE JORNALISTA INDEPENDENTE",
    "aliquota": 5
  },
  {
    "codigo_servico": "36.01",
    "codigo_atividade": "749019903",
    "descricao_servico": "SERVIÇOS DE METEOROLOGIA",
    "aliquota": 5
  },
  {
    "codigo_servico": "37.01",
    "codigo_atividade": "749010501",
    "descricao_servico": "AGENCIAMENTO DE PROFISSIONAIS PARA ATIVIDADES ESPORTIVAS, CULTURAIS E ARTÍSTICAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "38.01",
    "codigo_atividade": "910310000",
    "descricao_servico": "ATIVIDADES DE JARDINS BOTÂNICOS, ZOOLÓGICOS, PARQUES NACIONAIS, RESERVAS ECOLÓGICAS E ÁREAS DE PROTE",
    "aliquota": 5
  },
  {
    "codigo_servico": "38.01",
    "codigo_atividade": "910230100",
    "descricao_servico": "ATIVIDADES DE MUSEUS E DE EXPLORAÇÃO DE LUGARES E PRÉDIOS HISTÓRICOS E ATRAÇÕES SIMILARES",
    "aliquota": 5
  },
  {
    "codigo_servico": "39.01",
    "codigo_atividade": "321160100",
    "descricao_servico": "LAPIDAÇÃO DE GEMAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "39.01",
    "codigo_atividade": "321160300",
    "descricao_servico": "CUNHAGEM DE MOEDAS E MEDALHAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "4.01",
    "codigo_atividade": "863059900",
    "descricao_servico": "ATIVIDADES DE ATENÇÃO AMBULATORIAL NÃO ESPECIFICADAS ANTERIORMENTE",
    "aliquota": 5
  },
  {
    "codigo_servico": "4.02",
    "codigo_atividade": "864021000",
    "descricao_servico": "SERVIÇOS DE QUIMIOTERAPIA",
    "aliquota": 5
  },
  {
    "codigo_servico": "4.02",
    "codigo_atividade": "864021099",
    "descricao_servico": "SUS - SERVIÇOS DE QUIMIOTERAPIA",
    "aliquota": 2
  },
  {
    "codigo_servico": "4.02",
    "codigo_atividade": "864020499",
    "descricao_servico": "SUS - SERVIÇOS DE TOMOGRAFIA",
    "aliquota": 2
  },
  {
    "codigo_servico": "4.02",
    "codigo_atividade": "864020399",
    "descricao_servico": "SUS - SERVIÇOS DE DIÁLISE E NEFROLOGIA",
    "aliquota": 2
  },
  {
    "codigo_servico": "4.02",
    "codigo_atividade": "864020900",
    "descricao_servico": "SERVIÇOS DE DIAGNÓSTICO POR MÉTODOS ÓPTICOS - ENDOSCOPIA E OUTROS EXAMES ANÁLOGOS",
    "aliquota": 2
  },
  {
    "codigo_servico": "4.02",
    "codigo_atividade": "864021300",
    "descricao_servico": "SERVIÇOS DE LITOTRIPSIA",
    "aliquota": 2
  },
  {
    "codigo_servico": "4.02",
    "codigo_atividade": "864020700",
    "descricao_servico": "SERVIÇOS DE DIAGNÓSTICO POR IMAGEM SEM USO DE RADIAÇÃO IONIZANTE, EXCETO RESSONÂNCIA MAGNÉTICA",
    "aliquota": 2
  },
  {
    "codigo_servico": "4.02",
    "codigo_atividade": "864020500",
    "descricao_servico": "SERVIÇOS DE DIAGNÓSTICO POR IMAGEM COM USO DE RADIAÇÃO IONIZANTE, EXCETO TOMOGRAFIA",
    "aliquota": 2
  },
  {
    "codigo_servico": "4.02",
    "codigo_atividade": "864020100",
    "descricao_servico": "LABORATÓRIOS DE ANATOMIA PATOLÓGICA E CITOLÓGICA",
    "aliquota": 2
  },
  {
    "codigo_servico": "4.02",
    "codigo_atividade": "864029900",
    "descricao_servico": "ATIVIDADES DE SERVIÇOS DE COMPLEMENTAÇÃO DIAGNÓSTICA E TERAPÊUTICA NÃO ESPECIFICADAS ANTERIORMENTE",
    "aliquota": 2
  },
  {
    "codigo_servico": "4.02",
    "codigo_atividade": "864020699",
    "descricao_servico": "SUS - SERVIÇOS DE RESSONÂNCIA MAGNÉTICA",
    "aliquota": 2
  },
  {
    "codigo_servico": "4.02",
    "codigo_atividade": "864020599",
    "descricao_servico": "SUS - SERVIÇOS DE DIAGNÓSTICO POR IMAGEM COM USO DE RADIAÇÃO IONIZANTE, EXCETO TOMOGRAFIA",
    "aliquota": 2
  },
  {
    "codigo_servico": "4.02",
    "codigo_atividade": "864020300",
    "descricao_servico": "SERVIÇOS DE DIÁLISE E NEFROLOGIA",
    "aliquota": 2
  },
  {
    "codigo_servico": "4.02",
    "codigo_atividade": "864020600",
    "descricao_servico": "SERVIÇOS DE RESSONÂNCIA MAGNÉTICA",
    "aliquota": 2
  },
  {
    "codigo_servico": "4.02",
    "codigo_atividade": "864021100",
    "descricao_servico": "SERVIÇOS DE RADIOTERAPIA",
    "aliquota": 5
  },
  {
    "codigo_servico": "4.02",
    "codigo_atividade": "0050069",
    "descricao_servico": "LABORATORIO DE ANALISES CLINICAS E MEDICAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "4.02",
    "codigo_atividade": "864020400",
    "descricao_servico": "SERVIÇOS DE TOMOGRAFIA",
    "aliquota": 2
  },
  {
    "codigo_servico": "4.02",
    "codigo_atividade": "864020800",
    "descricao_servico": "SERVIÇOS DE DIAGNÓSTICO POR REGISTRO GRÁFICO - ECG, EEG E OUTROS EXAMES ANÁLOGOS",
    "aliquota": 2
  },
  {
    "codigo_servico": "4.03",
    "codigo_atividade": "866070001",
    "descricao_servico": "ATIVIDADES DE APOIO À GESTÃO DE SAÚDE",
    "aliquota": 5
  },
  {
    "codigo_servico": "4.03",
    "codigo_atividade": "863050699",
    "descricao_servico": "SUS - SERVIÇOS DE VACINAÇÃO E IMUNIZAÇÃO HUMANA",
    "aliquota": 5
  },
  {
    "codigo_servico": "4.03",
    "codigo_atividade": "864020299",
    "descricao_servico": "SUS - LABORATÓRIOS CLÍNICOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "4.03",
    "codigo_atividade": "861010101",
    "descricao_servico": "ATIVIDADES DE ATENDIMENTO HOSPITALAR, EXCETO PRONTO-SOCORRO E UNIDADES PARA ATENDIMENTO A URGÊNCIAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "4.03",
    "codigo_atividade": "863050100",
    "descricao_servico": "ATIVIDADE MÉDICA AMBULATORIAL COM RECURSOS PARA REALIZAÇÃO DE PROCEDIMENTOS CIRÚRGICOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "4.03",
    "codigo_atividade": "864020200",
    "descricao_servico": "LABORATÓRIOS CLÍNICOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "4.03",
    "codigo_atividade": "863050200",
    "descricao_servico": "ATIVIDADE MÉDICA AMBULATORIAL COM RECURSOS PARA REALIZAÇÃO DE EXAMES COMPLEMENTARES",
    "aliquota": 5
  },
  {
    "codigo_servico": "4.03",
    "codigo_atividade": "863050300",
    "descricao_servico": "ATIVIDADE MÉDICA AMBULATORIAL RESTRITA A CONSULTAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "4.03",
    "codigo_atividade": "861010299",
    "descricao_servico": "SUS - ATIVIDADES DE ATENDIMENTO EM PRONTO-SOCORRO E UNIDADES HOSPITALARES PARA ATENDIMENTO A URGÊNCI",
    "aliquota": 5
  },
  {
    "codigo_servico": "4.03",
    "codigo_atividade": "865009901",
    "descricao_servico": "ATIVIDADES DE PROFISSIONAIS DA ÁREA DE SAÚDE NÃO ESPECIFICADAS ANTERIORMENTE",
    "aliquota": 5
  },
  {
    "codigo_servico": "4.03",
    "codigo_atividade": "871150102",
    "descricao_servico": "CENTRO MÉDICO GERIÁTRICO",
    "aliquota": 5
  },
  {
    "codigo_servico": "4.03",
    "codigo_atividade": "863050399",
    "descricao_servico": "SUS - ATIVIDADE MÉDICA AMBULATORIAL RESTRITA A CONSULTAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "4.03",
    "codigo_atividade": "861010200",
    "descricao_servico": "ATIVIDADES DE ATENDIMENTO EM PRONTO-SOCORRO E UNIDADES HOSPITALARES PARA ATENDIMENTO A URGÊNCIAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "4.03",
    "codigo_atividade": "863050299",
    "descricao_servico": "SUS - ATIVIDADE MÉDICA AMBULATORIAL COM RECURSOS PARA REALIZAÇÃO DE EXAMES COMPLEMENTARES",
    "aliquota": 5
  },
  {
    "codigo_servico": "4.03",
    "codigo_atividade": "863050600",
    "descricao_servico": "SERVIÇOS DE VACINAÇÃO E IMUNIZAÇÃO HUMANA",
    "aliquota": 5
  },
  {
    "codigo_servico": "4.03",
    "codigo_atividade": "863050199",
    "descricao_servico": "SUS - ATIVIDADE MÉDICA AMBULATORIAL COM RECURSOS PARA REALIZAÇÃO DE PROCEDIMENTOS CIRÚRGICOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "4.04",
    "codigo_atividade": "865009902",
    "descricao_servico": "SERVIÇOS DE INSTRUMENTAÇÃO CIRÚRGICA",
    "aliquota": 5
  },
  {
    "codigo_servico": "4.05",
    "codigo_atividade": "869090300",
    "descricao_servico": "SERVIÇOS DE ACUPUNTURA",
    "aliquota": 5
  },
  {
    "codigo_servico": "4.05",
    "codigo_atividade": "869090102",
    "descricao_servico": "SERVIÇOS DE ACUPUNTURA",
    "aliquota": 5
  },
  {
    "codigo_servico": "4.06",
    "codigo_atividade": "865000100",
    "descricao_servico": "ATIVIDADES DE ENFERMAGEM",
    "aliquota": 5
  },
  {
    "codigo_servico": "4.07",
    "codigo_atividade": "861010102",
    "descricao_servico": "SERVIÇOS FARMACÊUTICOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "4.07",
    "codigo_atividade": "477170200",
    "descricao_servico": "COMÉRCIO VAREJISTA DE PRODUTOS FARMACÊUTICOS, COM MANIPULAÇÃO DE FÓRMULAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "4.08",
    "codigo_atividade": "865000600",
    "descricao_servico": "ATIVIDADES DE FONOAUDIOLOGIA",
    "aliquota": 5
  },
  {
    "codigo_servico": "4.08",
    "codigo_atividade": "865000500",
    "descricao_servico": "ATIVIDADES DE TERAPIA OCUPACIONAL",
    "aliquota": 5
  },
  {
    "codigo_servico": "4.08",
    "codigo_atividade": "865009904",
    "descricao_servico": "SERVIÇOS DE QUIROPRAXIA",
    "aliquota": 5
  },
  {
    "codigo_servico": "4.08",
    "codigo_atividade": "871150301",
    "descricao_servico": "ATIVIDADES DE ASSISTÊNCIA A DEFICIENTES FÍSICOS, IMUNODEPRIMIDOS E CONVALESCENTES",
    "aliquota": 5
  },
  {
    "codigo_servico": "4.08",
    "codigo_atividade": "865000400",
    "descricao_servico": "ATIVIDADES DE FISIOTERAPIA",
    "aliquota": 5
  },
  {
    "codigo_servico": "4.09",
    "codigo_atividade": "865000700",
    "descricao_servico": "ATIVIDADES DE TERAPIA DE NUTRIÇÃO ENTERAL E PARENTERAL",
    "aliquota": 5
  },
  {
    "codigo_servico": "4.09",
    "codigo_atividade": "8690904",
    "descricao_servico": "ATIVIDADE DE PODOLOGIA",
    "aliquota": 5
  },
  {
    "codigo_servico": "4.09",
    "codigo_atividade": "869099901",
    "descricao_servico": "OUTRAS ATIVIDADES DE ATENÇÃO À SAÚDE HUMANA NÃO ESPECIFICADAS ANTERIORMENTE",
    "aliquota": 5
  },
  {
    "codigo_servico": "4.09",
    "codigo_atividade": "869090101",
    "descricao_servico": "ATIVIDADES DE PRÁTICAS INTEGRATIVAS E COMPLEMENTARES EM SAÚDE HUMANA",
    "aliquota": 5
  },
  {
    "codigo_servico": "4.10",
    "codigo_atividade": "865000200",
    "descricao_servico": "ATIVIDADES DE PROFISSIONAIS DA NUTRIÇÃO",
    "aliquota": 5
  },
  {
    "codigo_servico": "4.11",
    "codigo_atividade": "869099902",
    "descricao_servico": "SERVIÇOS DE PARTEIRA",
    "aliquota": 5
  },
  {
    "codigo_servico": "4.12",
    "codigo_atividade": "863050400",
    "descricao_servico": "ATIVIDADE ODONTOLÓGICA",
    "aliquota": 5
  },
  {
    "codigo_servico": "4.13",
    "codigo_atividade": "325070702",
    "descricao_servico": "SERVIÇOS DE LABORATÓRIOS ÓPTICOS (LAPIDAÇÃO DE LENTES)",
    "aliquota": 5
  },
  {
    "codigo_servico": "4.13",
    "codigo_atividade": "865009903",
    "descricao_servico": "ATIVIDADES DE ORTÓPTICA",
    "aliquota": 5
  },
  {
    "codigo_servico": "4.14",
    "codigo_atividade": "325070600",
    "descricao_servico": "SERVIÇO DE PRÓTESE DENTARIA",
    "aliquota": 5
  },
  {
    "codigo_servico": "4.15",
    "codigo_atividade": "865000302",
    "descricao_servico": "ATIVIDADES DE PSICANÁLISE",
    "aliquota": 5
  },
  {
    "codigo_servico": "4.16",
    "codigo_atividade": "865000301",
    "descricao_servico": "ATIVIDADES DE PSICOLOGIA",
    "aliquota": 5
  },
  {
    "codigo_servico": "4.17",
    "codigo_atividade": "871150400",
    "descricao_servico": "CENTROS DE APOIO A PACIENTES COM CÂNCER E COM AIDS",
    "aliquota": 5
  },
  {
    "codigo_servico": "4.17",
    "codigo_atividade": "871230000",
    "descricao_servico": "ATIVIDADES DE FORNECIMENTO DE INFRA-ESTRUTURA DE APOIO E ASSISTÊNCIA A PACIENTE NO DOMICÍLIO",
    "aliquota": 5
  },
  {
    "codigo_servico": "4.17",
    "codigo_atividade": "851120000",
    "descricao_servico": "EDUCAÇÃO INFANTIL - CRECHE",
    "aliquota": 5
  },
  {
    "codigo_servico": "4.17",
    "codigo_atividade": "871150200",
    "descricao_servico": "INSTITUIÇÕES DE LONGA PERMANÊNCIA PARA IDOSOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "4.17",
    "codigo_atividade": "871150302",
    "descricao_servico": "CASAS DE REPOUSO PARA CONVALESCENTES E IMONODEPRIMIDOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "4.17",
    "codigo_atividade": "872040100",
    "descricao_servico": "ATIVIDADES DE CENTROS DE ASSISTÊNCIA PSICOSSOCIAL",
    "aliquota": 5
  },
  {
    "codigo_servico": "4.17",
    "codigo_atividade": "872049900",
    "descricao_servico": "ATIVIDADES DE ASSISTÊNCIA PSICOSSOCIAL E À SAÚDE A PORTADORES DE DISTÚRBIOS PSÍQUICOS, DEFICIÊNCIA M",
    "aliquota": 5
  },
  {
    "codigo_servico": "4.17",
    "codigo_atividade": "871150500",
    "descricao_servico": "CONDOMÍNIOS RESIDENCIAIS PARA IDOSOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "4.17",
    "codigo_atividade": "871150101",
    "descricao_servico": "CLÍNICAS E RESIDÊNCIAS GERIÁTRICAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "4.18",
    "codigo_atividade": "863050700",
    "descricao_servico": "ATIVIDADES DE REPRODUÇÃO HUMANA ASSISTIDA",
    "aliquota": 5
  },
  {
    "codigo_servico": "4.19",
    "codigo_atividade": "869090200",
    "descricao_servico": "ATIVIDADES DE BANCOS DE LEITE HUMANO",
    "aliquota": 5
  },
  {
    "codigo_servico": "4.19",
    "codigo_atividade": "864021400",
    "descricao_servico": "SERVIÇOS DE BANCOS DE CÉLULAS E TECIDOS HUMANOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "4.20",
    "codigo_atividade": "864021200",
    "descricao_servico": "SERVIÇOS DE HEMOTERAPIA",
    "aliquota": 5
  },
  {
    "codigo_servico": "4.21",
    "codigo_atividade": "862160100",
    "descricao_servico": "UTI MÓVEL",
    "aliquota": 5
  },
  {
    "codigo_servico": "4.21",
    "codigo_atividade": "862240000",
    "descricao_servico": "SERVIÇOS DE REMOÇÃO DE PACIENTES, EXCETO OS SERVIÇOS MÓVEIS DE ATENDIMENTO A URGÊNCIAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "4.21",
    "codigo_atividade": "862160200",
    "descricao_servico": "SERVIÇOS MÓVEIS DE ATENDIMENTO A URGÊNCIAS, EXCETO POR UTI MÓVEL",
    "aliquota": 5
  },
  {
    "codigo_servico": "4.22",
    "codigo_atividade": "655020001",
    "descricao_servico": "PLANOS DE SAÚDE",
    "aliquota": 5
  },
  {
    "codigo_servico": "4.23",
    "codigo_atividade": "655020002",
    "descricao_servico": "OUTROS PLANOS DE SAÚDE QUE SE CUMPRAM ATRAVÉS DE SERVIÇOS DE TERCEIROS CONTRATADOS, CREDENCIADOS, CO",
    "aliquota": 5
  },
  {
    "codigo_servico": "4.23",
    "codigo_atividade": "866070002",
    "descricao_servico": "COMPLEXOS REGULADORES DAS AÇÕES DO SISTEMA DE SAÚDE",
    "aliquota": 5
  },
  {
    "codigo_servico": "40.01",
    "codigo_atividade": "900270200",
    "descricao_servico": "RESTAURAÇÃO DE OBRAS DE ARTE",
    "aliquota": 5
  },
  {
    "codigo_servico": "40.01",
    "codigo_atividade": "900270101",
    "descricao_servico": "ATIVIDADES DE ARTISTAS PLÁSTICOS, JORNALISTAS INDEPENDENTES E ESCRITORES",
    "aliquota": 5
  },
  {
    "codigo_servico": "5.01",
    "codigo_atividade": "750010001",
    "descricao_servico": "ATIVIDADES VETERINÁRIAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "5.02",
    "codigo_atividade": "750010004",
    "descricao_servico": "CLIINICA, CONSULTÓRIO OU HOSPITAL VETERINÁRIO",
    "aliquota": 5
  },
  {
    "codigo_servico": "5.03",
    "codigo_atividade": "750010003",
    "descricao_servico": "LABORATÓRIO DE ANÁLISE VETERINÁRIA",
    "aliquota": 5
  },
  {
    "codigo_servico": "5.04",
    "codigo_atividade": "016280100",
    "descricao_servico": "SERVIÇO DE INSEMINAÇÃO ARTIFICIAL EM ANIMAIS",
    "aliquota": 5
  },
  {
    "codigo_servico": "5.05",
    "codigo_atividade": "750010005",
    "descricao_servico": "BANCO DE SANGUE DE ANIMAIS",
    "aliquota": 5
  },
  {
    "codigo_servico": "5.06",
    "codigo_atividade": "462310102",
    "descricao_servico": "COMÉRCIO ATACADISTA DE SÊMEN BOVINO",
    "aliquota": 5
  },
  {
    "codigo_servico": "5.07",
    "codigo_atividade": "750010002",
    "descricao_servico": "AMBULÂNCIA PARA ANIMAIS",
    "aliquota": 5
  },
  {
    "codigo_servico": "5.08",
    "codigo_atividade": "801110200",
    "descricao_servico": "SERVIÇOS DE ADESTRAMENTO DE CÃES DE GUARDA",
    "aliquota": 5
  },
  {
    "codigo_servico": "5.08",
    "codigo_atividade": "016280200",
    "descricao_servico": "SERVIÇO DE TOSQUIAMENTO DE OVINOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "5.08",
    "codigo_atividade": "960920700",
    "descricao_servico": "ALOJAMENTO DE ANIMAIS DOMÉSTICOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "5.08",
    "codigo_atividade": "016280300",
    "descricao_servico": "SERVIÇOS DE MANEJO DE ANIMAIS (CONDUÇÃO, PASTOREIO)",
    "aliquota": 5
  },
  {
    "codigo_servico": "5.08",
    "codigo_atividade": "016289902",
    "descricao_servico": "SERVIÇO DE ALOJAMENTO DO GADO DE CURTA DURAÇÃO E CLASSIFICAÇÃO DE PINTOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "5.08",
    "codigo_atividade": "960920800",
    "descricao_servico": "HIGIENE E EMBELEZAMENTO DE ANIMAIS ",
    "aliquota": 5
  },
  {
    "codigo_servico": "5.09",
    "codigo_atividade": "750010006",
    "descricao_servico": "SERVIÇOS DE ASSISTÊNCIA VETERINÁRIA",
    "aliquota": 5
  },
  {
    "codigo_servico": "5.09",
    "codigo_atividade": "655020003",
    "descricao_servico": "PLANOS DE ASSISTÊNCIA VETERINÁRIA",
    "aliquota": 5
  },
  {
    "codigo_servico": "6.01",
    "codigo_atividade": "869090400",
    "descricao_servico": "ATIVIDADES DE PODOLOGIA",
    "aliquota": 5
  },
  {
    "codigo_servico": "6.01",
    "codigo_atividade": "9609299",
    "descricao_servico": "OUTRAS ATIVIDADES DE SERVIÇOS PESSOAIS NÃO ESPECIFICADAS ANTERIORMENTE",
    "aliquota": 5
  },
  {
    "codigo_servico": "6.01",
    "codigo_atividade": "960250100",
    "descricao_servico": "CABELEIREIROS, MANICURE E PEDICURE",
    "aliquota": 5
  },
  {
    "codigo_servico": "6.02",
    "codigo_atividade": "960250201",
    "descricao_servico": "ATIVIDADES DE ESTÉTICA E OUTROS SERVIÇOS DE CUIDADOS COM A BELEZA",
    "aliquota": 5
  },
  {
    "codigo_servico": "6.03",
    "codigo_atividade": "960920501",
    "descricao_servico": "ATIVIDADES DE SAUNA E BANHO",
    "aliquota": 5
  },
  {
    "codigo_servico": "6.03",
    "codigo_atividade": "960920500",
    "descricao_servico": "ATIVIDADES DE SAUNA E BANHOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "6.04",
    "codigo_atividade": "931310000",
    "descricao_servico": "ATIVIDADES DE CONDICIONAMENTO FÍSICO",
    "aliquota": 5
  },
  {
    "codigo_servico": "6.04",
    "codigo_atividade": "859290100",
    "descricao_servico": "ENSINO DE DANÇA",
    "aliquota": 5
  },
  {
    "codigo_servico": "6.04",
    "codigo_atividade": "859290200",
    "descricao_servico": "ENSINO DE ARTES CÊNICAS, EXCETO DANÇA",
    "aliquota": 5
  },
  {
    "codigo_servico": "6.04",
    "codigo_atividade": "859110000",
    "descricao_servico": "ENSINO DE ESPORTES",
    "aliquota": 5
  },
  {
    "codigo_servico": "6.05",
    "codigo_atividade": "960250202",
    "descricao_servico": "CLÍNICA DE EMAGRECIMENTO",
    "aliquota": 5
  },
  {
    "codigo_servico": "6.06",
    "codigo_atividade": "960920600",
    "descricao_servico": "SERVIÇOS DE TATUAGEM E COLOCAÇÃO DE PIERCING",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.01",
    "codigo_atividade": "711200001",
    "descricao_servico": "SERVIÇOS DE ENGENHARIA",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.01",
    "codigo_atividade": "711110000",
    "descricao_servico": "SERVIÇOS DE ARQUITETURA",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.01",
    "codigo_atividade": "711970102",
    "descricao_servico": "SERVIÇOS DE AGRIMENSURA",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.02",
    "codigo_atividade": "433040502",
    "descricao_servico": "COLOCAÇÃO DE AZULEJOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.02",
    "codigo_atividade": "439910300",
    "descricao_servico": "OBRAS DE ALVENARIA",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.02",
    "codigo_atividade": "421110103",
    "descricao_servico": "Pavimentação",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.02",
    "codigo_atividade": "429280200",
    "descricao_servico": "OBRAS DE MONTAGEM INDUSTRIAL",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.02",
    "codigo_atividade": "439160001",
    "descricao_servico": "OBRAS DE FUNDAÇÕES",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.02",
    "codigo_atividade": "432910500",
    "descricao_servico": "TRATAMENTOS TÉRMICOS, ACÚSTICOS OU DE VIBRAÇÃO",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.02",
    "codigo_atividade": "439910200",
    "descricao_servico": "MONTAGEM E DESMONTAGEM DE ANDAIMES E OUTRAS ESTRUTURAS TEMPORÁRIAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.02",
    "codigo_atividade": "412040001",
    "descricao_servico": "CONSTRUÇÃO DE EDIFÍCIOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.02",
    "codigo_atividade": "432910401",
    "descricao_servico": "MONTAGEM E INSTALAÇÃO DE SISTEMAS E EQUIPAMENTOS DE ILUMINAÇÃO E SINALIZAÇÃO EM VIAS PÚBLICAS, PORTO",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.02",
    "codigo_atividade": "422190100",
    "descricao_servico": "CONSTRUÇÃO DE BARRAGENS E REPRESAS PARA GERAÇÃO DE ENERGIA ELÉTRICA",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.02",
    "codigo_atividade": "422270100",
    "descricao_servico": "CONSTRUÇÃO DE REDES DE ABASTECIMENTO DE ÁGUA, COLETA DE ESGOTO E CONSTRUÇÕES CORRELATAS, EXCETO OBRA",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.02",
    "codigo_atividade": "429959901",
    "descricao_servico": "OUTRAS OBRAS DE ENGENHARIA CIVIL NÃO ESPECIFICADAS ANTERIORMENTE",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.02",
    "codigo_atividade": "429280101",
    "descricao_servico": "MONTAGEM DE ESTRUTURAS METÁLICAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.02",
    "codigo_atividade": "433049901",
    "descricao_servico": "OUTRAS OBRAS DE ACABAMENTO DA CONSTRUÇÃO",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.02",
    "codigo_atividade": "432230301",
    "descricao_servico": "INSTALAÇÕES DE SISTEMA DE PREVENÇÃO CONTRA INCÊNDIO",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.02",
    "codigo_atividade": "422270200",
    "descricao_servico": "OBRAS DE IRRIGAÇÃO",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.02",
    "codigo_atividade": "233030102",
    "descricao_servico": "FABRICAÇÃO DE ESTRUTURAS PRÉ-MOLDADAS DE CONCRETO ARMADO, EM SÉRIE E SOB ENCOMENDA",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.02",
    "codigo_atividade": "432230100",
    "descricao_servico": "INSTALAÇÕES HIDRÁULICAS, SANITÁRIAS E DE GÁS",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.02",
    "codigo_atividade": "431340000",
    "descricao_servico": "OBRAS DE TERRAPLENAGEM",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.02",
    "codigo_atividade": "439910500",
    "descricao_servico": "PERFURAÇÃO E CONSTRUÇÃO DE POÇOS DE ÁGUA",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.02",
    "codigo_atividade": "432230201",
    "descricao_servico": "INSTALAÇÃO E MANUTENÇÃO DE SISTEMAS CENTRAIS DE AR CONDICIONADO, DE VENTILAÇÃO E REFRIGERAÇÃO",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.02",
    "codigo_atividade": "431180200",
    "descricao_servico": "PREPARAÇÃO DE CANTEIRO E LIMPEZA DE TERRENO",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.02",
    "codigo_atividade": "433040400",
    "descricao_servico": "SERVIÇOS DE PINTURA DE EDIFÍCIOS EM GERAL",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.02",
    "codigo_atividade": "421110200",
    "descricao_servico": "PINTURA PARA SINALIZAÇÃO EM PISTAS RODOVIÁRIAS E AEROPORTOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.02",
    "codigo_atividade": "422350000",
    "descricao_servico": "CONSTRUÇÃO DE REDES DE TRANSPORTES POR DUTOS, EXCETO PARA ÁGUA E ESGOTO",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.02",
    "codigo_atividade": "422190400",
    "descricao_servico": "CONSTRUÇÃO DE ESTAÇÕES E REDES DE TELECOMUNICAÇÕES",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.02",
    "codigo_atividade": "422190200",
    "descricao_servico": "CONSTRUÇÃO DE ESTAÇÕES E REDES DE DISTRIBUIÇÃO DE ENERGIA ELÉTRICA",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.02",
    "codigo_atividade": "429100001",
    "descricao_servico": "OBRAS PORTUÁRIAS, MARÍTIMAS E FLUVIAIS",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.02",
    "codigo_atividade": "431930000",
    "descricao_servico": "SERVIÇOS DE PREPARAÇÃO DO TERRENO NÃO ESPECIFICADOS ANTERIORMENTE",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.02",
    "codigo_atividade": "421110101",
    "descricao_servico": "CONSTRUÇÃO DE RODOVIAS E FERROVIAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.02",
    "codigo_atividade": "421200001",
    "descricao_servico": "CONSTRUÇÃO DE OBRAS-DE-ARTE ESPECIAIS",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.02",
    "codigo_atividade": "432150001",
    "descricao_servico": "INSTALAÇÃO E MANUTENÇÃO ELÉTRICA",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.02",
    "codigo_atividade": "421380001",
    "descricao_servico": "OBRAS DE URBANIZAÇÃO -",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.02",
    "codigo_atividade": "432919901",
    "descricao_servico": "OUTRAS OBRAS DE INSTALAÇÕES EM CONSTRUÇÕES NÃO ESPECIFICADAS ANTERIORMENTE",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.02",
    "codigo_atividade": "439919901",
    "descricao_servico": "SERVIÇOS ESPECIALIZADOS PARA CONSTRUÇÃO NÃO ESPECIFICADOS ANTERIORMENTE",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.02",
    "codigo_atividade": "431260000",
    "descricao_servico": "PERFURAÇÕES E SONDAGENS",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.02",
    "codigo_atividade": "429950101",
    "descricao_servico": "CONSTRUÇÃO DE INSTALAÇÕES ESPORTIVAS E RECREATIVAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.03",
    "codigo_atividade": "711970202",
    "descricao_servico": "ELABORAÇÃO DE PROJETOS DE PROSPECÇÃO DE PETRÓLEO E GÁS NATURAL",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.03",
    "codigo_atividade": "711200003",
    "descricao_servico": "ELABORAÇÃO DE PROJETOS DE ENGENHARIA",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.03",
    "codigo_atividade": "711970103",
    "descricao_servico": "PROJETOS TOPOGRÁFICOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.03",
    "codigo_atividade": "411070000",
    "descricao_servico": "INCORPORAÇÃO DE EMPREENDIMENTOS IMOBILIÁRIOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.04",
    "codigo_atividade": "431180100",
    "descricao_servico": "DEMOLIÇÃO DE EDIFÍCIOS E OUTRAS ESTRUTURAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.05",
    "codigo_atividade": "422190500",
    "descricao_servico": "MANUTENÇÃO DE ESTAÇÕES E REDES DE TELECOMUNICAÇÕES",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.05",
    "codigo_atividade": "421110104",
    "descricao_servico": "Recapeamento Asfaltico",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.05",
    "codigo_atividade": "432150002",
    "descricao_servico": "MANUTENÇÃO ELÉTRICA",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.05",
    "codigo_atividade": "432230203",
    "descricao_servico": "REPARAÇÃO OU MANUTENÇÃO DE SISTEMA DE REFRIGERAÇÃO CENTRAL EM IMÓVEIS RESIDENCIAIS E COMERCIAIS",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.05",
    "codigo_atividade": "429950102",
    "descricao_servico": "MANUTENÇÃO DE INSTALAÇÕES ESPORTIVAS (EXCETO EDIFICAÇÕES)",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.05",
    "codigo_atividade": "412040002",
    "descricao_servico": "REFORMAS EM APARTAMENTOS, CASAS, CONJUNTOS HABITACIONAIS, PRÉDIOS, EDIFÍCIOS, EDIFICAÇÕES, CONDOMÍNI",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.05",
    "codigo_atividade": "421380002",
    "descricao_servico": "REFORMA DE RUAS, PRAÇAS E CALÇADAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.05",
    "codigo_atividade": "421110102",
    "descricao_servico": "MANUTENÇÃO DE RODOVIAS E FERROVIAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.05",
    "codigo_atividade": "433040100",
    "descricao_servico": "IMPERMEABILIZAÇÃO EM OBRAS DE ENGENHARIA CIVIL",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.05",
    "codigo_atividade": "432910402",
    "descricao_servico": "MANUTENÇÃO DE REDE DE ILUMINAÇÃO PUBLICA E SINAIS LUMINOSOS (SEMÁFOROS)",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.05",
    "codigo_atividade": "421200002",
    "descricao_servico": "MANUTENÇÃO DE OBRAS-DE-ARTE ESPECIAIS",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.05",
    "codigo_atividade": "433049904",
    "descricao_servico": "TRATAMENTO DE TRINCAS E FISSURAS EM PAREDES",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.05",
    "codigo_atividade": "422190300",
    "descricao_servico": "MANUTENÇÃO DE REDES DE DISTRIBUIÇÃO DE ENERGIA ELÉTRICA",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.05",
    "codigo_atividade": "432230302",
    "descricao_servico": "MANUTENÇÃO DE SISTEMAS DE ALARME CONTRA INCÊNDIO",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.06",
    "codigo_atividade": "433049903",
    "descricao_servico": "SERVIÇOS DE TEXTURIZAÇÃO EM PAREDES",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.06",
    "codigo_atividade": "433040201",
    "descricao_servico": "INSTALAÇÃO DE PORTAS, JANELAS, TETOS, DIVISÓRIAS E ARMÁRIOS EMBUTIDOS DE QUALQUER MATERIAL",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.06",
    "codigo_atividade": "433040300",
    "descricao_servico": "OBRAS DE ACABAMENTO EM GESSO E ESTUQUE",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.06",
    "codigo_atividade": "433040501",
    "descricao_servico": "APLICAÇÃO DE REVESTIMENTOS E DE RESINAS EM INTERIORES E EXTERIORES",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.06",
    "codigo_atividade": "433049902",
    "descricao_servico": "INSTALAÇÃO DE (POR CONTA DE TERCEIROS) BALCÕES,EQUIPAMENTOS PARA LOJAS COMERCIAIS, TOLDOS, PERSIANAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.07",
    "codigo_atividade": "433040504",
    "descricao_servico": "SERVIÇOS DE RASPAGEM, POLIMENTO E APLICAÇÃO DE RESINAS EM PISOS, PAREDES E TETOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.08",
    "codigo_atividade": "433040503",
    "descricao_servico": "SERVIÇOS DE CALAFETAGEM",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.09",
    "codigo_atividade": "383940100",
    "descricao_servico": "USINAS DE COMPOSTAGEM",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.09",
    "codigo_atividade": "383199900",
    "descricao_servico": "RECUPERAÇÃO DE MATERIAIS METÁLICOS, EXCETO ALUMÍNIO",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.09",
    "codigo_atividade": "381140000",
    "descricao_servico": "COLETA DE RESÍDUOS NÃO-PERIGOSOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.09",
    "codigo_atividade": "381220000",
    "descricao_servico": "COLETA DE RESÍDUOS PERIGOSOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.09",
    "codigo_atividade": "382200000",
    "descricao_servico": "TRATAMENTO E DISPOSIÇÃO DE RESÍDUOS PERIGOSOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.09",
    "codigo_atividade": "383190100",
    "descricao_servico": "RECUPERAÇÃO DE SUCATAS DE ALUMÍNIO",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.09",
    "codigo_atividade": "383270000",
    "descricao_servico": "RECUPERAÇÃO DE MATERIAIS PLÁSTICOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.09",
    "codigo_atividade": "382110000",
    "descricao_servico": "TRATAMENTO E DISPOSIÇÃO DE RESÍDUOS NÃO-PERIGOSOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.10",
    "codigo_atividade": "016289903",
    "descricao_servico": "SERVIÇOS DE LIMPEZA DE GALINHEIROS E DE BANHEIRO CARRAPATICIDA",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.10",
    "codigo_atividade": "370290000",
    "descricao_servico": "ATIVIDADES RELACIONADAS A ESGOTO, EXCETO A GESTÃO DE REDES",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.10",
    "codigo_atividade": "812140000",
    "descricao_servico": "LIMPEZA EM PRÉDIOS E EM DOMICÍLIOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.10",
    "codigo_atividade": "812900002",
    "descricao_servico": "SERVIÇOS DE LIMPEZA E CONSERVAÇÃO DE RUAS, LOGRADOUROS",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.10",
    "codigo_atividade": "439919902",
    "descricao_servico": "SERVIÇOS DE LIMPEZA DE FACHADAS COM JATEAMENTO DE AREIA, VAPOR, ÁGUA OU SEMELHANTES",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.10",
    "codigo_atividade": "811170001",
    "descricao_servico": "SERVIÇOS COMBINADOS PARA APOIO A EDIFÍCIOS, EXCETO CONDOMÍNIOS PREDIAIS",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.10",
    "codigo_atividade": "910230200",
    "descricao_servico": "RESTAURAÇÃO E CONSERVAÇÃO DE LUGARES E PRÉDIOS HISTÓRICOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.11",
    "codigo_atividade": "813030000",
    "descricao_servico": "ATIVIDADES PAISAGÍSTICAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.11",
    "codigo_atividade": "016100200",
    "descricao_servico": "SERVIÇO DE PODA DE ARVORES PARA LAVOURA",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.11",
    "codigo_atividade": "741020202",
    "descricao_servico": "DECORAÇÃO DE INTERIORES",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.12",
    "codigo_atividade": "370110000",
    "descricao_servico": "GESTÃO DE REDES DE ESGOTO",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.12",
    "codigo_atividade": "360060100",
    "descricao_servico": "CAPTAÇÃO, TRATAMENTO E DISTRIBUIÇÃO DE ÁGUA",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.13",
    "codigo_atividade": "016100100",
    "descricao_servico": "SERVIÇO DE PULVERIZAÇÃO E CONTROLE DE PRAGAS AGRÍCOLAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.13",
    "codigo_atividade": "812900003",
    "descricao_servico": "SERVIÇO DE DESENTUPIMENTO EM PRÉDIOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.13",
    "codigo_atividade": "812220000",
    "descricao_servico": "IMUNIZAÇÃO E CONTROLE DE PRAGAS URBANAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.13",
    "codigo_atividade": "812900004",
    "descricao_servico": "SERVIÇO DE ESTERILIZAÇÃO DE OBJETOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.13",
    "codigo_atividade": "383949900",
    "descricao_servico": "RECUPERAÇÃO DE MATERIAIS NÃO ESPECIFICADOS ANTERIORMENTE",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.13",
    "codigo_atividade": "390050000",
    "descricao_servico": "DESCONTAMINAÇÃO E OUTROS SERVIÇOS DE GESTÃO DE RESÍDUOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.16",
    "codigo_atividade": "022090600",
    "descricao_servico": "CONSERVAÇÃO DE FLORESTAS NATIVAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.16",
    "codigo_atividade": "016100300",
    "descricao_servico": "SERVIÇO DE PREPARAÇÃO DE TERRENO, CULTIVO E COLHEITA",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.16",
    "codigo_atividade": "021010702",
    "descricao_servico": "REFLORESTAMENTO COM ABATE DE ARVORES",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.16",
    "codigo_atividade": "023060005",
    "descricao_servico": "ATIVIDADE DE SERVIÇOS LIGADOS COM A SILVICULTURA E EXPLORAÇÃO VEGETAL",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.17",
    "codigo_atividade": "429959903",
    "descricao_servico": "OBRAS DE CONTENÇÃO DE ENCOSTAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.17",
    "codigo_atividade": "429959902",
    "descricao_servico": "OBRAS DE AÇUDES",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.18",
    "codigo_atividade": "429100002",
    "descricao_servico": "LIMPEZA DE RIOS, PORTOS, CANAIS, BAIAS, LAGOS, LAGOAS, REPRESAS, AÇUDES E SIMILARES (OBRAS DE DRAGAG",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.19",
    "codigo_atividade": "711200002",
    "descricao_servico": "SERVIÇOS DE FISCALIZAÇÃO DE OBRAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.19",
    "codigo_atividade": "439910100",
    "descricao_servico": "ADMINISTRAÇÃO DE OBRAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.20",
    "codigo_atividade": "711970101",
    "descricao_servico": "SERVIÇOS DE CARTOGRAFIA, TOPOGRAFIA E GEODÉSIA",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.20",
    "codigo_atividade": "711970201",
    "descricao_servico": "ATIVIDADES DE ESTUDOS GEOLÓGICOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.21",
    "codigo_atividade": "091060003",
    "descricao_servico": "SERVIÇOS RELACIONADOS COM A EXTRAÇÃO DE PETRÓLEO E GÁS NATURAL, REALIZADO POR TERCEIROS, EXCETO A PR",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.21",
    "codigo_atividade": "749010200",
    "descricao_servico": "ESCAFANDRIA E MERGULHO",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.21",
    "codigo_atividade": "522909906",
    "descricao_servico": "SERVIÇOS DE LIQUEFAÇÃO DE GÁS PARA FINS DE TRANSPORTE EM VEÍCULOS DUTOS MÓVEIS",
    "aliquota": 5
  },
  {
    "codigo_servico": "7.22",
    "codigo_atividade": "749019904",
    "descricao_servico": "NUCLEAÇÃO E BOMBARDEAMENTO DE NUVENS E CONGÊNERES",
    "aliquota": 5
  },
  {
    "codigo_servico": "8.01",
    "codigo_atividade": "852010000",
    "descricao_servico": "ENSINO MÉDIO",
    "aliquota": 5
  },
  {
    "codigo_servico": "8.01",
    "codigo_atividade": "853250000",
    "descricao_servico": "EDUCAÇÃO SUPERIOR - GRADUAÇÃO E PÓS-GRADUAÇÃO",
    "aliquota": 5
  },
  {
    "codigo_servico": "8.01",
    "codigo_atividade": "854220000",
    "descricao_servico": "EDUCAÇÃO PROFISSIONAL DE NÍVEL TECNOLÓGICO",
    "aliquota": 5
  },
  {
    "codigo_servico": "8.01",
    "codigo_atividade": "853330000",
    "descricao_servico": "EDUCAÇÃO SUPERIOR - PÓS-GRADUAÇÃO E EXTENSÃO",
    "aliquota": 5
  },
  {
    "codigo_servico": "8.01",
    "codigo_atividade": "853170000",
    "descricao_servico": "EDUCAÇÃO SUPERIOR - GRADUAÇÃO",
    "aliquota": 5
  },
  {
    "codigo_servico": "8.01",
    "codigo_atividade": "851390000",
    "descricao_servico": "ENSINO FUNDAMENTAL",
    "aliquota": 5
  },
  {
    "codigo_servico": "8.01",
    "codigo_atividade": "853250001",
    "descricao_servico": "EDUCAÇÃO SUPERIOR - GRADUAÇÃO E PÓS GRADUAÇÃO EAD",
    "aliquota": 5
  },
  {
    "codigo_servico": "8.01",
    "codigo_atividade": "851210000",
    "descricao_servico": "EDUCAÇÃO INFANTIL - PRÉ-ESCOLA",
    "aliquota": 5
  },
  {
    "codigo_servico": "8.01",
    "codigo_atividade": "854140000",
    "descricao_servico": "EDUCAÇÃO PROFISSIONAL DE NÍVEL TÉCNICO",
    "aliquota": 5
  },
  {
    "codigo_servico": "8.01",
    "codigo_atividade": "853170001",
    "descricao_servico": "EDUCAÇÃO SUPERIOR- GRADUAÇÃO EAD",
    "aliquota": 5
  },
  {
    "codigo_servico": "8.02",
    "codigo_atividade": "859960400",
    "descricao_servico": "TREINAMENTO EM DESENVOLVIMENTO PROFISSIONAL E GERENCIAL",
    "aliquota": 5
  },
  {
    "codigo_servico": "8.02",
    "codigo_atividade": "859960500",
    "descricao_servico": "CURSOS PREPARATÓRIOS PARA CONCURSOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "8.02",
    "codigo_atividade": "859290300",
    "descricao_servico": "ENSINO DE MÚSICA",
    "aliquota": 5
  },
  {
    "codigo_servico": "8.02",
    "codigo_atividade": "859960200",
    "descricao_servico": "CURSOS DE PILOTAGEM",
    "aliquota": 5
  },
  {
    "codigo_servico": "8.02",
    "codigo_atividade": "859960100",
    "descricao_servico": "FORMAÇÃO DE CONDUTORES",
    "aliquota": 5
  },
  {
    "codigo_servico": "8.02",
    "codigo_atividade": "859969900",
    "descricao_servico": "OUTRAS ATIVIDADES DE ENSINO NÃO ESPECIFICADAS ANTERIORMENTE",
    "aliquota": 5
  },
  {
    "codigo_servico": "8.02",
    "codigo_atividade": "855030200",
    "descricao_servico": "ATIVIDADES DE APOIO À EDUCAÇÃO, EXCETO CAIXAS ESCOLARES",
    "aliquota": 5
  },
  {
    "codigo_servico": "8.02",
    "codigo_atividade": "859960300",
    "descricao_servico": "TREINAMENTO EM INFORMÁTICA",
    "aliquota": 5
  },
  {
    "codigo_servico": "8.02",
    "codigo_atividade": "859299900",
    "descricao_servico": "ENSINO DE ARTE E CULTURA NÃO ESPECIFICADO ANTERIORMENTE",
    "aliquota": 5
  },
  {
    "codigo_servico": "8.02",
    "codigo_atividade": "859370000",
    "descricao_servico": "ENSINO DE IDIOMAS",
    "aliquota": 5
  },
  {
    "codigo_servico": "9.01",
    "codigo_atividade": "559060200",
    "descricao_servico": "CAMPINGS",
    "aliquota": 5
  },
  {
    "codigo_servico": "9.01",
    "codigo_atividade": "559060100",
    "descricao_servico": "ALBERGUES, EXCETO ASSISTENCIAIS",
    "aliquota": 5
  },
  {
    "codigo_servico": "9.01",
    "codigo_atividade": "551080100",
    "descricao_servico": "HOTÉIS",
    "aliquota": 5
  },
  {
    "codigo_servico": "9.01",
    "codigo_atividade": "551080300",
    "descricao_servico": "MOTÉIS",
    "aliquota": 5
  },
  {
    "codigo_servico": "9.01",
    "codigo_atividade": "551080200",
    "descricao_servico": "APART-HOTÉIS",
    "aliquota": 5
  },
  {
    "codigo_servico": "9.01",
    "codigo_atividade": "559060300",
    "descricao_servico": "PENSÕES (ALOJAMENTO)",
    "aliquota": 5
  },
  {
    "codigo_servico": "9.01",
    "codigo_atividade": "559069900",
    "descricao_servico": "OUTROS ALOJAMENTOS NÃO ESPECIFICADOS ANTERIORMENTE",
    "aliquota": 5
  },
  {
    "codigo_servico": "9.02",
    "codigo_atividade": "799020000",
    "descricao_servico": "SERVIÇOS DE RESERVAS E OUTROS SERVIÇOS DE TURISMO NÃO ESPECIFICADOS ANTERIORMENTE",
    "aliquota": 5
  },
  {
    "codigo_servico": "9.02",
    "codigo_atividade": "492990400",
    "descricao_servico": "ORGANIZAÇÃO DE EXCURSÕES EM VEÍCULOS RODOVIÁRIOS PRÓPRIOS, INTERMUNICIPAL, INTERESTADUAL E INTERNACI",
    "aliquota": 5
  },
  {
    "codigo_servico": "9.02",
    "codigo_atividade": "791120000",
    "descricao_servico": "AGÊNCIAS DE VIAGENS",
    "aliquota": 5
  },
  {
    "codigo_servico": "9.02",
    "codigo_atividade": "791210001",
    "descricao_servico": "OPERADORES TURÍSTICOS",
    "aliquota": 5
  },
  {
    "codigo_servico": "9.02",
    "codigo_atividade": "492990300",
    "descricao_servico": "ORGANIZAÇÃO DE EXCURSÕES EM VEÍCULOS RODOVIÁRIOS PRÓPRIOS, MUNICIPAL",
    "aliquota": 5
  },
  {
    "codigo_servico": "9.03",
    "codigo_atividade": "791210002",
    "descricao_servico": "SERVIÇOS DE GUIA TURÍSTICO",
    "aliquota": 5
  },

  {
    "codigo_servico": "17.12",
    "codigo_atividade": "682260000",
    "descricao_servico": "GESTÃO E ADMINISTRAÇÃO DA PROPRIEDADE IMOBILIÁRIA",
    "aliquota": 5
  },
  {
    "codigo_servico": "1.09",
    "codigo_atividade": "619060100",
    "descricao_servico": "PROVEDORES DE ACESSO ÀS REDES DE COMUNICAÇÕES",
    "aliquota": 5
  }
]
