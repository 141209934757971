<template>

  <z-dialogo ref="dialogo" persistent largura="800px" titulo="Emitente" scrollable no-padding dividers>

      <template v-slot:texto>
        <v-form ref="formulario" v-model="valido" lazy-validation>
            <v-container grid-list-md>

              <v-layout row wrap>
                <v-flex sm12 class="mb-2">
                  <v-text-field box label="Razão" v-model="p.razao" autofocus></v-text-field>
                </v-flex>
              </v-layout>

              <v-layout row wrap>
                <v-flex sm6 class="mb-2">
                  <v-text-field box label="CNPJ" v-model="p.cnpj" mask="##.###.###/####-##"></v-text-field>
                </v-flex>

                <v-flex sm6 class="mb-2">
                  <v-text-field box label="Inscrição Municipal" v-model="p.inscricao_municipal"></v-text-field>
                </v-flex>

                <v-flex sm4>
                  <v-select
                    v-model="p.estado"
                    :items="estados"
                    box
                    label="Estado"
                    single-line
                  ></v-select>
                </v-flex>

                <v-flex sm8>
                  <v-autocomplete
                    v-model="p.codigo_municipio"
                    :items="atualizar_cidades_nfse(p.estado)"
                    box
                    label="Cidade"
                    single-line
                  ></v-autocomplete>
                </v-flex>


                <v-flex sm12 class="mb-2">
                  <v-text-field box label="Token Api NFSE" v-model="p.token" readonly></v-text-field>
                </v-flex>

              </v-layout>

            </v-container>

            <v-subheader style="height: 24px">Impostos</v-subheader>

            <v-container fluid grid-list-md>
              <v-layout>
                <v-flex sm3>
                  <z-campo-valor box dense label="Tributos" v-model="p.tributos" suffix="%"></z-campo-valor>
                </v-flex>
                <v-flex sm3>
                  <z-campo-valor box dense label="ISS" ref="iss" v-model="p.iss" suffix="%"></z-campo-valor>
                </v-flex>
                <v-flex sm3>
                  <z-campo-valor box dense label="CSLL" ref="csll" v-model="p.csll" suffix="%"></z-campo-valor>
                </v-flex>
                <v-flex sm3>
                  <z-campo-valor box dense label="COFINS" ref="cofins" v-model="p.cofins" suffix="%"></z-campo-valor>
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex sm3>
                  <z-campo-valor box dense label="PIS" ref="pis" v-model="p.pis" suffix="%"></z-campo-valor>
                </v-flex>
                <v-flex sm3>
                  <z-campo-valor box dense label="IR" ref="ir" v-model="p.ir" suffix="%"></z-campo-valor>
                </v-flex>
                <v-flex sm3>
                  <z-campo-valor box dense label="ICMS" ref="ir" v-model="p.icms" suffix="%"></z-campo-valor>
                </v-flex>
                <v-flex sm3>
                  <z-campo-valor box dense label="IBPT" ref="ibpt" v-model="p.ibpt" decimais="4"></z-campo-valor>
                </v-flex>
              </v-layout>

              <v-flex sm12 class="mb-2">
                <v-checkbox class="my-0 shrink" color="primary" label="Optante Simples Nacional" v-model="p.simples_nacional" hide-details></v-checkbox>
              </v-flex>
            </v-container>
        </v-form>
      </template>

      <template v-slot:actions_right>
        <v-btn flat color="secondary" @click="cancelar" :disabled="loading">Cancelar</v-btn>
        <v-btn flat color="primary" @click="confirmar" :loading="loading">Salvar</v-btn>
      </template>

  </z-dialogo>

</template>

<script>
  import emitentesApi from "@/services/emitentes"

  export default {
    props: {
      emitente: {
        type: Object
      }
    },

    computed: {
      estados() {
        return this.$store.getters.getEstados.map(estado => {
          return {
            'text': estado.sigla,
            'value': estado.sigla
          }
        });
      },
    },

    data() {
      return {
        loading: false,
        valido: false,
        p: {
          // id: null,
          razao: '',
          cnpj: '',
          inscricao_municipal: '',
          estado: '',
          codigo_municipio: null,
          token: '',
          simples_nacional: 0
        }
      }
    },

    mounted () {
      this.$store.commit('SET_TELA', 'Emitente');
    },

    methods: {

      abrir() {
        this.$refs.dialogo.abrir();
      },

      fechar() {
        this.$refs.dialogo.fechar();
      },

      confirmar() {
        if (this.p.id) {
          this.atualizar(this.p);
        } else {
          this.salvar(this.p);
        }
      },

      async salvar(emitente) {

        try {
          this.loading = true;
          const response = await emitentesApi.store(emitente);
          this.$emit('onSalvar', response);
          this.fechar();
        } catch (error) {
          console.log(error);
          this.$emit('onErro');
        } finally {
          this.loading = false;
        }

      },

      async atualizar(emitente) {

        try {
          this.loading = true;
          const response = await emitentesApi.update(emitente);
          this.$emit('onAtualizar', response);
          this.fechar();
        } catch (error) {
          console.log(error);
          this.$emit('onErro');
        } finally {
          this.loading = false;
        }

      },

      async remover(emitente) {

        try {
          this.loading = true;
          await emitentesApi.destroy(emitente.id);
          this.$emit('onRemover', emitente.id);
          this.fechar();
        } catch (error) {
          console.log(error);
          this.$emit('onErro');
        } finally {
          this.loading = false;
        }

      },

      cancelar() {
        this.$emit('onCancelar');
        this.fechar();
      },

      limparForm() {

        setTimeout(() => {
          // this.$refs.formulario.reset();

          this.p = {
            id: null,
            descricao: '',
            internet: false,
            velocidade: '10M',
            valor_internet: '0,00',
            telefonia: false,
            franquia: '100',
            valor_telefonia: '0,00',
            simples_nacional: 0
          }

        }, 500);
      },

      atualizar_cidades_nfse(estado) {
        const cidades_nfse = this.$store.getters.getCidades(estado)

        if (cidades_nfse) {
          return cidades_nfse.map(cidade => {
            return {
              'text': cidade.nome,
              'value': cidade.codigo
            }
          })
        }
      },
    },

    watch: {
      emitente(val) {
        if (val) {
          this.p = val;
        }
      }
    }
  }
</script>

<style scoped>
.container.grid-list-md .layout .flex{
    padding-top: 0px;
    padding-right: 4px;
    padding-bottom: 0px;
    padding-left: 4px;
}
</style>
