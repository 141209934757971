<template>
  <div>
      <v-app id="inspire" class="grey lighten-2">
          <v-container fill-height fluid>
              <v-layout justify-center align-center>
                  <v-flex xs12 sm6 md6 lg4 xl3>
                      <v-card elevation="24">
                          <!-- <v-card-text>
                              <v-layout justify-center>
                                  <img src="static/organize-verde/96x96.png">
                              </v-layout>
                          </v-card-text> -->

                          <v-container>
                              <v-form v-model="form_valido" ref="form">
                                <v-layout class="mb-5">
                                  <v-flex class="text-xs-center">
                                    <h1 class="headline">Acesso ao Sistema</h1>
                                  </v-flex>
                                </v-layout>
                                <v-layout class="mb-5">
                                  <v-flex class="text-xs-center">
                                    <v-avatar color="primary" size="120">
                                      <v-icon dark size="70">lock</v-icon>
                                    </v-avatar>
                                  </v-flex>
                                </v-layout>
                                <v-layout row wrap justify-center mt-3>
                                    <v-flex xs12 sm12 md12>
                                        <v-text-field
                                          v-model="usuario.email"
                                          box
                                          ref="usuario"
                                          name="usuario"
                                          color="primary"
                                          label="Usuário"
                                          prepend-inner-icon="person"
                                          :rules="regras.email"
                                          :error="erros.email.length > 0"
                                          :error-messages="erros.email"
                                          required autofocus
                                        ></v-text-field>
                                    </v-flex>
                                    <v-flex xs12 sm12 md12>
                                        <v-text-field
                                          v-model="usuario.password"
                                          box
                                          prepend-inner-icon="lock"
                                          ref="senha"
                                          name="senha"
                                          color="primary"
                                          label="Senha"
                                          type="password"
                                          :rules="regras.password"
                                          :error="erros.password.length > 0"
                                          :error-messages="erros.password"
                                          @keypress.enter="login"
                                          required
                                        ></v-text-field>
                                    </v-flex>
                                </v-layout>

                                <v-layout justify-center mt-3>
                                    <v-flex sm10 md5 lg4>
                                        <v-btn block color="primary" @click="login">Entrar</v-btn>
                                    </v-flex>
                                </v-layout>
                                <v-layout>
                                  <v-flex class="text-xs-center">
                                    <span>{{$config.APP_VERSION}}</span>
                                  </v-flex>
                                </v-layout>

                              </v-form>
                          </v-container>
                      </v-card>
                  </v-flex>
              </v-layout>
          </v-container>

          <z-aguardar ref="aguardar" color="primary"></z-aguardar>
      </v-app>
  </div>
</template>

<script>
// import UsuariosService from '../services/usuarios';

import config from "@/app/config";

export default {
  data() {
    return {
      usuario: {
        email: "",
        password: ""
      },
      erros: {
        email: [],
        password: []
      },
      dialog: false,
      form_valido: false,
      regras: {}
    };
  },
  mounted() {
    if (!config.IS_PRODUCTION) {
      this.usuario = {
        email: "admin",
        password: "81Adm97(&"
      };
    }
  },
  methods: {
    login() {
      // this.ativarRegras();

      this.$nextTick(() => {
        // if (this.$refs.form.validate()) {
        this.$refs.aguardar.abrir();
        this.zerarErros();
        this.$auth
          .login(this.usuario)
          .then(response => {
            this.limparForm();
            this.$router.push({ path: "/clientes" });
            this.$refs.aguardar.fechar();
          })
          .catch(error => {
            // this.limparForm();
            this.$refs.aguardar.fechar();
            this.erros.password.push("Usuário ou senha inválidos");
            this.$refs.senha.focus();
          });
        // }
      });
    },

    zerar() {
      this.usuario = {
        email: "",
        password: ""
      };
    },

    zerarErros() {
      this.erros = {
        email: [],
        password: []
      };
    },

    ativarRegras() {
      this.regras = {
        email: [
          v => !!v || "O campo email é obrigatório",
          v =>
            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
            "O campo e-mail é inválido"
        ],
        password: [
          v => !!v || "O campo senha é obrigatório"
          // (v) => v && v.length >= 4 || 'Mínimo 4 caracteres'
        ]
      };
    },

    processarErrosServidor(erros) {
      if (erros.email) {
        this.erros.email = erros.email;
        console.log("Erro no email: ", this.erros.email);
      }
      if (erros.password) {
        this.erros.password = erros.password;
        console.log("Erro no password");
      }
    },

    limparForm() {
      this.$refs.form.reset();
      this.regras = {};
    }
  }
};
</script>

<style scoped>
</style>
