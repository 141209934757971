<template>
  <div>

    <z-loading :loading="loading" height="100px" color="primary"></z-loading>

    <v-layout row wrap justify-center>
      <v-flex sm12 xl10>

        <v-tabs v-model="tab" color="transparent" v-show="!loading">
          <v-tab key="0">Cadastro</v-tab>
          <v-tab key="1" v-show="clienteId">Ramais</v-tab>
          <v-tab key="2" v-show="clienteId">Faturas</v-tab>
          <v-tabs-slider color="primary"></v-tabs-slider>


          <v-tabs-items v-model="tab" style="margin-top: 20px">

                <v-tab-item key="0">
                  <v-form v-model="form_valido" ref="form" lazy-validation>
                    <!-- <v-flex :grid-list-md="$vuetify.breakpoint.smAndDown"> -->
                    <v-container>
                      <v-card>
                        <v-card-title>
                          <span>Cliente</span>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-container fluid grid-list-md>
                          <v-layout row wrap>
                            <v-flex sm12>
                              <v-text-field box ref="nome" label="Razão Social/Cliente" name="razao" :rules="regras.razao" v-model="cliente.razao" @input="value => cliente.razao = value.toUpperCase()" autofocus required></v-text-field>
                            </v-flex>
                            <v-flex sm9>
                              <v-text-field box ref="fantasia" label="Nome Fantasia" :rules="regras.fantasia" v-model="cliente.fantasia" @input="value => cliente.fantasia = value.toUpperCase()"></v-text-field>
                            </v-flex>
                            <v-flex sm3>
                              <v-select box :items="globals.TIPO_CLIENTE" label="Tipo Cliente" v-model="cliente.tipo_cliente"></v-select>
                            </v-flex>
                            <v-flex sm2>
                              <v-select box :items="globals.TIPO_PESSOA" label="Pessoa" v-model="cliente.tipo_pessoa"></v-select>
                            </v-flex>
                            <v-flex sm3>
                              <z-cpf-cnpj box v-model="cliente.cpfcnpj" :rules="regras.cpfcnpj"></z-cpf-cnpj>
                            </v-flex>
                            <v-flex sm3>
                              <v-text-field box label="RG/Inscrição" v-model="cliente.rg_inscricao" @input="value => cliente.rg_inscricao = value.toUpperCase()"></v-text-field>
                            </v-flex>
                            <v-flex sm4>
                              <v-text-field box label="Contato" v-model="cliente.contato" @input="value => cliente.contato = value.toUpperCase()"></v-text-field>
                            </v-flex>
                          </v-layout>

                          <v-layout row wrap>
                            <v-flex sm12>
                              <v-textarea box label="Observações" v-model="cliente.observacoes" hide-details></v-textarea>
                            </v-flex>
                          </v-layout>
                        </v-container>
                      </v-card>
                    </v-container>

                    <v-container>
                      <v-card>
                        <v-card-title>
                          <span>Endereço</span>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-container fluid grid-list-md>
                          <v-layout row wrap>
                            <v-flex sm2>
                                <!-- <v-text-field label="CEP" v-model="cliente.cep" mask="#####-###" return-masked-value></v-text-field> -->
                                <z-cep box v-model="cliente.cep" buscarcep @resultado="preencherEndereco" :rules="[regras.obrigatorio]"></z-cep>
                            </v-flex>
                            <v-flex sm8>
                                <v-text-field box label="Endereço" v-model="cliente.endereco" :rules="[regras.obrigatorio]" @input="value => cliente.endereco = value.toUpperCase()"></v-text-field>
                            </v-flex>
                            <v-flex sm2>
                                <v-text-field box ref="numero" label="Número" v-model="cliente.numero" :rules="[regras.obrigatorio]"></v-text-field>
                            </v-flex>
                          </v-layout>
                          <v-layout row wrap>
                            <v-flex sm5>
                                <v-text-field box label="Bairro" v-model="cliente.bairro" @input="value => cliente.bairro = value.toUpperCase()" :rules="[regras.obrigatorio]"></v-text-field>
                            </v-flex>

                            <v-flex sm2>
                              <v-select box
                                v-model="cliente.estado"
                                :items="estados"
                                :rules="[regras.obrigatorio]"
                                label="Estado"
                                single-line
                              ></v-select>
                            </v-flex>

                            <v-flex sm5>
                              <v-autocomplete box
                                v-model="cliente.codigo_municipio"
                                :items="atualizar_cidades_nfse(cliente.estado)"
                                :rules="[regras.obrigatorio]"
                                label="Cidade"
                                single-line
                              ></v-autocomplete>
                            </v-flex>

                          </v-layout>
                          <v-layout row wrap>
                            <v-flex sm12>
                              <v-text-field box label="Complemento" v-model="cliente.complemento" maxlength="60" @input="value => cliente.complemento = value.toUpperCase()"></v-text-field>
                            </v-flex>
                          </v-layout>
                        </v-container>
                      </v-card>
                    </v-container>

                    <v-container>
                      <v-card>
                        <v-card-title>
                          <span>Contato</span>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-container fluid grid-list-md>
                          <v-layout row wrap>
                            <v-flex sm3>
                                <v-text-field box label="Telefone" v-model="cliente.telefone" mask="(##) ####-####" return-masked-value></v-text-field>
                            </v-flex>
                            <v-flex sm3>
                                <v-text-field box label="Celular" v-model="cliente.celular" mask="(##) #####-####" return-masked-value></v-text-field>
                            </v-flex>
                            <v-flex sm6>
                                <v-text-field box label="Email" :rules="regras.email" v-model="cliente.email" @input="value => cliente.email = value.toLowerCase()" required></v-text-field>
                            </v-flex>
                          </v-layout>
                        </v-container>
                      </v-card>
                    </v-container>

                    <v-container v-show="cliente.tipo_pessoa == 'J'">
                      <v-card>
                        <v-card-title>
                          Impostos
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-container fluid grid-list-md class="pt-0">
                          <v-layout row wrap>
                            <v-flex sm12>
                              <v-checkbox color="primary" label="Optante pelo Simples Nacional" v-model="cliente.simples" hide-details></v-checkbox>
                            </v-flex>
                          </v-layout>
                          <v-layout row wrap>
                            <v-flex sm12>
                              <v-checkbox color="primary" label="Calcular ISS" v-model="cliente.iss" hide-details></v-checkbox>
                            </v-flex>
                          </v-layout>
                          <v-layout row wrap>
                            <v-flex sm12>
                              <v-checkbox color="primary" label="Retem ISS" v-model="cliente.retem_iss" hide-details></v-checkbox>
                            </v-flex>
                          </v-layout>
                        </v-container>
                      </v-card>
                    </v-container>

                    <v-container>
                      <v-card>
                        <v-card-title>
                          Plano
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-container fluid grid-list-md>
                          <v-layout row wrap>
                            <v-flex xs12 md9>
                              <v-autocomplete box :items="listaplanos" label="Plano" v-model="cliente.plano_id"></v-autocomplete>
                            </v-flex>
                            <v-flex xs12 md3>
                              <v-text-field box label="Dia Vencimento" v-model="cliente.dia_vencimento"></v-text-field>
                            </v-flex>
                            <!-- <v-flex sm12>
                              Aqui vai a descrição do plano
                            </v-flex> -->
                          </v-layout>
                        </v-container>
                      </v-card>
                    </v-container>
                  </v-form>
                </v-tab-item>

                <v-tab-item key="1">
                  <v-container>
                    <v-layout row wrap>
                        <v-btn color="primary" class="mb-2 mr-0" @click="$refs.dialogoAdicionarRamal.abrir(); listarRamais()">
                          <v-icon left>add</v-icon> Adicionar
                        </v-btn>
                    </v-layout>

                    <v-container v-if="cliente.ramais.length > 0" grid-list-sm>
                      <v-layout row wrap>
                        <!-- <fade-transition group > -->
                          <v-flex sm3 v-for="(ramal, index) in cliente.ramais" :key="index">
                            <v-card>
                              <v-list>
                                <v-list-tile>
                                    <v-list-tile-avatar>
                                      <v-icon class="grey lighten-1 white--text">phone</v-icon>
                                    </v-list-tile-avatar>
                                    <v-list-tile-content>
                                      <v-list-tile-title>{{ ramal.ramal }}</v-list-tile-title>
                                    </v-list-tile-content>
                                    <v-list-tile-action>
                                      <v-btn icon ripple @click="removerRamal(ramal)">
                                        <v-icon class="grey--text text--lighten-1">delete</v-icon>
                                      </v-btn>
                                    </v-list-tile-action>
                                </v-list-tile>
                              </v-list>
                              <!-- <v-divider></v-divider>
                              <v-card-actions class="py-1">
                                  <v-spacer></v-spacer>
                                  <v-btn icon>
                                      <v-icon class="grey--text text--lighten-1">delete</v-icon>
                                  </v-btn>
                              </v-card-actions> -->
                            </v-card>
                          </v-flex>
                        <!-- </fade-transition> -->
                      </v-layout>
                    </v-container>

                    <v-layout v-else class="title" justify-center mt-5>Cliente não possui ramais cadastrados</v-layout>
                  </v-container>
                </v-tab-item>

                <v-tab-item key="2">
                  <v-container>
                    <cliente-faturas v-if="faturas.length > 0" :faturas="faturas"></cliente-faturas>
                    <v-layout v-else class="title" justify-center>Nenhuma fatura foi encontrada</v-layout>
                  </v-container>
                </v-tab-item>

          </v-tabs-items>
        </v-tabs>

        <v-container grid-list-xs v-if="!loading">
          <v-layout row wrap>
            <v-flex sm12>
              <v-checkbox color="primary" label="Ativo" v-model="cliente.ativo" hide-details></v-checkbox>
            </v-flex>
          </v-layout>
          <v-layout row wrap>
            <v-spacer></v-spacer>
            <v-btn flat color="secondary" @click="cancelar" :disabled="saving">Cancelar</v-btn>
            <v-btn color="primary" @click="confirmar" :loading="saving">Salvar</v-btn>
          </v-layout>
        </v-container>

      </v-flex>
    </v-layout>


    <!-- <v-speed-dial style="top: 80px" fixed :value="true" top right direction="bottom">
      <v-btn slot="activator" v-model="fab" color="blue darken-2" dark fab>
        <v-icon>account_circle</v-icon>
        <v-icon>close</v-icon>
      </v-btn>
      <v-btn fab dark small color="green">
        <v-icon>edit</v-icon>
      </v-btn>
      <v-btn fab dark small color="indigo">
        <v-icon>add</v-icon>
      </v-btn>
      <v-btn fab dark small color="red">
        <v-icon>delete</v-icon>
      </v-btn>
    </v-speed-dial> -->

    <!-- <v-btn style="top: 80px" color="primary" @click="confirmar" :loading="saving" dark fixed top right fab>
      <v-icon>save</v-icon>
    </v-btn>
    <v-btn style="top: 150px" color="grey darken-1" @click="cancelar" :loading="saving" small dark fixed top right fab>
      <v-icon>arrow_back</v-icon>
    </v-btn> -->

    <z-dialogo ref="dialogoAdicionarRamal" titulo="Adicionar ramal" largura="500px" scrollable altura="300px" dividers>
        <div slot="texto">
          <z-loading :loading="loadingRamais" height="100px" color="primary"></z-loading>
            <v-list v-if="!loadingRamais">
              <div v-for="(ramal, index) in ramais" :key="index">
                <v-list-tile>
                  <v-list-tile-avatar :size="32">
                    <v-icon class="grey lighten-1 white--text">phone</v-icon>
                  </v-list-tile-avatar>
                  <v-list-tile-content>
                    <v-list-tile-title>{{ ramal.ramal }}</v-list-tile-title>
                    <!-- <v-list-tile-sub-title>subTitle</v-list-tile-sub-title> -->
                  </v-list-tile-content>
                  <v-list-tile-action>
                      <v-btn icon ripple @click="adicionarRamal(ramal)">
                        <v-icon class="grey--text text--lighten-1">add</v-icon>
                      </v-btn>
                  </v-list-tile-action>
                </v-list-tile>
                <v-divider v-if="index != ramais.length - 1"></v-divider>
              </div>
            </v-list>
        </div>
        <!-- <v-btn color="primary darken-1" flat @click="confirmarExcluir" :loading="loading" :disabled="loading">Excluir</v-btn> -->
        <v-btn color="primary" @click="$refs.dialogoAdicionarRamal.fechar()" :disabled="loading">Fechar</v-btn>
    </z-dialogo>

    <z-dialogo ref="dialogoRemoverRamal" titulo="Deseja remover esse ramal?">
        <div slot="texto">
          {{this.ramal.ramal}}
        </div>
        <v-btn color="primary" flat @click="confirmarRemoverRamal" :loading="loadingExcluir">Remover</v-btn>
        <v-btn color="grey darken-1" flat @click="$refs.dialogoRemoverRamal.fechar()" :disabled="loadingExcluir">Cancelar</v-btn>
    </z-dialogo>

  </div>
</template>

<script>
  import ClienteFaturas from './ClienteFaturas'
  import ServiceClientes from '@/services/clientes'
  import validacoes from '@/utils/validacoes'
  import globals from '@/app/globals'

export default {

    components: {
      ClienteFaturas,
    },

    props: {
      clienteId: {
        type: [String, Number],
        default: null
      }
    },

    computed: {
      estados() {
        return this.$store.getters.getEstados.map(estado => {
          return {
            'text': estado.sigla,
            'value': estado.sigla
          }
        });
      },

      listaplanos() {
        return this.planos.map(plano => {
          const valor_internet = this.valorBR(plano.valor_internet);
          const valor_telefonia = this.valorBR(plano.valor_telefonia);
          let descricao = `${plano.descricao} (`;
          if (plano.internet) {
            descricao = `${descricao}Internet: ${valor_internet}`;
          }

          if (plano.internet && plano.telefonia) {
            descricao = `${descricao} - `;
          }

          if (plano.telefonia) {
            descricao = `${descricao}Telefonia: ${valor_telefonia}`;
          }

          descricao = descricao.concat(')');

          return {
            value: plano.id,
            text: descricao
          }
        })
      }
    },

    data() {
      return {
        globals,
        cliente: {
          razao: '',
          ramais: [],
          tipo_pessoa: 'J',
          estado: 'PE',
          cidade: 'CARUARU',
          tipo_cliente: 'empresarial'
        },
        loading: false,
        loadingRamais: false,
        loadingExcluir: false,
        saving: false,
        form_valido: false,
        tab: null,
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        planos: [],
        faturas: [],
        ramais: [],
        ramal: {},
        tipo_pessoa: [{text: 'Física', value: 'F'}, {text: 'Jurídica', value: 'J'}],

        regras: {
          razao: [
            v => !!v || 'Não pode ser vazio',
            v => (v && v.length >= 10) || 'Tamanho mínimo de 10 caracteres'
          ],
          cpfcnpj: [
            v => !!v || 'Não pode ser vazio',
            v => validacoes.validarCPFCNPJ(v) || 'CPF ou CNPJ inválido'
          ],

          obrigatorio: v => !!v || 'Campo obrigatório'
          // email: [
          //   v => !!v || 'O e-mail precisa ser preenchido',
          //   v => /.+@.+/.test(v) || 'E-mail inválido'
          // ]
        },

        from_route: {
          name: '',
          params: {}
        }
      }
    },

    beforeRouteEnter(to, from, next) {
      console.log(from)
      next(vm => {
        if (from.name) {
          vm.from_route = from;
        } else {
          vm.from_route.name = 'ClientesLista'
        }
      });
    },

    async mounted() {
      this.$store.commit('SET_TELA', 'Clientes');
      if (this.clienteId) {
        await this.abrirCliente();
        this.listarFaturas();
        // this.listarRamais();
      } else {
        this.$store.commit('clientes/RESET_CLIENTE');
      }
      this.listarPlanos();
      this.setFocus('nome');

    },

    methods: {
      async abrirCliente() {
        try {
          this.loading = true;
          this.cliente = await ServiceClientes.abrirCliente(this.clienteId);
          // await this.$store.dispatch('clientes/abrirCliente', this.clienteId);
          // this.cliente = this.clone(this.$store.state.clientes.cliente);
        } catch(error) {
          this.$router.push('/clientes');
        } finally {
          this.loading = false;
        }
      },

      async listarPlanos() {
        try {
          this.planos  = await ServiceClientes.listarPlanos();
        } catch (error) {
          // TODO
        }
      },

      async listarFaturas() {
        try {
          this.faturas  = await ServiceClientes.listarFaturas(this.clienteId);
        } catch (error) {
          // TODO
        }
      },

      async listarRamais() {
        try {
          this.ramais = [];
          this.loadingRamais = true;
          this.ramais = await ServiceClientes.listarRamais();
        } finally {
          this.loadingRamais = false;
        }
      },

      async adicionarRamal(ramal) {
        try {
          await ServiceClientes.adicionarRamal(this.clienteId, ramal);
          let indice = this.ramais.findIndex(r => r.id = ramal.id);
          this.ramais.splice(indice, 1);
          this.cliente.ramais.push(ramal);
        } catch (error) {
          // TODO
        }
      },

      removerRamal(ramal) {
        this.ramal = ramal;
        this.$refs.dialogoRemoverRamal.abrir();
      },

      async confirmarRemoverRamal() {
        try {
          this.loadingExcluir = true;
          await ServiceClientes.removerRamal(this.ramal.id);
          this.$refs.dialogoRemoverRamal.fechar();
          let indice = this.cliente.ramais.findIndex(r => r.id == this.ramal.id);
          this.cliente.ramais.splice(indice, 1);
          // this.ramal = null;
        } finally {
          this.loadingExcluir = false;
        }
      },

      confirmar() {
        if (this.$refs.form.validate()) {
          if (this.cliente.tipo_pessoa == 'F') {
            this.cliente.simples = true;
            this.cliente.iss = false;
          }

          if (!this.clienteId) {
              this.salvar(this.cliente);
          } else {
            this.atualizar(this.cliente);
          }
        }
      },

      cancelar() {
        this.$router.push(this.from_route);
      },

      async salvar() {
        try {
          this.saving = true;

          await ServiceClientes.salvar(this.cliente);
          this.$router.push(this.from_route);
        } finally {
          this.saving = false;
        }
      },

      async atualizar(cliente) {
        try {
          this.saving = true;
          await ServiceClientes.atualizar(this.cliente);
          this.$router.push(this.from_route);
        } finally {
          this.saving = false;
        }
      },

      preencherEndereco(endereco) {
          if (endereco.erro) {
              this.cliente.endereco = '';
              this.cliente.bairro   = '';
              this.cliente.cidade   = '';
              this.cliente.estado   = '';
              this.cliente.ibge  = '';
          } else {
              this.cliente.endereco         = endereco.logradouro.toUpperCase();
              this.cliente.bairro           = endereco.bairro.toUpperCase();
              this.cliente.cidade           = endereco.localidade.toUpperCase();
              this.cliente.estado           = endereco.uf.toUpperCase();
              this.cliente.codigo_municipio = Number(endereco.ibge);
              this.$refs.numero.focus();
          }
      },

      validacoes() {
      },

      atualizar_cidades_nfse(estado) {
        const cidades_nfse = this.$store.getters.getCidades(estado)

        if (cidades_nfse) {
          return cidades_nfse.map(cidade => {
            return {
              'text': cidade.nome,
              'value': cidade.codigo
            }
          })
        }
      },

    },

  }
</script>

<style scoped>

.container.grid-list-md .layout .flex{
    padding-top: 0px;
    padding-right: 4px;
    padding-bottom: 0px;
    padding-left: 4px;
}

</style>
